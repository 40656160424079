import { ApplicationState } from 'rootReducer'

type LoadingState = 'REQUEST' | 'SUCCESS' | 'FAILURE'
export interface LoadingReducerState {
  [key: string]: LoadingState
}

interface GenericReduxAction {
  type: string
  requestId?: string
  payload?: object
}

export const initialState: LoadingReducerState = {}
export const makeRequestKey = (requestName: string, requestId?: string) => {
  return requestId ? `${requestName}#${requestId}` : requestName
}

/* Taken from https://link.medium.com/qQRJEwQdHT
 *
 * Extended to allow a set of actions to opt-in to per-request loading states
 * by including a requestId key in the action. This will be appended to the
 * request name in the reducer state and must be included when creating
 * selectors, using the makeRequestKey helper function
 *
 */
const loadingReducer = (
  state: LoadingReducerState = initialState,
  action: GenericReduxAction
): LoadingReducerState => {
  const { type, requestId } = action
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)$/.exec(type)

  // not a *_REQUEST / *_SUCCESS /  *_FAILURE actions, so we ignore them
  if (!matches) return state

  const [, requestName, requestState] = matches

  const key = makeRequestKey(requestName, requestId)
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST
    //      and false when receiving GET_TODOS_SUCCESS / GET_TODOS_FAILURE
    [key]: requestState as LoadingState,
  }
}

/* Return true only when all actions are no longer loading */
export const makeLoadingSelector = (actions: string[]) => (state: ApplicationState): boolean =>
  actions.some((action) => state.loading[action] === 'REQUEST')

/* Return true when all actions are successful */
export const makeSuccessSelector = (actions: string[]) => (state: ApplicationState): boolean =>
  actions.every((action) => state.loading[action] === 'SUCCESS')

/* Return true when any action has failed */
export const makeFailureSelector = (actions: string[]) => (state: ApplicationState): boolean =>
  actions.some((action) => state.loading[action] === 'FAILURE')

export default loadingReducer
