import React, { Fragment, useState, useCallback, useEffect } from 'react'
import { Header, Segment } from 'semantic-ui-react'
import PlanCard, { PlaceholderPlanCard } from 'common/ProductCard'
import LinkButton from 'common/LinkButton'
import { Product, ProductsReducerState } from 'common/Products/types'
import { useBoolean } from 'common/hooks'
import { makePlanSelector } from 'pages/ActivatePage/Stages/SelectPlan/PlanSelector'
import { getAllPlansWithCondition } from 'common/Products/reducer'
import { makeLoadingSelector } from 'common/Loading/reducer'

const PlanSelector = makePlanSelector(
  (state) => getAllPlansWithCondition(state, (p) => !p.new_customer_only),
  (state) => makeLoadingSelector(['GET_PRODUCTS'])(state)
)

interface PlanSelectionFormProps {
  plans: ProductsReducerState
  previousPlan?: Product
  onPlanChange: (plan?: Product) => void
}

const PlanSelectionForm = ({ plans, previousPlan, onPlanChange }: PlanSelectionFormProps) => {
  const [selectedPlan, setSelectedPlan] = useState<Product>()
  const [planSelectorVisible, showPlanSelector, hidePlanSelector] = useBoolean(false)

  let isNewPlan = false
  if (selectedPlan && previousPlan) {
    isNewPlan = selectedPlan.unique_id !== previousPlan.unique_id
  }

  useEffect(() => {
    setSelectedPlan(previousPlan)
  }, [previousPlan])

  const onChange = useCallback(
    (event: any, data: { value: string }) => {
      const newPlan = plans.byUniqueId[data.value]
      setSelectedPlan(newPlan)
      onPlanChange(newPlan)
      hidePlanSelector()
    },
    [plans]
  )

  const deselectAndShowPlanSelector = useCallback(() => {
    onPlanChange()
    showPlanSelector()
  }, [setSelectedPlan, showPlanSelector])

  return (
    <Fragment>
      {!planSelectorVisible && (
        <Fragment>
          {isNewPlan ? (
            <Header as="h3" color="grey">
              Selected Prepay Plan
            </Header>
          ) : (
            <Header as="h3" color="grey">
              Your Current Prepay Plan
            </Header>
          )}
          {selectedPlan ? (
            <PlanCard horizontal key={selectedPlan.unique_id} plan={selectedPlan} active />
          ) : (
            <PlaceholderPlanCard horizontal />
          )}
          <Segment basic textAlign="center">
            <LinkButton secondary onClick={deselectAndShowPlanSelector}>
              Choose Another Prepay Plan
            </LinkButton>
          </Segment>
        </Fragment>
      )}
      {planSelectorVisible && (
        <Fragment>
          <PlanSelector name="plan" onChange={onChange} errors={[]} />
        </Fragment>
      )}
    </Fragment>
  )
}

export default PlanSelectionForm
