// Polyfills
import 'ts-polyfill/lib/es2015-collection'
import 'ts-polyfill/lib/es2015-core'
import 'ts-polyfill/lib/es2015-iterable'
import 'ts-polyfill/lib/es2015-promise'
import 'ts-polyfill/lib/es2016-array-include'
import 'ts-polyfill/lib/es2017-object'
import 'ts-polyfill/lib/es2017-string'
import 'ts-polyfill/lib/es2018-promise'

// Dependencies
import axios from 'axios'
import React from 'react'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'

// App
import App from './App'
import configureStore from './configureStore'
import initSegment from './segment'
import initSentry from './sentry'
// semantic-ui-less
import './styles'

const store = configureStore()

// Init External Libraries
initSentry(
  'https://8605594919fe4f5392793edbe60a17a4@sentry.io/1414979',
  process.env.HEROKU_APP_NAME
)
if (window && (window as any).SEGMENT_WRITE_KEY) {
  const host = (window as any).SEGMENT_CDN_HOST_NAME
  initSegment((window as any).SEGMENT_WRITE_KEY, host)
} else if (window && window.console) {
  window.console.error('Segment Write Key is undefined')
}

// Default Config
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'
axios.defaults.xsrfCookieName = 'csrftoken'

// First time render
ReactDOM.render(
  <AppContainer>
    <App store={store} />
  </AppContainer>,
  document.getElementById('root')
)

// Hot Reload Module API
if (module.hot) {
  module.hot.accept('./App', () => {
    import('./App').then(({ default: NextApp }) => {
      ReactDOM.render(
        <AppContainer>
          <NextApp store={store} />
        </AppContainer>,
        document.getElementById('root')
      )
    })
  })
}
