import React, { Fragment } from 'react'
import { Colours } from 'common/Utils'
import styled from 'styled-components'

const SmallP = styled.p`
  margin-top: 1em;
  color: ${Colours.GREY};
`
export default SmallP

interface SmallTextInterface {
  buttonText: string
  rebillPrice?: number
}

interface CreditCardSmallText extends SmallTextInterface {
  action: string
}

export const CreditCardSmallText = ({ rebillPrice, buttonText, action }: CreditCardSmallText) => {
  let rebillPriceText: any = ''
  if (rebillPrice) {
    rebillPriceText = (
      <Fragment>
        at <strong>${rebillPrice.toFixed(2)}</strong>{' '}
      </Fragment>
    )
  }
  return (
    <p className="fine-print">
      By selecting {buttonText} you agree to the{' '}
      <a href="//www.koganmobile.co.nz/terms-conditions/" target="_blank" rel="noopener noreferrer">
        Terms and Conditions
      </a>
      . You also agree that your selected credit or debit card will be charged for {action} your
      selected Prepay Plan, and that your next renewal, and any subsequent renewals, will be
      automatically charged to your selected credit or debit card {rebillPriceText}in New Zealand
      dollars. You can turn Automatic Renewal on and off at any time before your planned renewal day
      via the customer dashboard. Learn more about Automatic Renewal{' '}
      <a href="//www.koganmobile.co.nz/faq-auto-renewal/" target="_blank" rel="noopener noreferrer">
        here
      </a>
      .
    </p>
  )
}

export const VoucherSmallText = ({ rebillPrice, buttonText }: SmallTextInterface) => {
  let rebillPriceText: any = ''
  if (rebillPrice) {
    rebillPriceText = (
      <Fragment>
        at <strong>${rebillPrice.toFixed(2)}</strong>{' '}
      </Fragment>
    )
  }
  return (
    <p className="fine-print">
      By selecting {buttonText} you agree to the{' '}
      <a href="//www.koganmobile.co.nz/legal/" target="_blank" rel="noopener noreferrer">
        Terms and Conditions
      </a>
      . You also agree to use your voucher to pay for your selected Prepay Plan, and that your next
      renewal, and any subsequent renewals, will be automatically charged to your selected credit or
      debit card {rebillPriceText}in New Zealand dollars. You can turn Automatic Renewal on and off
      at any time before your planned renewal day via the customer dashboard. Learn more about
      Automatic Renewal{' '}
      <a href="//www.koganmobile.co.nz/faq-auto-renewal/" target="_blank" rel="noopener noreferrer">
        here
      </a>
      .
    </p>
  )
}
