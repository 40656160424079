import React, { Fragment } from 'react'
import { Grid, SemanticCOLORS } from 'semantic-ui-react'
import styled from 'styled-components'
import { Product } from 'common/Products/types'

interface PlanRowProps {
  length: number
  price: number
  rrp: number
}

interface GroupedPlanProps {
  plans: Product[]
  color: SemanticCOLORS
  groupSize: number
  groupTitle: string
}

const UnpaddedGridColumn = styled(Grid.Column)`
  .grid-card .ui.grid .row.content .column& {
    padding-left: 0;
    padding-right: 0;
  }
`

const PlanRow = ({ length, price, rrp }: PlanRowProps) => {
  const isMonthlyPlan = length === 30
  const monthlyPrice = (price / length) * 30
  const showSpecialPrice = !!rrp

  const upfrontPriceColumn = () => (
    <Fragment>
      {showSpecialPrice && (
        <Fragment>
          <div>
            <span className="tablePrice">
              <strong>${price.toFixed(2)}</strong>
            </span>
          </div>
          <div>
            <span className="old-value">${rrp.toFixed(2)}</span>
          </div>
        </Fragment>
      )}
      {!showSpecialPrice && (
        <span>
          <strong>${price.toFixed(2)}</strong>
        </span>
      )}
    </Fragment>
  )

  return (
    <Fragment>
      <Grid.Row className="content" verticalAlign="middle">
        <Fragment>
          <Grid.Column width={5} textAlign="left">
            {length} days
          </Grid.Column>
          <UnpaddedGridColumn
            className={showSpecialPrice ? 'highlight' : ''}
            width={6}
            textAlign="center"
          >
            {!isMonthlyPlan && <div className="subtitle">Works out to</div>}
            <span className="tablePrice">${monthlyPrice.toFixed(2)}</span>
            <span className="subtitle">{isMonthlyPlan ? ' for 30 days' : ' per 30 days'}</span>
          </UnpaddedGridColumn>
          <Grid.Column width={5} className={showSpecialPrice ? 'highlight' : ''} textAlign="right">
            {upfrontPriceColumn()}
          </Grid.Column>
        </Fragment>
      </Grid.Row>
    </Fragment>
  )
}

const GroupedPlanCard = ({ plans, color, groupSize, groupTitle }: GroupedPlanProps) => (
  <div className="grid-card borderless">
    <Grid columns={16}>
      <Grid.Row className="content dark-overlay" color={color}>
        <Grid.Column mobile={9} tablet={9} computer={8} verticalAlign="bottom">
          <strong>
            <span className="headline">{groupSize}GB</span> per 30 days
          </strong>
        </Grid.Column>
        <Grid.Column mobile={7} tablet={7} computer={8} verticalAlign="bottom" textAlign="right">
          <strong>{groupTitle}</strong>
        </Grid.Column>
      </Grid.Row>
      {plans.map((plan) => (
        <PlanRow
          key={plan.length}
          length={plan.length}
          price={Number(plan.price)}
          rrp={Number(plan.rrp)}
        />
      ))}
    </Grid>
  </div>
)

export default GroupedPlanCard
