const preloadImage = (path: string) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve()
    img.onerror = () => reject()
    img.src = path
  })

export default function preloadImages(paths: string[]) {
  return Promise.all(paths.map(preloadImage))
}
