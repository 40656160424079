import axios from 'axios'
import { ApplicationState } from 'rootReducer'
import { getPurchaseHistoryByUuid } from 'pages/PurchaseHistoryPage/reducer'
import { Uuid } from 'src/types'
import { PurchaseHistory, InPurchaseHistory } from './types'

export enum PurchaseHistoryActionTypes {
  GET_PURCHASE_HISTORY_REQUEST = 'DASHBOARD_GET_PURCHASE_HISTORY_REQUEST',
  GET_PURCHASE_HISTORY_SUCCESS = 'DASHBOARD_GET_PURCHASE_HISTORY_SUCCESS',
  GET_PURCHASE_HISTORY_FAILURE = 'DASHBOARD_GET_PURCHASE_HISTORY_FAILURE',
}

export interface GetPurchaseHistoryAction {
  type: PurchaseHistoryActionTypes
  payload: {
    uuid: Uuid
    data?: PurchaseHistory[]
  }
}

export const getPurchaseHistoryRequest = (uuid: Uuid): GetPurchaseHistoryAction => ({
  type: PurchaseHistoryActionTypes.GET_PURCHASE_HISTORY_REQUEST,
  payload: { uuid },
})
export const getPurchaseHistoryFailure = (uuid: Uuid): GetPurchaseHistoryAction => ({
  type: PurchaseHistoryActionTypes.GET_PURCHASE_HISTORY_FAILURE,
  payload: { uuid },
})
export const getPurchaseHistorySuccess = (
  uuid: Uuid,
  data: PurchaseHistory[]
): GetPurchaseHistoryAction => ({
  type: PurchaseHistoryActionTypes.GET_PURCHASE_HISTORY_SUCCESS,
  payload: { uuid, data },
})

const convertDates = (data: InPurchaseHistory[]): PurchaseHistory[] => {
  return data.map(
    (entry: InPurchaseHistory): PurchaseHistory => ({
      created: new Date(entry.created),
      order_code: entry.order_code,
      total_price: entry.total_price,
      document_type: entry.document_type,
    })
  )
}

export const fetchPurchaseHistory = (uuid: Uuid, cache: boolean = true) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    // purchase history isn't going to change per request, so we'll cache it in redux
    const state = getState()
    if (cache && getPurchaseHistoryByUuid(uuid)(state)) return

    dispatch(getPurchaseHistoryRequest(uuid))
    axios
      .get(`/api/account/invoices/${uuid}/`)
      .then((data) => {
        dispatch(getPurchaseHistorySuccess(uuid, convertDates(data.data)))
      })
      .catch(() => dispatch(getPurchaseHistoryFailure(uuid)))
  }
}
