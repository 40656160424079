import React from 'react'
import { Form } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'

interface Props {
  options: DropDownItem[]
  loading: boolean
  label: string
  name: string
  onChange: () => void
  value: string | number
  disabled: boolean
  tabIndex: number
  required: boolean
  errors?: string[]
}

export const Dropdown = ({
  options,
  required,
  loading,
  label,
  name,
  onChange,
  value,
  disabled,
  tabIndex,
  errors,
}: Props) => {
  return (
    <Form.Select
      options={options}
      id={name}
      key={name}
      name={name}
      onChange={onChange}
      value={value}
      disabled={disabled}
      tabIndex={tabIndex}
      loading={loading}
      label={label}
      required={required}
      error={errors && !!errors.length}
    />
  )
}

export interface DropDownItem {
  key: string
  text: string
  value: string | number
}

export type GetItems = (state: ApplicationState) => DropDownItem[]
export type GetLoading = (state: ApplicationState) => boolean

export const makeDropdown = (getItems: GetItems, getLoading: GetLoading) => {
  return connect(
    (state: ApplicationState) => ({
      loading: getLoading(state),
      options: getItems(state),
    }),
    () => ({})
  )(Dropdown)
}
