import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Header, Responsive } from 'semantic-ui-react'
import styled from 'styled-components'
import { ApplicationState } from 'rootReducer'
import FormattedCurrency from 'common/FormattedCurrency'
import AnimatedTransition from 'common/AnimatedTransition'
import { Caption } from 'common/Typography'
import { ProductCard, BottomlessHeader } from './index'
import { AddonCardProps } from './types'

const ENTITLEMENT_KEYS = ['data_gb', 'voice_mins', 'txt_sms']

export const EntitlementSpacer = styled.span`
  width: 5px;
  display: inline-block;
`

const AddonCard: React.FC<AddonCardProps> = ({
  addon,
  horizontal,
  onClick,
  linkText,
  linkUrl,
  ...props
}) => {
  const extraProps: any = {}
  const onClickWithPlan = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      if (onClick) {
        onClick(event, addon)
      }
    },
    [onClick, addon]
  )
  if (onClick) {
    extraProps.onClick = onClickWithPlan
  }

  let data_value = addon.data_gb
  let data_unit = 'GB'
  if (addon.data_gb < 1) {
    data_unit = 'MB'
    data_value *= 1000
  }

  const hasMultipleEntitlements =
    Object.keys(addon).filter((key) => {
      return ENTITLEMENT_KEYS.includes(key) && addon[key] > 0
    }).length > 1
  return (
    <ProductCard horizontal={horizontal} {...props} {...extraProps}>
      <ProductCard.Header color={addon.colour}>
        <Header as="h4">{addon.title}</Header>
      </ProductCard.Header>
      <ProductCard.Content>
        <BottomlessHeader forwardedAs="h3">
          {data_value > 0 && `${data_value} ${data_unit}`}
          <EntitlementSpacer />
          {addon.voice_mins > 0 && `${addon.voice_mins} Mins`}
          <EntitlementSpacer />
          {addon.txt_sms > 0 && `${addon.txt_sms} SMS/MMS`}
        </BottomlessHeader>
        {addon.roaming && linkText && linkUrl && (
          <Caption>
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkText}
            </a>
          </Caption>
        )}
        {/* Add padding if it doesn't go over 2 lines */}
        {addon.roaming && !hasMultipleEntitlements && (
          <Responsive as="p" minWidth={Responsive.onlyTablet.minWidth}>
            <br />
          </Responsive>
        )}
      </ProductCard.Content>
      <ProductCard.Content header={`For ${addon.length} days`} />
      <AnimatedTransition transitionOnMount animation="fade" duration={{ show: 300, hide: 0 }}>
        <Header as="h1" color={addon.rrp ? 'red' : undefined}>
          <FormattedCurrency value={parseFloat(addon.price)} />
        </Header>
      </AnimatedTransition>
    </ProductCard>
  )
}

export default connect((state: ApplicationState) => ({
  linkText: state.layout.roaming_link_text,
  linkUrl: state.layout.roaming_link_url,
}))(AddonCard)
