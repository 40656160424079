import Form, { FieldInterface } from 'common/Form'
import LinkButton from 'common/LinkButton'
import { API_AUTH_BASE } from 'common/UserContainer/actions'
import React, { Fragment } from 'react'
import { Divider, Header, Segment } from 'semantic-ui-react'

export const LOGIN_FORM_ID = 'login'

const fields: FieldInterface[] = [
  {
    name: 'username',
    icon: 'user circle',
    iconPosition: 'left',
    placeholder: 'E-mail',
    type: 'email',
    required: true,
    autocomplete: 'username',
  },
  {
    name: 'password',
    icon: 'lock',
    iconPosition: 'left',
    placeholder: 'Password',
    type: 'password',
    required: true,
    autocomplete: 'current-password',
  },
]

const LoginForm = () => (
  <Fragment>
    <Header as="h1" textAlign="left">
      Welcome Back!
      <Header.Subheader>Sign In to your Kogan Mobile Account</Header.Subheader>
    </Header>
    <Segment vertical basic textAlign="left">
      <Form
        id={LOGIN_FORM_ID}
        size="large"
        fields={fields}
        submitButtonText="LOGIN"
        submitButtonIcon="arrow right"
        url={`${API_AUTH_BASE}login/`}
      />
    </Segment>
    <Segment vertical basic textAlign="center">
      <LinkButton to="/forgot-password/">Forgot Password</LinkButton>
    </Segment>
    <Divider horizontal content="or" />
    <Segment vertical basic textAlign="center">
      <Fragment>
        <LinkButton to="/activate/">Activate your SIM</LinkButton>
      </Fragment>
    </Segment>
  </Fragment>
)

export default LoginForm
