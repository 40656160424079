import axios, { AxiosResponse } from 'axios'
import { User } from './reducer'

export const API_AUTH_BASE = '/api/account/'
export const API_PASSWORD_RESET_BASE = '/api/forgot_password_reset/'

export enum RequestActionTypes {
  USER_AUTHENTICATED_REQUEST = 'USER_AUTHENTICATED_REQUEST',
  USER_LOGOUT_REQUEST = 'USER_LOGOUT_REQUEST',
  RESEND_VERIFY_REQUEST = 'RESEND_VERIFY_REQUEST',
}

export enum FailureActionTypes {
  USER_AUTHENTICATED_FAILURE = 'USER_AUTHENTICATED_FAILURE',
  USER_LOGOUT_FAILURE = 'USER_LOGOUT_FAILURE',
  RESEND_VERIFY_FAILURE = 'RESEND_VERIFY_FAILURE',
}

export enum SuccessActionTypes {
  USER_AUTHENTICATED_SUCCESS = 'USER_AUTHENTICATED_SUCCESS',
  USER_LOGOUT_SUCCESS = 'USER_LOGOUT_SUCCESS',
  RESEND_VERIFY_SUCCESS = 'RESEND_VERIFY_SUCCESS',
}

export enum RequestTypes {
  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  USER_LOGOUT = 'USER_LOGOUT',
  RESEND_VERIFY = 'RESEND_VERIFY',
}

interface RequestOrFailureAction {
  type: RequestActionTypes | FailureActionTypes
}

interface SuccessAction {
  type: SuccessActionTypes
  payload: any
}

export interface UserAuthenticatedSuccessAction extends SuccessAction {
  type: SuccessActionTypes.USER_AUTHENTICATED_SUCCESS
  payload: {
    authenticated: boolean
    user?: User
  }
}

export type AuthenticatedActions =
  | RequestOrFailureAction
  | SuccessAction
  | UserAuthenticatedSuccessAction

export function requestAction(request: RequestTypes): RequestOrFailureAction {
  return {
    type: `${request}_REQUEST` as RequestActionTypes,
  }
}

export function failureAction(request: RequestTypes): RequestOrFailureAction {
  return {
    type: `${request}_FAILURE` as FailureActionTypes,
  }
}

export function successAction(request: RequestTypes, payload: any): SuccessAction {
  return {
    type: `${request}_SUCCESS` as SuccessActionTypes,
    payload,
  }
}

export const isAuthenticatedSuccess = (
  authenticated: boolean,
  user?: User
): UserAuthenticatedSuccessAction => ({
  type: SuccessActionTypes.USER_AUTHENTICATED_SUCCESS,
  payload: {
    authenticated,
    user,
  },
})

export function isAuthenticated() {
  return (dispatch: Function) => {
    dispatch(requestAction(RequestTypes.USER_AUTHENTICATED))
    axios
      .get(API_AUTH_BASE)
      .then((response: AxiosResponse) => {
        dispatch(isAuthenticatedSuccess(response.data.authenticated, response.data.user))
      })
      .catch(() => {
        dispatch(failureAction(RequestTypes.USER_AUTHENTICATED))
      })
  }
}

export function logout() {
  return (dispatch: Function) => {
    dispatch(requestAction(RequestTypes.USER_LOGOUT))
    axios
      .post(`${API_AUTH_BASE}logout/`)
      .then(() => {
        dispatch(successAction(RequestTypes.USER_LOGOUT, {}))
      })
      .catch(() => {
        dispatch(failureAction(RequestTypes.USER_LOGOUT))
      })
  }
}

export function resendVerification() {
  return (dispatch: Function) => {
    dispatch(requestAction(RequestTypes.RESEND_VERIFY))
    axios
      .get(`${API_AUTH_BASE}resend_verification/`)
      .then(() => {
        dispatch(successAction(RequestTypes.RESEND_VERIFY, {}))
      })
      .catch(() => {
        dispatch(failureAction(RequestTypes.RESEND_VERIFY))
      })
  }
}
