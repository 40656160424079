import React from 'react'
import { Accordion } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { Caption } from 'common/Typography'

interface PlanTermsAccordionProps {
  termsText: string
}

const PlanTermsAccordion: React.FC<PlanTermsAccordionProps> = ({ termsText }) => (
  <p>
    <Accordion
      panels={[
        {
          key: 'help',
          title: {
            content: 'See Prepay Plan Terms & Conditions',
            as: 'h4',
            className: 'ui',
          },
          content: {
            content: <Caption as="div" dangerouslySetInnerHTML={{ __html: termsText }} />,
          },
        },
      ]}
    />
  </p>
)

export default connect((state: ApplicationState) => ({
  termsText: state.layout.plan_selection_terms,
}))(PlanTermsAccordion)
