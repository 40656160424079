import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Segment, Header, Image } from 'semantic-ui-react'

/* eslint-disable @typescript-eslint/no-var-requires */
const genericSim = require('static/img/generic-sim-number.png')

const GenericSimImage = styled(Image)`
  margin: 1em;
`

const CustomSegment = styled(Segment)`
  &&& {
    margin-top: 1em;
  }
`

const SimInfo = ({ method }: { method: number }) => (
  <CustomSegment raised basic padded>
    {method === 1 && (
      <Fragment>
        <Header as="h3">How to find your SIM Number</Header>
        <p>You can find your old SIM Number on the physical SIM card itself.</p>

        <GenericSimImage src={genericSim} centered />

        <p>
          Remove your old SIM card from your phone and read the SIM Number which is printed on the
          SIM card. This number should be between 16 and 20 digits, and will start with either an 89
          or 64.
        </p>

        <p>
          If your SIM card number is not visible, please contact your current provider to retrieve
          it.
        </p>
      </Fragment>
    )}
    {method === 2 && (
      <Fragment>
        <Header as="h3"> How to find your Account Number</Header>
        <p>You can find the account number on your bill.</p>

        <p>
          If you currently top-up, please select &quot;Prepay&quot; instead of &quot;Pay Monthly
          (Account)&quot;.
        </p>
      </Fragment>
    )}
  </CustomSegment>
)

export default SimInfo
