import { PureComponent, ReactNode } from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { makeLoadingSelector, makeSuccessSelector } from 'common/Loading/reducer'
import * as userActions from 'common/UserContainer/actions'

interface ChildrenRenderProps {
  isSending: boolean
  success: boolean
  sendVerification(): void
}

interface UserContainerProps {
  children(arg0: ChildrenRenderProps): ReactNode
  isSending: boolean
  success: boolean
  sendVerification(): void
}

class VerificationEmailContainer extends PureComponent<UserContainerProps> {
  render() {
    const { children, sendVerification, isSending, success } = this.props
    return children({
      isSending,
      sendVerification,
      success,
    })
  }
}

const verificationEmailSendingSelector = makeLoadingSelector(['RESEND_VERIFY'])
const verificationEmailSuccessSelector = makeSuccessSelector(['RESEND_VERIFY'])
const mapStateToProps = (state: ApplicationState) => {
  return {
    isSending: verificationEmailSendingSelector(state),
    success: verificationEmailSuccessSelector(state),
  }
}

const ConnectedVerificationEmailContainer = connect(mapStateToProps, {
  sendVerification: userActions.resendVerification,
})(VerificationEmailContainer)

export default ConnectedVerificationEmailContainer
