import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import { Segment } from 'semantic-ui-react'

import { ApplicationState } from 'rootReducer'

import { User } from 'common/UserContainer/reducer'
import DashboardContentContainer from 'common/Dashboard'
import { OnlyProps } from 'src/types'
import { useResponsive } from 'common/hooks'

import ContactDetailsForm from './ContactDetailsForm'
import ConfirmPhoneChange from './ConfirmPhoneChange'
import PhoneChangeSuccess from './PhoneChangeSuccess'
import ChangeEmailForm from './ChangeEmailForm'

const ConnectedContactForm = connect((state: ApplicationState) => ({
  user: state.user.user as User,
}))(ContactDetailsForm)

const ConnectedConfirmPhoneChange = connect((state: ApplicationState) => ({
  user: state.user.user as User,
}))(ConfirmPhoneChange)

const ConnectedPhoneChangeSuccess = connect((state: ApplicationState) => ({
  user: state.user.user as User,
}))(PhoneChangeSuccess)

const ContactDetailsPage = () => {
  const { isMobile } = useResponsive()
  return (
    <Segment padded={!isMobile ? 'very' : undefined}>
      <Switch>
        <Route path="/account/contact-details/change-email/" component={ChangeEmailForm} />
        <Route
          path="/account/contact-details/phone-confirm/"
          component={ConnectedConfirmPhoneChange}
        />
        <Route
          path="/account/contact-details/phone-success/"
          component={ConnectedPhoneChangeSuccess}
        />
        <Route component={ConnectedContactForm} />
      </Switch>
    </Segment>
  )
}

const RoutedContactDetailsPage = ({ only }: OnlyProps) => (
  <DashboardContentContainer only={only}>
    <Route path="/account/contact-details/" component={ContactDetailsPage} />
  </DashboardContentContainer>
)

export default RoutedContactDetailsPage
