import React, { Fragment, useState, useCallback } from 'react'
import { Grid, TransitionGroup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { PlaceholderAddonCard, AddonCard } from 'common/ProductCard'
import { ApplicationState } from 'rootReducer'
import { getAllAddons } from 'common/Products/reducer'
import { makeLoadingSelector } from 'common/Loading/reducer'
import { Product } from 'common/Products/types'
import parseErrors from 'common/Form/utils'
import { FieldInterface } from 'common/Form'

interface AddonSelectionFormProps extends FieldInterface {
  addons: Product[]
  loading: boolean
  value: string
  errors: string[]
  onChange: (event: React.ChangeEvent, data: { name: string; value: string }) => void
}

const AddonSelectionForm: React.FC<AddonSelectionFormProps> = ({
  loading,
  name,
  addons,
  onChange,
  errors,
  value,
}) => {
  const showError = !!errors
  if (loading) {
    return (
      <Fragment>
        <p className="semibold">Fetching our best offers</p>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={4}>
              <PlaceholderAddonCard />
              <div />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={4}>
              <PlaceholderAddonCard />
              <div />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={4}>
              <PlaceholderAddonCard />
              <div />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <br />
        <p className="semibold">&nbsp;</p>
        <Grid>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={4}>
              <PlaceholderAddonCard />
              <div />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={4}>
              <PlaceholderAddonCard />
              <div />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={5} computer={4}>
              <PlaceholderAddonCard />
              <div />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fragment>
    )
  }
  const [selectedAddon, setSelectedAddons] = useState<string>(value)
  const toggleSelection = useCallback(
    (event, addon) => {
      setSelectedAddons(addon.unique_id)
      onChange(event, { name, value: addon.unique_id })
    },
    [selectedAddon, setSelectedAddons]
  )
  const dataAddons = addons.filter((a) => !a.roaming)
  const otherAddons = addons.filter((a) => a.roaming)

  return (
    <Fragment>
      <p className="semibold">Extra Data</p>
      <Grid>
        <Grid.Row>
          {dataAddons.map((addon) => {
            return (
              <Grid.Column mobile={16} tablet={5} computer={4} key={addon.unique_id}>
                <AddonCard
                  addon={addon}
                  onClick={toggleSelection}
                  active={selectedAddon === addon.unique_id}
                />
                <div />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      </Grid>
      <br />
      <p className="semibold">International Roaming Add-Ons and International Calls</p>
      <Grid>
        <Grid.Row>
          {otherAddons.map((addon) => {
            return (
              <Grid.Column mobile={16} tablet={5} computer={4} key={addon.unique_id}>
                <AddonCard
                  addon={addon}
                  onClick={toggleSelection}
                  active={selectedAddon === addon.unique_id}
                />
                <div />
              </Grid.Column>
            )
          })}
        </Grid.Row>
      </Grid>
      <TransitionGroup animation="slide down" duration={{ hide: 0, show: 300 }}>
        {showError && <span className="help error">{parseErrors(errors)[0]}</span>}
      </TransitionGroup>
    </Fragment>
  )
}

const ConnectedAddonSelectionForm = connect((state: ApplicationState) => ({
  addons: getAllAddons(state),
  loading: makeLoadingSelector(['GET_PRODUCTS'])(state),
}))(AddonSelectionForm)

export default ConnectedAddonSelectionForm
