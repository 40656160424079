import React, { MouseEvent as ReactMouseEvent, FunctionComponent, ReactNode } from 'react'
import { ButtonProps } from 'semantic-ui-react'
import { differenceInCalendarDays, format, addDays } from 'date-fns'
import PhoneNumber from 'awesome-phonenumber'
import { Msisdn, OnlyProps } from 'src/types'

export enum Keys {
  UP = 38,
  RIGHT = 39,
  DOWN = 37,
  LEFT = 40,
}

export enum Colours {
  TEAL = '#39A486',
  GREY = '#77818B',
  MEDIUM_GREY = '#BBBBBB',
}

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/Reduce
// @ts-ignore
export const groupBy = (objectArray, property) => {
  // @ts-ignore
  return objectArray.reduce((acc, obj) => {
    const key = obj[property]
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(obj)
    return acc
  }, {})
}

// Chunk arrays into a smaller array per chunk size
// https://stackoverflow.com/questions/8495687/split-array-into-chunks
export function chunk<T>(arr: T[], chunkSize: number): T[][] {
  const R: T[][] = []
  for (let i = 0, len = arr.length; i < len; i += chunkSize) R.push(arr.slice(i, i + chunkSize))
  return R
}

export const formatMsisdn = (phone: Msisdn | string): string => {
  const normalised = phone.replace(/^00(?=[1-9])/, '+')
  const parsed = new PhoneNumber(normalised, 'NZ')
  if (!parsed.isValid()) return normalised
  if (parsed.getRegionCode() === 'NZ') return parsed.getNumber('national')
  return parsed.getNumber('international')
}

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>

export type ClickEvent = (
  event: ReactMouseEvent<HTMLButtonElement, MouseEvent>,
  data: ButtonProps
) => void

export const formatPrice = (initialValue: string | number): string => {
  const stringValue = initialValue.toString()
  const isNegative = stringValue.substr(0, 1) === '-'

  const value = parseFloat(isNegative ? stringValue.substr(1) : stringValue)
  if (isNegative) {
    return `-$${value.toFixed(2)}`
  }

  return `$${value.toFixed(2)}`
}

export const formatDate = (date: Date): string => {
  return format(date, 'DD MMM YYYY')
}

export const formatLongDate = (date: Date): string => {
  return format(date, 'D MMMM YYYY')
}

export const formatDateTime = (date: Date): string => {
  return format(date, 'DD MMM hh:mm A')
}

export const formatShortDate = (date: Date): string => {
  return format(date, 'DD MMM')
}

export const formatTime = (date: Date): string => {
  return format(date, 'hh:mm A')
}

export const isNil = (children: React.ReactNode) =>
  children === null || children === undefined || (Array.isArray(children) && children.length === 0)

export const findByType = (children: any, component: any) => {
  const result: React.ReactNode[] = []
  /* This is the array of result since can have multiple times the same sub-component */
  const type = [component.displayName] || [component.name]
  /* We can store the actual name of the component through the displayName or name property of our sub-component */
  React.Children.forEach(children, (child) => {
    const childType = child && child.type && (child.type.displayName || child.type.name)
    if (type.includes(childType)) {
      result.push(child)
    }
  })
  /* Then we go through each React children, if one of matches the name
  of the sub-component we’re looking for we put it in the result array */
  return result[0]
}

export const formatDaysRemaining = (endDate: Date): number => {
  const currentDate = new Date()
  // Add an extra day to the end date to make the value inclusive
  return differenceInCalendarDays(addDays(endDate, 1), currentDate)
}

export const makeResponsiveColumn = (
  InnerComponent: FunctionComponent<OnlyProps>,
  only?: string
) => (
  // props should be arbitrary
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any
): ReactNode => {
  return <InnerComponent only={only} {...props} />
}
