import { useEffect } from 'react'
import { connect } from 'react-redux'
import * as actions from './actions'
import { Product } from './types'

export const ProductLoader = connect(null, {
  fetchProducts: actions.fetchProducts,
})(({ fetchProducts }: { fetchProducts: Function }) => {
  useEffect(() => {
    // componentDidMount -- fetch data
    fetchProducts()
  }, [null])

  return null
})

export const orderByDataLimit = (a: Product, b: Product): number => {
  const aData = parseFloat(a.data)
  const bData = parseFloat(b.data)
  if (aData > bData) return 1
  if (aData < bData) return -1
  return 0
}

export const orderByPrice = (a: Product, b: Product): number => {
  const aPrice = parseFloat(a.price)
  const bPrice = parseFloat(b.price)
  if (aPrice > bPrice) return 1
  if (aPrice < bPrice) return -1
  return 0
}

export const orderByLength = (a: Product, b: Product): number => {
  const aLength = a.length
  const bLength = b.length
  if (aLength > bLength) return 1
  if (aLength < bLength) return -1
  return 0
}

export const orderByDataThenPrice = (a: Product, b: Product): number => {
  const aData = parseFloat(a.data)
  const bData = parseFloat(b.data)
  const aPrice = parseFloat(a.price)
  const bPrice = parseFloat(b.price)
  if (aData > bData) return 1
  if (aData < bData) return -1
  if (aPrice > bPrice) return 1
  if (aPrice < bPrice) return -1
  return 0
}

export const orderByDefault = (a: Product, b: Product): number => a.sort_order - b.sort_order
