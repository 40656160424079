import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'

const ScrollToTop: React.FC<RouteComponentProps> = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <React.Fragment>{children}</React.Fragment>
}

export default withRouter(ScrollToTop)
