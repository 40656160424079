import React from 'react'
import { Product } from 'common/Products/types'
import { ProductCard } from './index'

const PlanSummary = ({ plan }: { plan: Product }) => (
  <ProductCard horizontal>
    <ProductCard.Header color={plan.colour}>
      <h4>
        {plan.title} - {plan.length} Days
      </h4>
    </ProductCard.Header>
    <ProductCard.Content>
      <h4>{plan.data_gb} GB</h4>
      {plan.length === 30 ? 'for' : 'per'} 30 days
    </ProductCard.Content>
    <div style={{ textAlign: 'left' }}>
      <h4>Unlimited</h4>
      Standard Calls &amp; Texts to <br /> Australia and New Zealand
    </div>
  </ProductCard>
)

export default PlanSummary
