import React, { MouseEvent as ReactMouseEvent, Fragment, useState, useCallback } from 'react'
import { Header } from 'semantic-ui-react'
import { SliderOption } from 'common/RangeSlider/'
import DynamicPlanFilter from 'common/DynamicPlanFilter'
import PlanCard, { PlaceholderPlanCard } from 'common/ProductCard'
import { Product } from 'common/Products/types'
import { orderByDataLimit } from 'common/Products'

interface PlanListProps {
  plans: Product[]
  loading: boolean
  onChange: (event: ReactMouseEvent<Element, MouseEvent>, unique_id: Product['unique_id']) => void
}

const PlanList = ({ plans, loading, onChange }: PlanListProps) => {
  if (loading)
    return (
      <Fragment>
        <DynamicPlanFilter name="planFilter" isLoading />
        <PlaceholderPlanCard horizontal />
        <PlaceholderPlanCard horizontal />
        <PlaceholderPlanCard horizontal />
        <PlaceholderPlanCard horizontal />
      </Fragment>
    )

  const [selected, setSelected] = useState()

  const [selectedLength, changeSelectedLength] = useState(30)

  const onPlanFilterChange = (name: string, option: SliderOption) => {
    changeSelectedLength(option.filterValue)
  }

  const onPlanClick = useCallback(
    (event: ReactMouseEvent<Element, MouseEvent> | React.KeyboardEvent, plan: Product) => {
      setSelected(plan.unique_id)
      onChange(event as ReactMouseEvent<Element, MouseEvent>, plan.unique_id)
    },
    []
  )

  return (
    <Fragment>
      <Header as="h3" color="grey">
        Choose Prepay Plan Length
      </Header>
      <DynamicPlanFilter name="planFilter" onChange={onPlanFilterChange} />
      <Header as="h3" color="grey">
        Choose Prepay Plan
      </Header>
      {plans
        .sort(orderByDataLimit)
        .filter((plan: Product) => plan.length === selectedLength && plan.sku)
        .map((plan: Product) => (
          <PlanCard
            onClick={onPlanClick}
            active={selected === plan.unique_id}
            key={plan.unique_id}
            plan={plan}
            horizontal
          />
        ))}
    </Fragment>
  )
}

export default PlanList
