import axios from 'axios'
import { ServiceByUuid } from './types'

export enum ServiceActionTypes {
  GET_SERVICES_FAILURE = 'GET_SERVICES_FAILURE',
  GET_SERVICES_REQUEST = 'GET_SERVICES_REQUEST',
  GET_SERVICES_SUCCESS = 'GET_SERVICES_SUCCESS',
}

export enum RechargeVoucherActionTypes {
  SET_VOUCHER_CODE = 'RECHARGE_SET_VOUCHER_CODE',
  CLEAR_VOUCHER_CODE = 'RECHARGE_CLEAR_VOUCHER_CODE',
}

export interface GetServicesAction {
  type: ServiceActionTypes
  payload: {
    data?: ServiceByUuid
  }
}

interface RechargeVoucherActions {
  type: RechargeVoucherActionTypes
  payload?: string
}

export type ServiceActions = GetServicesAction | RechargeVoucherActions

export const getServicesRequest = (): GetServicesAction => ({
  type: ServiceActionTypes.GET_SERVICES_REQUEST,
  payload: {},
})

export const getServicesFailure = (): GetServicesAction => ({
  type: ServiceActionTypes.GET_SERVICES_FAILURE,
  payload: {},
})

export const getServicesSuccess = (data: ServiceByUuid): GetServicesAction => ({
  type: ServiceActionTypes.GET_SERVICES_SUCCESS,
  payload: { data },
})

export function fetchServices(): Function {
  return (dispatch: Function) => {
    dispatch(getServicesRequest())
    axios
      .get('/api/services/')
      .then((data) => {
        dispatch(getServicesSuccess(data.data))
      })
      .catch(() => {
        dispatch(getServicesFailure())
      })
  }
}

export const setVoucherCode = (code: string) => ({
  type: RechargeVoucherActionTypes.SET_VOUCHER_CODE,
  payload: code,
})

export const clearVoucherCode = () => ({
  type: RechargeVoucherActionTypes.CLEAR_VOUCHER_CODE,
})
