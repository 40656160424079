import React from 'react'
import { Header, Transition, Container } from 'semantic-ui-react'
import { connect } from 'react-redux'
import SvgIcon from 'common/SvgIcon'
import { restartActivation } from 'pages/ActivatePage/actions'
import PrimaryActionButton from 'common/Buttons/PrimaryActionButton'

const TryAgainButton = connect(undefined, { onClick: restartActivation })(PrimaryActionButton)

const ActivationError = () => (
  <Transition animation="fade up" transitionOnMount>
    <Container text>
      <Header as="h1" textAlign="center" icon>
        <SvgIcon name="sad-face" color="red" />
        Something Went Wrong
        <Header.Subheader>Unfortunately, something didn&apos;t go as planned.</Header.Subheader>
        <Header.Subheader>Please try again later.</Header.Subheader>
      </Header>
      <TryAgainButton fluid>Try Again</TryAgainButton>
    </Container>
  </Transition>
)

export default ActivationError
