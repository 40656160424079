import axios from 'axios'
import { ProductByUniqueId } from 'common/Products/types'

export enum ActionTypes {
  GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE',
  GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS',
}

export interface GetProductsAction {
  type: ActionTypes
  payload: {
    data?: ProductByUniqueId
  }
}

export type ProductActions = GetProductsAction

export const getProductsRequest = (): GetProductsAction => ({
  type: ActionTypes.GET_PRODUCTS_REQUEST,
  payload: {},
})

export const getProductsFailure = (): GetProductsAction => ({
  type: ActionTypes.GET_PRODUCTS_FAILURE,
  payload: {},
})

export const getProductsSuccess = (data: ProductByUniqueId): GetProductsAction => ({
  type: ActionTypes.GET_PRODUCTS_SUCCESS,
  payload: { data },
})

export function fetchProducts(): Function {
  return (dispatch: Function) => {
    dispatch(getProductsRequest())
    axios
      .get('/api/products/')
      .then((data) => {
        dispatch(getProductsSuccess(data.data))
      })
      .catch(() => {
        dispatch(getProductsFailure())
      })
  }
}
