import React, { Fragment, useCallback } from 'react'
import IconButtonOption from 'common/IconButtonOption'

export enum PaymentType {
  OPTION_PAYMENT = '1',
  OPTION_VOUCHER = '2',
}

interface VoucherPlanSwitcherDefaultProps {
  paymentText: string
  voucherText: string
  value?: string
}

interface VoucherPlanSwitcherProps extends VoucherPlanSwitcherDefaultProps {
  onChange: (arg0: string | undefined) => void
}

export const typeToUrl = (type?: PaymentType) => {
  if (type === PaymentType.OPTION_PAYMENT) return 'plan'
  if (type === PaymentType.OPTION_VOUCHER) return 'voucher'
  return undefined
}

export const urlToType = (type?: 'voucher' | 'plan') => {
  if (type === 'voucher') return PaymentType.OPTION_VOUCHER
  if (type === 'plan') return PaymentType.OPTION_PAYMENT
  return undefined
}

const VoucherPlanSwitcher = ({
  onChange,
  value,
  paymentText,
  voucherText,
}: VoucherPlanSwitcherProps) => {
  const showPayment = value === PaymentType.OPTION_PAYMENT
  const showVoucher = value === PaymentType.OPTION_VOUCHER
  const showBoth = value === undefined
  const togglePayment = useCallback(() => {
    const newValue = value !== PaymentType.OPTION_PAYMENT ? PaymentType.OPTION_PAYMENT : undefined
    onChange(newValue)
  }, [onChange, value])
  const toggleVoucher = useCallback(() => {
    const newValue = value !== PaymentType.OPTION_VOUCHER ? PaymentType.OPTION_VOUCHER : undefined
    onChange(newValue)
  }, [onChange, value])
  return (
    <Fragment>
      {(showBoth || showPayment) && (
        <p>
          <IconButtonOption
            iconLeft="list"
            direction={showPayment ? 'backwards' : 'forwards'}
            onClick={togglePayment}
          >
            {paymentText}
          </IconButtonOption>
        </p>
      )}
      {(showBoth || showVoucher) && (
        <p>
          <IconButtonOption
            direction={showVoucher ? 'backwards' : 'forwards'}
            iconLeft="envelope"
            onClick={toggleVoucher}
          >
            {voucherText}
          </IconButtonOption>
        </p>
      )}
    </Fragment>
  )
}

VoucherPlanSwitcher.defaultProps = {
  paymentText: 'I would like to purchase a Prepay Plan now',
  voucherText: 'I have an email with a Voucher Code',
}

export default VoucherPlanSwitcher
