import React from 'react'
import { useToggle } from 'common/hooks'
import BaseFormField, { BaseFormFieldInterface } from 'common/Form/BaseFormField'
import { Icon } from 'semantic-ui-react'

const PasswordFormField = ({
  errors,
  value,
  onChange,
  ...field
}: BaseFormFieldInterface): JSX.Element => {
  const [isMasked, toggleMasked] = useToggle(false)

  const updatedField = {
    ...field,
    icon: <Icon name={isMasked ? 'eye slash' : 'eye'} link onClick={toggleMasked} />,
    type: isMasked ? 'text' : field.type,
  }

  return <BaseFormField errors={errors} value={value} onChange={onChange} {...updatedField} />
}

export default PasswordFormField
