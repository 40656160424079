import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { StrictTransitionProps, Transition } from 'semantic-ui-react'

interface AnimatedTransitionProps extends StrictTransitionProps {
  children: ReactElement
}

/*
Alternative to Semantic UI's TransitionGroup, but it waits for the "out" animation to complete first.
The main difference is that it only supports a single child component.

Usage:

<AnimatedTransition animation="fade">
  <div key={currentItemId}>{currentItemId}</div>
</AnimatedTransition>

 */
const AnimatedTransition = ({ children, ...props }: AnimatedTransitionProps) => {
  const [renderedChild, setChild] = useState<ReactElement>(children)
  const [visible, setVisibility] = useState<boolean>(true)
  useEffect(() => {
    // Dont animate on first mount
    if (children.key !== renderedChild.key) {
      setVisibility(false)
    }
  }, [children.key])
  const onHide = useCallback(() => {
    setChild(children)
    setVisibility(true)
  }, [children, setChild, setVisibility])

  return (
    <Transition onHide={onHide} visible={visible} {...props}>
      {renderedChild}
    </Transition>
  )
}

export default AnimatedTransition
