import React, { Fragment } from 'react'
import Form, { FieldInterface } from 'common/Form'
import { Header, Segment } from 'semantic-ui-react'
import { API_PASSWORD_RESET_BASE } from 'common/UserContainer/actions'
import { Redirect } from 'react-router-dom'
import * as Yup from 'yup'

export const RESET_PASSWORD_FORM_ID = 'reset-password'

const fields: FieldInterface[] = [
  {
    name: 'password',
    icon: 'lock',
    iconPosition: 'left',
    placeholder: 'Password',
    type: 'password',
    required: true,
    autocomplete: 'new-password',
  },
  {
    name: 'password_confirm',
    icon: 'lock',
    iconPosition: 'left',
    placeholder: 'Confirm Password',
    type: 'password',
    required: true,
    autocomplete: 'new-password',
  },
]

const PasswordConfirmationSchema = Yup.object({
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters.')
    .required('This field is required.'),
  password_confirm: Yup.string()
    .nullable()
    .oneOf([Yup.ref('$password'), null], "The two password fields don't match.")
    .required('This field is required.'),
})

interface PropsInterface {
  uid: string
  token: string
}
const successMessage = () => <Redirect to="/" />
const ChangePasswordForm = ({ uid, token }: PropsInterface) => {
  return (
    <Fragment>
      <Header as="h1" textAlign="left">
        Reset Password
        <Header.Subheader textAlign="left">
          Your password needs to have at least one letter, 6 characters and cannot contain your name
          or email address
        </Header.Subheader>
      </Header>
      <Segment vertical basic textAlign="left">
        <Form
          id={RESET_PASSWORD_FORM_ID}
          fields={fields}
          submitButtonText="UPDATE"
          submitButtonIcon="arrow right"
          renderSuccess={successMessage}
          validationSchema={PasswordConfirmationSchema}
          url={`${API_PASSWORD_RESET_BASE}${uid}/${token}/`}
        />
      </Segment>
    </Fragment>
  )
}

export default ChangePasswordForm
