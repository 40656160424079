import React, { Fragment, useEffect } from 'react'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { Grid, Header, Image } from 'semantic-ui-react'
import { ApplicationState } from 'rootReducer'

import ActivationStageHeader from 'common/ActivationStageHeader'
import LinkButton from 'common/LinkButton'
import { makeLoadingSelector } from 'common/Loading/reducer'
import IconButtonOption from 'common/IconButtonOption'
import { useToggle } from 'common/hooks'
import ConnectedForm, {
  ErrorsInterface,
  FieldErrorsInterface,
  uppercaseModifier,
} from 'common/Form'
import { FormRenderProps, ButtonRenderer } from 'common/Form/BaseForm'
import BaseFormField, { FieldOnBlur, FieldOnChange } from 'common/Form/BaseFormField'
import { PlanSummary } from 'common/ProductCard'
import { Product } from 'common/Products/types'
import { setFieldError } from 'common/Form/actions'
import { getVoucherProduct } from 'pages/ActivatePage/reducer'
import { ErrorAction, makeloadVoucher, goBack } from 'pages/ActivatePage/actions'
import { CheckoutStepTracking } from '../../../../segment'

const FORM_ID = 'activate_voucher_code'

// also need to submit this to the server, but don't want a field for it
const initialVoucherFormValue = { method: 'voucher' }
const initialPaymentFormValue = { method: 'payment' }
const schema = Yup.object({
  code: Yup.string().required('Please enter a Voucher Code.'),
})

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const planDetailsImageSrc = require('static/img/plan-details.png')

interface VoucherLoaderProps {
  formId: string
  value: string
  errors: FieldErrorsInterface
  onChange: FieldOnChange
  onBlur: FieldOnBlur
  checkVoucher: (voucherCode: string, errorAction: ErrorAction) => void
  setError: (id: string, errors: ErrorsInterface) => void
  product?: Product
  isLoading?: boolean
}

export const VoucherInput = ({
  formId,
  errors,
  value,
  onChange,
  onBlur,
  checkVoucher,
  product,
  isLoading,
  setError,
}: VoucherLoaderProps) => {
  useEffect(() => {
    if (value.length === 12) {
      checkVoucher(value, (err: string[]) => setError(formId, { code: err }))
    }
  }, [value, checkVoucher])

  return (
    <Fragment>
      <BaseFormField
        errors={errors}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        formId={formId}
        tabIndex={0}
        name="code"
        required
        type="text"
        maxLength={12}
        label="Enter your Voucher Code"
        autocomplete="off"
        inputModifier={uppercaseModifier}
        isLoading={isLoading}
      />
      {product && value && (
        // Add field spacing for consistency
        <div className="field">
          <PlanSummary plan={product} />
        </div>
      )}
    </Fragment>
  )
}

VoucherInput.defaultProps = {
  value: '',
}

const ConnectedActivationVoucherInput = connect(
  (state: ApplicationState) => {
    const product = getVoucherProduct(state)
    return {
      product,
      isLoading: makeLoadingSelector(['LOAD_VOUCHER'])(state),
    }
  },
  {
    checkVoucher: makeloadVoucher('ACTIVATION'),
    setError: setFieldError,
  }
)(VoucherInput)

const VoucherInputField = ({ formData, errors, onChange, onBlur }: FormRenderProps) => {
  return (
    <Fragment>
      <ConnectedActivationVoucherInput
        errors={errors.code}
        value={formData.code as string}
        onChange={onChange}
        onBlur={onBlur}
        formId={FORM_ID}
      />
    </Fragment>
  )
}

const VoucherForm = ({ goBackHandler }: { goBackHandler: () => void }) => (
  <Fragment>
    <ConnectedForm
      id={FORM_ID}
      url="/api/activate/method/"
      submitButtonText="CONTINUE"
      submitButtonIcon="arrow right"
      validationSchema={schema}
      initialValue={initialVoucherFormValue}
      cancelButtonText="BACK"
      cancelProps={{ onClick: goBackHandler, secondary: true }}
    >
      {VoucherInputField}
    </ConnectedForm>
  </Fragment>
)

const RenderPurchaseButton: React.FC<ButtonRenderer> = () => (
  <IconButtonOption iconLeft="list" direction="forwards">
    I would like to purchase a Prepay Plan now
  </IconButtonOption>
)

interface SelectPaymentMethodInterface {
  setup_porting: boolean
  authenticated: boolean
  doStartAgain: () => void
  backToDetails: () => void
  backToPorting: () => void
}

const SelectPaymentMethod = (props: SelectPaymentMethodInterface) => {
  const [showVoucher, toggleShowVoucher] = useToggle(false)

  const { setup_porting, authenticated, doStartAgain, backToDetails, backToPorting } = props

  let goBackHandler = backToDetails

  // Skip personal details if authenticated
  if (authenticated) {
    // But take into account of porting
    if (setup_porting) goBackHandler = backToPorting
    else goBackHandler = doStartAgain
  }

  return (
    <Fragment>
      <CheckoutStepTracking step={4} />
      <ActivationStageHeader activeStage="Prepay Plan Details" />
      <Grid columns="equal" relaxed>
        <Grid.Row>
          <Grid.Column only="computer tablet" verticalAlign="middle">
            <Image src={planDetailsImageSrc} />
          </Grid.Column>
          <Grid.Column>
            {/* Add empty div to give Header top margin */}
            <div />
            <Header as="h1">Choose Your Prepay Plan</Header>
            <p>Please select which of the following applies to you:</p>

            {!showVoucher && (
              <Fragment>
                <ConnectedForm
                  id="activate_method_purchase"
                  url="/api/activate/method/"
                  renderSubmitButton={RenderPurchaseButton}
                  initialValue={initialPaymentFormValue}
                />
                <p />
              </Fragment>
            )}
            <p>
              <IconButtonOption
                direction={showVoucher ? 'backwards' : 'forwards'}
                iconLeft="envelope"
                onClick={toggleShowVoucher}
              >
                I have an email with a Voucher Code
              </IconButtonOption>
            </p>
            {showVoucher ? (
              <VoucherForm goBackHandler={goBackHandler} />
            ) : (
              <Fragment>
                <LinkButton size="large" floated="left" secondary onClick={goBackHandler}>
                  Back
                </LinkButton>
              </Fragment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

export default connect(
  (state: ApplicationState) => {
    const { checkout } = state.activation
    const { authenticated } = state.user
    return {
      setup_porting: (checkout && checkout.setup_porting) || false,
      authenticated: authenticated || false,
    }
  },
  {
    backToPorting: () => goBack('PORTING_ENTERED'),
    backToDetails: () => goBack('CREATED'),
    doStartAgain: () => goBack(null),
  }
)(SelectPaymentMethod)
