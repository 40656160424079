import React from 'react'
import Layout from 'common/Layout'
import { Header, Icon } from 'semantic-ui-react'

const HomePage = () => (
  <Layout.Page>
    <Header as="h1" icon textAlign="center">
      <Icon name="question" />
      Not Found
      <Header.Subheader>Unfortunately we could not find what you were looking for</Header.Subheader>
    </Header>
  </Layout.Page>
)

export default HomePage
