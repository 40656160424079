import axios from 'axios'
import { ChangeEmailRequest } from 'pages/ContactDetailsPage/ChangeEmailForm/types'

export enum ChangeEmailStatusActions {
  REQUEST = 'GET_CHANGE_EMAIL_REQUEST',
  SUCCESS = 'GET_CHANGE_EMAIL_SUCCESS',
  FAILURE = 'GET_CHANGE_EMAIL_FAILURE',
}

export enum ChangeEmailNewOTPActions {
  REQUEST = 'CHANGE_EMAIL_OTP_REQUEST',
  SUCCESS = 'CHANGE_EMAIL_OTP_SUCCESS',
  FAILURE = 'CHANGE_EMAIL_OTP_FAILURE',
}

export enum ChangeEmailResendEmailActions {
  REQUEST = 'CHANGE_EMAIL_RESEND_EMAIL_REQUEST',
  SUCCESS = 'CHANGE_EMAIL_RESEND_EMAIL_SUCCESS',
  FAILURE = 'CHANGE_EMAIL_RESEND_EMAIL_FAILURE',
}

export enum ChangeEmailActions {
  GO_BACK = 'CHANGE_EMAIL_GO_BACK',
}

export interface ChangeEmailAction {
  type:
    | ChangeEmailStatusActions
    | ChangeEmailNewOTPActions
    | ChangeEmailResendEmailActions
    | ChangeEmailActions
  payload?: ChangeEmailRequest
}

const changeEmailStatusRequest = (): ChangeEmailAction => ({
  type: ChangeEmailStatusActions.REQUEST,
})
const changeEmailStatusSuccess = (payload: ChangeEmailRequest): ChangeEmailAction => ({
  type: ChangeEmailStatusActions.SUCCESS,
  payload,
})
const changeEmailStatusFailure = (): ChangeEmailAction => ({
  type: ChangeEmailStatusActions.FAILURE,
})

const newOtpRequest = (): ChangeEmailAction => ({
  type: ChangeEmailNewOTPActions.REQUEST,
})
const newOtpSuccess = (payload: ChangeEmailRequest): ChangeEmailAction => ({
  type: ChangeEmailNewOTPActions.SUCCESS,
  payload,
})
const newOtpFailure = (): ChangeEmailAction => ({
  type: ChangeEmailNewOTPActions.FAILURE,
})

const resendEmailRequest = (): ChangeEmailAction => ({
  type: ChangeEmailResendEmailActions.REQUEST,
})
const resendEmailSuccess = (payload: ChangeEmailRequest): ChangeEmailAction => ({
  type: ChangeEmailResendEmailActions.SUCCESS,
  payload,
})
const resendEmailFailure = (): ChangeEmailAction => ({
  type: ChangeEmailResendEmailActions.FAILURE,
})

export const getChangeEmailStatus = () => {
  return (dispatch: Function) => {
    dispatch(changeEmailStatusRequest())
    axios
      .get('/api/account/update_email/')
      .then((data) => {
        dispatch(changeEmailStatusSuccess(data.data))
      })
      .catch(() => {
        dispatch(changeEmailStatusFailure())
      })
  }
}

export const getNewOtp = () => {
  return (dispatch: Function) => {
    dispatch(newOtpRequest())
    axios
      .post('/api/account/update_email/resend_otp/')
      .then((data) => {
        dispatch(newOtpSuccess(data.data))
      })
      .catch(() => dispatch(newOtpFailure()))
  }
}

export const resendEmail = () => {
  return (dispatch: Function) => {
    dispatch(resendEmailRequest())
    axios
      .post('/api/account/update_email/resend_email/')
      .then((data) => {
        dispatch(resendEmailSuccess(data.data))
      })
      .catch(() => dispatch(resendEmailFailure()))
  }
}

export const goBackToEmail = (): ChangeEmailAction => ({
  type: ChangeEmailActions.GO_BACK,
})
