export interface InternalMenuLink {
  id: string
  type: 'internal_link'
  value: {
    title: string
    path: string
  }
}

export interface ExternalMenuLink {
  id: string
  type: 'external_link'
  value: {
    title: string
    url: string
  }
}

export type Link = InternalMenuLink | ExternalMenuLink

export interface MenuSectionInterface {
  id: string
  type: 'section'
  value: {
    heading: string
    links: Link[]
  }
}

export interface MenuInterface {
  position: string
  sections: MenuSectionInterface[]
}

export interface MenuState {
  header?: MenuInterface
  footer?: MenuInterface
}

export interface ContentState {
  roaming_link_text: string
  roaming_link_url: string
  plan_selection_terms: string
  menus: MenuState
}

function getContentData(): ContentState {
  const el = document.getElementById('content-data')
  if (el) {
    const data = JSON.parse(el.textContent || '[]')
    const { menus, ...rest } = data
    const menuData = menus.reduce(
      (prev: {}, item: MenuInterface) => ({ ...prev, [item.position]: item }),
      {}
    )
    return {
      menus: menuData,
      ...rest,
    }
  }
  return {
    menus: {},
    roaming_link_text: '',
    roaming_link_url: '',
    plan_selection_terms: '',
  }
}

export const initialState: ContentState = getContentData()

export default function reducer(state = initialState): ContentState {
  if (Object.keys(state).length === 0) {
    return getContentData()
  }
  return state
}
