import { ApplicationState } from 'rootReducer'
import { Uuid } from 'src/types'
import { UsageSummary } from './types'

// eslint-disable-next-line import/prefer-default-export
export const getSummmaryByUuid = (uuid: Uuid) => (
  state: ApplicationState
): UsageSummary | undefined => {
  if (state.dashboard.byUuid[uuid]) return state.dashboard.byUuid[uuid].summary
  return undefined
}
