import React from 'react'
import { Button } from 'semantic-ui-react'

interface Props {
  onClick: () => void
  loading: boolean
}

const RefreshButton = ({ onClick, loading }: Props) => (
  <Button
    icon="refresh"
    inverted
    color="teal"
    disabled={loading}
    onClick={onClick}
    loading={loading}
  />
)

RefreshButton.defaultProps = {
  loading: false,
}

export default RefreshButton
