import React from 'react'
import { Step } from 'semantic-ui-react'

export interface Stage {
  title: string
}
interface Props {
  stages: Stage[]
  activeStage?: 'ALL' | string
}

const StageHeader = ({ stages, activeStage }: Props) => {
  const activeIndex =
    activeStage === 'ALL' ? 999 : stages.findIndex((stage) => stage.title === activeStage)
  return (
    <Step.Group ordered fluid unstackable className="kgn-above">
      {stages.map((stageItem: Stage, idx: number) => (
        <Step completed={idx < activeIndex} active={idx === activeIndex} key={stageItem.title}>
          <Step.Content>{stageItem.title}</Step.Content>
        </Step>
      ))}
    </Step.Group>
  )
}

export default StageHeader
