import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { Product } from 'common/Products/types'
import { Caption } from 'common/Typography'
import { ProductCard } from './index'
import { EntitlementSpacer } from './AddonCard'

const NoMarginHeader = styled.h3`
  margin-bottom: 0;
`

const AddonSummary = ({
  addon,
  linkText,
  linkUrl,
}: {
  addon: Product
  linkText?: string
  linkUrl?: string
}) => {
  let data_value = addon.data_gb
  let data_unit = 'GB'
  if (addon.data_gb < 1) {
    data_unit = 'MB'
    data_value *= 1000
  }

  return (
    <ProductCard horizontal>
      <ProductCard.Header color={addon.colour}>
        <h4>
          {addon.title} - {addon.length} Days
        </h4>
      </ProductCard.Header>
      <ProductCard.Content>
        <NoMarginHeader>
          {data_value > 0 && `${data_value} ${data_unit}`}
          <EntitlementSpacer />
          {addon.voice_mins > 0 && `${addon.voice_mins} Mins`}
          <EntitlementSpacer />
          {addon.txt_sms > 0 && `${addon.txt_sms} SMS/MMS`}
        </NoMarginHeader>
        {addon.roaming && linkText && linkUrl && (
          <Caption>
            <a href={linkUrl} target="_blank" rel="noopener noreferrer">
              {linkText}
            </a>
          </Caption>
        )}
      </ProductCard.Content>
    </ProductCard>
  )
}

export default connect((state: ApplicationState) => ({
  linkText: state.layout.roaming_link_text,
  linkUrl: state.layout.roaming_link_url,
}))(AddonSummary)
