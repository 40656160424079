import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Accordion,
  Menu,
  Icon,
  Image,
  Loader,
  Dropdown,
  Sidebar,
  Responsive,
  Container,
} from 'semantic-ui-react'
import UserContainer from 'common/UserContainer'
import LogoutContainer, { LogoutRenderProps } from 'common/UserContainer/LogoutContainer'
import { renderMobileDropdownSection, renderDesktopDropdownSection } from 'common/menuHelpers'
import { MenuInterface, MenuSectionInterface } from 'common/Layout/reducer'
import { ApplicationState } from 'rootReducer'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('static/img/kogan_mobile_logo.svg')

const LogoutButton = ({ doLogout, isLoading }: LogoutRenderProps) => (
  <Menu.Item onClick={doLogout}>
    Logout
    {isLoading && <Loader as={Icon} size="tiny" active inline />}
  </Menu.Item>
)

type MenuRenderer = (section: MenuSectionInterface) => JSX.Element

const dynamicMenuContent = (renderer: MenuRenderer) => ({ layout }: { layout?: MenuInterface }) => (
  <Fragment>
    {layout && layout.sections && layout.sections.map((section) => renderer(section))}
  </Fragment>
)

const ConnectedDesktopMenuContent = connect((state: ApplicationState) => ({
  layout: state.layout.menus.header,
}))(dynamicMenuContent(renderDesktopDropdownSection))

const ConnectedMobileMenuContent = connect((state: ApplicationState) => ({
  layout: state.layout.menus.header,
}))(dynamicMenuContent(renderMobileDropdownSection))

const UserMenuContent = (
  <Fragment>
    <Menu.Item as={Link} to="/account/contact-details/">
      Contact Details
    </Menu.Item>
    <Menu.Item as={Link} to="/account/payment-method/">
      Payment Method
    </Menu.Item>
    <Menu.Item as={Link} to="/account/change-password/">
      Change Password
    </Menu.Item>
  </Fragment>
)

interface MenuProps {
  open: boolean
  closeMenu(): void
}

export const MobileAccountSettings = () => (
  <Accordion
    as={Menu}
    vertical
    inverted
    fluid
    size="huge"
    panels={[
      {
        key: 'account-settings-dropdown',
        title: 'Account Settings',
        content: { content: UserMenuContent },
      },
    ]}
  />
)

export const MobileSiteMenu = ({ open, closeMenu }: MenuProps) => (
  <Sidebar
    as={Menu}
    animation="overlay"
    direction="top"
    vertical
    inverted
    borderless
    size="huge"
    visible={open}
    onHide={closeMenu}
  >
    <Menu.Item onClick={closeMenu} className="kogan-close-menu-icon">
      <Icon style={{ float: 'none', margin: 0 }} name="close" size="large" />
      &nbsp;
    </Menu.Item>
    <ConnectedMobileMenuContent />
    <UserContainer>
      {({ authenticated }) =>
        authenticated ? (
          <Fragment>
            <MobileAccountSettings />
            <LogoutContainer>{LogoutButton}</LogoutContainer>
          </Fragment>
        ) : (
          <Menu.Item as={Link} to="/login/" content="Login" />
        )
      }
    </UserContainer>
  </Sidebar>
)

const DesktopAccountSettings = () => (
  <Dropdown simple item trigger="Account Settings">
    <Dropdown.Menu as={Menu}>{UserMenuContent}</Dropdown.Menu>
  </Dropdown>
)

interface HeaderProps {
  openSiteMenu(): void
}

const MobileMenu = ({ openSiteMenu }: HeaderProps) => (
  <Menu secondary size="huge">
    <Menu.Item>
      <Icon name="bars" size="large" color="grey" onClick={openSiteMenu} />
    </Menu.Item>
    <Menu.Item position="right" as={Link} to="/">
      <Image src={logo} alt="logo" size="tiny" />
    </Menu.Item>

    <UserContainer>
      {({ authenticated }) =>
        authenticated ? (
          // hack to center the icon
          <Menu.Item position="right">
            <Icon name="bars" size="large" style={{ opacity: 0 }} />
          </Menu.Item>
        ) : (
          <Menu.Item as={Link} to="/login/" content="Login" position="right" />
        )
      }
    </UserContainer>
  </Menu>
)

const DesktopMenu = () => (
  <Menu borderless as="nav" size="huge">
    <Container>
      <Menu.Item header as={Link} to="/">
        <Image src={logo} alt="logo" size="tiny" />
      </Menu.Item>
      <Menu.Menu position="right">
        <ConnectedDesktopMenuContent />
        <UserContainer>
          {({ authenticated }) =>
            authenticated ? (
              <Fragment>
                <DesktopAccountSettings />
                <LogoutContainer>{LogoutButton}</LogoutContainer>
              </Fragment>
            ) : (
              <Menu.Item as={Link} to="/login/" content="Login" />
            )
          }
        </UserContainer>
      </Menu.Menu>
    </Container>
  </Menu>
)

const Header = (mobileProps: HeaderProps) => (
  <Fragment>
    <Responsive as={MobileMenu} maxWidth={Responsive.onlyMobile.maxWidth} {...mobileProps} />
    <Responsive as={DesktopMenu} minWidth={Responsive.onlyTablet.minWidth} />
  </Fragment>
)

export default Header
