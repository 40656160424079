import React, { MouseEvent as ReactMouseEvent, useCallback } from 'react'
import { Form, TransitionGroup, SemanticWIDTHS } from 'semantic-ui-react'
import parseErrors from 'common/Form/utils'
import { Product } from 'common/Products/types'
import { FieldErrorsInterface, FieldInterface, FormDataValue } from 'common/Form'
import { CombinedChangeEvent } from 'common/Form/BaseForm'
import PlanList from '../PlanSelector/PlanList'

const helpAnimationStyle = { hide: 0, show: 300 }

interface CardInputData {
  name: string
  value: string
}

interface PlanSelectionFieldProps extends FieldInterface {
  errors: FieldErrorsInterface
  value?: FormDataValue
  onChange(event: CombinedChangeEvent, data: CardInputData): void
  plans: Product[]
  loading: boolean
}

const PlanSelectionField = ({
  errors,
  plans,
  onChange,
  loading,
  ...field
}: PlanSelectionFieldProps) => {
  const showError = !!errors
  const showHelp = !showError && field.help

  const newOnChange = useCallback(
    (event: ReactMouseEvent<Element, MouseEvent>, unique_id: Product['unique_id']) => {
      onChange(event, { name: field.name, value: unique_id })
    },
    [onChange]
  )

  return (
    <Form.Field
      key={field.name}
      required={field.required}
      error={!!errors}
      width={field.width as SemanticWIDTHS}
    >
      {field.label && <label htmlFor={field.name}>{field.label}</label>}
      <PlanList onChange={newOnChange} plans={plans} loading={loading} />
      {showHelp && <span className="help">{field.help}</span>}
      <TransitionGroup animation="slide down" duration={helpAnimationStyle}>
        {showError && <span className="help error">{parseErrors(errors)[0]}</span>}
      </TransitionGroup>
    </Form.Field>
  )
}

export default PlanSelectionField
