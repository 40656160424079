import React, { useEffect } from 'react'
import { Redirect, RedirectProps } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'common/hooks'
import { setLoginNext, clearLoginNext } from 'common/Routes/actions'
import { getNextLoginPage } from 'common/Routes/reducer'
import { getAuthenticated } from 'common/UserContainer/reducer'

const SaveRedirect: React.FC<RedirectProps> = (props) => {
  const location = useLocation()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setLoginNext(location))
  }, [location, dispatch])

  return <Redirect {...props} />
}

export default SaveRedirect

export const RedirectOnLogin: React.FC<{}> = () => {
  const location = useSelector(getNextLoginPage)
  const isLoggedIn = useSelector(getAuthenticated)
  const navigate = useNavigate(true)
  const dispatch = useDispatch()
  useEffect(() => {
    if (isLoggedIn && location) {
      dispatch(clearLoginNext())
      navigate(location)
    }
  }, [isLoggedIn, location, navigate, dispatch])
  return null
}
