import { ApplicationState } from 'rootReducer'
import { FormAction, FormActions } from './actions'
import { ErrorsInterface } from './index'

interface IndividualFormState {
  isSubmitting: boolean
  success: boolean
  errors: ErrorsInterface
  responseData?: any
  responseStatus?: number
  data?: any
  dirtyFields?: { [key: string]: boolean }
}
export interface FormState {
  [key: string]: IndividualFormState
}

export const initialState: FormState = {}

function updateForm(state: FormState, id: string, updates: IndividualFormState): FormState {
  return {
    ...state,
    [id]: {
      ...state[id],
      ...updates,
      dirtyFields: { ...(state[id] || {}).dirtyFields, ...updates.dirtyFields },
    },
  }
}

interface GenericAnyObject {
  [key: string]: any
}

const removeKeys = (original: GenericAnyObject, keys: string[]) => {
  const copy = { ...original }
  keys.forEach((k: string) => delete copy[k])
  return copy
}

export const getFormDataById = (state: ApplicationState, id: string) => (state.forms[id] || {}).data

export default function reducer(state = initialState, action: FormAction): FormState {
  switch (action.type) {
    case FormActions.FORM_RESET:
      return {
        ...state,
        [action.payload.id]: {
          isSubmitting: false,
          success: false,
          errors: {},
          data: {},
          dirtyFields: {},
        },
      }
    case FormActions.FORM_RESET_FIELDS: {
      const formData = state[action.payload.id]
      return updateForm(state, action.payload.id, {
        isSubmitting: false,
        success: false,
        errors: removeKeys(formData.errors, action.payload.fieldNames),
        data: removeKeys(formData.data, action.payload.fieldNames),
        dirtyFields: removeKeys(formData.dirtyFields || {}, action.payload.fieldNames),
      })
    }
    case FormActions.FORM_CHANGE: {
      const keys = Object.keys(action.payload.data)
      const dirtyFields = action.payload.initialSet
        ? {}
        : keys.reduce((acc, key) => {
            return { ...acc, [key]: true }
          }, {})
      return updateForm(state, action.payload.id, {
        ...state[action.payload.id],
        data: {
          ...(state[action.payload.id] || {}).data,
          ...action.payload.data,
        },
        dirtyFields,
      })
    }
    case FormActions.FORM_REQUEST:
      return updateForm(state, action.payload.id, {
        isSubmitting: true,
        success: false,
        errors: {},
      })
    case FormActions.FORM_SUCCESS:
      return updateForm(state, action.payload.id, {
        isSubmitting: false,
        success: true,
        errors: {},
        responseData: action.payload.data,
        responseStatus: action.payload.status,
      })
    case FormActions.FORM_FAILURE:
      return updateForm(state, action.payload.id, {
        isSubmitting: false,
        success: false,
        errors: action.payload.errors,
      })
    case FormActions.FORM_FIELD_ERROR:
      return updateForm(state, action.payload.id, {
        isSubmitting: false,
        success: false,
        errors: {
          ...(state[action.payload.id] || {}).errors,
          ...action.payload.errors,
        },
      })
    case FormActions.FORM_CLEAR_FIELD_ERROR: {
      const form = state[action.payload.id] || {}
      const { [action.payload.field]: omit, ...otherErrors } = form.errors
      return updateForm(state, action.payload.id, {
        isSubmitting: false,
        success: false,
        errors: {
          ...otherErrors,
        },
      })
    }
    default:
      return state
  }
}
