import React, { useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { Label } from 'semantic-ui-react'

import { ApplicationState } from 'rootReducer'
import RangeSlider, { SliderOption } from 'common/RangeSlider'
import { makeLoadingSelector } from 'common/Loading/reducer'

import * as actions from './action'
import { PlanFilterOption } from './types'

interface DefaultDynamicPlanFilterProps {
  name: string
  onChange: (name: string, position: SliderOption) => void
  isLoading: boolean
}

const defaultProps: DefaultDynamicPlanFilterProps = {
  name: '',
  onChange: () => null,
  isLoading: false,
}

const PlanFilterLoader = connect(null, {
  fetchPlanFilter: actions.fetchPlanFilter,
})(({ fetchPlanFilter }: { fetchPlanFilter: Function }) => {
  useEffect(() => {
    fetchPlanFilter()
  }, [null])
  return null
})

const lastContainerStyle = {
  position: 'relative' as 'relative',
  minWidth: '100px',
}

const firstContainerStyle = {
  position: 'relative' as 'relative',
  left: '10px',
}

const firstStickerStyle = {
  left: '-2px',
}

const middleStickerStyle = {
  left: '-20px',
}

// This has been too coupled with the label functionality
const customTransformLabel = (position: number, opt: SliderOption) => {
  const getContainerStyle = () => {
    if (opt.position === 0) return firstContainerStyle
    if (opt.position === 100) return lastContainerStyle
    return {}
  }

  const getStickerStyle = () => {
    if (opt.position === 0) return firstStickerStyle
    if (opt.position !== 100) return middleStickerStyle
    return {}
  }

  return (
    <div className="labelContainer" style={getContainerStyle()}>
      <span className="hiddenSelectRegion" />
      {opt.position === position ? (
        <strong>{opt.label}</strong>
      ) : (
        <span className="unselectedLabel">{opt.label}</span>
      )}
      {opt.extraText && (
        <Label className="sticker" style={getStickerStyle()} color="red">
          {opt.extraText}
        </Label>
      )}
      {opt.position !== 0 && opt.position !== 100 && <span className="marker" />}
    </div>
  )
}

const translatePlanFilterToOptions = (planfilter: PlanFilterOption[]): SliderOption[] => {
  let options: SliderOption[] = []
  options = planfilter.map((pfilter) => {
    return {
      position: pfilter.position,
      label: `${pfilter.length} ${pfilter.unit}`,
      extraText: pfilter.promo_text,
      filterValue: pfilter.length,
    }
  })
  return options
}

const ConnectedPlanFilter = connect((state: ApplicationState) => {
  const isLoading = makeLoadingSelector(['GET_PLAN_FILTER'])
  return {
    options: translatePlanFilterToOptions(state.checkout.planFilter),
    isLoading: isLoading(state),
  }
})(RangeSlider)

const DynamicPlanFilter = ({ name, onChange }: DefaultDynamicPlanFilterProps) => {
  return (
    <Fragment>
      <PlanFilterLoader />
      <div style={{ marginBottom: '96px' }}>
        <ConnectedPlanFilter
          name={name}
          onChange={onChange}
          labelClassName="labelContainer"
          transformLabel={customTransformLabel}
        />
      </div>
    </Fragment>
  )
}

DynamicPlanFilter.defaultProps = defaultProps

export default DynamicPlanFilter
