import React, { Fragment, ReactNode } from 'react'
import { Header, Segment, Menu, SegmentProps, SemanticCOLORS } from 'semantic-ui-react'
import classNames from 'classnames'
import { useRouter } from 'common/hooks'
import { useSelector } from 'react-redux'
import { getPlanSchedule, getServiceById } from 'pages/RechargePage/reducer'
import { ApplicationState } from 'rootReducer'
import { Uuid } from '../../types'

interface GenericNavProps {
  footerLink: ReactNode
  heading: string
  children: ReactNode
  redirect?: ReactNode
}

const ColouredSegmentHeading: React.FC<SegmentProps> = ({ className, ...props }) => {
  const { match } = useRouter<{ uuid: Uuid }>()
  const { uuid } = match.params
  const colour = useSelector<ApplicationState, SemanticCOLORS | undefined>((state) => {
    if (match.path !== '/services/:uuid/' || !match.isExact) return undefined
    if (!uuid) return undefined
    const service = getServiceById(state, uuid)
    if (!service) return undefined
    if (service.is_expired) return undefined
    const schedule = getPlanSchedule(state, uuid)
    if (!schedule) return undefined
    return schedule.product.colour
  })
  const newClassName = classNames(className, colour ? `product product-${colour}` : null)
  return <Segment className={newClassName} {...props} />
}

const GenericNav = ({ footerLink, heading, children, redirect }: GenericNavProps) => {
  return (
    <Fragment>
      {redirect}
      <Segment inverted className="sidenav nopadding" color="teal">
        <ColouredSegmentHeading padded basic className="heading">
          <Header as="h1" textAlign="left" inverted icon>
            {heading}
          </Header>
        </ColouredSegmentHeading>
        <Menu size="large" secondary fluid vertical compact>
          {children}
        </Menu>
        <Segment basic style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          {footerLink}
        </Segment>
      </Segment>
    </Fragment>
  )
}

export default GenericNav
