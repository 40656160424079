import axios from 'axios'
import { ActivationCheckoutInterface, ActivationState, Voucher } from './types'

export enum VoucherActionTypes {
  LOAD_VOUCHER_REQUEST = 'LOAD_VOUCHER_REQUEST',
  LOAD_VOUCHER_FAILURE = 'LOAD_VOUCHER_FAILURE',
  LOAD_VOUCHER_SUCCESS = 'LOAD_VOUCHER_SUCCESS',
}

export enum Actions {
  GET_ACTIVATION_STATE_FAILURE = 'GET_ACTIVATION_STATE_FAILURE',
  GET_ACTIVATION_STATE_REQUEST = 'GET_ACTIVATION_STATE_REQUEST',
  GET_ACTIVATION_STATE_SUCCESS = 'GET_ACTIVATION_STATE_SUCCESS',
  ACTIVATION_POLL_FAILURE = 'ACTIVATION_POLL_FAILURE',
  ACTIVATION_POLL_REQUEST = 'ACTIVATION_POLL_REQUEST',
  ACTIVATION_POLL_SUCCESS = 'ACTIVATION_POLL_SUCCESS',
  ACTIVATION_GO_BACK = 'ACTIVATION_GO_BACK',
}

export interface GetActivationStateAction {
  type: Actions
  payload: {
    data?: ActivationCheckoutInterface
  }
}

export interface ActivationBackAction {
  type: Actions.ACTIVATION_GO_BACK
  payload: ActivationState
}

export interface GetVoucherAction {
  type: VoucherActionTypes
  payload?: string
}

export interface GetVoucherSuccessAction {
  type: VoucherActionTypes
  payload: Voucher
}

export type VoucherActions = GetVoucherAction | GetVoucherSuccessAction

export type ActivateActions = GetActivationStateAction | VoucherActions

const getActivationStateRequest = (): GetActivationStateAction => ({
  type: Actions.GET_ACTIVATION_STATE_REQUEST,
  payload: {},
})

const getActivationStateFailure = (): GetActivationStateAction => ({
  type: Actions.GET_ACTIVATION_STATE_FAILURE,
  payload: {},
})

const getActivationStateSuccess = (
  data?: ActivationCheckoutInterface
): GetActivationStateAction => ({
  type: Actions.GET_ACTIVATION_STATE_SUCCESS,
  payload: { data },
})

const activationPollRequest = (): GetActivationStateAction => ({
  type: Actions.ACTIVATION_POLL_REQUEST,
  payload: {},
})

const activatoinPollFailure = (): GetActivationStateAction => ({
  type: Actions.ACTIVATION_POLL_FAILURE,
  payload: {},
})

const activationPollSuccess = (data: ActivationCheckoutInterface): GetActivationStateAction => ({
  type: Actions.ACTIVATION_POLL_SUCCESS,
  payload: { data },
})

export const goBack = (state: ActivationState): ActivationBackAction => ({
  type: Actions.ACTIVATION_GO_BACK,
  payload: state,
})

export const loadVoucherRequest = (voucherCode: string): GetVoucherAction => ({
  type: VoucherActionTypes.LOAD_VOUCHER_REQUEST,
  payload: voucherCode,
})

export const loadVoucherFailure = (): GetVoucherAction => ({
  type: VoucherActionTypes.LOAD_VOUCHER_FAILURE,
})

export const loadVoucherSuccess = (payload: Voucher): GetVoucherSuccessAction => ({
  type: VoucherActionTypes.LOAD_VOUCHER_SUCCESS,
  payload,
})

export type ErrorAction = (err: string[]) => void

export const makeloadVoucher = (eventType: string) => (
  voucherCode: string,
  errorAction?: ErrorAction
) => {
  return (dispatch: Function) => {
    dispatch(loadVoucherRequest(voucherCode))
    axios
      .get(`/api/vouchers/${voucherCode}/`, { params: { event_type: eventType } })
      .then((data) => {
        dispatch(loadVoucherSuccess(data.data))
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          if (error.response.status === 412) {
            dispatch(loadVoucherFailure())
            if (errorAction) dispatch(errorAction(error.response.data.type))
          } else {
            dispatch(loadVoucherFailure())
            if (errorAction) dispatch(errorAction(error.response.data.code))
          }
        } else {
          dispatch(loadVoucherFailure())
          if (errorAction)
            errorAction(['There was an error loading your voucher. Please try again'])
        }
      })
  }
}

export function getActivationState(): Function {
  return (dispatch: Function) => {
    dispatch(getActivationStateRequest())
    axios
      .get('/api/activate/')
      .then((data) => {
        dispatch(getActivationStateSuccess(data.data))
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          dispatch(getActivationStateSuccess())
        } else {
          dispatch(getActivationStateFailure())
        }
      })
  }
}

export function restartActivation(): Function {
  return (dispatch: Function) => {
    axios
      .post('/api/activate/activate/')
      .then((data) => {
        dispatch(getActivationStateSuccess(data.data))
      })
      .catch(() => {
        dispatch(getActivationStateFailure())
      })
  }
}

export function pollActivationState(): Function {
  return (dispatch: Function) => {
    dispatch(activationPollRequest())
    axios
      .get('/api/activate/activate/')
      .then((data) => {
        dispatch(activationPollSuccess(data.data))
      })
      .catch(() => {
        dispatch(activatoinPollFailure())
      })
  }
}
