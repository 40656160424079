import React, { Fragment } from 'react'
import BaseFormField, { BaseFormFieldInterface, FieldOnChange } from 'common/Form/BaseFormField'
import SvgIcon from 'common/SvgIcon'
import { getCardTypeByValue, formatCardNumber } from '../cardTypes'

export default class CreditCardNumberFormField extends React.PureComponent<BaseFormFieldInterface> {
  onChange: FieldOnChange = (event, { name, value }) => {
    const { onChange } = this.props
    onChange(event, { name, value: formatCardNumber(value as string) })
  }

  render() {
    const { errors, value, onChange, ...field } = this.props
    const cardType = getCardTypeByValue(`${value}`)
    const updatedField = {
      ...field,
      type: 'tel',
      maxLength: cardType ? cardType.maxCardNumberLength : 19,
      icon: cardType ? (
        <SvgIcon name={cardType.type} />
      ) : (
        <Fragment>
          <SvgIcon name="visa" className="push-2" />
          <SvgIcon name="mastercard" className="push-1" />
          <SvgIcon name="amex" />
        </Fragment>
      ),
    }
    return (
      <BaseFormField errors={errors} value={value} onChange={this.onChange} {...updatedField} />
    )
  }
}
