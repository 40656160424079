import React, { Fragment } from 'react'
import { FieldErrorsInterface, FieldError } from 'common/Form/'
import { Link } from 'react-router-dom'

const parseErrors = (errors: FieldErrorsInterface) =>
  errors.map(
    (error: FieldError): React.ReactNode => {
      if (typeof error === 'string') {
        return error
      }

      const { actions, message } = error
      return (
        <Fragment>
          {message}{' '}
          {actions.map(({ url, text }: { url: string; text: string }) => {
            if (url.indexOf('://') > 0 || url.indexOf('//') === 0) {
              return (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {text}
                </a>
              )
            }
            return <Link to={url}>{text}</Link>
          })}
        </Fragment>
      )
    }
  )

export default parseErrors
