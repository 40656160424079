import adyen from 'dist/adyen'
import { FormData } from 'common/Form'

const getServerNow = () => {
  const now = Date.now()
  return new Date(now - (window as any).TIME_OFFSET)
}

const getCseInstance = () => {
  return adyen.createEncryption((window as any).ADYEN_PUBLIC_KEY, {})
}

export const validate = (data: FormData) => {
  const cseInstance = getCseInstance()
  return cseInstance.validate(data)
}

const encryptData = (data: FormData) => {
  const cseInstance = getCseInstance()
  const validator = cseInstance.validate(data)

  if (!validator.valid) {
    return null
  }

  return cseInstance.encrypt({
    ...data,
    generationtime: getServerNow().toISOString(),
  })
}

export default encryptData
