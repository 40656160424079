import React, { Fragment } from 'react'
import { Header, Grid, Segment, Modal, Responsive } from 'semantic-ui-react'
import ConnectedForm from 'common/Form'
import makeCreditCardSection from 'common/Form/CreditCardFormSection'
import * as Yup from 'yup'
import ActivationStageHeader from 'common/ActivationStageHeader'
import { PortingRequest } from 'common/Porting/types'
import { connect } from 'react-redux'
import { goBack as connectedGoBack } from 'pages/ActivatePage/actions'
import { PlanSummary } from 'common/ProductCard'
import { ApplicationState } from 'rootReducer'
import styled from 'styled-components'
import { Product } from 'common/Products/types'
import LinkButton from 'common/LinkButton'
import { CreditCardSmallText, VoucherSmallText } from 'common/SmallText'
import { CheckoutStepTracking } from '../../../segment'

const { fields, schema } = makeCreditCardSection()

interface SummaryInterface {
  serviceNumber: string
  plan: Product
  name: string
  email: string
  balanceDue: string
  portingRequest?: PortingRequest
}

interface PaymentFormInterface {
  goBack?: Function
  balanceDue: string
  usingVoucher: boolean
  plan?: Product
}

const StyledHeader = styled.span`
  color: grey;
`

const PlainH4 = styled.h4`
  font-weight: normal;
`

const SummarySection = ({
  serviceNumber,
  plan,
  name,
  email,
  balanceDue,
  portingRequest,
}: SummaryInterface) => {
  return (
    <Fragment>
      {!portingRequest && (
        <Fragment>
          <StyledHeader>Phone Number (on the SIM pack)</StyledHeader>
          <Segment basic secondary>
            <h4>{serviceNumber}</h4>
          </Segment>
        </Fragment>
      )}
      {portingRequest && (
        <Fragment>
          <StyledHeader>Number you want to bring</StyledHeader>
          <Segment basic secondary>
            <h4>{portingRequest.msisdn}</h4>
          </Segment>
          <StyledHeader>Phone Number (on the SIM pack)</StyledHeader>
          <Segment basic secondary>
            <PlainH4>{serviceNumber}</PlainH4>
          </Segment>
        </Fragment>
      )}

      <StyledHeader>Selected Prepay Plan</StyledHeader>
      <PlanSummary plan={plan} />

      <StyledHeader>Contact Details</StyledHeader>
      <Segment.Group className="basic gaps">
        <Segment basic secondary>
          <h4>{name}</h4>
        </Segment>
        <Segment basic secondary>
          <h4>{email}</h4>
        </Segment>
      </Segment.Group>

      <StyledHeader>Total Due</StyledHeader>
      <Segment basic secondary>
        <Header as="h3">${balanceDue}</Header>
      </Segment>
    </Fragment>
  )
}

const ConnectedSummarySection = connect((state: ApplicationState) => {
  const { checkout } = state.activation
  return {
    plan: (checkout && checkout.product) as Product,
    balanceDue: (checkout && checkout.balance_due) || '',
    name: (checkout && checkout.full_name) || '',
    email: (checkout && checkout.email) || '',
    serviceNumber: (checkout && checkout.phone_number) || '',
    portingRequest: (checkout && checkout.porting_request) || undefined,
  }
})(SummarySection)

const MobileBalanceSection = ({ balanceDue }: { balanceDue: string }) => {
  return (
    <p>
      <Modal
        closeIcon
        trigger={
          <LinkButton floated="right" secondary type="button">
            SEE SUMMARY
          </LinkButton>
        }
      >
        <Modal.Header>Summary</Modal.Header>
        <Modal.Content>
          <ConnectedSummarySection />
        </Modal.Content>
      </Modal>
      <Header as="h3">Total Due: ${balanceDue}</Header>
    </p>
  )
}

const PaymentForm = ({ plan, goBack, balanceDue, usingVoucher }: PaymentFormInterface) => {
  const rebillPrice = plan ? parseFloat(plan.price_rebill) : 0
  return (
    <Fragment>
      <div />
      {usingVoucher ? (
        <Header as="h1">
          Enter your billing details to verify your identity and set up future payments!
          <Header.Subheader>Don&apos;t worry, you won&apos;t be charged today</Header.Subheader>
        </Header>
      ) : (
        <Header as="h1">Enter your billing details &amp; activate your Prepay Plan!</Header>
      )}
      <ConnectedForm
        id="activate_payment"
        fields={[
          ...fields,
          {
            name: 'balance',
            content: (
              <Responsive
                key="balance"
                maxWidth={Responsive.onlyMobile.maxWidth}
                as={MobileBalanceSection}
                balanceDue={balanceDue}
              />
            ),
          },
        ]}
        validationSchema={Yup.object(schema)}
        url="/api/activate/pay/"
        submitButtonText="ACTIVATE"
        submitButtonIcon="arrow right"
        cancelProps={{ onClick: goBack, secondary: true }}
        cancelButtonText="BACK"
      />
      <h4>Important Information</h4>
      {usingVoucher ? (
        <VoucherSmallText rebillPrice={rebillPrice} buttonText="Activate" />
      ) : (
        <CreditCardSmallText rebillPrice={rebillPrice} buttonText="Activate" action="activating" />
      )}
    </Fragment>
  )
}

const ConnectedPaymentForm = connect(
  (state: ApplicationState) => ({
    balanceDue: (state.activation.checkout && state.activation.checkout.balance_due) || '',
    usingVoucher: !!(state.activation.checkout && state.activation.checkout.using_voucher),
    plan: state.activation.checkout && state.activation.checkout.product,
  }),
  { goBack: () => connectedGoBack('DETAILS_ENTERED') }
)(PaymentForm)

const PaymentStage = () => (
  <Fragment>
    <CheckoutStepTracking step={6} />
    <ActivationStageHeader activeStage="Payment Details" />
    <Grid columns="equal" stackable relaxed>
      <Grid.Column only="computer tablet" verticalAlign="middle">
        <Segment raised basic padded>
          <Header as="h3">Summary</Header>
          <ConnectedSummarySection />
        </Segment>
      </Grid.Column>
      <Grid.Column>
        <ConnectedPaymentForm />
      </Grid.Column>
    </Grid>
  </Fragment>
)

export default PaymentStage
