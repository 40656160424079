import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { makeLoadingSelector } from 'common/Loading/reducer'
import * as userActions from 'common/UserContainer/actions'
import { ApplicationState } from 'rootReducer'

interface FetchInitialInterface {
  fetched: boolean
  isLoading: boolean
  fetchAuthenticated(): void
}

/*
  When first mounted, trigger the initial user load
 */
class FetchInitialUserComponent extends PureComponent<FetchInitialInterface> {
  componentDidMount(): void {
    const { fetched, isLoading, fetchAuthenticated } = this.props

    if (!fetched && !isLoading) {
      fetchAuthenticated()
    }
  }

  componentDidUpdate(prevProps: FetchInitialInterface): void {
    const { fetched, isLoading, fetchAuthenticated } = this.props
    if (prevProps.fetched && !fetched && !isLoading) {
      fetchAuthenticated()
    }
  }

  render() {
    return null
  }
}

const FetchInitialUser = connect(
  (state: ApplicationState) => ({
    fetched: state.user.fetched,
    isLoading: makeLoadingSelector(['USER_AUTHENTICATED'])(state),
  }),
  {
    fetchAuthenticated: userActions.isAuthenticated,
  }
)(FetchInitialUserComponent)

export default FetchInitialUser
