import React from 'react'
import { Container, ContainerProps } from 'semantic-ui-react'

export const TextContainer = ({ children, ...props }: ContainerProps) => (
  <Container {...props} className="no-margin-container">
    {children}
  </Container>
)

export default TextContainer
