import React, { useState, useCallback, Fragment } from 'react'
import {
  Grid,
  Segment,
  Header,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  Responsive,
  Placeholder,
} from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Link, Redirect } from 'react-router-dom'
import { ServicesLoader } from 'pages/RechargePage'
import { ProductLoader } from 'common/Products'
import makeCreditCardForm from 'common/Form/CreditCardFormSection'
import { makeRetryHandler } from 'pages/CheckoutPage/utils'
import { useBoolean, useParams } from 'common/hooks'
import { getAllAddons } from 'common/Products/reducer'
import { getPhoneNumberByUuid } from 'common/Dashboard/reducer'
import LinkButton from 'common/LinkButton'
import PrimaryActionButton from 'common/Buttons/PrimaryActionButton'
import { FormRenderProps } from 'common/Form/BaseForm'
import { RenderedFieldSet } from 'common/Form/BaseFormField'
import { PaymentType } from 'pages/ActivatePage/Stages/SelectPaymentMethod/VoucherPlanSwitcher'
import SvgIcon from 'common/SvgIcon'
import { CreditCardToken, getSavedCard } from 'common/UserContainer/reducer'
import Form from 'common/Form'
import ActionRow from 'common/ActionRow'
import { Uuid } from 'src/types'
import AddonsSummarySection, { MobileAddonsSummarySection } from '../SummarySection'
import AddonSelectionForm from '../AddonSelectionForm'

const PurchaseAddonsBreadcrumb = () => {
  const { uuid } = useParams()
  const phoneNumber = useSelector(getPhoneNumberByUuid(uuid))
  return (
    <Breadcrumb size="small" className="">
      <BreadcrumbSection
        link
        as={phoneNumber ? Link : Placeholder}
        to={`/services/${uuid}/`}
        push="true"
      >
        {phoneNumber || '000 000 0000'}
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection active>Purchase Add-Ons</BreadcrumbSection>
    </Breadcrumb>
  )
}

export const ADDON_PURCHASE_ID = 'purchase_addons'
const { schema, fields } = makeCreditCardForm()
const RetryHandler = makeRetryHandler(ADDON_PURCHASE_ID)
const renderSuccess = ({ uuid }: { uuid: Uuid }) => (
  <Fragment>
    <Redirect to={`/services/${uuid}/addons/success/`} />
  </Fragment>
)

const images: { [key: string]: JSX.Element } = {
  visa: <SvgIcon size="big" name="visa" />,
  mc: <SvgIcon size="big" name="mastercard" />,
  amex: <SvgIcon size="big" name="amex" />,
}

interface DisplayCardProps {
  card: CreditCardToken
}

const DisplayCard: React.FC<DisplayCardProps> = ({ card }) => (
  <Fragment>
    <p className="semibold">Card Number</p>
    <p className="kogan-text-body1">
      {images[card.card_type]} {card.masked_card_number}
    </p>
  </Fragment>
)

const AddonCheckout: React.FC<{}> = () => {
  const { uuid } = useParams()
  const addons = useSelector(getAllAddons)
  const phoneNumber = useSelector(getPhoneNumberByUuid(uuid))
  const savedCard = useSelector(getSavedCard)

  const [creditCardFormVisible, showCreditCardForm, hideCreditCardForm] = useBoolean(!savedCard)
  const [selectedAddonId, selectAddons] = useState<string>('')
  const [summaryVisible, showSummary, hideSummary] = useBoolean(false)

  const onChange = useCallback(
    (event, data) => {
      selectAddons(data.value)
    },
    [selectAddons]
  )

  const viewAddons = useCallback(() => {
    hideCreditCardForm()
    hideSummary()
  }, [hideCreditCardForm, hideSummary])

  const selectedAddon = addons.find((a) => selectedAddonId === a.unique_id)
  const balanceDue = selectedAddon ? parseFloat(selectedAddon.price) : 0

  const renderSuccessWithUuid = useCallback(() => {
    return renderSuccess({ uuid })
  }, [uuid])

  return (
    <Fragment>
      <ServicesLoader />
      <Grid columns="equal" stackable relaxed>
        {summaryVisible && (
          <Grid.Column only="computer tablet" verticalAlign="middle">
            <Segment raised basic padded>
              <Header as="h3">Summary</Header>
              <AddonsSummarySection
                serviceNumber={phoneNumber}
                addons={selectedAddon ? [selectedAddon] : []}
                balanceDue={balanceDue}
              />
            </Segment>
          </Grid.Column>
        )}
        <Grid.Column>
          <PurchaseAddonsBreadcrumb />
          <ProductLoader />
          <Header as="h2">Purchase Add-Ons</Header>
          {!summaryVisible ? (
            <Fragment>
              <AddonSelectionForm
                name="addons"
                value={selectedAddonId}
                onChange={onChange}
                errors={[]}
              />
              <Grid width={16}>
                <Grid.Column computer={12} tablet={15} mobile={16}>
                  <ActionRow
                    primaryAction={
                      <PrimaryActionButton disabled={!selectedAddonId} onClick={showSummary}>
                        CONTINUE
                      </PrimaryActionButton>
                    }
                    cancelAction={<LinkButton to={`/services/${uuid}/`}>Cancel</LinkButton>}
                  />
                </Grid.Column>
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <RetryHandler />
              <Form
                size="large"
                id={ADDON_PURCHASE_ID}
                url="/api/orders/addons/"
                validationSchema={Yup.object(schema)}
                submitButtonText="CONFIRM &amp; PAY"
                submitButtonIcon="arrow right"
                cancelButtonText="Back"
                cancelProps={{ onClick: viewAddons, secondary: true }}
                renderSuccess={renderSuccessWithUuid}
                initialValue={{
                  use_saved_card: !creditCardFormVisible,
                  save_token: false, // For Add-On purchase we do not want to replace saved card
                  product: selectedAddonId,
                  payment_method: PaymentType.OPTION_PAYMENT,
                  uuid,
                }}
              >
                {(renderProps: FormRenderProps) => (
                  <Fragment>
                    <RenderedFieldSet
                      fields={creditCardFormVisible ? fields : []}
                      {...renderProps}
                    />
                    {!creditCardFormVisible && savedCard && (
                      <Fragment>
                        <DisplayCard card={savedCard} />
                        <LinkButton secondary onClick={showCreditCardForm}>
                          USE ANOTHER CARD
                        </LinkButton>
                        <p />
                      </Fragment>
                    )}
                    {creditCardFormVisible && savedCard && (
                      <Segment basic textAlign="center">
                        <p>
                          The credit card used on your current plan will not be affected by this
                          purchase.
                        </p>
                      </Segment>
                    )}
                    <Responsive
                      key="balance"
                      maxWidth={Responsive.onlyMobile.maxWidth}
                      as={MobileAddonsSummarySection}
                      serviceNumber={phoneNumber}
                      addons={[selectedAddon]}
                      balanceDue={balanceDue}
                    />
                    <p />
                  </Fragment>
                )}
              </Form>
            </Fragment>
          )}
        </Grid.Column>
      </Grid>
    </Fragment>
  )
}

export default AddonCheckout
