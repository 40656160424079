import React, { PureComponent } from 'react'
import { Container, Header } from 'semantic-ui-react'
import SvgIcon from 'common/SvgIcon'
import PrimaryActionButton from 'common/Buttons/PrimaryActionButton'

export const ErrorContainer = ({
  children,
  noPadding,
  message,
  tryAgainMethod,
}: {
  children?: React.ReactNode
  noPadding?: boolean
  message?: string
  tryAgainMethod: tryAgainMethods
}) => (
  <Container text>
    <Header as="h1" textAlign="center" icon style={{ paddingTop: noPadding ? 0 : 90 }}>
      <SvgIcon name="sad-face" color="red" />
      Something Went Wrong
      <Header.Subheader>
        {message || "Unfortunately, something didn't go as planned."}
      </Header.Subheader>
      <Header.Subheader>Please try again later.</Header.Subheader>
    </Header>
    {children}
    {tryAgainMethod === 'refresh' && (
      <PrimaryActionButton as="a" href="." fluid>
        Try Again
      </PrimaryActionButton>
    )}
  </Container>
)

type tryAgainMethods = 'refresh'

interface ErrorBoundaryProps {
  silenceError?: boolean
  message?: React.ReactNode
  children: React.ReactNode
}
interface ErrorBoundaryState {
  hasError: boolean
}

class ErrorBoundary extends PureComponent<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error | null) {
    if (window && window.Sentry) {
      window.Sentry.captureException(error)
    }
    this.setState({ hasError: true })
  }

  render() {
    const { silenceError, message, children } = this.props
    const { hasError } = this.state
    if (hasError) {
      if (silenceError) {
        return null
      }
      // You can render any custom fallback UI
      return <ErrorContainer tryAgainMethod="refresh">{message}</ErrorContainer>
    }
    return children
  }
}

export default ErrorBoundary
