import React from 'react'
import { Header, Transition, Container } from 'semantic-ui-react'
import { connect } from 'react-redux'
import SvgIcon from 'common/SvgIcon'
import { goBack as startAgain } from 'pages/ActivatePage/actions'

import PrimaryActionButton from 'common/Buttons/PrimaryActionButton'

const ResetButton = connect(undefined, { onClick: startAgain })(PrimaryActionButton)

interface Props {
  tryAgainMethod?: 'refresh' | 'startAgain'
}

const ErrorScreen = ({ tryAgainMethod }: Props) => (
  <Transition animation="fade up" transitionOnMount>
    <Container text>
      <Header as="h1" textAlign="center" icon style={{ paddingTop: 90 }}>
        <SvgIcon name="sad-face" color="red" />
        Something Went Wrong
        <Header.Subheader>Unfortunately, something didn&apos;t go as planned.</Header.Subheader>
        <Header.Subheader>Please try again later.</Header.Subheader>
      </Header>
      {tryAgainMethod === 'refresh' && (
        <PrimaryActionButton as="a" href="." fluid>
          Try Again
        </PrimaryActionButton>
      )}
      {tryAgainMethod === 'startAgain' && <ResetButton fluid>Try Again</ResetButton>}
    </Container>
  </Transition>
)

export default ErrorScreen
