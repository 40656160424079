import React, { Fragment } from 'react'
import { Uuid } from 'src/types'
import Form, { GroupedFieldInterface } from 'common/Form'
import { API_AUTH_BASE } from 'common/UserContainer/actions'
import { User } from 'common/UserContainer/reducer'
import { DropDownItem, makeDropdown } from 'common/Dropdown'
import { makeLoadingSelector } from 'common/Loading/reducer'
import { Redirect } from 'react-router-dom'
import { Divider, Grid, Header } from 'semantic-ui-react'
import { ServicesLoader } from 'pages/RechargePage'
import { getServiceById } from 'pages/RechargePage/reducer'
import LinkButton from 'common/LinkButton'

export const CONTACT_DETAILS_FORM_ID = 'contact_details'

const ContactNumberDropdown = makeDropdown(
  (state) => {
    const options: DropDownItem[] = state.services.allUuids.map(
      (uuid: Uuid): DropDownItem => {
        const service = getServiceById(state, uuid)
        return {
          key: service.uuid,
          text: service.phone_number,
          value: service.msisdn,
        }
      }
    )

    // If the existing number was not in the dropdown, add it as an option
    const existing = state.user.user && state.user.user.phone
    if (existing && state.user.user && !options.find((option) => option.key === existing)) {
      // Put it at the start (unshift)
      options.unshift({
        key: existing,
        text: state.user.user.phone_display || existing,
        value: existing,
      })
    }
    return options
  },
  (state) => makeLoadingSelector(['GET_SERVICES'])(state)
)

const fields: GroupedFieldInterface[] = [
  {
    name: 'email',
    label: 'Email Address',
    placeholder: 'eg. you@example.com',
    type: 'email',
    disabled: true,
    required: true,
    maxLength: 254,
  },
  {
    name: 'button',
    content: (
      <LinkButton to="/account/contact-details/change-email/">CHANGE EMAIL ADDRESS</LinkButton>
    ),
  },
  { name: 'divider', content: <Divider /> },
  [
    {
      name: 'first_name',
      label: 'First Name',
      type: 'text',
      required: true,
      maxLength: 30,
    },
    {
      name: 'last_name',
      label: 'Last Name',
      type: 'text',
      maxLength: 150,
    },
  ],
  {
    name: 'phone',
    label: 'Primary Contact Number',
    required: true,
    component: ContactNumberDropdown,
  },
]

interface ContactDetailsResponse {
  user: User
  email_verification: boolean
}

function interceptEmailVerification(responseData: ContactDetailsResponse) {
  if (!responseData.email_verification) return null
  return <Redirect to="/account/contact-details/phone-confirm/" />
}

interface Props {
  user: User | null
}

const ContactDetailsForm = ({ user }: Props) => (
  <Fragment>
    <Header as="h1">Contact Details</Header>
    <ServicesLoader />
    <Grid relaxed stackable divided="vertically">
      <Grid.Row>
        <Grid.Column mobile={16} tablet={16} computer={9}>
          <Form
            id={CONTACT_DETAILS_FORM_ID}
            key={(user && user.last_updated) || ''}
            fields={fields}
            submitButtonText="UPDATE"
            submitButtonIcon="arrow right"
            url={`${API_AUTH_BASE}update_details/`}
            resetKey={(user && user.last_updated) || ''}
            initialValue={{
              first_name: (user && user.first_name) || '',
              last_name: (user && user.last_name) || '',
              phone: (user && user.phone) || '',
              email: (user && user.email) || '',
            }}
            renderSuccess={interceptEmailVerification}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Fragment>
)

export default ContactDetailsForm
