import { LoginTextAction, RouteTypes } from 'common/Routes/actions'
import { ApplicationState } from 'rootReducer'
import { Actions, GetActivationStateAction } from 'pages/ActivatePage/actions'

export interface RouterState {
  nextLoginPage?: string
}

export const initialRouterState: RouterState = {}

export default function reducer(
  state = initialRouterState,
  action: LoginTextAction | GetActivationStateAction
): RouterState {
  switch (action.type) {
    case RouteTypes.LoginNext:
      return { ...state, nextLoginPage: action.url }
    case Actions.ACTIVATION_POLL_SUCCESS:
      return { ...state, nextLoginPage: undefined }
    default:
      return state
  }
}

export const getNextLoginPage = (state: ApplicationState): string | undefined => {
  return state.router.nextLoginPage
}
