import React from 'react'
import { Header, Icon, Segment } from 'semantic-ui-react'
import LinkButton from 'common/LinkButton'
import Layout from 'common/Layout'

const PortingSuccessPage = () => {
  return (
    <Layout.Page form="two wide">
      <Header as="h1" textAlign="center">
        <Header.Content>
          <Icon name="check" color="teal" /> Number Transfer Request Submitted
          <Header.Subheader>
            You will receive an email confirmation upon success of your Number Transfer Request.
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Segment basic textAlign="center">
        <LinkButton secondary to="/">
          Back to Dashboard
        </LinkButton>
      </Segment>
    </Layout.Page>
  )
}

export default PortingSuccessPage
