import React, { Fragment } from 'react'
import { Table, Placeholder } from 'semantic-ui-react'

const PlaceholderRows = ({ columns, rows }: { columns: number; rows: number }) => {
  const result = []
  for (let i = 0; i < rows; i++) {
    const row = []
    for (let j = 0; j < columns; j++) {
      row.push(
        <Table.Cell key={j}>
          <Placeholder fluid>
            <Placeholder.Line />
          </Placeholder>
        </Table.Cell>
      )
    }
    result.push(<Table.Row key={i}>{row}</Table.Row>)
  }
  return <Fragment>{result}</Fragment>
}

export default PlaceholderRows
