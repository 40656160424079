import { Header, Grid, Image, Button, Icon } from 'semantic-ui-react'
import React, { Fragment, useMemo } from 'react'
import * as Yup from 'yup'
import ConnectedForm, { GroupedFieldInterface } from 'common/Form'
import ActivationStageHeader from 'common/ActivationStageHeader'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { goBack } from 'pages/ActivatePage/actions'
import { ApplicationState } from 'rootReducer'
import { ButtonRenderer, submitIconStyle } from 'common/Form/BaseForm'
import { useResponsive } from 'common/hooks'
import { CheckoutStepTracking } from '../../../segment'

const fields: GroupedFieldInterface[] = [
  {
    label: 'Full Name',
    name: 'full_name',
    required: true,
  },
  {
    label: 'Email',
    name: 'email',
    required: true,
    type: 'email',
  },
  {
    label: 'Create a New Password',
    name: 'password',
    required: true,
    masked: true,
    type: 'password',
  },
  {
    label: (
      /* eslint-disable-next-line */
      <label>
        By clicking CONTINUE, you are agreeing to the Kogan Mobile{' '}
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://www.koganmobile.co.nz/terms-conditions/" target="_blank">
          Terms &amp; Conditions
        </a>{' '}
        and {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a href="https://www.koganmobile.co.nz/privacy/" target="_blank">
          Privacy Policy
        </a>
        , and to receiving marketing communications from Kogan Mobile and its partners.
      </label>
    ),
    name: 'marketing_consent',
    type: 'checkbox',
  },
]

const signupSchema = Yup.object({
  email: Yup.string().required('This field is required.'),
  full_name: Yup.string()
    .required('This field is required.')
    .test('both_names', 'Please provide your full name.', (val) => /\s/.test(val)),
  password: Yup.string()
    .min(6, 'Your password must be at least 6 characters.')
    .required('This field is required.'),
  marketing_consent: Yup.boolean(),
})

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const personalDetailsImageSrc = require('static/img/personal-details.png')

const PersonalDetailsImage = styled(Image)`
  padding-right: 26px;
  margin-top: 3em;
`

interface SignupStageStageInterface {
  name: string
  email: string
  marketing_consent: boolean
  setup_porting: boolean
  doStartAgain: () => void
  backToPorting: () => void
}

const PERSONAL_DETAILS_FORM_ID = 'activate_2_contact_details'

const SignupStage = (props: SignupStageStageInterface) => {
  const { name, email, marketing_consent, setup_porting, doStartAgain, backToPorting } = props

  const RenderSubmitButton: React.FC<ButtonRenderer> = useMemo(
    () => ({ isSubmitting, isDisabled, submitButtonIcon, submitButtonText, hasCancelButton }) => {
      const responsiveData = useResponsive()
      return (
        <Button
          type="submit"
          primary
          loading={isSubmitting}
          disabled={isDisabled || !marketing_consent}
          size="medium"
          fluid={responsiveData.isMobile || !hasCancelButton}
          tabIndex={0}
        >
          {submitButtonText}
          {submitButtonIcon && <Icon name={submitButtonIcon} style={submitIconStyle} />}
        </Button>
      )
    },
    [marketing_consent]
  )

  const backAction = setup_porting ? backToPorting : doStartAgain
  return (
    <Fragment>
      <CheckoutStepTracking step={3} />
      <ActivationStageHeader activeStage="Personal Details" />
      <Grid columns="equal" relaxed>
        <Grid.Row>
          <Grid.Column only="computer tablet">
            <PersonalDetailsImage src={personalDetailsImageSrc} centered />
          </Grid.Column>
          <Grid.Column>
            {/* Add empty div to give Header top margin */}
            <div />
            <Header as="h1">Your Personal Details</Header>
            <ConnectedForm
              id={PERSONAL_DETAILS_FORM_ID}
              fields={fields}
              initialValue={{
                full_name: name,
                email,
              }}
              validationSchema={signupSchema}
              url="/api/activate/signup/"
              submitButtonText="CONTINUE"
              submitButtonIcon="arrow right"
              renderSubmitButton={RenderSubmitButton}
              cancelButtonText="BACK"
              cancelProps={{ onClick: backAction, secondary: true }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

export default connect(
  (state: ApplicationState) => {
    const { activation, forms } = state
    const { checkout } = activation
    return {
      name: (checkout && checkout.full_name) || '',
      email: (checkout && checkout.email) || '',
      setup_porting: (checkout && checkout.setup_porting) || false,
      marketing_consent:
        (forms &&
          forms[PERSONAL_DETAILS_FORM_ID] &&
          forms[PERSONAL_DETAILS_FORM_ID].data &&
          forms[PERSONAL_DETAILS_FORM_ID].data.marketing_consent) ||
        false,
    }
  },
  {
    backToPorting: () => goBack('PORTING_ENTERED'),
    doStartAgain: () => goBack(null),
  }
)(SignupStage)
