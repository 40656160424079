import React from 'react'
import Layout from 'common/Layout'
import ConnectedForgotPasswordForm from './ForgotPasswordForm'

const ForgotPasswordPage = () => (
  <Layout.Page form>
    <ConnectedForgotPasswordForm />
  </Layout.Page>
)

export default ForgotPasswordPage
