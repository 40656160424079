import React, { Fragment } from 'react'
import Form, { FieldInterface } from 'common/Form'
import SuccessConfirmation from 'common/Form/SuccessConfirmation'
import { Header, Segment } from 'semantic-ui-react'
import { API_AUTH_BASE } from 'common/UserContainer/actions'
import { connect } from 'react-redux'
import { BodyText1 } from 'common/Typography'
import LinkButton from 'common/LinkButton'
import { ApplicationState } from 'rootReducer'

export const PASSWORD_RESET_FORM_ID = 'forgot-password'

const fields: FieldInterface[] = [
  {
    name: 'email',
    icon: 'mail',
    iconPosition: 'left',
    placeholder: 'E-mail',
    type: 'email',
    required: true,
  },
]
const successMessage = ({ email }: { email: string }) => (
  <SuccessConfirmation title="Email Sent">
    <Fragment>
      A link to reset your password has been sent to <b>{email}</b>
    </Fragment>
  </SuccessConfirmation>
)

const ForgotPasswordForm = ({ resetEmail }: { resetEmail: string | undefined }) => (
  <Fragment>
    <Form
      id={PASSWORD_RESET_FORM_ID}
      fields={fields}
      submitButtonText="RESET PASSWORD"
      submitButtonIcon="arrow right"
      renderSuccess={successMessage}
      url={`${API_AUTH_BASE}password_reset_request/`}
      initialValue={{ email: resetEmail || '' }}
      headerSection={
        <Fragment>
          <Header as="h1" textAlign="left">
            Forgot Password?
          </Header>
          <BodyText1>
            Enter your email and we&apos;ll send you a link to reset your password.
          </BodyText1>
        </Fragment>
      }
    />
    <Segment vertical textAlign="center">
      <LinkButton to="/login/">Back to login</LinkButton>
    </Segment>
  </Fragment>
)

const mapStateToProps = (state: ApplicationState) => {
  const { passwordReset } = state

  return {
    ...passwordReset,
  }
}

const ConnectedForgotPasswordForm = connect(mapStateToProps)(ForgotPasswordForm)

export default ConnectedForgotPasswordForm
