import React, { Fragment, useCallback, useEffect } from 'react'
import {
  Grid,
  Header,
  Image,
  Breadcrumb,
  BreadcrumbSection,
  BreadcrumbDivider,
  TransitionGroup,
  Placeholder,
} from 'semantic-ui-react'
import { connect, useDispatch, useSelector } from 'react-redux'
import ConnectedForm from 'common/Form'
import { Link, Redirect } from 'react-router-dom'
import { getPhoneNumberByUuid } from 'common/Dashboard/reducer'
import { useParams, useBooleanToggle, useNavigate, useResponsive } from 'common/hooks'
import SimInfo from 'common/Porting/SimInfo'
import { Uuid } from 'src/types'
import { getPortingProviders } from 'common/Porting/actions'
import { generatePortingFormFields, portingFormschema, BILLING_METHODS } from 'common/Porting/Forms'
import { ApplicationState } from 'rootReducer'
import { ServicesLoader } from 'pages/RechargePage'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const portingImage = require('static/img/porting-details.png')

const PortingServiceBreadcrumb = () => {
  const { uuid } = useParams()
  const phoneNumber = useSelector(getPhoneNumberByUuid(uuid))
  return (
    <Breadcrumb size="small" className="">
      <BreadcrumbSection
        link
        as={phoneNumber ? Link : Placeholder}
        to={`/services/${uuid}/`}
        push="true"
      >
        {phoneNumber || '000 000 0000'}
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection active>Request Number Transfer</BreadcrumbSection>
    </Breadcrumb>
  )
}

const PortingProvidersLoader: React.FC<{}> = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getPortingProviders())
  }, [null])
  return null
}

const renderSuccess = ({ uuid }: { uuid: Uuid }) => (
  <Fragment>
    <Redirect to={`/services/${uuid}/port/success/`} />
  </Fragment>
)

interface ServicePortingFormInterface {
  handleCancelClick: () => void
  toggleSimHelp: () => void
  successHandler: () => void
  hideSimHelp: () => void
  showSimHelp: () => void
  isPortHelpShown: boolean
  uuid: Uuid
  method: number
}

const PortingForm = ({
  method,
  toggleSimHelp,
  isPortHelpShown,
  hideSimHelp,
  showSimHelp,
  handleCancelClick,
  successHandler,
  uuid,
}: ServicePortingFormInterface) => {
  const { isMobile } = useResponsive()
  // Show SIM Help when we select prepay option in radio button
  // Do so only in desktop
  useEffect(() => {
    if (method === BILLING_METHODS.PREPAY && !isMobile) {
      showSimHelp()
    }
    if (method === BILLING_METHODS.ACCOUNT && !isMobile) {
      hideSimHelp()
    }
  }, [method])

  return (
    <Fragment>
      <ConnectedForm
        id="porting_service"
        fields={generatePortingFormFields(method, toggleSimHelp, isPortHelpShown)}
        validationSchema={portingFormschema}
        url={`/api/services/port/${uuid}/`}
        renderSuccess={successHandler}
        submitButtonText="Port Number"
        submitButtonIcon="arrow right"
        cancelProps={{ onClick: handleCancelClick, secondary: true }}
        cancelButtonText="Cancel"
      />
    </Fragment>
  )
}

const ConnectedPortingForm = connect((state: ApplicationState) => {
  const { porting_service } = state.forms
  return {
    method: (porting_service && porting_service.data && porting_service.data.billing_method) || '',
  }
})(PortingForm)

const InfoColumn = ({ isPortHelpShown, method }: { isPortHelpShown: boolean; method: number }) => {
  return (
    <TransitionGroup animation="slide left" duration={{ hide: 0, show: 300 }}>
      {isPortHelpShown && (
        <div>
          <SimInfo method={method} />
        </div>
      )}
      {!isPortHelpShown && (
        <div>
          <Image src={portingImage} />
        </div>
      )}
    </TransitionGroup>
  )
}

const ConnectedInfoColumn = connect((state: ApplicationState) => {
  const { activate_2_porting_details } = state.forms
  return {
    method:
      (activate_2_porting_details &&
        activate_2_porting_details.data &&
        activate_2_porting_details.data.billing_method) ||
      '',
  }
})(InfoColumn)

const PortingService = () => {
  const [isPortHelpShown, toggleSimHelp, showSimHelp, hideSimHelp] = useBooleanToggle(false)
  const { uuid } = useParams()
  const navigate = useNavigate()

  const handleCancelClick = useCallback(() => navigate(`/services/${uuid}/`), [uuid, navigate])

  const renderSuccessWithUuid = useCallback(() => {
    return renderSuccess({ uuid })
  }, [uuid])

  return (
    <Grid columns="equal" stackable relaxed>
      <ServicesLoader />
      <PortingProvidersLoader />
      <Grid.Column only="computer tablet" verticalAlign="middle">
        <ConnectedInfoColumn isPortHelpShown={isPortHelpShown} />
      </Grid.Column>
      <Grid.Column>
        <PortingServiceBreadcrumb />
        <Header as="h2">Request Number Transfer</Header>
        <ConnectedPortingForm
          isPortHelpShown={isPortHelpShown}
          toggleSimHelp={toggleSimHelp}
          hideSimHelp={hideSimHelp}
          showSimHelp={showSimHelp}
          handleCancelClick={handleCancelClick}
          successHandler={renderSuccessWithUuid}
          uuid={uuid}
        />
      </Grid.Column>
    </Grid>
  )
}

export default PortingService
