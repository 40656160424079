import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import classNames from 'classnames'

const LinkButton = (props: any) => {
  const { to: isRouterLink, href: isAnchor, onClick: isButton, className } = props

  if (isRouterLink) {
    return <Link {...props} className={classNames(className, 'kogan-text-overline')} />
  }
  if (isAnchor) {
    const { secondary: _, ...linkProps } = props
    // eslint-disable-next-line jsx-a11y/anchor-has-content
    return <a {...linkProps} className={classNames(className, 'kogan-text-overline')} />
  }
  if (isButton) {
    return (
      <Button
        basic
        type="button"
        {...props}
        className={classNames(className, 'kogan-text-overline')}
      />
    )
  }
  throw Error('You must specify a "to", "href" or "onClick" prop')
}
export default LinkButton
