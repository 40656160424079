import { FormActions, SuccessAction, FailAction } from 'common/Form/actions'
import { Actions as PlanFilterActions, GetPlanFilterAction } from 'common/DynamicPlanFilter/action'
import { Product } from 'common/Products/types'
import { PlanFilterOption } from 'common/DynamicPlanFilter/types'
import { RECHARGE_FORM_ID } from 'pages/RechargePage'
import { ADDON_PURCHASE_ID } from 'pages/PurchaseAddonsPage/AddonCheckout'

export interface CheckoutState {
  orderId?: string
  products: Product[]
  planFilter: PlanFilterOption[]
  retry: boolean
}

export const initialState: CheckoutState = {
  products: [],
  planFilter: [],
  retry: false,
}

const PAYMENT_FORMS = [RECHARGE_FORM_ID, ADDON_PURCHASE_ID]

export default function reducer(
  state = initialState,
  action: SuccessAction | GetPlanFilterAction | FailAction
): CheckoutState {
  switch (action.type) {
    case FormActions.FORM_SUCCESS:
      if (PAYMENT_FORMS.includes(action.payload.id)) {
        return {
          ...state,
          retry: action.payload.status === 202,
          orderId: action.payload.data.order_code,
        }
      }
      return state
    case FormActions.FORM_FAILURE:
      if (PAYMENT_FORMS.includes(action.payload.id)) {
        return {
          ...state,
          retry: false,
        }
      }
      return state
    case PlanFilterActions.GET_PLAN_FILTER_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          planFilter: action.payload.data,
        }
      }
      return state
    default:
      return state
  }
}
