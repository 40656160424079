import axios from 'axios'

export enum PortingProviderActions {
  REQUEST = 'GET_PORTING_PROVIDERS_REQUEST',
  SUCCESS = 'GET_PORTING_PROVIDERS_SUCCESS',
  FAILURE = 'GET_PORTING_PROVIDERS_FAILURE',
}

export interface PortingProviderAction {
  type: PortingProviderActions
  payload?: [string, string][]
}

const getProvidersRequest = (): PortingProviderAction => ({
  type: PortingProviderActions.REQUEST,
})

const getProvidersSuccess = (payload: [string, string][]): PortingProviderAction => ({
  type: PortingProviderActions.SUCCESS,
  payload,
})

const getProvidersFailure = (): PortingProviderAction => ({
  type: PortingProviderActions.FAILURE,
})

export const getPortingProviders = () => {
  return (dispatch: Function) => {
    dispatch(getProvidersRequest())
    axios
      .get('/api/account/porting_providers/')
      .then((data) => {
        if (data.data.providers) {
          dispatch(getProvidersSuccess(data.data.providers))
        }
      })
      .catch(() => {
        dispatch(getProvidersFailure())
      })
  }
}

export const acknowledgePortingFailure = (uuid: string) => {
  return () => {
    axios.post(`/api/services/acknowledge_port_failure/${uuid}/`, {})
  }
}
