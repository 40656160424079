import React, { Fragment, useMemo, useEffect, useCallback } from 'react'
import * as Yup from 'yup'
import { Header, Grid, Segment, Responsive, Message } from 'semantic-ui-react'
import { withUser } from 'common/UserContainer'
import Form, { FormSubmitSignature, FormData, DirtyFields } from 'common/Form'
import makeCreditCardSection from 'common/Form/CreditCardFormSection'
import { CreditCardToken, User } from 'common/UserContainer/reducer'
import { RenderedFieldSet } from 'common/Form/BaseFormField'
import SubmitIntercept from 'common/Form/SubmitIntercept'
import AddPaymentMethod from 'pages/PaymentMethodsPage/AddPaymentMethod'
import { useBoolean, useToggle, useResponsive } from 'common/hooks'
import SvgIcon from 'common/SvgIcon'
import { ClickEvent } from 'common/Utils'
import { connect } from 'react-redux'
import { clearFields } from 'common/Form/actions'
import PrimaryActionButton from 'common/Buttons/PrimaryActionButton'
import DashboardContentContainer from 'common/Dashboard'
import { Route } from 'react-router-dom'
import { OnlyProps } from 'src/types'
import { BodyText1 } from 'common/Typography'

export const UPDATE_CARD_FORM_ID = 'update-card'

const images: { [key: string]: JSX.Element } = {
  visa: <SvgIcon size="huge" name="visa" />,
  mc: <SvgIcon size="huge" name="mastercard" />,
  amex: <SvgIcon size="huge" name="amex" />,
}

const { fields, schema } = makeCreditCardSection()

const discardCleanFields: FormSubmitSignature = (data, errors, dirtyFields) => {
  const newData = Object.keys(data).reduce((acc: FormData, key) => {
    if (dirtyFields.indexOf(key) !== -1) {
      acc[key] = data[key]
    }
    return acc
  }, {})
  return { data: newData, errors }
}

const DisplayedCard = ({
  creditCard,
  onClick,
}: {
  creditCard: CreditCardToken
  onClick: ClickEvent
}) => {
  return (
    <div>
      <Grid padded="vertically" stretched>
        <Grid.Row>
          <Grid.Column computer={2} mobile={3}>
            <Grid.Row>&nbsp;</Grid.Row>
            <Grid.Row verticalAlign="bottom">{images[creditCard.card_type]}</Grid.Row>
          </Grid.Column>
          <Grid.Column computer={4} mobile={12}>
            <Grid.Row>
              <div className="semibold">Card Number</div>
            </Grid.Row>
            <Grid.Row>{creditCard.masked_card_number}</Grid.Row>
          </Grid.Column>
          <Grid.Column only="tablet mobile" mobile={3} tablet={3} />
          <Grid.Column computer={2} mobile={4}>
            <Grid.Row className={creditCard.is_expired ? 'text red' : undefined}>
              <div className="semibold">Expiry</div>
            </Grid.Row>
            <Grid.Row className={creditCard.is_expired ? 'text red' : undefined}>
              {creditCard.formatted_expiry_date}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column computer={5} mobile={8}>
            <Grid.Row>
              <div className="semibold">Name on Card</div>
            </Grid.Row>
            <Grid.Row>{creditCard.card_holder_name}</Grid.Row>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column mobile={16} computer={13}>
            <Segment basic textAlign="right">
              <Responsive as="div" maxWidth={Responsive.onlyMobile.maxWidth}>
                <PrimaryActionButton fluid onClick={onClick}>
                  UPDATE CARD
                </PrimaryActionButton>
              </Responsive>
              <Responsive as="div" minWidth={Responsive.onlyTablet.minWidth}>
                <PrimaryActionButton onClick={onClick}>UPDATE CARD</PrimaryActionButton>
              </Responsive>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

interface UnconnectedClearFormHandlerProps {
  formId: string
  formData: FormData
  dirtyFields: DirtyFields
  localClearFields: (formId: string, fieldName: string[]) => void
}

/**
 * UnconnectedClearFormHandler
 *
 * Clears the form if the trigger field is edited
 */
const UnconnectedClearFormHandler = ({
  formId,
  formData,
  dirtyFields,
  localClearFields,
}: UnconnectedClearFormHandlerProps) => {
  useEffect(() => {
    // If number and name have a value, we can safetly clear the form
    if (formData['credit-card-number'] && formData['credit-card-name']) {
      localClearFields(formId, [
        'credit-card-number',
        'credit-card-name',
        'credit-card-expiry',
        'credit-card-cvc',
      ])
    }
  }, [dirtyFields['credit-card-number']])
  return null
}

const ClearFormHandler = connect(undefined, { localClearFields: clearFields })(
  UnconnectedClearFormHandler
)

const ChangeCardForm = ({ creditCard }: { creditCard?: CreditCardToken }) => {
  const initialValue = useMemo(() => {
    if (!creditCard) return undefined

    return {
      'credit-card-number': creditCard.masked_card_number,
      'credit-card-expiry': creditCard.formatted_expiry_date,
      'credit-card-name': creditCard.card_holder_name,
    }
  }, [creditCard])

  const [showUpdatedSuccess, setUpdatedSuccess, removeUpdatedSuccess] = useBoolean(false)
  const [editing, setEditing, disableEditing] = useBoolean(false)

  const cancelAndHideSuccess = useCallback(() => {
    removeUpdatedSuccess()
    disableEditing()
  }, [removeUpdatedSuccess, disableEditing])

  const renderSuccess = useCallback(() => {
    setUpdatedSuccess()
    disableEditing()
    return null
  }, [setUpdatedSuccess, disableEditing])

  if (!editing && creditCard) {
    return (
      <Fragment>
        {showUpdatedSuccess && (
          <Message
            success
            size="small"
            content="Thank you, we've updated your payment details."
            icon="checkmark"
          />
        )}
        <DisplayedCard creditCard={creditCard} onClick={setEditing} />
      </Fragment>
    )
  }

  // Add cancel button to form if a card exists
  let extraProps = {}
  if (creditCard !== null) {
    extraProps = {
      cancelButtonText: 'Cancel',
      cancelProps: { onClick: cancelAndHideSuccess, secondary: true },
    }
  }

  return (
    <Grid padded="vertically" stretched>
      <Grid.Column mobile={16} tablet={16} computer={14} largeScreen={14}>
        <Form
          url="/api/orders/update_payment/"
          id={UPDATE_CARD_FORM_ID}
          validationSchema={Yup.object(schema)}
          submitButtonText="SAVE CARD"
          submitButtonIcon="arrow right"
          initialValue={initialValue}
          renderSuccess={renderSuccess}
          {...extraProps}
        >
          {(renderProps) => {
            return (
              <Fragment>
                <ClearFormHandler
                  formId={UPDATE_CARD_FORM_ID}
                  formData={renderProps.formData}
                  dirtyFields={renderProps.dirtyFields}
                />
                <SubmitIntercept handler={discardCleanFields} />
                <RenderedFieldSet fields={fields} {...renderProps} />
              </Fragment>
            )
          }}
        </Form>
      </Grid.Column>
    </Grid>
  )
}

const EditCard = ({ existing }: { existing?: CreditCardToken }) => {
  return (
    <Fragment>
      <BodyText1>
        We&apos;ll use the payment details below for your next Prepay Plan renewal or Add-On
        purchase.
      </BodyText1>
      <ChangeCardForm creditCard={existing} />
    </Fragment>
  )
}

const PaymentMethodsPage = withUser(function PaymentMethodsPage({ user }: { user: User }) {
  const [isEditing, enableEditing] = useToggle(false)
  const { isMobile } = useResponsive()
  return (
    <Segment padded={!isMobile ? 'very' : undefined}>
      <Grid stretched>
        <Grid.Column mobile={16} tablet={16} largeScreen={12} widescreen={12}>
          <Header as="h1">Payment Method</Header>
          {isEditing || user.credit_card_token ? (
            <EditCard existing={user.credit_card_token} />
          ) : (
            <AddPaymentMethod onAddNewCard={enableEditing} />
          )}
        </Grid.Column>
      </Grid>
    </Segment>
  )
})

const RoutedPaymentMethodsPage = ({ only }: OnlyProps) => (
  <DashboardContentContainer only={only}>
    <Route path="/account/payment-method/" component={PaymentMethodsPage} />
  </DashboardContentContainer>
)

export default RoutedPaymentMethodsPage
