import React from 'react'
import { Container, Segment, Grid, Menu } from 'semantic-ui-react'
import { connect } from 'react-redux'
import TextContainer from 'common/TextContainer'
import { MenuInterface } from 'common/Layout/reducer'
import { translateColumn } from 'common/menuHelpers'
import { ApplicationState } from 'rootReducer'

interface FooterProps {
  layout?: MenuInterface
  className?: string
}

const Footer = ({ layout, className }: FooterProps) => (
  <Segment basic inverted padded as="footer" className={className}>
    <Container>
      <Grid columns={4} stackable>
        {layout &&
          layout.sections.map((section) => (
            <Grid.Column key={section.id}>
              <Menu text vertical inverted size="small">
                {section.value.heading && <Menu.Item header>{section.value.heading}</Menu.Item>}
                {translateColumn(section)}
              </Menu>
            </Grid.Column>
          ))}
      </Grid>
      <TextContainer textAlign="center">
        <p>&copy; Copyright {new Date().getFullYear()}. Kogan Mobile Operations Pty Ltd</p>
      </TextContainer>
    </Container>
  </Segment>
)

export default connect((state: ApplicationState) => ({
  layout: state.layout.menus.footer,
}))(Footer)
