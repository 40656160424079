import React from 'react'
import GroupedPlanCard from 'common/GroupedPlanCard'
import { Product } from 'common/Products/types'
import { orderByLength, orderByDataLimit } from 'common/Products'
import { groupBy } from 'common/Utils'

interface AllPlanlistProps {
  plans: Product[]
}

const AllPlanlist = ({ plans }: AllPlanlistProps) => {
  const groupedPlans = groupBy(plans.sort(orderByDataLimit), 'data')
  return (
    <div>
      {Object.keys(groupedPlans)
        .sort((a, b) => parseFloat(a) - parseFloat(b))
        .map((planSize) => {
          const sortedPlans = [...groupedPlans[planSize]].sort(orderByLength)

          return (
            <GroupedPlanCard
              key={planSize}
              plans={sortedPlans}
              color={sortedPlans[0].colour}
              groupTitle={sortedPlans[0].title}
              groupSize={Number(planSize)}
            />
          )
        })}
    </div>
  )
}

export default AllPlanlist
