import React from 'react'
import Layout from 'common/Layout'
import { useParams } from 'common/hooks'
import PasswordResetForm from './PasswordResetForm'

const PasswordResetPage = () => {
  const { uid64, token } = useParams()
  return (
    <Layout.Page form>
      <PasswordResetForm uid={uid64} token={token} />
    </Layout.Page>
  )
}

export default PasswordResetPage
