import React from 'react'
import { Grid, Container, Segment } from 'semantic-ui-react'
import PrimaryActionButton from 'common/Buttons/PrimaryActionButton'

const AddPaymentMethod = ({ onAddNewCard }: { onAddNewCard: () => void }) => (
  <Container>
    <Grid padded="vertically" stretched>
      <Grid.Row>
        <Grid.Column width={16}>
          <p>You don&apos;t have a saved credit card</p>
          <p>Add a credit card to enable auto-recharge and express checkout on future purchases.</p>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column textAlign="right" />
      </Grid.Row>
    </Grid>
    <Segment basic textAlign="right">
      <PrimaryActionButton onClick={onAddNewCard}>ADD CREDIT CARD</PrimaryActionButton>
    </Segment>
  </Container>
)

export default AddPaymentMethod
