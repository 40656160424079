import React, { Fragment } from 'react'
import Form, { FieldInterface } from 'common/Form'
import SuccessConfirmation from 'common/Form/SuccessConfirmation'
import { Header, Segment } from 'semantic-ui-react'
import { BodyText1 } from 'common/Typography'
import LinkButton from 'common/LinkButton'
import { API_AUTH_BASE } from 'common/UserContainer/actions'
import UserContainer from 'common/UserContainer'
import * as Yup from 'yup'

export const PASSWORD_CHANGE_FORM_ID = 'change-password'

const fields: FieldInterface[] = [
  {
    name: 'old_password',
    label: 'Current Password',
    placeholder: 'Please enter your old password',
    type: 'password',
    required: true,
    masked: true,
    autocomplete: 'current-password',
  },
  {
    name: 'new_password',
    label: 'New Password',
    placeholder: 'Your new password',
    type: 'password',
    required: true,
    autocomplete: 'new-password',
  },
  {
    name: 'password_confirm',
    label: 'Confirm New Password',
    placeholder: 'Please confirm your password',
    type: 'password',
    required: true,
    autocomplete: 'new-password',
  },
]

export const PasswordConfirmationSchema = Yup.object({
  old_password: Yup.string().required('Please confirm your current password.'),
  new_password: Yup.string()
    .min(6, 'Your password must be at least 6 characters.')
    .required('This field is required.'),
  password_confirm: Yup.string()
    .nullable()
    .oneOf([Yup.ref('$new_password'), null], "The two password fields didn't match.")
    .required('This field is required.'),
})

const successMessage = () => (
  <SuccessConfirmation title="Password Successfully reset">
    <UserContainer>
      {({ user }) => (
        <Fragment>
          The password for the account <b>{user && user.email}</b> was successfully reset.
        </Fragment>
      )}
    </UserContainer>
  </SuccessConfirmation>
)

const ChangePasswordForm = () => {
  return (
    <Fragment>
      <Form
        headerSection={
          <Fragment>
            <Header as="h1" textAlign="left">
              Change Password
            </Header>
            <BodyText1>
              Your password needs to have at least one letter, 6 characters and cannot contain your
              name or email address.
            </BodyText1>
          </Fragment>
        }
        id={PASSWORD_CHANGE_FORM_ID}
        fields={fields}
        submitButtonText="CHANGE PASSWORD"
        submitButtonIcon="arrow right"
        renderSuccess={successMessage}
        validationSchema={PasswordConfirmationSchema}
        url={`${API_AUTH_BASE}change_password/`}
      />
      <Segment vertical textAlign="center">
        <LinkButton to="/">BACK TO DASHBOARD</LinkButton>
      </Segment>
    </Fragment>
  )
}

export default ChangePasswordForm
