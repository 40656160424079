import React, { ReactNode } from 'react'
import { Button, Icon, SemanticICONS } from 'semantic-ui-react'

export interface Props {
  iconLeft: SemanticICONS
  direction?: 'forwards' | 'backwards'
  children: ReactNode
  className?: string
  onClick?: () => void
  as?: any
  to?: string
}

export default ({ iconLeft, children, onClick, direction, className, ...rest }: Props) => (
  <Button
    basic
    fluid
    size="medium"
    onClick={onClick}
    className={`directional ${className}`}
    {...rest}
  >
    {direction === 'backwards' && <Icon name="chevron left" />}
    <Icon name={iconLeft} color="teal" size="large" />
    <span className="directional-text">{children}</span>
    {direction === 'forwards' && <Icon name="chevron right" />}
  </Button>
)
