import React from 'react'
import { Grid } from 'semantic-ui-react'
import { Route, Switch } from 'react-router-dom'
import Layout from 'common/Layout'
import DashboardNav from 'common/DashboardNav'
import { ServicesLoader } from 'pages/RechargePage'
import RoutedUsageSummaryPage from 'pages/UsageSummaryPage'
import { OnlyProps } from 'src/types'
import { makeResponsiveColumn } from 'common/Utils'

const NavColumn = ({ only }: OnlyProps) => (
  <Grid.Column tablet={4} computer={4} style={{ paddingRight: 0 }} only={only}>
    <DashboardNav />
  </Grid.Column>
)

const HomePage = () => {
  return (
    <Layout.Page bodyClass="fullwidth">
      <ServicesLoader />
      <Grid stretched stackable columns="equal">
        <Switch>
          <Route
            path="/services/:uuid/"
            render={makeResponsiveColumn(NavColumn, 'computer tablet')}
          />
          <Route component={NavColumn} />
        </Switch>
        <Switch>
          <Route path="/services/:uuid/" component={RoutedUsageSummaryPage} />
          <Route render={makeResponsiveColumn(RoutedUsageSummaryPage, 'computer tablet')} />
        </Switch>
      </Grid>
    </Layout.Page>
  )
}

export default HomePage
