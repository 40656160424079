import React, { useCallback, useEffect, useMemo, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Grid,
  Header,
  Icon,
  Tab,
  Table,
} from 'semantic-ui-react'
import styled from 'styled-components'
import { subDays } from 'date-fns'
import { ApplicationState } from 'rootReducer'

import { HasDate, Uuid } from 'src/types'
import RefreshButton from 'common/Buttons/RefreshButton'
import { makeFailureSelector, makeLoadingSelector } from 'common/Loading/reducer'
import { ErrorContainer } from 'common/ErrorBoundary'
import { formatMsisdn, formatTime, formatShortDate, formatDateTime } from 'common/Utils'

import { useNavigate, useParams, useResponsive } from 'common/hooks'
import { fetchDetails } from 'pages/UsageDetailPage/actions'
import { getPhoneNumberByUuid } from 'common/Dashboard/reducer'
import { getDetailsByUuid } from 'pages/UsageDetailPage/reducer'
import PlaceholderRows from 'common/PlaceholderRows'
import { DataUsage, DetailsSelector, ItemsByDay, TxtUsage, UsageDetail, VoiceUsage } from './types'
import Chart from './Chart'

const DetailBreadcrumb = () => {
  const { uuid } = useParams()
  const navigate = useNavigate()
  const onClick = useCallback(() => navigate(`/services/${uuid}/`), [uuid, navigate])
  const phoneNumber = useSelector(getPhoneNumberByUuid(uuid))
  return (
    <Breadcrumb size="small">
      <BreadcrumbSection link onClick={onClick}>
        {phoneNumber}
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection active>Usage Detail</BreadcrumbSection>
    </Breadcrumb>
  )
}

const DataTable = ({ dataUsages }: { dataUsages?: DataUsage[] }) => {
  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Date</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Data Usage (MB)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {dataUsages ? (
          dataUsages.map((value) => (
            <Table.Row key={value.date.toISOString()}>
              <Table.Cell textAlign="left">{formatShortDate(value.date)}</Table.Cell>
              <Table.Cell textAlign="right">{value.value}</Table.Cell>
            </Table.Row>
          ))
        ) : (
          <PlaceholderRows columns={2} rows={5} />
        )}
      </Table.Body>
    </Table>
  )
}

function useByDay<T extends HasDate<Date>>(items?: T[]): ItemsByDay<T>[] {
  return useMemo<ItemsByDay<T>[]>(() => {
    if (!items) return []
    const result: ItemsByDay<T>[] = []
    let currentDay: ItemsByDay<T> = { date: '', days: [] }
    items.forEach((item) => {
      const date = formatShortDate(item.date)
      if (date !== currentDay.date) {
        currentDay = { date, days: [] }
        result.push(currentDay)
      }
      currentDay.days.push(item)
    })
    return result
  }, [items])
}

const CallTable = ({ calls }: { calls?: VoiceUsage[] }) => {
  const byDay = useByDay<VoiceUsage>(calls)
  const { isMobile } = useResponsive()
  const body = useMemo(() => {
    if (!calls) return <PlaceholderRows columns={3} rows={5} />
    if (isMobile)
      return byDay.map((day) => (
        <Fragment key={day.date}>
          <Table.Row className="bold">
            <Table.Cell>{day.date}</Table.Cell>
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
          {day.days.map((value) => (
            <Table.Row key={value.date.toISOString()}>
              <Table.Cell textAlign="left">{formatTime(value.date)}</Table.Cell>
              <Table.Cell textAlign="left">{formatMsisdn(value.destination)}</Table.Cell>
              <Table.Cell textAlign="right">{value.duration}</Table.Cell>
            </Table.Row>
          ))}
        </Fragment>
      ))
    return calls.map((value) => (
      <Table.Row key={value.date.toISOString()}>
        <Table.Cell textAlign="left">{formatDateTime(value.date)}</Table.Cell>
        <Table.Cell textAlign="left">{formatMsisdn(value.destination)}</Table.Cell>
        <Table.Cell textAlign="right">{value.duration}</Table.Cell>
      </Table.Row>
    ))
  }, [byDay, isMobile])

  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Date</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">To Number</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">Duration</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{body}</Table.Body>
    </Table>
  )
}

const TxtTable = ({ txts }: { txts?: TxtUsage[] }) => {
  const byDay = useByDay<TxtUsage>(txts)
  const { isMobile } = useResponsive()
  const body = useMemo(() => {
    if (!txts) return <PlaceholderRows columns={2} rows={5} />
    if (isMobile)
      return byDay.map((day) => (
        <Fragment key={day.date}>
          <Table.Row className="bold">
            <Table.Cell>{day.date}</Table.Cell>
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
          {day.days.map((value) => (
            <Table.Row key={value.date.toISOString()}>
              <Table.Cell textAlign="left">{formatTime(value.date)}</Table.Cell>
              <Table.Cell textAlign="right">{formatMsisdn(value.destination)}</Table.Cell>
            </Table.Row>
          ))}
        </Fragment>
      ))
    return txts.map((value) => (
      <Table.Row key={value.date.toISOString()}>
        <Table.Cell textAlign="left">{formatDateTime(value.date)}</Table.Cell>
        <Table.Cell textAlign="right">{formatMsisdn(value.destination)}</Table.Cell>
      </Table.Row>
    ))
  }, [byDay, isMobile])

  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Date</Table.HeaderCell>
          <Table.HeaderCell textAlign="right">To Number</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{body}</Table.Body>
    </Table>
  )
}

const loadingSelector = makeLoadingSelector(['DASHBOARD_GET_DETAILS'])
const failureSelector = makeFailureSelector(['DASHBOARD_GET_DETAILS'])

const UsagePane = ({ details }: { details?: UsageDetail }) => {
  const isLoading = useSelector(loadingSelector)
  const isFailed = useSelector(failureSelector)

  const panes = useMemo(
    () => [
      {
        menuItem: { content: 'Data', color: 'teal' },
        render: () => (
          <Tab.Pane as="div" loading={isLoading}>
            <Chart details={details} />
            {/* disable this linting rule to please the Travis gods */}
            {/* eslint-disable-next-line react/prop-types */}
            <DataTable dataUsages={details ? details.data_usage : undefined} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: { content: 'Calls', color: 'teal' },
        render: () => (
          <Tab.Pane as="div" loading={isLoading}>
            {/* disable this linting rule to please the Travis gods */}
            {/* eslint-disable-next-line react/prop-types */}
            <CallTable calls={details ? details.voice_usage : undefined} />
          </Tab.Pane>
        ),
      },
      {
        menuItem: { content: 'SMS', color: 'teal' },
        render: () => (
          <Tab.Pane as="div" loading={isLoading}>
            {/* disable this linting rule to please the Travis gods */}
            {/* eslint-disable-next-line react/prop-types */}
            <TxtTable txts={details ? details.txt_usage : undefined} />
          </Tab.Pane>
        ),
      },
    ],
    [details, isLoading]
  )

  if (isFailed) {
    return (
      <ErrorContainer
        noPadding
        message="Unfortunately, your usage detail is not available at this moment."
        tryAgainMethod="refresh"
      />
    )
  }

  return <Tab panes={panes} menu={{ pointing: true, secondary: true, size: 'large' }} />
}

const Loader = ({ uuid, page }: { uuid: Uuid; page?: number }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchDetails(uuid, page))
  }, [uuid, dispatch, page])
  return null
}

const ClickableIcon = styled(Icon)`
  cursor: pointer;
`

const MarginTopGrid = styled(Grid)`
  .ui.grid & {
    margin: 1em 0;
  }
`

const UsageDetailPage = () => {
  const { uuid } = useParams()
  const dispatch = useDispatch()
  const [page, setPage] = useState(0)
  const isLoading = useSelector(loadingSelector)
  const detailsSelector = useMemo<DetailsSelector>(() => getDetailsByUuid(uuid, page), [uuid, page])
  const details = useSelector<ApplicationState, UsageDetail | undefined>(detailsSelector)
  const { start_date: startDate, end_date: endDate } = details || {
    end_date: new Date(),
    start_date: subDays(new Date(), 7),
  }

  const refreshDetails = useCallback(() => {
    dispatch(fetchDetails(uuid, page, false))
  }, [dispatch, uuid, page])

  const moveToNextWeek = useCallback(() => {
    if (page > 0) {
      setPage(page - 1)
    }
  }, [page, setPage])

  const moveToPreviousWeek = useCallback(() => {
    if (page < 4) {
      setPage(page + 1)
    }
  }, [page, setPage])

  return (
    <Fragment>
      <Loader uuid={uuid} page={page} />
      <DetailBreadcrumb />
      <MarginTopGrid>
        <Grid.Row columns="equal">
          <Grid.Column textAlign="left">
            <Header as="h1">
              Usage Detail
              <Header.Subheader>
                {page < 4 && !isLoading && (
                  <ClickableIcon name="angle left" size="large" onClick={moveToPreviousWeek} />
                )}
                {!isLoading && `${formatShortDate(startDate)} - ${formatShortDate(endDate)}`}
                {isLoading && 'Loading data...'}
                {page > 0 && !isLoading && (
                  <ClickableIcon name="angle right" size="large" onClick={moveToNextWeek} />
                )}
              </Header.Subheader>
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <RefreshButton onClick={refreshDetails} loading={isLoading} />
          </Grid.Column>
        </Grid.Row>
      </MarginTopGrid>
      <UsagePane details={details} />
    </Fragment>
  )
}

export default UsageDetailPage
