import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Divider, Grid, Header, Placeholder, Message } from 'semantic-ui-react'
import styled from 'styled-components'
import * as Yup from 'yup'

import * as formActions from 'common/Form/actions'
import { makeLoadingSelector } from 'common/Loading/reducer'
import { selectCurrentChangeEmailState } from 'common/Dashboard/reducer'
import SuccessButton from 'common/SuccessButton'
import SubmitIntercept from 'common/Form/SubmitIntercept'

import {
  ChangeEmailRequest,
  ChangeEmailRequestState,
} from 'pages/ContactDetailsPage/ChangeEmailForm/types'
import ConnectedForm, { FormData, FormSubmitSignature } from 'common/Form'
import { BodyText1 } from 'common/Typography'
import StageHeader, { Stage } from 'common/StageHeader'

import { getChangeEmailStatus, getNewOtp, goBackToEmail, resendEmail } from './actions'

const StateLoader: React.FC<{}> = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getChangeEmailStatus())
  }, [null])
  return null
}

export const FORM_ID = 'change-email-request'
const STAGES: Stage[] = [
  { title: 'Change Email Address' },
  { title: 'Verify Identity' },
  { title: 'Verify New Email' },
]

const resendEmailLoadingSelector = makeLoadingSelector(['CHANGE_EMAIL_RESEND_EMAIL'])

const createdFields = [
  {
    label: 'New Email Address',
    name: 'new_email',
    type: 'email',
    required: true,
  },
]

const ActionRow = {
  Group: styled.div`
    display: flex;
    justify-content: space-between;
  `,
  Item: styled.div`
    align-self: center;
  `,
}

const CreatedStage: React.FC<{ existingEmail: string }> = ({ existingEmail }) => {
  const initialValue = useMemo<FormData>(() => ({ new_email: existingEmail }), [existingEmail])

  return (
    <Fragment>
      <StageHeader stages={STAGES} activeStage="Change Email Address" />
      <Header as="h2">Change Email Address</Header>
      <Grid relaxed stackable divided="vertically">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={9}>
            <ConnectedForm
              id={FORM_ID}
              url="/api/account/update_email/"
              fields={createdFields}
              submitButtonText="CONTINUE"
              submitButtonIcon="arrow right"
              initialValue={initialValue}
              cancelButtonText="CANCEL"
              cancelProps={{ secondary: true, to: '/account/contact-details/' }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

const otpSchema = Yup.object({
  otp: Yup.string().required('Please enter your One-Time PIN.'),
})

const RequestedStage: React.FC<{}> = () => {
  const dispatch = useDispatch()
  const submitHandler = useCallback<FormSubmitSignature>((data, errors) => {
    // same form handler for both otp and email address, so need to notify which stage we're up to.
    const newData = { ...data }
    if (!newData.otp) newData.otp = ''
    return { data: newData, errors }
  }, [])
  const resendOtp = useCallback(() => {
    dispatch(getNewOtp())
    dispatch(formActions.reset(FORM_ID))
  }, [dispatch])
  const requestedFields = useMemo(
    () => [
      {
        label: 'One-time PIN',
        name: 'otp',
        type: 'pin_code',
        required: true,
        maxLength: 6,
      },
      {
        name: '',
        content: (
          <Fragment>
            <SubmitIntercept handler={submitHandler} />
            <ActionRow.Group>
              <ActionRow.Item>
                <Header as="h4">Didn&apos;t receive a code?</Header>
              </ActionRow.Item>
              <ActionRow.Item>
                <SuccessButton
                  size="small"
                  basic
                  color="red"
                  onClick={resendOtp}
                  actions={['CHANGE_EMAIL_OTP']}
                  successText="SENT"
                  content="RESEND"
                  type="button"
                />
              </ActionRow.Item>
            </ActionRow.Group>
            <Divider hidden />
          </Fragment>
        ),
      },
    ],
    [resendOtp]
  )
  const goBack = useCallback(() => {
    dispatch(goBackToEmail())
  }, [dispatch])
  return (
    <Fragment>
      <StageHeader stages={STAGES} activeStage="Verify Identity" />

      <Header as="h2">Verify Your Identity</Header>
      <BodyText1>
        To change your email address, we need to verify your identity first.
        <br />A verification code has been sent by SMS to your primary mobile number.
      </BodyText1>
      <Grid relaxed stackable divided="vertically">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={9}>
            <ConnectedForm
              id={FORM_ID}
              url="/api/account/update_email/"
              fields={requestedFields}
              submitButtonText="CONTINUE"
              submitButtonIcon="arrow right"
              cancelButtonText="BACK"
              validationSchema={otpSchema}
              cancelProps={{ secondary: true, onClick: goBack }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

const VerifiedStage: React.FC<{ existingEmail: string }> = ({ existingEmail }) => {
  const dispatch = useDispatch()
  const resendIsLoading: boolean = useSelector(resendEmailLoadingSelector)
  const onClick = useCallback(() => {
    dispatch(resendEmail())
  }, [dispatch])
  return (
    <Fragment>
      <StageHeader stages={STAGES} activeStage="Verify New Email" />

      <Header as="h2">Verify New Email Address</Header>

      <BodyText1>
        A confirmation email has been sent to <strong>{existingEmail}</strong>. Click the link in
        the email to confirm your new email address.
      </BodyText1>
      <Message color="grey" className="borderless">
        Please note: Your email address will not change until you&apos;ve successfully confirmed
        your new email address.
      </Message>
      <Grid relaxed stackable divided="vertically">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={9}>
            <ActionRow.Group>
              <ActionRow.Item>
                <Header as="h4">Didn&apos;t receive the email?</Header>
              </ActionRow.Item>
              <ActionRow.Item>
                <Button
                  size="small"
                  basic
                  color="red"
                  onClick={onClick}
                  loading={resendIsLoading}
                  disabled={resendIsLoading}
                >
                  RESEND
                </Button>
              </ActionRow.Item>
            </ActionRow.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

const StateMachine: React.FC<{}> = () => {
  const currentEmailState: undefined | ChangeEmailRequest = useSelector(
    selectCurrentChangeEmailState
  )
  const existingEmail = currentEmailState ? currentEmailState.new_email : ''
  const currentState = currentEmailState ? currentEmailState.state : ChangeEmailRequestState.CREATED

  switch (currentState) {
    case ChangeEmailRequestState.VERIFIED:
      return <VerifiedStage existingEmail={existingEmail} />
    case ChangeEmailRequestState.REQUESTED:
    case ChangeEmailRequestState.EXPIRED:
      return <RequestedStage />
    case ChangeEmailRequestState.CREATED:
    default:
      return <CreatedStage existingEmail={existingEmail} />
  }
}

const loadingSelector = makeLoadingSelector(['GET_CHANGE_EMAIL'])

const ChangeEmailForm: React.FC<{}> = () => {
  const isLoading: boolean = useSelector(loadingSelector)
  return (
    <Fragment>
      <StateLoader />

      {isLoading ? (
        <Placeholder>
          <Placeholder.Paragraph>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder.Paragraph>
        </Placeholder>
      ) : (
        <StateMachine />
      )}
    </Fragment>
  )
}

export default ChangeEmailForm
