import React, { Fragment, FormEvent, ChangeEvent } from 'react'
import styled from 'styled-components'
import { PortingChoice } from 'common/Porting/types'
import { BaseFormFieldInterface } from 'common/Form/BaseFormField'
import parseErrors from 'common/Form/utils'
import SvgIcon from 'common/SvgIcon'
import { TransitionGroup, Segment, Form, Icon } from 'semantic-ui-react'

const enabledIconProps = {
  inverted: true,
  color: 'teal' as 'teal',
  bordered: true,
  size: 'large' as 'large',
  className: 'tallonmobile rounded',
}

const disabledIconProps = {
  bordered: true,
  color: 'grey' as 'grey',
  size: 'large' as 'large',
  className: 'tallonmobile rounded disabled opaque',
}

const InlineTextDiv = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-left: 1%;
  width: 80%; // Take into account of icon
`

const PortingOptions = ({ value, label, errors, name, onChange }: BaseFormFieldInterface) => {
  const showError = errors && !!errors.length

  const getOnClick = (option: boolean) => (event: FormEvent<HTMLInputElement>) => {
    onChange(event as ChangeEvent<HTMLInputElement>, { name, value: option || false })
  }

  const segmentBaseClass = 'basic gaps selectable shadow'
  const segmentClassPort =
    value === PortingChoice.PORT ? `${segmentBaseClass} active` : segmentBaseClass
  const segmentClassOwnSim =
    value === PortingChoice.KEEP ? `${segmentBaseClass} active` : segmentBaseClass
  const iconPropsPort = value === PortingChoice.PORT ? enabledIconProps : disabledIconProps
  const iconPropsOwnSim = value === PortingChoice.KEEP ? enabledIconProps : disabledIconProps
  const simIconName = value === PortingChoice.KEEP ? 'sim-card-white' : 'sim-card'

  return (
    <Fragment>
      {label && (
        <Form.Field>
          <label htmlFor={name}>{label}</label>
        </Form.Field>
      )}
      <Form.Field>
        <Segment.Group
          className={segmentClassPort}
          onClick={getOnClick(PortingChoice.PORT)}
          onKeyPress={getOnClick(PortingChoice.PORT)}
          tabIndex={0}
        >
          <Segment className="nopadding">
            <Icon name="exchange" {...iconPropsPort} />
            <InlineTextDiv>Yes, Transfer (Port) Existing Number</InlineTextDiv>
          </Segment>
        </Segment.Group>
        <Segment.Group
          className={segmentClassOwnSim}
          onClick={getOnClick(PortingChoice.KEEP)}
          onKeyPress={getOnClick(PortingChoice.KEEP)}
          tabIndex={0}
        >
          <Segment className="nopadding">
            <SvgIcon name={simIconName} {...iconPropsOwnSim} />
            <InlineTextDiv>No, use the Phone Number on the SIM pack</InlineTextDiv>
          </Segment>
        </Segment.Group>
      </Form.Field>
      <Form.Field>
        <TransitionGroup animation="slide down">
          {showError && <span className="help error">{parseErrors(errors)[0]}</span>}
        </TransitionGroup>
      </Form.Field>
    </Fragment>
  )
}

export default PortingOptions
