import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ButtonProps, Button } from 'semantic-ui-react'
import { makeLoadingSelector, makeSuccessSelector } from 'common/Loading/reducer'

interface Props extends ButtonProps {
  actions: string[]
  toastDuration?: number
  onHide?: () => void
  successText?: string
}

const SuccessButton: React.FC<Props> = ({
  actions,
  toastDuration,
  content,
  onHide,
  successText = 'SUCCESS',
  ...buttonProps
}) => {
  const loadingSelector = useMemo(() => makeLoadingSelector(actions), [...actions])
  const successSelector = useMemo(() => makeSuccessSelector(actions), [...actions])
  const [showMessage, setFlag] = useState<boolean>(false)
  const isLoading = useSelector(loadingSelector)
  const isSuccess = useSelector(successSelector)
  const oldStatus = useRef({ isLoading, isSuccess })

  // flag is set when going from loading -> success, cleared when going from success -> loading
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (oldStatus.current.isLoading && isSuccess) {
      setFlag(true)
      const timeout = setTimeout(() => {
        setFlag(false)
        if (onHide) onHide()
      }, toastDuration)
      return () => {
        clearTimeout(timeout)
      }
    }
    if (oldStatus.current.isSuccess && isLoading) {
      setFlag(false)
    }
    oldStatus.current = { isLoading, isSuccess }
  }, [isLoading, isSuccess, setFlag, oldStatus, onHide])

  const newContent = showMessage ? successText : content

  return (
    <Button
      disabled={isLoading || showMessage}
      loading={isLoading}
      {...buttonProps}
      icon={showMessage && 'check'}
      content={newContent}
    />
  )
}

SuccessButton.defaultProps = {
  toastDuration: 1500,
}

export default SuccessButton
