export const DEFAULT_CVC_LENGTH = 3
export const DEFAULT_CARD_FORMAT = /(\d{1,4})/g

export interface Card {
  type: 'amex' | 'mastercard' | 'visa'
  format: RegExp
  startPattern: RegExp
  maxCardNumberLength: number
  cvcLength: number
  cvcIcon: 'cvc' | 'cvc-amex'
}

export const CARD_TYPES: Card[] = [
  {
    type: 'amex',
    format: /(\d{1,4})(\d{1,6})?(\d{1,5})?/,
    startPattern: /^3[47]/,
    maxCardNumberLength: 17,
    cvcLength: 4,
    cvcIcon: 'cvc-amex',
  },
  {
    type: 'mastercard',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^(5[1-5]|677189)|^(222[1-9]|2[3-6]\d{2}|27[0-1]\d|2720)/,
    maxCardNumberLength: 19,
    cvcLength: DEFAULT_CVC_LENGTH,
    cvcIcon: 'cvc',
  },

  {
    type: 'visa',
    format: DEFAULT_CARD_FORMAT,
    startPattern: /^4/,
    maxCardNumberLength: 19,
    cvcLength: DEFAULT_CVC_LENGTH,
    cvcIcon: 'cvc',
  },
]

export const getCardTypeByValue = (value: string): Card =>
  CARD_TYPES.filter((cardType) => cardType.startPattern.test(value))[0]

export const getCardTypeByType = (type: string): Card =>
  CARD_TYPES.filter((cardType) => cardType.type === type)[0]

export const formatCardNumber = (cardNumber: string = ''): string => {
  const cardType = getCardTypeByValue(cardNumber)
  if (!cardType) return (cardNumber.match(/\d+/g) || []).join('')
  const { format } = cardType
  if (format.global) {
    const match = cardNumber.match(format)
    if (match) return match.join(' ')
    return ''
  }
  const execResult = format.exec(cardNumber.split(' ').join(''))
  if (execResult) {
    return execResult
      .splice(1, 3)
      .filter((x) => x)
      .join(' ')
  }
  return cardNumber
}

export const formatCvc = (cvc: string) => {
  return (cvc.match(/\d+/g) || []).join('')
}

export const formatExpiry = (prevExpiry: string) => {
  if (!prevExpiry) return null
  let expiry: any = prevExpiry
  if (/^[2-9]$/.test(expiry)) {
    expiry = `0${expiry}`
  }
  expiry = expiry.match(/(\d{1,2})/g) || []
  if (expiry.length === 1) {
    if (prevExpiry.includes('/')) {
      return expiry[0]
    }
    if (/\d{2}/.test(expiry)) {
      return `${expiry[0]} / `
    }
  }
  if (expiry.length > 2) {
    const [, month = '', year = ''] = expiry.join('').match(/^(\d{2}).*(\d{2})$/) || []
    return [month, year].join(' / ')
  }
  return expiry.join(' / ')
}
