import React, { Fragment } from 'react'
import { User } from 'common/UserContainer/reducer'
import { BodyText1 } from 'common/Typography'
import { Link } from 'react-router-dom'
import { Header, Icon, Segment } from 'semantic-ui-react'

interface Props {
  user: User
}

const PhoneChangeSuccess = ({ user }: Props) => (
  <Fragment>
    <Header as="h1">
      <Icon name="check" color="teal" /> Contact Phone Updated
    </Header>
    <BodyText1>
      Thanks {user.first_name}, the primary contact phone number on your account has been changed to{' '}
      <strong>{user.phone_display}</strong>.
    </BodyText1>
    <Segment basic textAlign="center">
      <strong>
        <Link to="/">BACK TO DASHBOARD</Link>
      </strong>
    </Segment>
  </Fragment>
)

export default PhoneChangeSuccess
