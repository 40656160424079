import React, { ReactNode } from 'react'

export interface Props {
  align: 'left' | 'right' | 'center'
  as?: any
  children?: ReactNode
  style?: object
}

const TextAlign = ({ align, children, as = 'div', style, ...rest }: Props) => {
  const ElementType = as
  return (
    <ElementType style={{ ...style, textAlign: align }} {...rest}>
      {children}
    </ElementType>
  )
}

export default TextAlign
