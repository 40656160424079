import React from 'react'
import { Button, Icon, SemanticICONS, ButtonProps } from 'semantic-ui-react'

const PrimaryActionButton = ({ icon = 'arrow right', children, ...rest }: ButtonProps) => (
  <Button primary size="large" {...rest}>
    {children} {icon && <Icon name={icon as SemanticICONS} />}
  </Button>
)

export default PrimaryActionButton
