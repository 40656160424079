import { FormActions, SuccessAction } from 'common/Form/actions'
import { LOGIN_FORM_ID } from 'pages/LoginPage/LoginForm'
import { CONTACT_DETAILS_FORM_ID } from 'pages/ContactDetailsPage/ContactDetailsForm'
import { RESET_PASSWORD_FORM_ID } from 'pages/PasswordResetPage/PasswordResetForm'
import { UPDATE_CARD_FORM_ID } from 'pages/PaymentMethodsPage'
import { ADDON_PURCHASE_ID } from 'pages/PurchaseAddonsPage/AddonCheckout'
import { RECHARGE_FORM_ID } from 'pages/RechargePage'

import { ApplicationState } from 'rootReducer'
import { AuthenticatedActions, FailureActionTypes, SuccessActionTypes } from './actions'

export interface CreditCardToken {
  masked_card_number: string
  formatted_expiry_date: string
  card_holder_name: string
  card_type: string
  is_expired: boolean
}

export interface User {
  unique_id: string
  email: string
  first_name: string
  last_name?: string
  phone?: string
  phone_display?: string
  has_usable_password: boolean
  last_updated: string
  email_verified: boolean
  credit_card_token?: CreditCardToken
}

export interface UserReducerState {
  authenticated: boolean
  user: User | null
  fetched: boolean
}

export const initialState: UserReducerState = {
  authenticated: false,
  user: null,
  fetched: false,
}

export default function reducer(
  state = initialState,
  action: AuthenticatedActions | SuccessAction
): UserReducerState {
  switch (action.type) {
    case FailureActionTypes.USER_AUTHENTICATED_FAILURE:
      return {
        ...state,
        authenticated: false,
        user: null,
      }
    case SuccessActionTypes.USER_AUTHENTICATED_SUCCESS:
      return {
        ...state,
        authenticated: action.payload.authenticated,
        fetched: true,
        user: action.payload.user || null,
      }
    case SuccessActionTypes.USER_LOGOUT_SUCCESS:
      return {
        ...initialState,
        authenticated: false,
        fetched: state.fetched,
      }
    case FormActions.FORM_SUCCESS:
      // Some forms on the website will change the user's details.
      if (action.payload.id === LOGIN_FORM_ID) {
        return {
          ...state,
          fetched: true,
          ...action.payload.data,
        }
      }
      if (action.payload.id === CONTACT_DETAILS_FORM_ID) {
        return {
          ...state,
          fetched: true,
          user: action.payload.data.user,
        }
      }
      if (action.payload.id === RESET_PASSWORD_FORM_ID) {
        return {
          ...state,
          fetched: true,
          authenticated: action.payload.data.authenticated || false,
          user: action.payload.data.user,
        }
      }
      if (action.payload.id === UPDATE_CARD_FORM_ID) {
        return {
          ...state,
          user: {
            ...(state.user as User),
            credit_card_token: action.payload.data,
          },
        }
      }
      if (action.payload.id === ADDON_PURCHASE_ID || action.payload.id === RECHARGE_FORM_ID) {
        return {
          ...state,
          user: {
            ...(state.user as User),
            credit_card_token: action.payload.data.saved_token,
          },
        }
      }
      return state
    default:
      return state
  }
}

export const getSavedCard = (state: ApplicationState) =>
  state.user.user ? state.user.user.credit_card_token : undefined

export const getAuthenticated = (state: ApplicationState): boolean =>
  state.user.user ? state.user.authenticated : false
