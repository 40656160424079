import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { Product } from 'common/Products/types'
import PlanSelectionField from '../PlanSelectionField'

export type GetItems = (state: ApplicationState) => Product[]
export type GetLoading = (state: ApplicationState) => boolean

export const makePlanSelector = (getItems: GetItems, getLoading: GetLoading) => {
  return connect(
    (state: ApplicationState) => ({
      loading: getLoading(state),
      plans: getItems(state),
    }),
    () => ({})
  )(PlanSelectionField)
}
