import React, { useEffect, useMemo, useState } from 'react'
import { Bar, BarChart, Tooltip, XAxis, YAxis, ResponsiveContainer } from 'recharts'
import styled from 'styled-components'
import { DataUsage, UsageDetail } from 'pages/UsageDetailPage/types'
import { addDays, startOfDay, subDays } from 'date-fns'
import { Label } from 'semantic-ui-react'
import { Colours, formatShortDate } from 'common/Utils'

const PERIOD_IN_DAYS = 7

interface ChartData {
  date: number
  value: number
}

const transformDataForChart = (startDate: Date, usages: DataUsage[]): ChartData[] => {
  const values: ChartData[] = []
  // DATA returned for a given date is the usage for the previous 24 hours
  // Therefore, the chart date rage is for the previous 7 days
  // while the calls and SMS usage is inclusive of the final day (eg 8 days)
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < PERIOD_IN_DAYS; i++) {
    values.push({
      date: startOfDay(addDays(startDate, i)).getTime(),
      value: 0,
    })
  }
  usages.forEach((usage) => {
    const value = values.find((v) => v.date === startOfDay(usage.date).getTime())
    if (value) {
      value.value = Number(usage.value)
    }
  })

  return values
}

const xAxisFormat = (time: number) => {
  return formatShortDate(new Date(time))
}

const yAxisFormat = (value: number) => `${value}MB`

const TooltipContent = ({ active, payload }: { active: any; payload: any }) => {
  if (!active) return null
  const { date, value } = payload[0].payload
  return (
    <Label>
      {formatShortDate(date)}
      <Label.Detail>{value}MB</Label.Detail>
    </Label>
  )
}

const ChartContainer = styled.div`
  overflow: hidden;
`

interface DataChartProps {
  data: ChartData[]
  isLoading: boolean
  startDate?: Date
  endDate?: Date
}

const DataChart = ({ data, isLoading, endDate, startDate }: DataChartProps) => {
  const defaultStartDate = subDays(new Date(), PERIOD_IN_DAYS + 2).getTime()
  const defaultEndDate = addDays(new Date(), 2).getTime()

  const chartStartDate = startDate ? subDays(startDate, 2).getTime() : defaultStartDate
  const chartEndDate = endDate ? addDays(endDate, 2).getTime() : defaultEndDate

  return (
    <ChartContainer>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis
            domain={[chartStartDate, chartEndDate]}
            dataKey="date"
            type="number"
            scale="time"
            tickFormatter={xAxisFormat}
          />
          <YAxis domain={isLoading ? [0, 200] : undefined} tickFormatter={yAxisFormat} />
          {!isLoading && <Tooltip content={TooltipContent} />}
          <Bar dataKey="value" fill={isLoading ? Colours.MEDIUM_GREY : Colours.TEAL} />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}

const generateRandomData = (): ChartData[] => {
  const result = []
  for (let i = 0; i < PERIOD_IN_DAYS + 1; i++) {
    result.push({
      date: startOfDay(subDays(new Date(), PERIOD_IN_DAYS - i)).getTime(),
      value: Math.random() * 50 + 100,
    })
  }
  return result
}
const wrapperStyle = { marginTop: 36 }
const Chart = ({ details }: { details?: UsageDetail }) => {
  const transformedData = useMemo<ChartData[]>(
    () => (details ? transformDataForChart(details.start_date, details.data_usage) : []),
    [details]
  )
  const initialData = useMemo<ChartData[]>(() => generateRandomData(), [null])
  const [randomData, setRandomData] = useState<ChartData[]>(initialData)
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!details) {
      const interval = setInterval(() => {
        setRandomData(generateRandomData())
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [!!details, setRandomData])

  return (
    <div style={wrapperStyle}>
      <DataChart
        isLoading={!details}
        data={details ? transformedData : randomData}
        endDate={details ? details.end_date : undefined}
        startDate={details ? details.start_date : undefined}
      />
    </div>
  )
}

export default Chart
