import React from 'react'
import Layout from 'common/Layout'
import PortingService from './PortingService'

const PortingServicePage: React.FC<{}> = () => {
  return (
    <Layout.Page form="two wide">
      <PortingService />
    </Layout.Page>
  )
}

export default PortingServicePage
