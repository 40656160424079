import analytics from 'redux-analytics'
import { BrowserClient } from '@sentry/browser'

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
    Sentry: BrowserClient
  }
}

export enum SegmentActionType {
  TRACK = 'SEGMENT_TRACK',
}

interface SegmentAnalyticsEvent {
  type: SegmentActionType | string
  payload: {
    event: string
    properties: {
      category?: string
      label?: string
      value?: number
      nonInteraction?: 1
    }
  }
}

export const middleware = analytics(({ type, payload }: SegmentAnalyticsEvent) => {
  switch (type) {
    case SegmentActionType.TRACK: {
      window.analytics.track(payload.event, payload.properties)
      break
    }
    default:
      break
  }
})

export function trackEvent(
  category: string,
  action: string,
  label?: string,
  value?: number,
  nonInteraction: boolean = false
): SegmentAnalyticsEvent {
  return {
    type: SegmentActionType.TRACK,
    payload: {
      event: action,
      properties: {
        category,
        label,
        value,
        nonInteraction: nonInteraction ? 1 : undefined,
      },
    },
  }
}
