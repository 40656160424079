/* eslint-disable import/prefer-default-export */
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'common/../rootReducer'
import { submit } from 'common/Form/actions'

export const makeRetryHandler = (formId: string) =>
  connect(
    (state: ApplicationState) => {
      return {
        orderId: state.checkout.orderId,
        retry: state.checkout.retry,
        isSubmitting: (state.forms[formId] || { isSubmitting: false }).isSubmitting,
      }
    },
    { submitFunc: submit }
  )(
    ({
      orderId,
      retry,
      submitFunc,
      isSubmitting,
    }: {
      orderId?: string
      retry: boolean
      submitFunc: Function
      isSubmitting: boolean
    }) => {
      useEffect(() => {
        if (retry && orderId && !isSubmitting)
          submitFunc(formId, `/api/orders/${orderId}/resume/`, {})
      })
      return null
    }
  )
