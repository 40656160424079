import React from 'react'
import Layout from 'common/Layout'
import AccountNav from 'common/AccountNav'
import { Route, Switch } from 'react-router-dom'
import RoutedChangePasswordPage from 'pages/ChangePasswordPage'
import RoutedContactDetailsPage from 'pages/ContactDetailsPage'
import RoutedPaymentMethodsPage from 'pages/PaymentMethodsPage'
import { Grid } from 'semantic-ui-react'
import { useResponsive } from 'common/hooks'
import { makeResponsiveColumn } from 'common/Utils'
import { OnlyProps } from 'src/types'

const NavColumn = ({ only }: OnlyProps) => (
  <Grid.Column tablet={4} computer={4} style={{ paddingRight: 0 }} only={only}>
    <AccountNav />
  </Grid.Column>
)

const AccountPage = () => {
  const { isMobile } = useResponsive()
  return (
    <Layout.Page bodyClass="fullwidth">
      <Grid stretched stackable columns="equal">
        {!isMobile && (
          <Route
            component={NavColumn}
            render={makeResponsiveColumn(NavColumn, 'computer tablet')}
          />
        )}
        <Switch>
          <Route path="/account/contact-details/" component={RoutedContactDetailsPage} />
          <Route path="/account/payment-method/" component={RoutedPaymentMethodsPage} />
          <Route path="/account/change-password/" component={RoutedChangePasswordPage} />
        </Switch>
      </Grid>
    </Layout.Page>
  )
}

export default AccountPage
