import React from 'react'
import BaseFormField, { BaseFormFieldInterface, FieldOnChange } from 'common/Form/BaseFormField'
import { formatExpiry } from '../cardTypes'

export default class CreditCardExpiryFormField extends React.PureComponent<BaseFormFieldInterface> {
  onChange: FieldOnChange = (event, { name, value }) => {
    const { onChange } = this.props
    onChange(event, { name, value: formatExpiry(value as string) })
  }

  render() {
    const { errors, onChange, ...field } = this.props
    const updatedField = {
      ...field,
      type: 'tel',
      maxLength: 7,
    }

    return <BaseFormField errors={errors} onChange={this.onChange} {...updatedField} />
  }
}
