import { Header, Icon, StrictIconProps, SemanticTRANSITIONS } from 'semantic-ui-react'
import React, { Fragment, useEffect, useState } from 'react'
import AnimatedTransition from 'common/AnimatedTransition'
import { connect } from 'react-redux'
import { ProcessActivationStates } from 'pages/ActivatePage/types'
import { pollActivationState } from 'pages/ActivatePage/actions'
import { makeLoadingSelector } from 'common/Loading/reducer'
import ActivationStageHeader from 'common/ActivationStageHeader'
import { ApplicationState } from 'rootReducer'
import { CheckoutStepTracking } from '../../../segment'

interface StageDef {
  heading: string
  subheader?: string
  animation?: SemanticTRANSITIONS
  icon: StrictIconProps
}

const STAGES: StageDef[] = [
  {
    heading: 'Activating',
    subheader: 'Activating your SIM on the Network',
    icon: { name: 'mobile alternate' },
  },
  {
    heading: 'Adding Prepay Plan',
    subheader: 'Adding Data to your SIM',
    icon: { name: 'list alternate outline' },
  },
  {
    heading: 'Processing Payment',
    subheader: 'Processing payment for your Prepay Plan',
    icon: { name: 'credit card outline' },
  },
  {
    heading: 'Just a few moments left!',
    subheader: 'Things are a little busy, thanks for your patience!',
    icon: { name: 'spinner', loading: true },
  },
]

const PENDING_STAGE: StageDef = {
  heading: 'Starting...',
  subheader: 'Connecting to the Network',
  icon: { name: 'spinner', loading: true },
}

interface Props {
  state: ProcessActivationStates
  loading: boolean
  doPoll: Function
}

export const ActivationProcessingComponent = ({ state, loading, doPoll }: Props) => {
  const [animatedStage, setStage] = useState(0)
  useEffect(() => {
    if (loading) return undefined
    const timer = setTimeout(
      () => {
        if (state !== 'NOT_STARTED' && animatedStage < STAGES.length - 1) {
          setStage(animatedStage + 1)
        }
        doPoll()
      },
      state === 'NOT_STARTED' ? 1000 : 3000
    )
    return () => {
      clearTimeout(timer)
    }
  }, [loading, state])

  const activeStage = state === 'NOT_STARTED' ? PENDING_STAGE : STAGES[animatedStage]
  return (
    <Fragment>
      <CheckoutStepTracking step={7} />
      <ActivationStageHeader activeStage="ALL" />
      <AnimatedTransition animation={activeStage.animation || 'scale'} duration={300}>
        <Header
          icon
          as="h1"
          textAlign="center"
          style={{ marginTop: 100 }}
          key={activeStage.heading}
        >
          <Icon {...activeStage.icon} />
          <Header.Content>{activeStage.heading}</Header.Content>
          <Header.Subheader>{activeStage.subheader}</Header.Subheader>
        </Header>
      </AnimatedTransition>
    </Fragment>
  )
}

export default connect(
  (state: ApplicationState) => ({
    state:
      (state.activation.checkout && state.activation.checkout.activation_state) || 'NOT_STARTED',
    loading: makeLoadingSelector(['ACTIVATION_POLL'])(state),
  }),
  {
    doPoll: pollActivationState,
  }
)(ActivationProcessingComponent)
