import React from 'react'
import { Header, Icon, Segment } from 'semantic-ui-react'
import LinkButton from 'common/LinkButton'
import Layout from 'common/Layout'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { ApplicationState } from 'src/rootReducer'

interface ConnectedSuccessPageProps {
  orderId?: string
}

interface SuccessPageProps extends ConnectedSuccessPageProps {
  action: string
}

const SuccessPage: React.FC<SuccessPageProps> = ({ orderId, action }) => {
  if (!orderId) {
    return <Redirect to="/" />
  }
  return (
    <Layout.Page form="two wide">
      <Header as="h1" textAlign="center">
        <Header.Content>
          <Icon name="check" color="teal" /> {action} Successful!
          <Header.Subheader>
            Your order reference is {orderId}.<br />
            You will receive a confirmation email in a few minutes.
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Segment basic textAlign="center">
        <LinkButton secondary to="/">
          Back to Dashboard
        </LinkButton>
      </Segment>
    </Layout.Page>
  )
}

const ConnectedSuccessPage = connect((state: ApplicationState) => {
  return {
    orderId: state.checkout.orderId,
  }
})(SuccessPage)

const RechargeSuccessPage: React.FC<ConnectedSuccessPageProps> = (props) => (
  <ConnectedSuccessPage action="Renewal" {...props} />
)
export const AddonsSuccessPage: React.FC<ConnectedSuccessPageProps> = (props) => (
  <ConnectedSuccessPage action="Add-On Purchase" {...props} />
)
export default RechargeSuccessPage
