import React, { Fragment, useEffect } from 'react'
import { Header, Image, Grid, TransitionGroup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { useBooleanToggle, useResponsive } from 'common/hooks'
import ConnectedForm from 'common/Form'
import SimInfo from 'common/Porting/SimInfo'
import { PortingRequest } from 'common/Porting/types'
import { generatePortingFormFields, portingFormschema, BILLING_METHODS } from 'common/Porting/Forms'
import { goBack } from 'pages/ActivatePage/actions'
import ActivateStageHeader from 'common/ActivationStageHeader'
import { ApplicationState } from 'rootReducer'
import { CheckoutStepTracking } from '../../../segment'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const portingImage = require('static/img/porting-details.png')

interface PortingFormInterface {
  porting_request?: PortingRequest
  doStartAgain: () => void
  toggleSimHelp: () => void
  showSimHelp: () => void
  isPortHelpShown: boolean
  method: number
}

const PortingForm = ({
  method,
  porting_request,
  doStartAgain,
  toggleSimHelp,
  showSimHelp,
  isPortHelpShown,
}: PortingFormInterface) => {
  const { isMobile } = useResponsive()
  // Show SIM Help when we select prepay option in radio button
  // Do so only in desktop
  useEffect(() => {
    if (method === BILLING_METHODS.PREPAY && !isMobile) {
      showSimHelp()
    }
    if (method === BILLING_METHODS.ACCOUNT && !isMobile) {
      showSimHelp()
    }
  }, [method])

  return (
    <Fragment>
      <div />
      <Header as="h1">Number Transfer Details</Header>
      <ConnectedForm
        id="activate_2_porting_details"
        fields={generatePortingFormFields(method, toggleSimHelp, isPortHelpShown)}
        validationSchema={portingFormschema}
        url="/api/activate/port/"
        submitButtonText="CONTINUE"
        submitButtonIcon="arrow right"
        cancelProps={{ onClick: doStartAgain, secondary: true }}
        cancelButtonText="BACK"
        initialValue={{ ...porting_request }}
      />
    </Fragment>
  )
}

const ConnectedPortingForm = connect(
  (state: ApplicationState) => {
    const { activate_2_porting_details } = state.forms
    const { checkout } = state.activation
    return {
      method:
        (activate_2_porting_details &&
          activate_2_porting_details.data &&
          activate_2_porting_details.data.billing_method) ||
        '',
      porting_request: checkout && checkout.porting_request,
    }
  },
  {
    doStartAgain: () => goBack(null), // Go back to Enter Sim Details
  }
)(PortingForm)

const InfoColumn = ({ isPortHelpShown, method }: { isPortHelpShown: boolean; method: number }) => {
  return (
    <TransitionGroup animation="fade" duration={{ hide: 0, show: 300 }}>
      {isPortHelpShown && (
        <div>
          {/* This extra <div> is needed for the TransitionGroup to apply transition styles */}
          <SimInfo method={method} />
        </div>
      )}
      {!isPortHelpShown && (
        <div>
          <Image src={portingImage} />
        </div>
      )}
    </TransitionGroup>
  )
}

const ConnectedInfoColumn = connect((state: ApplicationState) => {
  const { activate_2_porting_details } = state.forms
  return {
    method:
      (activate_2_porting_details &&
        activate_2_porting_details.data &&
        activate_2_porting_details.data.billing_method) ||
      '',
  }
})(InfoColumn)

const PortingStage = () => {
  const [isPortHelpShown, toggleSimHelp, showSimHelp] = useBooleanToggle(false)

  return (
    <Fragment>
      <CheckoutStepTracking step={2} />
      <ActivateStageHeader activeStage="Number Transfer Details" />
      <Grid columns="equal" stackable relaxed>
        <Grid.Column only="computer tablet" verticalAlign="middle">
          <ConnectedInfoColumn isPortHelpShown={isPortHelpShown} />
        </Grid.Column>
        <Grid.Column>
          <ConnectedPortingForm
            toggleSimHelp={toggleSimHelp}
            showSimHelp={showSimHelp}
            isPortHelpShown={isPortHelpShown}
          />
        </Grid.Column>
      </Grid>
    </Fragment>
  )
}

export default PortingStage
