import React from 'react'
import { Segment, Grid } from 'semantic-ui-react'
import { OnlyProps } from 'src/types'
import { Route } from 'react-router-dom'
import DashboardContentContainer from 'common/Dashboard'
import { useResponsive } from 'common/hooks'
import ChangePasswordForm from './ChangePasswordForm'

export const ChangePasswordPage = () => {
  const { isMobile } = useResponsive()

  return (
    <Segment padded={!isMobile ? 'very' : undefined}>
      <Grid relaxed stackable divided="vertically">
        <Grid.Row>
          <Grid.Column mobile={16} tablet={16} computer={9}>
            <ChangePasswordForm />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  )
}

const RoutedChangePasswordPage = ({ only }: OnlyProps) => (
  <DashboardContentContainer only={only}>
    <Route path="/account/change-password/" component={ChangePasswordPage} />
  </DashboardContentContainer>
)

export default RoutedChangePasswordPage
