/* eslint-disable */

// https://github.com/AdamDonna/react-code-input
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { InputOnChangeData } from 'semantic-ui-react'

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

const BACKSPACE_KEY = 8
const LEFT_ARROW_KEY = 37
const UP_ARROW_KEY = 38
const RIGHT_ARROW_KEY = 39
const DOWN_ARROW_KEY = 40
const E_KEY = 69

interface Props {
  onChange(event: React.ChangeEvent, data: InputOnChangeData): void
  value: string | number | boolean | any[]
  inputMode: string
  filterChars: any[]
  autoFocus: boolean
  type?: string
  errors?: boolean
  tabIndex?: number
}

class ReactCodeInput extends Component<Props> {
  constructor(props: any) {
    super(props)

    const { fields, type, isValid, disabled, filterKeyCodes, forceUppercase, errors } = props
    let { value } = props

    if (forceUppercase) {
      value = value.toUpperCase()
    }

    this.state = {
      value,
      fields,
      type,
      errors,
      input: [],
      isValid,
      disabled,
      filterKeyCodes,
      defaultInputStyle: {
        MozAppearance: 'textfield',
        marginRight: '4px',
        paddingLeft: '0px',
        paddingRight: '0px',
        width: '36px',
        height: '42px',
        boxSizing: 'border-box',
        textAlign: 'center',
      },
    }

    // @ts-ignore
    for (let i = 0; i < Number(this.state.fields); i++) {
      if (i < 32) {
        // @ts-ignore
        const value = this.state.value[i] || ''
        // @ts-ignore
        this.state.input.push(value)
      }
    }

    // @ts-ignore
    this.textInput = []

    // @ts-ignore
    this.uuid = uuidv4()
  }

  // @ts-ignore
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isValid: nextProps.isValid,
      value: nextProps.value,
      disabled: nextProps.disabled,
    })
  }

  // @ts-ignore
  handleBlur(e) {
    this.handleTouch(e.target.value)
  }

  // @ts-ignore
  handleTouch(value) {
    // @ts-ignore
    const { touch, untouch, name } = this.props

    if (typeof touch === 'function' && typeof untouch === 'function') {
      if (value === '') {
        touch(name)
      } else {
        untouch(name)
      }
    }
  }

  // @ts-ignore
  handleChange(e: any) {
    // @ts-ignore
    const { filterChars } = this.props

    let value = String(e.target.value)

    // @ts-ignore
    if (this.props.forceUppercase) {
      value = value.toUpperCase()
    }

    // @ts-ignore
    if (this.state.type === 'number') {
      value = value.replace(/[^\d]/g, '')
    }

    /** Filter Chars */
    value = value
      .split('')
      .filter((currChar) => !filterChars.includes(currChar))
      .join('')

    let fullValue = value

    if (value !== '') {
      // @ts-ignore
      const input = this.state.input.slice()

      if (value.length > 1) {
        value.split('').map((chart, i) => {
          // @ts-ignore
          if (Number(e.target.dataset.id) + i < this.props.fields) {
            input[Number(e.target.dataset.id) + i] = chart
          }
          return false
        })
      } else {
        input[Number(e.target.dataset.id)] = value
      }

      // @ts-ignore
      input.map((s, i) => {
        // @ts-ignore
        if (this.textInput[i]) {
          // @ts-ignore
          this.textInput[i].value = s
        }
        return false
      })

      // @ts-ignore
      const newTarget = this.textInput[
        e.target.dataset.id < input.length ? Number(e.target.dataset.id) + 1 : e.target.dataset.id
      ]

      if (newTarget) {
        newTarget.focus()
        newTarget.select()
      }

      fullValue = input.join('')

      this.setState({
        value: input.join(''),
        input,
      })
    }

    // @ts-ignore
    if (this.props.onChange && fullValue) {
      // @ts-ignore
      this.props.onChange(e, fullValue)
    }

    this.handleTouch(fullValue)
  }

  // @ts-ignore
  handleKeyDown(e) {
    const target = Number(e.target.dataset.id),
      // @ts-ignore
      nextTarget = this.textInput[target + 1],
      // @ts-ignore
      prevTarget = this.textInput[target - 1]

    let input, value

    // @ts-ignore
    if (this.state.filterKeyCodes.length > 0) {
      // @ts-ignore
      this.state.filterKeyCodes.map((item) => {
        if (item === e.keyCode) {
          e.preventDefault()
          return true
        }
      })
    }

    switch (e.keyCode) {
      case BACKSPACE_KEY:
        e.preventDefault()
        // @ts-ignore
        this.textInput[target].value = ''
        // @ts-ignore
        input = this.state.input.slice()
        input[target] = ''
        value = input.join('')

        this.setState({ value, input })
        // @ts-ignore
        if (this.textInput[target].value === '') {
          if (prevTarget) {
            prevTarget.focus()
            prevTarget.select()
          }
        }
        // @ts-ignore
        if (this.props.onChange) {
          // @ts-ignore
          this.props.onChange(e, value)
        }
        break

      case LEFT_ARROW_KEY:
        e.preventDefault()
        if (prevTarget) {
          prevTarget.focus()
          prevTarget.select()
        }
        break

      case RIGHT_ARROW_KEY:
        e.preventDefault()
        if (nextTarget) {
          nextTarget.focus()
          nextTarget.select()
        }
        break

      case UP_ARROW_KEY:
        e.preventDefault()
        break

      case DOWN_ARROW_KEY:
        e.preventDefault()
        break

      case E_KEY: // This case needs to be handled because of https://stackoverflow.com/questions/31706611/why-does-the-html-input-with-type-number-allow-the-letter-e-to-be-entered-in
        if (e.target.type === 'number') {
          e.preventDefault()
          break
        }

      default:
        break
    }

    this.handleTouch(value)
  }

  render() {
    // @ts-ignore
    const {
        className,
        style = {},
        inputStyle = {},
        inputStyleInvalid = {},
        type,
        name,
        autoFocus,
        pattern,
        inputMode,
        errors,
        children,
        tabIndex,
      } = this.props as any,
      // @ts-ignore
      { disabled, input, isValid, defaultInputStyle } = this.state,
      styles = {
        container: style,
        input: isValid ? inputStyle : inputStyleInvalid,
        error: inputStyleInvalid,
      }

    Object.assign(styles.container, {
      display: 'inline-block',
    })

    if (!className && Object.keys(inputStyle).length === 0) {
      Object.assign(inputStyle, {
        ...defaultInputStyle,
        color: 'black',
        backgroundColor: 'white',
        borderColor: 'lightgrey',
      })
    }

    if (!className && Object.keys(inputStyleInvalid).length === 0) {
      Object.assign(inputStyleInvalid, {
        ...defaultInputStyle,
        color: '#b94a48',
        borderColor: '#eed3d7',
      })
    }

    if (disabled) {
      Object.assign(styles.input, {
        cursor: 'not-allowed',
        color: 'lightgrey',
        borderColor: 'lightgrey',
        backgroundColor: '#efeff1',
      })
    }

    return (
      <div className={`${className || ''}ui input react-code-input`} style={styles.container}>
        {input.map((value: any, i: number) => {
          return (
            <input
              ref={(ref) => {
                // @ts-ignore
                this.textInput[i] = ref
              }}
              // @ts-ignore
              id={`${this.uuid}-${i}`}
              name={name}
              data-id={i}
              autoFocus={autoFocus && i === 0 ? true : undefined}
              value={value}
              key={`input_${i}`}
              type={type}
              min={0}
              max={9}
              maxLength={input.length === i + 1 ? 1 : input.length}
              style={errors ? styles.error : styles.input}
              // @ts-ignore
              onFocus={(e) => e.target.select(e)}
              onBlur={(e) => this.handleBlur(e)}
              onChange={(e) => this.handleChange(e)}
              onKeyDown={(e) => this.handleKeyDown(e)}
              disabled={disabled}
              data-valid={isValid}
              data-lpignore="true"
              pattern={pattern}
              inputMode={inputMode}
              tabIndex={tabIndex}
              autoComplete="off"
            />
          )
        })}
        {children}
      </div>
    )
  }
}

// @ts-ignore
ReactCodeInput.defaultProps = {
  autoFocus: true,
  isValid: true,
  disabled: false,
  forceUppercase: false,
  fields: 4,
  value: '',
  type: 'text',
  filterKeyCodes: [189, 190],
  filterChars: ['-', '.'],
  children: null,
}

// @ts-ignore
ReactCodeInput.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
  fields: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  touch: PropTypes.func,
  untouch: PropTypes.func,
  className: PropTypes.string,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  inputStyleInvalid: PropTypes.object,
  autoFocus: PropTypes.bool,
  forceUppercase: PropTypes.bool,
  filterKeyCodes: PropTypes.array,
  filterChars: PropTypes.array,
  pattern: PropTypes.string,
  inputMode: PropTypes.oneOf([
    'verbatim',
    'latin',
    'latin-name',
    'latin-prose',
    'full-width-latin',
    'kana',
    'kana-name',
    'katakana',
    'numeric',
    'tel',
    'email',
    'url',
  ]),
}

export default ReactCodeInput
// @ts-ignore-end
