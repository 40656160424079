import React, { Fragment, useCallback } from 'react'
import { Responsive, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import FormattedCurrency from 'common/FormattedCurrency'
import AnimatedTransition from 'common/AnimatedTransition'
import { Caption } from 'common/Typography'
import { ProductCard, BottomlessHeader } from './index'
import { PlanCardProps } from './types'

const PlanTitle = ({ data }: { data: number }) => (
  <Fragment>
    {data}GB <small>Total</small>
  </Fragment>
)

const Small = styled.small`
  font-size: 58.33%;
`

const RRP = styled.small`
  text-decoration: line-through;
  font-weight: normal;
  padding-left: 6px;
`

const PlanCard: React.FC<PlanCardProps> = ({ plan, horizontal, onClick, ...props }) => {
  const extraProps: any = {}
  const onClickWithPlan = useCallback(
    (event: React.MouseEvent | React.KeyboardEvent) => {
      if (onClick) {
        onClick(event, plan)
      }
    },
    [onClick, plan]
  )
  if (onClick) {
    extraProps.onClick = onClickWithPlan
  }

  const months = Math.round((plan.length / 365) * 12)
  const perFor = plan.length === 30 ? 'for' : 'per'
  return (
    <ProductCard horizontal={horizontal} {...props} {...extraProps}>
      <ProductCard.Header
        color={plan.colour}
        content={<PlanTitle data={plan.data_gb * months} />}
        extra={`${plan.data_gb}GB ${perFor} 30 days`}
        underlay
      />
      <ProductCard.Content>
        <AnimatedTransition transitionOnMount animation="fade" duration={{ show: 300, hide: 0 }}>
          <BottomlessHeader forwardedAs="h4">
            <span>${plan.price}</span>
            {plan.rrp && <RRP>${plan.rrp}</RRP>}
          </BottomlessHeader>
        </AnimatedTransition>
        <Caption>Total Upfront Cost</Caption>
      </ProductCard.Content>
      {!horizontal && (
        <Responsive
          as={ProductCard.Content}
          minWidth={Responsive.onlyTablet.minWidth}
          header={`${plan.data_gb}GB`}
          content={`${perFor} 30 days`}
        />
      )}
      <ProductCard.Content>
        <BottomlessHeader forwardedAs="h3">Unlimited</BottomlessHeader>
        <Caption>
          Standard Calls &amp; Texts to <br />
          Australia and New Zealand
        </Caption>
      </ProductCard.Content>
      <Fragment>
        <Header as="h4" style={{ margin: 0 }}>
          {plan.length > 30 && <Fragment>Works out to</Fragment>}
        </Header>
        <AnimatedTransition transitionOnMount animation="fade" duration={{ show: 300, hide: 0 }}>
          <Header as="h1" color={plan.rrp ? 'red' : undefined} style={{ margin: 0 }}>
            <FormattedCurrency value={(parseFloat(plan.price) / plan.length) * 30} />
            {!horizontal && <Small>{perFor} 30 days</Small>}
          </Header>
        </AnimatedTransition>
        {horizontal && (
          <Header as="h4" style={{ margin: 0 }}>
            {perFor} 30 days
          </Header>
        )}
      </Fragment>
    </ProductCard>
  )
}

export default PlanCard
