import React from 'react'
import { Button } from 'semantic-ui-react'

interface PortHelpInterface {
  onHelpClick: () => void
  helpText: string
}

const PortHelpButton = ({ onHelpClick, helpText }: PortHelpInterface) => {
  return (
    <Button large basic secondary className="kogan-text-overline" onClick={onHelpClick}>
      {helpText}
    </Button>
  )
}

export default PortHelpButton
