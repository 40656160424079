import { ApplicationState } from 'rootReducer'
import { Uuid } from 'src/types'
import { VoucherActionTypes, GetVoucherSuccessAction } from 'pages/ActivatePage/actions'
import { ServiceActionTypes, ServiceActions } from 'pages/RechargePage/actions'
import { ActionTypes, ProductActions } from './actions'
import { Product, ProductsReducerState, UniqueId, ProductByUniqueId, ProductType } from './types'
import { orderByDefault } from './index'

export const initialProductState: ProductsReducerState = {
  byUniqueId: {},
  allUniqueIds: [],
}

const productsReducer = (
  state: ProductsReducerState = initialProductState,
  action: ProductActions | GetVoucherSuccessAction | ServiceActions
) => {
  switch (action.type) {
    case ActionTypes.GET_PRODUCTS_SUCCESS:
      if (action.payload.data) {
        const byUniqueId = {
          ...state.byUniqueId,
          ...action.payload.data,
        }
        return {
          ...state,
          byUniqueId,
          allUniqueIds: Object.keys(byUniqueId),
        }
      }
      return state
    case VoucherActionTypes.LOAD_VOUCHER_SUCCESS: {
      if (!action.payload.product) {
        return state
      }

      const { product } = action.payload
      // Store voucher returned by product API
      const byUniqueId = {
        ...state.byUniqueId,
        [product.unique_id]: product,
      }
      return {
        ...state,
        byUniqueId,
        allUniqueIds: Object.keys(byUniqueId),
      }
    }
    case ServiceActionTypes.GET_SERVICES_SUCCESS: {
      const { data: services } = action.payload
      if (!services) return state

      // Get products from service schedules
      const newProducts = Object.keys(services).reduce((acc: Product[], uuid: Uuid) => {
        const service = services[uuid]
        const products = Object.keys(service.schedules).map(
          (scheduleId: string) => service.schedules[scheduleId].product
        )
        return [...acc, ...products]
      }, [])

      // Create new keyed dictionary of the products
      const newProductsById = newProducts.reduce(
        (d: ProductByUniqueId, p: Product) => ({
          ...d,
          [p.unique_id]: p,
        }),
        {}
      )

      // Merge with existing
      const mergedById = {
        ...state.byUniqueId,
        ...newProductsById,
      }

      // Recreate allUniqueIds to avoid duplicates
      return {
        ...state,
        byUniqueId: mergedById,
        allUniqueIds: Object.keys(mergedById),
      }
    }
    default:
      return state
  }
}

export const getProductById = (state: ApplicationState, id: UniqueId) =>
  state.products.byUniqueId[id]

export const getAllProducts = (state: ApplicationState) => {
  const { products } = state

  return products.allUniqueIds
    .reduce((acc: Product[], id: UniqueId) => {
      return [...acc, products.byUniqueId[id]]
    }, [])
    .sort(orderByDefault)
}

export const getAllAddons = (state: ApplicationState) => {
  const allProducts = getAllProducts(state)

  return allProducts.filter((p) => p.product_type === ProductType.ADDON).sort(orderByDefault)
}

export const getAllPlans = (state: ApplicationState) => {
  const allProducts = getAllProducts(state)

  return allProducts.filter((p) => p.product_type === ProductType.PLAN).sort(orderByDefault)
}

export const getAllPlansWithCondition = (
  state: ApplicationState,
  condition: (value: Product, index: number, array: Product[]) => boolean
) => {
  const allProducts = getAllPlans(state)
  return allProducts.filter(condition).sort(orderByDefault)
}

export default productsReducer
