import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'semantic-ui-react'
import GenericNav from 'common/GenericNav'
import TabLink from 'common/TabLink'

const AccountNav = () => {
  const footerLink = (
    <Button as={Link} to="/" fluid inverted>
      MY DASHBOARD
    </Button>
  )

  const menuLinks = (
    <Fragment>
      <TabLink link="/account/contact-details/" heading="Contact Details" />
      <TabLink link="/account/payment-method/" heading="Payment Method" />
      <TabLink link="/account/change-password/" heading="Change Password" />
    </Fragment>
  )

  return (
    <GenericNav footerLink={footerLink} heading="Account Settings">
      {menuLinks}
    </GenericNav>
  )
}

export default AccountNav
