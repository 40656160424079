import React, { PureComponent } from 'react'
import { BaseFormFieldInterface } from 'common/Form/BaseFormField'
import parseErrors from 'common/Form/utils'
import { Form, TransitionGroup } from 'semantic-ui-react'
import ReactCodeInput from './ReactCodeInput'

function invalidCharacters() {
  const includeOnly = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0']
  const allChars = []
  for (let i = 0; i < 128; i++) allChars.push(String.fromCharCode(i))
  return allChars.filter((character) => !includeOnly.includes(character))
}
/*
 TODO: 1 - Submit upsteam css fix for paddingRight
 TODO: 2 - Submit upsteam fix for autocomplete (if possible)
*/
export default class PinCodeFormField extends PureComponent<BaseFormFieldInterface> {
  // Only execute once for the lifetime of the component
  invalidCharacterList = invalidCharacters()

  onChangeHandler = (event: any, value: any) => {
    const { name, onChange } = this.props
    onChange(event, { name, value })
  }

  render() {
    const { errors, value, maxLength = 4, tabIndex, disabled, ...field } = this.props
    const showError = errors && !!errors.length

    const reactCodeInput = (
      // @ts-ignore
      <ReactCodeInput
        fields={maxLength}
        onChange={this.onChangeHandler}
        value={value}
        name={field.name}
        type="tel"
        errors={showError}
        inputMode="numeric"
        filterChars={this.invalidCharacterList}
        autoFocus={false}
        tabIndex={tabIndex}
        disabled={disabled}
      />
    )

    return (
      <Form.Field
        key={field.name}
        id={field.name}
        type="tel"
        required={field.required}
        error={showError}
        style={{ minWidth: '55%' }}
      >
        {field.label && <label htmlFor={field.name}>{field.label}</label>}
        {field.help && <div className="help">{field.help}</div>}
        {reactCodeInput}
        <TransitionGroup animation="slide down">
          {showError && <span className="help error">{parseErrors(errors)[0]}</span>}
        </TransitionGroup>
      </Form.Field>
    )
  }
}
