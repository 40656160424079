import React, { Fragment, useState, useMemo, useEffect, createRef } from 'react'
import { Header, Image, Grid, Responsive, Modal, Segment, Ref } from 'semantic-ui-react'
import AnimatedTransition from 'common/AnimatedTransition'
import { useResponsive } from 'common/hooks'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import ConnectedForm from 'common/Form'
import { ProductLoader } from 'common/Products/index'
import { makeLoadingSelector } from 'common/Loading/reducer'
import ActivationStageHeader from 'common/ActivationStageHeader'
import LinkButton from 'common/LinkButton'
import { goBack } from 'pages/ActivatePage/actions'
import { ApplicationState } from 'src/rootReducer'

import { getAllPlans } from 'common/Products/reducer'
import { makePlanSelector } from './PlanSelector'
import AllPlanList from './AllPlanList'
import PlanTermsAccordion from './PlanTermsAccordion'
import { CheckoutStepTracking } from '../../../../segment'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const planDetailsImageSrc = require('static/img/plan-details.png')

const PlanSelector = makePlanSelector(
  (state) => getAllPlans(state),
  (state) => makeLoadingSelector(['GET_PRODUCTS'])(state)
)

interface UnconnectedEnterPlanFormProps {
  doStartAgain: () => void
  displayAllPlan: () => void
  isAllPlanVisible: boolean
}

const planSchema = Yup.object({
  plan: Yup.string().required('Please select a Prepay Plan.'),
})

const UnconnectedEnterPlanForm = ({
  doStartAgain,
  displayAllPlan,
  isAllPlanVisible,
}: UnconnectedEnterPlanFormProps) => {
  const linkButtonSnippet = (
    <LinkButton type="button" size="large" onClick={displayAllPlan} secondary>
      {isAllPlanVisible ? 'Hide All Prepay Plans' : 'Show All Prepay Plans'}
    </LinkButton>
  )

  return (
    <Fragment>
      <ProductLoader />
      <ConnectedForm
        id="activate_3_plan"
        fields={[
          {
            name: 'plan',
            required: true,
            component: PlanSelector,
          },
        ]}
        url="/api/activate/plan/"
        submitButtonText="CONTINUE"
        submitButtonIcon="arrow right"
        cancelButtonText="BACK"
        cancelProps={{ onClick: doStartAgain, secondary: true }}
        validationSchema={planSchema}
      >
        {() => (
          <Fragment>
            <Grid columns="equal" stackable reversed="mobile">
              <Grid.Column verticalAlign="middle" textAlign="center" only="mobile">
                {linkButtonSnippet}
              </Grid.Column>
              <Grid.Column verticalAlign="middle" textAlign="left" only="tablet computer">
                {linkButtonSnippet}
              </Grid.Column>
            </Grid>
            <PlanTermsAccordion />
          </Fragment>
        )}
      </ConnectedForm>
    </Fragment>
  )
}

const transDuration = {
  hide: 0,
  show: 300,
}

const EnterPlanForm = connect(undefined, { doStartAgain: () => goBack('DETAILS_ENTERED') })(
  UnconnectedEnterPlanForm
)

const ConnectedAllPlanList = connect((state: ApplicationState) => ({
  plans: getAllPlans(state),
}))(AllPlanList)

const SelectPlan = () => {
  const [isAllPlanVisible, displayAllPlan] = useState(false)
  const { isMobile } = useResponsive()
  const focusRef = createRef<HTMLDivElement>()

  useEffect(() => {
    if (isMobile && isAllPlanVisible) {
      if (focusRef.current) {
        focusRef.current.focus()
      }

      // Apply styling to lock body from scrolling
      document.body.style.position = 'fixed'
      document.body.style.overflow = 'hidden'
      document.body.style.width = '100%'
      document.body.style.height = '100%'

      // Then release the lock - this is to prevent initial weird scrolling behaviour
      setTimeout(() => {
        document.body.removeAttribute('style')
      }, 300)
    }
  }, [isAllPlanVisible, isMobile, focusRef])

  const closeModal = useMemo(
    () => () => {
      displayAllPlan(false)
    },
    []
  )

  const switchAllPlanDisplay = useMemo(
    () => () => {
      window.scrollTo({ top: 100, behavior: 'smooth' })
      displayAllPlan(!isAllPlanVisible)
    },
    [isAllPlanVisible]
  )

  return (
    <Fragment>
      <CheckoutStepTracking step={5} />
      <ProductLoader />
      <ActivationStageHeader activeStage="Prepay Plan Details" />
      <Grid columns="equal" relaxed>
        <Grid.Row>
          <Grid.Column only="computer tablet" style={{ marginTop: '250px' }}>
            <div />
            <AnimatedTransition
              visible={!isAllPlanVisible}
              transitionOnMount
              animation="fade"
              duration={transDuration}
            >
              <div>
                <Image src={planDetailsImageSrc} />
              </div>
            </AnimatedTransition>
            <AnimatedTransition
              visible={isAllPlanVisible}
              transitionOnMount
              animation="fade"
              duration={transDuration}
            >
              <div>
                <Header as="h3">All Prepay Plans</Header>
                <ConnectedAllPlanList />
              </div>
            </AnimatedTransition>
          </Grid.Column>
          <Grid.Column>
            {/* Add empty div to give Header top margin */}
            <div />
            <Header as="h1">Choose Your Prepay Plan</Header>
            <EnterPlanForm
              displayAllPlan={switchAllPlanDisplay}
              isAllPlanVisible={isAllPlanVisible}
            />
            <Responsive as="div" maxWidth={Responsive.onlyMobile.maxWidth}>
              <Modal
                open={isAllPlanVisible}
                onClose={closeModal}
                size="small"
                closeIcon={{
                  style: { top: '2.0535rem', right: '1rem' },
                  color: 'black',
                  name: 'close',
                }}
              >
                <Modal.Content>
                  <Ref innerRef={focusRef}>
                    <Segment basic>
                      <Header as="h3">All Prepay Plans</Header>
                      <ConnectedAllPlanList />
                    </Segment>
                  </Ref>
                </Modal.Content>
              </Modal>
            </Responsive>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Fragment>
  )
}

export default SelectPlan
