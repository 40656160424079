import axios from 'axios'
import { ApplicationState } from 'rootReducer'
import { getDetailsByUuid } from 'pages/UsageDetailPage/reducer'
import { Uuid, HasDate } from 'src/types'
import { UsageDetail, InUsageDetail } from './types'

export enum UsageDetailActionTypes {
  GET_DETAILS_REQUEST = 'DASHBOARD_GET_DETAILS_REQUEST',
  GET_DETAILS_SUCCESS = 'DASHBOARD_GET_DETAILS_SUCCESS',
  GET_DETAILS_FAILURE = 'DASHBOARD_GET_DETAILS_FAILURE',
}

export interface GetDetailsAction {
  type: UsageDetailActionTypes
  payload: {
    uuid: Uuid
    data?: UsageDetail
    page: number
  }
}

export const getDetailsRequest = (uuid: Uuid, page: number): GetDetailsAction => ({
  type: UsageDetailActionTypes.GET_DETAILS_REQUEST,
  payload: { uuid, page },
})
export const getDetailsFailure = (uuid: Uuid, page: number): GetDetailsAction => ({
  type: UsageDetailActionTypes.GET_DETAILS_FAILURE,
  payload: { uuid, page },
})
export const getDetailsSuccess = (
  uuid: Uuid,
  data: UsageDetail,
  page: number
): GetDetailsAction => ({
  type: UsageDetailActionTypes.GET_DETAILS_SUCCESS,
  payload: { uuid, data, page },
})

function dateMapFn<T extends HasDate<string>>(value: T) {
  return {
    ...value,
    date: new Date(value.date),
  }
}

const convertDates = (data: InUsageDetail): UsageDetail => {
  return {
    data_usage: data.data_usage.map(dateMapFn),
    voice_usage: data.voice_usage.map(dateMapFn),
    txt_usage: data.txt_usage.map(dateMapFn),
    start_date: new Date(data.start_date),
    end_date: new Date(data.end_date),
  }
}

export const fetchDetails = (uuid: Uuid, page: number = 0, cache: boolean = true) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    // details aren't going to change per request, so we'll cache it in redux
    const state = getState()
    if (cache && getDetailsByUuid(uuid, page)(state)) return

    let url = `/api/account/usage_detail/${uuid}/`
    if (page > 0) url = `${url}?page=${page}`

    dispatch(getDetailsRequest(uuid, page))
    axios
      .get(url)
      .then((data) => {
        dispatch(getDetailsSuccess(uuid, convertDates(data.data), page))
      })
      .catch(() => dispatch(getDetailsFailure(uuid, page)))
  }
}
