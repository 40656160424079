import * as Sentry from '@sentry/browser'
import axios from 'axios'
import { trackEvent } from '../../middleware/segment'
import { ErrorsInterface, FieldError, FormData } from './index'

export enum FormActions {
  FORM_REQUEST = 'FORM_REQUEST',
  FORM_FAILURE = 'FORM_FAILURE',
  FORM_FIELD_ERROR = 'FORM_FIELD_ERROR',
  FORM_CLEAR_FIELD_ERROR = 'FORM_CLEAR_FIELD_ERROR',
  FORM_SUCCESS = 'FORM_SUCCESS',
  FORM_RESET = 'FORM_RESET',
  FORM_RESET_FIELDS = 'FORM_RESET_FIELDS',
  FORM_CHANGE = 'FORM_CHANGE',
}

// Pattern for typescript and redux actions taken from
// https://medium.com/@pie6k/better-way-to-create-type-safe-redux-actions-and-reducers-with-typescript-45386808c103
interface SubmitAction {
  type: FormActions.FORM_REQUEST
  payload: {
    id: string
  }
  meta?: any
}

export interface FailAction {
  type: FormActions.FORM_FAILURE | FormActions.FORM_FIELD_ERROR
  payload: {
    id: string
    errors: ErrorsInterface
    formData: any
  }
  meta?: any
}

export interface FormFieldErrorAction {
  type: FormActions.FORM_FIELD_ERROR
  payload: {
    id: string
    errors: ErrorsInterface
  }
  meta?: any
}

export interface FormFieldClearErrorAction {
  type: FormActions.FORM_CLEAR_FIELD_ERROR
  payload: {
    id: string
    field: string
  }
  meta?: any
}

export interface SuccessAction {
  type: FormActions.FORM_SUCCESS
  payload: {
    id: string
    data: any
    formData: any
    status: number
  }
  meta?: any
}

export interface ResetAction {
  type: FormActions.FORM_RESET
  payload: {
    id: string
  }
  meta?: any
}

export interface ResetFieldsAction {
  type: FormActions.FORM_RESET_FIELDS
  payload: {
    id: string
    fieldNames: string[]
  }
  meta?: any
}

export interface ChangeAction {
  type: FormActions.FORM_CHANGE
  payload: {
    id: string
    data: { [key: string]: string | number }
    initialSet?: boolean
  }
}

export type FormAction =
  | SubmitAction
  | FailAction
  | SuccessAction
  | ResetAction
  | ResetFieldsAction
  | FormFieldErrorAction
  | FormFieldClearErrorAction
  | ChangeAction

// Find the first error in a Django style error object
// and format it for analytics tracking
export const findFirstError = (errors: ErrorsInterface): string | undefined => {
  const fieldsWithErrors = Object.keys(errors).filter((name) => errors[name] && errors[name].length)
  if (fieldsWithErrors.length === 0) return undefined
  const fieldname = fieldsWithErrors[0]
  const error = errors[fieldname]
  const errorText = error.reduce((acc: string, currentError: FieldError, index: number): string => {
    let cleanError = currentError
    if (typeof cleanError !== 'string') {
      cleanError = cleanError.message
    }
    return index === 0 ? cleanError : `${acc},${cleanError}`
  }, '')
  return `${fieldname}: ${errorText}`
}

const isLoading = (id: string) => ({
  type: FormActions.FORM_REQUEST,
  payload: {
    id,
  },
  meta: {
    analytics: trackEvent(`Form: ${id}`, 'Form Attempt'),
  },
})

export const failed = (id: string, errors: ErrorsInterface, formData: object): FailAction => ({
  type: FormActions.FORM_FAILURE,
  payload: {
    id,
    errors,
    formData,
  },
  meta: {
    analytics: trackEvent(`Form: ${id}`, 'Form Validation Error', findFirstError(errors)),
  },
})

export const setFieldError = (id: string, errors: ErrorsInterface): FormFieldErrorAction => ({
  type: FormActions.FORM_FIELD_ERROR,
  payload: {
    id,
    errors,
  },
  meta: {
    analytics: trackEvent(`Form: ${id}`, 'Form Validation Error', findFirstError(errors)),
  },
})

export const clearFieldError = (id: string, field: string): FormFieldClearErrorAction => ({
  type: FormActions.FORM_CLEAR_FIELD_ERROR,
  payload: {
    id,
    field,
  },
})

export const success = (
  id: string,
  data: object,
  formData: object,
  status: number
): SuccessAction => ({
  type: FormActions.FORM_SUCCESS,
  payload: {
    id,
    data,
    formData,
    status,
  },
  meta: {
    analytics: trackEvent(`Form: ${id}`, 'Form Submit Success'),
  },
})

const requestError = (id: string, error: string, formData: object): FailAction => ({
  type: FormActions.FORM_FAILURE,
  payload: {
    id,
    errors: {
      __all__: [error] /* eslint-disable no-underscore-dangle */,
    },
    formData,
  },
  meta: {
    analytics: trackEvent(`Form: ${id}`, 'Other Errors', error),
  },
})

export function submit(id: string, url: string, data: FormData) {
  return (dispatch: Function) => {
    dispatch(isLoading(id))
    axios
      .post(url, data)
      .then((response) => {
        dispatch(success(id, response.data, data, response.status))
      })
      .catch((error) => {
        if (error.response && error.response.status === 400 && error.response.data) {
          // Got an error back from the API
          dispatch(failed(id, error.response.data, data))
        } else {
          let errorMessage = 'Sorry, there was an unexpected error. Please refresh and try again.'
          if (error.response && error.response.status === 429) {
            errorMessage = 'Too many requests. Please try again shortly.'
          } else if (error.response) {
            // Not an error from axios, it crashed in the .then block
            errorMessage = 'Something went wrong. Please try refreshing the page.'
            Sentry.captureException(error)
          }
          dispatch(requestError(id, errorMessage, data))
        }
      })
  }
}

export type FormOnChange = (id: string, name: string, value: string | number) => FormAction

export const onChange: FormOnChange = (id, name, value) => ({
  type: FormActions.FORM_CHANGE,
  payload: {
    id,
    data: { [name]: value },
  },
})

export const setFormValues = (
  id: string,
  data: { [key: string]: string | number }
): FormAction => ({
  type: FormActions.FORM_CHANGE,
  payload: {
    id,
    data,
    initialSet: true,
  },
})

export const reset = (id: string): FormAction => ({
  type: FormActions.FORM_RESET,
  payload: {
    id,
  },
})

export const clearFields = (id: string, fieldNames: string[]): ResetFieldsAction => ({
  type: FormActions.FORM_RESET_FIELDS,
  payload: {
    id,
    fieldNames,
  },
})
