import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { getVoucherProduct } from 'pages/ActivatePage/reducer'
import { makeLoadingSelector } from 'common/Loading/reducer'
import { makeloadVoucher } from 'pages/ActivatePage/actions'
import { setFieldError } from 'common/Form/actions'
import { FormRenderProps } from 'common/Form/BaseForm'
import { VoucherInput } from 'pages/ActivatePage/Stages/SelectPaymentMethod'

const ConnectedRechargeVoucherInput = connect(
  (state: ApplicationState) => {
    const product = getVoucherProduct(state)
    return {
      product,
      isLoading: makeLoadingSelector(['LOAD_VOUCHER'])(state),
    }
  },
  {
    checkVoucher: makeloadVoucher('RECHARGE'),
    setError: setFieldError,
  }
)(VoucherInput)

interface ExtraProps {
  formId: string
}
type VoucherInputFieldProps = FormRenderProps & ExtraProps

const VoucherInputField = ({
  formId,
  formData,
  errors,
  onChange,
  onBlur,
}: VoucherInputFieldProps) => {
  return (
    <Fragment>
      <ConnectedRechargeVoucherInput
        errors={errors.code}
        value={formData.code as string}
        onChange={onChange}
        onBlur={onBlur}
        formId={formId}
      />
    </Fragment>
  )
}

export default VoucherInputField
