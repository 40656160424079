import React from 'react'
import { SemanticVERTICALALIGNMENTS } from 'semantic-ui-react'
import styled from 'styled-components'

interface AlignmentInnerProps {
  verticalAlign: SemanticVERTICALALIGNMENTS
}

const AlignmentInner = styled.div<AlignmentInnerProps>`
  vertical-align: ${(props) => (props.verticalAlign ? props.verticalAlign : 'inherit')};
  align-self: ${(props) => (props.verticalAlign === 'middle' ? 'center' : 'auto')};
  width: 100%;
`

const AlignmentContainer = styled.div`
  display: flex;
  height: 100%;
`

interface AlignmentProps {
  children: React.ReactNode
  verticalAlign: SemanticVERTICALALIGNMENTS
}

const Alignment = ({ verticalAlign, children }: AlignmentProps) => (
  <AlignmentContainer>
    <AlignmentInner verticalAlign={verticalAlign}>{children}</AlignmentInner>
  </AlignmentContainer>
)

export default Alignment
