import React, { useCallback, useState } from 'react'
import { Responsive, Segment, SemanticCOLORS, Header } from 'semantic-ui-react'
import styled from 'styled-components'
import { isNil, findByType } from 'common/Utils'
import Alignment from 'common/Alignment'
import { Caption } from 'common/Typography'
import PlanCard from './PlanCard'
import { ProductCardProps, ProductCardBaseProps } from './types'

interface ProductCardHeaderProps {
  color?: SemanticCOLORS
  content?: React.ReactNode
  extra?: React.ReactNode
  underlay?: boolean
  horizontal?: boolean
}

const ProductCardHeader: React.FC<ProductCardHeaderProps> = ({
  color,
  content,
  extra,
  children,
  underlay,
  horizontal,
}) => {
  const hasChildren = !isNil(children)
  const hasContent = !hasChildren && !isNil(content)
  const hasExtra = !hasChildren && !isNil(extra)
  const className = `
    ${underlay ? 'underlay' : ''}
    ${horizontal ? 'horizontal' : ''}
  `
  const extraBlock = horizontal ? (
    <div className="ui right floated">
      <small>{extra}</small>
    </div>
  ) : (
    <Responsive as="div" maxWidth={Responsive.onlyMobile.maxWidth} className="ui right floated">
      <small>{extra}</small>
    </Responsive>
  )

  return (
    <Segment inverted basic color={color} className={className}>
      {hasChildren && children}
      {hasContent && (
        <h2>
          {content}
          {hasExtra && extraBlock}
        </h2>
      )}
    </Segment>
  )
}

export const BottomlessHeader = styled(Header)`
  &.ui.header {
    margin-bottom: 0;
  }
`

interface ProductCardContentProps {
  content?: React.ReactNode
  header?: React.ReactNode
  style?: React.CSSProperties
}

const ProductCardContent: React.FC<ProductCardContentProps> = ({
  children,
  content,
  header,
  ...rest
}) => {
  const hasChildren = !isNil(children)
  const hasHeader = !hasChildren && !isNil(header)
  const hasContent = !hasChildren && !isNil(content)
  return (
    <Segment secondary basic {...rest}>
      {hasChildren && children}
      {hasHeader && <BottomlessHeader forwardedAs="h3">{header}</BottomlessHeader>}
      {hasContent && <Caption>{content}</Caption>}
    </Segment>
  )
}

interface ProductCardComponent extends React.FC<ProductCardProps> {
  Header: typeof ProductCardHeader
  Content: typeof ProductCardContent
}

export const ProductCard: ProductCardComponent = (props) => {
  const { children, color, header, onClick, active, horizontal } = props
  const baseProps: ProductCardBaseProps = {
    className: `
      basic gaps
      ${onClick || active ? 'selectable' : ''}
      ${active ? 'active' : ''}
    `,
  }

  if (onClick) {
    baseProps.onClick = onClick
    baseProps.onKeyPress = onClick
    baseProps.tabIndex = 0
  }

  const hasChildren = !isNil(children)
  const hasHeader = !isNil(header)

  const [width, setWidth] = useState<number>(Responsive.onlyMobile.maxWidth as number)
  const onResponsiveUpdate = useCallback(
    (event, data) => {
      setWidth(data.width)
    },
    [width, setWidth]
  )

  const childrenAsArray = React.Children.toArray(children)
  const [lastChild, ...otherChildrenReversed] = childrenAsArray.reverse()
  const [firstChild, ...otherChildren] = otherChildrenReversed.reverse()
  const headerChild = findByType(firstChild, ProductCard.Header)
  const hasHeaderChild = !hasHeader && !!headerChild
  const isHorizontalLayout = horizontal || width <= (Responsive.onlyMobile.maxWidth || 0)
  let middleChildren = otherChildren
  if (!hasHeaderChild) {
    middleChildren = [firstChild, ...otherChildren]
  }
  if (!otherChildren.length) {
    middleChildren = [...middleChildren, lastChild]
  }
  return (
    <Segment.Group {...baseProps}>
      {hasHeader && <ProductCard.Header horizontal={horizontal} color={color} content={header} />}
      {hasHeaderChild && React.cloneElement(firstChild as any, { horizontal })}
      {hasChildren && (
        <Responsive
          as={Segment.Group}
          onUpdate={onResponsiveUpdate}
          fireOnMount
          horizontal={isHorizontalLayout}
          className="basic"
        >
          {middleChildren && <Segment.Group className="basic gaps">{middleChildren}</Segment.Group>}
          {middleChildren.length > 1 && (
            <Segment
              secondary
              basic
              className="gaps"
              textAlign={isHorizontalLayout ? 'right' : 'left'}
            >
              <Alignment verticalAlign="middle">{lastChild}</Alignment>
            </Segment>
          )}
        </Responsive>
      )}
    </Segment.Group>
  )
}

ProductCard.displayName = 'ProductCard'
ProductCard.Header = ProductCardHeader
ProductCard.Header.displayName = 'ProductCard.Header'
ProductCard.Content = ProductCardContent
ProductCard.Content.displayName = 'ProductCard.Content'

export { default as AddonCard } from './AddonCard'
export { default as PlanSummary } from './PlanSummary'
export { default as PlaceholderPlanCard, PlaceholderAddonCard } from './PlaceholderCard'
export default PlanCard
