import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import {
  Button,
  ButtonProps,
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Icon,
  Menu,
  Modal,
  Progress,
  Responsive,
  Segment,
  Message,
  Popup,
} from 'semantic-ui-react'
import { Link, Route } from 'react-router-dom'
import classNames from 'classnames'
import { ApplicationState } from 'rootReducer'
import { useBoolean, useNavigate, useParams, useResponsive } from 'common/hooks'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { formatDate, formatDaysRemaining, formatLongDate } from 'common/Utils'
import SvgIcon from 'common/SvgIcon'
import LinkButton from 'common/LinkButton'
import SuccessButton from 'common/SuccessButton'
import { BodyText1 } from 'common/Typography'
import { OnlyProps, Uuid } from 'src/types'
import {
  makeFailureSelector,
  makeLoadingSelector,
  makeSuccessSelector,
  makeRequestKey,
} from 'common/Loading/reducer'
import DashboardContentContainer from 'common/Dashboard'
import PortingRequestInfoBar from 'common/Porting/InfoBar'
import { PortingStatus } from 'common/Porting/types'
import { MobileSelectedNav } from 'common/DashboardNav'
import UsageDetailPage from 'pages/UsageDetailPage'
import { Service, Schedule } from 'pages/RechargePage/types'
import PurchaseHistoryPage from 'pages/PurchaseHistoryPage'
import InvoicePage from 'pages/InvoicePage'
import { getAutoRechargeEnabled, getPlanSchedule, getServiceById } from 'pages/RechargePage/reducer'
import { getSummmaryByUuid } from './reducer'
import { fetchUsageSummary, updateAutoRechargeStatus } from './actions'
import { UsageSummary } from './types'
import UsageSummaryPlaceHolder from './placeholder'
import AddonTable from './Addons'
import { calculateUsedPercentage } from './utils'

const DASHBOARD_ERROR_FAQ = 'https://www.koganmobile.co.nz/customer-dashboard-error/'

interface TParams {
  msisdn: string
}

export const SummaryLoader = ({ uuid, cache }: { uuid: Uuid; cache?: boolean }) => {
  const loadingSelector = useMemo(
    () => makeLoadingSelector([makeRequestKey('GET_USAGE_SUMMARY', uuid)]),
    [uuid]
  )
  const dispatch = useDispatch()
  const isLoading = useSelector(loadingSelector)
  useEffect(() => {
    if (!isLoading) {
      dispatch(fetchUsageSummary(uuid, cache))
    }
  }, [uuid, dispatch])
  return null
}

SummaryLoader.defaultProps = {
  cache: true,
}

// need some whitespace between the heading and the "Usage Detail"
const LeftPaddedLinkedButton = styled(LinkButton)`
  padding-left: 1ch;
`

const NoPaddingTopRow = styled(Grid.Row)`
  &&& {
    padding-top: 0;
  }
`

const AutoRechargeButton: React.FC<ButtonProps> = (props) => {
  const { uuid } = useParams()
  const autoRechargeEnabled = useSelector<ApplicationState, boolean>(getAutoRechargeEnabled(uuid))
  const dispatch = useDispatch()
  const [buttonShown, showButton, hideButton] = useBoolean(!autoRechargeEnabled)

  useEffect(() => {
    // show the button once autoRecharge is enabled again.
    // the onHide callback will hide the button when it is ready to be hidden.
    if (!autoRechargeEnabled) showButton()
  }, [autoRechargeEnabled, showButton])
  const enableAutoRecharge = useCallback(() => {
    dispatch(updateAutoRechargeStatus(uuid, true))
  }, [dispatch, uuid])

  return buttonShown ? (
    <SuccessButton
      actions={['UPDATE_AUTO_RECHARGE']}
      onClick={enableAutoRecharge}
      color="teal"
      basic
      content="ENABLE AUTO-RENEWAL"
      onHide={hideButton}
      {...props}
    />
  ) : null
}

const ButtonMenu: React.FC<{ expired?: boolean }> = ({ expired }: { expired?: boolean }) => {
  const { isMobile: mobile, isTablet: tablet } = useResponsive()
  const isMobile = mobile || tablet
  const dispatch = useDispatch()
  const { uuid } = useParams()
  const [isMenuOpen, openMenu, closeMenu] = useBoolean(false)
  const [isModalOpen, openModal, closeModal] = useBoolean(false)
  const autoRechargeEnabled = useSelector<ApplicationState, boolean>(getAutoRechargeEnabled(uuid))
  const disableAutoRecharge = useCallback(() => {
    dispatch(updateAutoRechargeStatus(uuid, false))
  }, [dispatch, uuid])

  const onActionClick = useCallback(() => {
    closeModal()
  }, [disableAutoRecharge, closeModal])

  return (
    <Fragment>
      {!isMobile && !expired && <AutoRechargeButton size="small" />}

      <Button basic={!isMenuOpen} color="teal" size="small" onClick={openMenu}>
        <Icon fitted name="ellipsis horizontal" />
      </Button>
      <Dropdown
        open={isMenuOpen}
        onClose={closeMenu}
        closeOnChange
        closeOnBlur
        openOnFocus={false}
        simple
        item
        icon={false}
        direction="left"
      >
        <Dropdown.Menu as={Menu}>
          <Modal
            size="large"
            centered
            open={isModalOpen}
            onClose={closeModal}
            trigger={
              autoRechargeEnabled &&
              !expired && <Menu.Item onClick={openModal}>Disable Auto Renewal</Menu.Item>
            }
          >
            <Modal.Content>
              <Header as="h2">
                Are you sure you want to disable automatic renewal and payment for this Prepay Plan?
              </Header>
              <p>
                Once your Prepay Plan has expired, you will no longer be able to use data, make
                calls, or send text messages, until you purchase a Prepay Plan again.
              </p>
            </Modal.Content>
            <Modal.Actions
              actions={[
                <Button content="Cancel" onClick={onActionClick} />,
                <SuccessButton
                  actions={['UPDATE_AUTO_RECHARGE']}
                  positive
                  onClick={disableAutoRecharge}
                  content="OK"
                  onHide={onActionClick}
                />,
              ]}
            />
          </Modal>

          <Menu.Item as={Link} to={`/services/${uuid}/invoices/`}>
            Invoices
          </Menu.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Fragment>
  )
}

const PlanColour = styled('div')`
  &:before {
    content: ' ';
    position: absolute;
    top: -50px;
    bottom: 0;
    right: 100%;
    background-color: inherit;
    left: -50px; /* needs to extend past the border, so be 50 for safety */
    z-index: 0;
  }
`

const RelativeGrid = styled(Grid)`
  position: relative;
`

const MarginTopGrid = styled(Grid)`
  .ui.grid & {
    margin-top: 1em;
  }
`

const RechargeButton: React.FC<ButtonProps> = (props) => {
  const { uuid } = useParams()
  const navigate = useNavigate()
  const service = useSelector<ApplicationState, Service>((state) => getServiceById(state, uuid))
  const onClick = useCallback(() => {
    navigate(`/services/renew/${service.plan_short_id}/`)
  }, [service, navigate])
  return (
    <Button color="teal" size="small" basic onClick={onClick} {...props}>
      RENEW OR BUY PREPAY PLAN
    </Button>
  )
}

const NoPaddingGridRow = styled(Grid.Row)`
  padding-bottom: 0 !important;
`

const SummaryTable = ({ summary }: { summary?: UsageSummary }) => {
  const { isMobile: mobileOnly, isTablet: tabletOnly } = useResponsive()
  const isMobile = mobileOnly || tabletOnly
  const { uuid } = useParams()

  const autoRechargeEnabled = useSelector<ApplicationState, boolean>(getAutoRechargeEnabled(uuid))
  const service = useSelector<ApplicationState, Service>((state) => getServiceById(state, uuid))
  const schedule = useSelector<ApplicationState, Schedule | undefined>((state) => {
    if (!uuid) return undefined
    const planSchedule = getPlanSchedule(state, uuid)
    if (!planSchedule) return undefined
    return planSchedule
  })
  const colour = (schedule && schedule.product.colour) || undefined
  const planName =
    (schedule && schedule.product.title) || (summary && summary.plan.title) || 'Unknown'
  const dataEntitlement = summary ? summary.plan.entitlements[0] : undefined
  const usageAllowanceAmount =
    (summary &&
      dataEntitlement &&
      parseFloat(dataEntitlement.totalAllowance.amount) -
        parseFloat(dataEntitlement.remainingAllowance.amount)) ||
    0

  const portingRequest = (service && service.porting_request) || false

  const planExpiryDate = (summary && formatDate(new Date(summary.expiry_date))) || ''

  const entitlementRenewalDate = (summary && formatDate(new Date(summary.renewal_date))) || ''
  const daysRemainingToRenew = (summary && formatDaysRemaining(new Date(summary.renewal_date))) || 0

  const entitlementRenewalLength = (summary && summary.plan.renewal_length) || ''

  return (
    <Fragment>
      <RelativeGrid>
        {mobileOnly && <PlanColour className={classNames('background', colour)} />}
        <Grid.Row>
          <Grid.Column width={isMobile ? 12 : 8}>
            <Header as="h1">
              {planName} Prepay Plan
              {summary && (
                <Header.Subheader className="nomargin">
                  {autoRechargeEnabled
                    ? `Prepay Plan renews on: ${planExpiryDate}`
                    : `Prepay Plan expires on: ${planExpiryDate}`}
                </Header.Subheader>
              )}
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="right" width={isMobile ? 4 : 8}>
            <ButtonMenu />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            {portingRequest && summary && <PortingRequestInfoBar portingRequest={portingRequest} />}
            {!summary && (
              <Message negative>
                {!portingRequest &&
                  'Sorry, your usage details are currently unavailable. Please refresh and try again.'}
                {portingRequest && (
                  <Fragment>
                    Sorry, your usage details are currently unavailable. Please check the{' '}
                    <a href={DASHBOARD_ERROR_FAQ} target="_blank" rel="noopener noreferrer">
                      FAQ
                    </a>{' '}
                    for more information or refresh and try again.
                  </Fragment>
                )}
              </Message>
            )}
          </Grid.Column>
        </Grid.Row>
        <NoPaddingGridRow>
          <Grid.Column width={16}>{isMobile && <AutoRechargeButton fluid />}</Grid.Column>
        </NoPaddingGridRow>
      </RelativeGrid>
      <Divider hidden />

      <MarginTopGrid className="condensed medium">
        <Grid.Row>
          <Grid.Column width={isMobile ? 16 : 8}>
            <Header as="h2">
              Data Usage
              {summary && (
                <LeftPaddedLinkedButton to={`/services/${uuid}/detail/`}>
                  USAGE&nbsp;DETAILS
                </LeftPaddedLinkedButton>
              )}
              {summary && !summary.plan.is_final_cycle && (
                <Header.Subheader className="nomargin">
                  Data renews at midnight on {entitlementRenewalDate}
                </Header.Subheader>
              )}
            </Header>
          </Grid.Column>
          <Grid.Column textAlign="right" width={isMobile ? 4 : 8}>
            {!isMobile && <RechargeButton />}
          </Grid.Column>
        </Grid.Row>
        {summary && dataEntitlement && entitlementRenewalLength && (
          <Fragment>
            <Grid.Row>
              <Grid.Column>
                <BodyText1>
                  {usageAllowanceAmount.toFixed(2)}
                  {dataEntitlement.totalAllowance.unit} of {dataEntitlement.totalAllowance.amount}
                  {dataEntitlement.totalAllowance.unit} used
                </BodyText1>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="middle">
              <Grid.Column verticalAlign="middle" width={isMobile ? 10 : 8}>
                <Progress
                  color="teal"
                  className="nomargin"
                  percent={calculateUsedPercentage(
                    dataEntitlement.totalAllowance.amount,
                    dataEntitlement.remainingAllowance.amount
                  )}
                />
              </Grid.Column>
              <Grid.Column width={isMobile ? 6 : 8}>
                {dataEntitlement.remainingAllowance.amount}
                {dataEntitlement.remainingAllowance.unit} remaining
              </Grid.Column>
            </Grid.Row>
            <Grid.Row verticalAlign="middle">
              <Grid.Column verticalAlign="middle" width={isMobile ? 10 : 8}>
                <Progress
                  className="nomargin"
                  size="small"
                  percent={(1 - daysRemainingToRenew / entitlementRenewalLength) * 100}
                />
              </Grid.Column>
              <Grid.Column width={isMobile ? 6 : 8}>
                {daysRemainingToRenew} days remaining{' '}
                <Popup
                  trigger={<Icon color="grey" name="info circle" />}
                  content={`${
                    autoRechargeEnabled ? 'Renews' : 'Expires'
                  } on ${entitlementRenewalDate}`}
                  position="top center"
                  inverted
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>{isMobile && <RechargeButton fluid />}</Grid.Column>
            </Grid.Row>
          </Fragment>
        )}
      </MarginTopGrid>
      {!summary && (
        <MarginTopGrid className="medium">
          <NoPaddingTopRow>
            <Grid.Column>
              <p>Your data usage is not available at the moment</p>
            </Grid.Column>
          </NoPaddingTopRow>
        </MarginTopGrid>
      )}
      <Divider hidden />
      <AddonTable addons={(summary && summary.addons) || []} apiDown={!summary} />
      {(!portingRequest || portingRequest.status === PortingStatus.FAILED) && (
        <MarginTopGrid className="medium">
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">Phone Number Settings</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <Link to={`/services/${uuid}/port/`}>
                <Icon name="exchange" />
                <strong> Request Number Transfer </strong>
                <Icon name="arrow right" />
              </Link>
            </Grid.Column>
          </Grid.Row>
        </MarginTopGrid>
      )}
      <Divider hidden />
    </Fragment>
  )
}

const ExpiredServiceContent = ({ summary }: { summary: UsageSummary }) => {
  const { isMobile } = useResponsive()
  const { uuid } = useParams()
  const service = useSelector<ApplicationState, Service>((state) => getServiceById(state, uuid))
  const serviceId = service.plan_short_id
  return (
    <Container fluid>
      <Grid>
        <Grid.Row columns="equal">
          <Grid.Column />
          <Grid.Column textAlign="right">
            <ButtonMenu expired />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Header as="h2" textAlign="center" icon style={{ paddingTop: isMobile ? 0 : 90 }}>
        <SvgIcon name="surprised-face" color="grey" />
        Prepay Plan Expired on {formatLongDate(new Date(summary.expiry_date))}!
        <Header.Subheader>
          Your Phone Number will be reserved for 90 days past your Prepay Plan expiry.
        </Header.Subheader>
        <Header.Subheader>Renew a Prepay Plan to keep your Phone Number.</Header.Subheader>
        <Segment basic>
          <Button as={Link} to={`/services/renew/${serviceId}`} inverted color="teal">
            RENEW OR BUY PREPAY PLAN
          </Button>
        </Segment>
      </Header>
    </Container>
  )
}

const UsageSummaryPage = () => {
  const { uuid } = useParams()
  if (!uuid) return <Fragment />
  const loadingSelector = useMemo(
    () => makeLoadingSelector([makeRequestKey('GET_USAGE_SUMMARY', uuid)]),
    [uuid]
  )
  const successSelector = useMemo(
    () => makeSuccessSelector([makeRequestKey('GET_USAGE_SUMMARY', uuid)]),
    [uuid]
  )
  const failureSelector = useMemo(
    () => makeFailureSelector([makeRequestKey('GET_USAGE_SUMMARY', uuid)]),
    [uuid]
  )
  const summarySelector = useMemo(() => getSummmaryByUuid(uuid), [uuid])
  const summary = useSelector(summarySelector)
  const isLoading = useSelector(loadingSelector)
  const isFailed = useSelector(failureSelector)
  const isSuccess = useSelector(successSelector)
  return (
    <Fragment>
      <div>
        <Grid columns="equal" stackable relaxed>
          <Grid.Column>
            {/* summary loaded with content */}
            {summary && isSuccess && summary.is_expired && (
              <ExpiredServiceContent summary={summary} />
            )}
            {/* summary table should only load if it's either successful or failure to prevent content jank */}
            {!isLoading && (isSuccess || isFailed) && !(summary && summary.is_expired) && (
              <SummaryTable summary={summary} />
            )}
            {/* loading summary */}
            {isLoading && <UsageSummaryPlaceHolder />}
          </Grid.Column>
        </Grid>
      </div>
    </Fragment>
  )
}

const RoutedUsageSummaryPage = ({ only }: OnlyProps) => {
  const { isMobile } = useResponsive()
  return (
    <DashboardContentContainer only={only}>
      <Segment className="nopadding">
        <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
          <MobileSelectedNav />
        </Responsive>
        <Segment basic padded={isMobile ? true : 'very'}>
          <Route exact path="/services/:uuid/detail/" component={UsageDetailPage} />
          <Route exact path="/services/:uuid/invoices/" component={PurchaseHistoryPage} />
          <Route exact path="/services/:uuid/invoices/:orderCode/" component={InvoicePage} />
          <Route exact path="/services/:uuid/" component={UsageSummaryPage} />
        </Segment>
      </Segment>
    </DashboardContentContainer>
  )
}
export default RoutedUsageSummaryPage
