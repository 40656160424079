import React, { ReactNode } from 'react'
import { Grid } from 'semantic-ui-react'

interface DashboardContentContainer {
  only?: string
  children: ReactNode
}

const DashboardContentContainer = ({ only, children }: DashboardContentContainer) => (
  <Grid.Column className="content" style={{ paddingLeft: 0 }} only={only}>
    {children}
  </Grid.Column>
)

export default DashboardContentContainer
