export interface PortingRequest {
  msisdn: string // Full number with country code (MSISDN)
  phone_number: string // Display number with spacing + no country prefix
  provider: string
  account_number: string
  prepaid_sim_number: string
  billing_method: number
  status: number
  external_id: string
  failure_acknowledged_at: string
  failed_message: string
}

export enum PortingStatus {
  NEW = 1,
  SUBMITTED = 2,
  COMPLETED = 3,
  FAILED = 4,
}

export const PortingChoice = {
  PORT: true,
  KEEP: false,
}
