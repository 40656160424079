import React, { Fragment, useMemo, useCallback } from 'react'
import {
  Form,
  Input,
  InputOnChangeData,
  SemanticWIDTHS,
  StrictFormGroupProps,
  TransitionGroup,
} from 'semantic-ui-react'
import parseErrors from 'common/Form/utils'
import PasswordFormField from 'common/Form/PasswordFormField'
import useAutoNext from 'common/Form/AutoNext'
import { FormRenderProps } from 'common/Form/BaseForm'
import PinCodeFormField from 'common/Form/PinCodeFormField'
import RadioGroupFormField from 'common/Form/RadioGroupFormField'
import CheckboxFormField from 'common/Form/CheckboxFormField'
import {
  FieldInterface,
  FieldErrorsInterface,
  GroupedFieldInterface,
  InformationBlockInterface,
  FormDataValue,
} from '..'

// As opposed to InputOnChangeData this interface allow us to store
// FormDataValue: string | number | boolean | string[] from the overriding field
interface OnChangeData {
  name: string
  value: FormDataValue
}

export type FieldOnChange = (event: React.ChangeEvent, data: OnChangeData) => void
export type FieldOnBlur = (event: React.FocusEvent) => void

export interface BaseFormFieldInterface extends FieldInterface {
  errors: FieldErrorsInterface
  value: string | number | boolean
  onChange: FieldOnChange
  onBlur: FieldOnBlur
  onFinishInput?: (name: string) => void
  formId: string
  tabIndex: number
}

const helpAnimationStyle = { hide: 0, show: 300 }

const BaseFormField = ({
  errors,
  value,
  onChange,
  onBlur,
  tabIndex,
  maxLength,
  inputModifier,
  isLoading,
  ...field
}: BaseFormFieldInterface) => {
  const showError = !!errors
  const showHelp = !showError && field.help

  const [ref, onFinishInput] = useAutoNext(field.formId, field.name)
  const newOnChange = useCallback(
    (event: React.ChangeEvent, data: InputOnChangeData) => {
      const modifiedData = inputModifier ? inputModifier(data) : data
      onChange(event, { name: field.name, ...modifiedData })
      // base assumption: if the user has reached the end of the input, then they should be finished with it.
      if (onFinishInput && maxLength && `${modifiedData.value}`.length === maxLength) {
        onFinishInput()
      }
    },
    [field.name, maxLength, inputModifier, onFinishInput]
  )

  return (
    <Form.Field
      key={field.name}
      required={field.required}
      error={!!errors}
      width={field.width as SemanticWIDTHS}
    >
      {field.label && <label htmlFor={field.name}>{field.label}</label>}
      <Input
        name={field.name}
        id={field.name}
        type={field.type}
        value={value || ''}
        disabled={field.disabled}
        onChange={newOnChange}
        onBlur={onBlur}
        icon={field.icon}
        iconPosition={field.iconPosition}
        placeholder={field.placeholder}
        required={field.required}
        maxLength={maxLength}
        autoComplete={field.autocomplete}
        className={field.className}
        ref={ref}
        tabIndex={tabIndex}
        input={{ inputMode: field.inputMode }}
        loading={isLoading}
      />
      {showHelp && <span className="help">{field.help}</span>}
      <TransitionGroup animation="slide down" duration={helpAnimationStyle}>
        {showError && <span className="help error">{parseErrors(errors)[0]}</span>}
      </TransitionGroup>
    </Form.Field>
  )
}

const getFormFieldComponent = (field: FieldInterface) => {
  switch (field.type) {
    case 'pin_code':
      return PinCodeFormField

    case 'radio_group':
      return RadioGroupFormField

    case 'checkbox':
      return CheckboxFormField

    case 'password':
      if (field.masked) {
        return PasswordFormField
      }
      return BaseFormField

    default:
      if (field.component) return field.component
      return BaseFormField
  }
}

export default BaseFormField

/* Required to work with interface union */
const isContent = (formField: GroupedFieldInterface): formField is InformationBlockInterface => {
  if ((formField as InformationBlockInterface).content) {
    return true
  }
  return false
}

export const RenderedFieldSet = ({
  formData,
  fields,
  errors,
  isSubmitting,
  formId,
  onChange,
  onBlur,
}: FormRenderProps & { fields: GroupedFieldInterface[] }): JSX.Element => {
  const renderField = useMemo(() => {
    return (field: FieldInterface | InformationBlockInterface, value: FormDataValue) => {
      if (isContent(field)) {
        if (React.isValidElement(field.content)) {
          return field.content
        }
        return <p key={field.name}>{field.content}</p>
      }
      const FieldComponent = getFormFieldComponent(field)

      return (
        <FieldComponent
          key={field.name}
          value={value}
          errors={errors[field.name]}
          onChange={onChange}
          onBlur={onBlur}
          disabled={field.disabled || isSubmitting}
          formId={formId}
          tabIndex={0}
          {...field}
        />
      )
    }
  }, [errors, isSubmitting, formId, onChange, onBlur])
  return (
    <Fragment>
      {fields.map((field) => {
        if (Array.isArray(field)) {
          const width: StrictFormGroupProps['widths'] = Math.min(Math.round(field.length), 4) as
            | 1
            | 2
            | 3
            | 4
          return (
            <Form.Group key={field.map((groupField) => groupField.name).join('|')} widths={width}>
              {field.map((groupField) => {
                const { [groupField.name]: value } = formData
                return renderField(groupField, value)
              })}
            </Form.Group>
          )
        }
        const { [field.name]: value } = formData
        return renderField(field, value)
      })}
    </Fragment>
  )
}
