import { ApplicationState } from 'rootReducer'

import { GetDetailsAction, UsageDetailActionTypes } from 'pages/UsageDetailPage/actions'
import { GetUsageSummaryAction, UsageSummaryActionTypes } from 'pages/UsageSummaryPage/actions'
import {
  PurchaseHistoryActionTypes,
  GetPurchaseHistoryAction,
} from 'pages/PurchaseHistoryPage/actions'
import { InvoiceActionTypes, GetInvoiceAction } from 'pages/InvoicePage/actions'
import {
  ChangeEmailAction,
  ChangeEmailActions,
  ChangeEmailNewOTPActions,
  ChangeEmailStatusActions,
} from 'pages/ContactDetailsPage/ChangeEmailForm/actions'
import { PortingProviderActions, PortingProviderAction } from 'common/Porting/actions'
import { FormActions, SuccessAction } from 'common/Form/actions'
import { FORM_ID as changeEmailFormId } from 'pages/ContactDetailsPage/ChangeEmailForm'
import {
  ChangeEmailRequest,
  ChangeEmailRequestState,
} from 'pages/ContactDetailsPage/ChangeEmailForm/types'

import { Uuid } from 'src/types'
import { DashboardState } from './types'

export const initialDashboardState: DashboardState = {
  byUuid: {},
  changeEmailStatus: { currentPage: ChangeEmailRequestState.CREATED },
  providers: [],
}

const dashboardReducer = (
  state: DashboardState = initialDashboardState,
  action:
    | GetUsageSummaryAction
    | GetDetailsAction
    | GetPurchaseHistoryAction
    | SuccessAction
    | ChangeEmailAction
    | GetInvoiceAction
    | PortingProviderAction
) => {
  switch (action.type) {
    case UsageSummaryActionTypes.GET_USAGE_SUMMARY_SUCCESS:
      if (!action.payload.data) {
        return state
      }
      return {
        ...state,
        byUuid: {
          ...state.byUuid,
          [action.payload.uuid]: {
            ...state.byUuid[action.payload.uuid],
            summary: action.payload.data,
          },
        },
      }
    case UsageDetailActionTypes.GET_DETAILS_REQUEST: {
      const uuidState = state.byUuid[action.payload.uuid] || {}
      const { detail } = uuidState

      if (detail) {
        delete detail[action.payload.page]
      }
      return {
        ...state,
        byUuid: {
          ...state.byUuid,
          [action.payload.uuid]: {
            ...state.byUuid[action.payload.uuid],
            detail,
          },
        },
      }
    }
    case UsageDetailActionTypes.GET_DETAILS_SUCCESS:
      if (!action.payload.data) return state
      return {
        ...state,
        byUuid: {
          ...state.byUuid,
          [action.payload.uuid]: {
            ...state.byUuid[action.payload.uuid],
            detail: {
              ...(state.byUuid[action.payload.uuid].detail || {}),
              [action.payload.page]: action.payload.data,
            },
          },
        },
      }
    case PurchaseHistoryActionTypes.GET_PURCHASE_HISTORY_SUCCESS:
      if (!action.payload.data) return state
      return {
        ...state,
        byUuid: {
          ...state.byUuid,
          [action.payload.uuid]: {
            ...state.byUuid[action.payload.uuid],
            purchase_history: action.payload.data,
          },
        },
      }
    case InvoiceActionTypes.GET_INVOICE_SUCCESS:
      if (!action.payload.data) return state
      return {
        ...state,
        byUuid: {
          ...state.byUuid,
          [action.payload.uuid]: {
            ...state.byUuid[action.payload.uuid],
            invoices: {
              ...(state.byUuid[action.payload.uuid] || {}).invoices,
              [action.payload.orderCode]: action.payload.data,
            },
          },
        },
      }
    case ChangeEmailStatusActions.SUCCESS:
    case ChangeEmailNewOTPActions.SUCCESS:
      if (!action.payload) return state
      return {
        ...state,
        changeEmailStatus: {
          ...state.changeEmailStatus,
          current: action.payload,
          currentPage: action.payload.state,
        },
      }
    case FormActions.FORM_SUCCESS:
      if (action.payload.id !== changeEmailFormId) return state
      return {
        ...state,
        changeEmailStatus: {
          ...state.changeEmailStatus,
          current: action.payload.data,
          currentPage: action.payload.data.state,
        },
      }
    case ChangeEmailActions.GO_BACK:
      return {
        ...state,
        changeEmailStatus: {
          ...state.changeEmailStatus,
          currentPage: ChangeEmailRequestState.CREATED,
        },
      }

    case PortingProviderActions.SUCCESS:
      if (!action.payload) return state
      return {
        ...state,
        providers: action.payload,
      }
    default:
      return state
  }
}

export const selectCurrentChangeEmailState = (
  state: ApplicationState
): ChangeEmailRequest | undefined => {
  return {
    new_email: '',
    ...state.dashboard.changeEmailStatus.current,
    state: state.dashboard.changeEmailStatus.currentPage,
  }
}

export const getPhoneNumberByUuid = (uuid: Uuid) => (state: ApplicationState): string | null => {
  const service = state.services.byUuid[uuid]
  if (service) {
    return service.phone_number
  }
  return null
}

export default dashboardReducer
