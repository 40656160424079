import axios from 'axios'
import { ApplicationState } from 'rootReducer'
import { getPlanSchedule } from 'pages/RechargePage/reducer'
import { Schedule, Service } from 'pages/RechargePage/types'

import { Uuid } from 'src/types'
import { UsageSummary } from './types'
import { getSummmaryByUuid } from './reducer'

export enum UsageSummaryActionTypes {
  GET_USAGE_SUMMARY_FAILURE = 'GET_USAGE_SUMMARY_FAILURE',
  GET_USAGE_SUMMARY_REQUEST = 'GET_USAGE_SUMMARY_REQUEST',
  GET_USAGE_SUMMARY_SUCCESS = 'GET_USAGE_SUMMARY_SUCCESS',
}

export enum UpdateAutoRechargeActionTypes {
  UPDATE_AUTO_RECHARGE_SUCCESS = 'UPDATE_AUTO_RECHARGE_SUCCESS',
  UPDATE_AUTO_RECHARGE_REQUEST = 'UPDATE_AUTO_RECHARGE_REQUEST',
  UPDATE_AUTO_RECHARGE_FAILURE = 'UPDATE_AUTO_RECHARGE_FAILURE',
}

export interface GetUsageSummaryAction {
  type: UsageSummaryActionTypes
  requestId: Uuid
  payload: {
    uuid: Uuid
    data?: UsageSummary
  }
}

export const getUsageSummaryRequest = (uuid: Uuid): GetUsageSummaryAction => ({
  type: UsageSummaryActionTypes.GET_USAGE_SUMMARY_REQUEST,
  requestId: uuid,
  payload: { uuid },
})

export const getUsageSummaryFailure = (uuid: Uuid): GetUsageSummaryAction => ({
  type: UsageSummaryActionTypes.GET_USAGE_SUMMARY_FAILURE,
  requestId: uuid,
  payload: { uuid },
})

export const getUsageSummarySuccess = (uuid: Uuid, data: UsageSummary): GetUsageSummaryAction => ({
  type: UsageSummaryActionTypes.GET_USAGE_SUMMARY_SUCCESS,
  requestId: uuid,
  payload: { uuid, data },
})

export const fetchUsageSummary = (uuid: Uuid, cache: boolean = true) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    // details aren't going to change per request, so we'll cache it in redux
    const state = getState()
    if (cache && getSummmaryByUuid(uuid)(state)) return

    dispatch(getUsageSummaryRequest(uuid))
    axios
      .get(`/api/account/usage_summary/${uuid}/`)
      .then((data) => {
        dispatch(getUsageSummarySuccess(uuid, data.data))
      })
      .catch(() => {
        dispatch(getUsageSummaryFailure(uuid))
      })
  }
}

export interface UpdateAutoRechargeAction {
  type: UpdateAutoRechargeActionTypes
  payload?: { service: Service }
}

const updateAutoRechargeStatusRequest = () => ({
  type: UpdateAutoRechargeActionTypes.UPDATE_AUTO_RECHARGE_REQUEST,
})
const updateAutoRechargeStatusSuccess = (payload: { service: Service }) => ({
  type: UpdateAutoRechargeActionTypes.UPDATE_AUTO_RECHARGE_SUCCESS,
  payload,
})
const updateAutoRechargeStatusFailure = () => ({
  type: UpdateAutoRechargeActionTypes.UPDATE_AUTO_RECHARGE_FAILURE,
})

export const updateAutoRechargeStatus = (uuid: Uuid, setRechargeEnabled: boolean) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    const serviceId = (getPlanSchedule(getState(), uuid) as Schedule).unique_id
    dispatch(updateAutoRechargeStatusRequest())
    const axiosFn = setRechargeEnabled ? axios.put : axios.delete
    axiosFn(`/api/services/auto_renew/${uuid}/${serviceId}/`)
      .then((data) => {
        dispatch(updateAutoRechargeStatusSuccess(data.data))
      })
      .catch(() => {
        dispatch(updateAutoRechargeStatusFailure())
      })
  }
}
