import React, { useEffect, useState } from 'react'
import { Image, Transition, SemanticTRANSITIONS } from 'semantic-ui-react'
import { useToggle } from 'common/hooks'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('static/img/kogan_mobile_logo.svg')

const LoadingPage = () => {
  const [visibility, toggle] = useToggle(false)
  const [animation, setAnimation] = useState<SemanticTRANSITIONS>('fade up')
  function changeAnimation() {
    setAnimation('pulse')
  }
  useEffect(() => {
    const timer = setTimeout(toggle, 1000)
    return () => {
      clearTimeout(timer)
    }
  })
  return (
    <div
      style={{
        display: 'table',
        height: '100vh',
        width: '100%',
        position: 'absolute',
      }}
    >
      <div style={{ verticalAlign: 'middle', display: 'table-cell' }}>
        <Transition
          visible={visibility}
          duration={500}
          animation={animation}
          onComplete={changeAnimation}
        >
          <Image src={logo} size="small" centered />
        </Transition>
      </div>
    </div>
  )
}

export default LoadingPage
