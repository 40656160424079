import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import { User } from 'common/UserContainer/reducer'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'

interface SegmentSnippet {
  initialize: Function
  invoked: boolean
  methods: string[]
  factory: any
  // Because @types/segment-analytics has an outdated signature...
  load(writeKey: string, options: Record<string, any>): void
}

interface SegmentWindow extends Window {
  analytics: SegmentSnippet | SegmentAnalytics.AnalyticsJS | any
}

declare const window: SegmentWindow

export default function initSegment(writeKey: string, hostname: string = 'api.segment.io') {
  /* eslint-disable */
  // Create a queue, but don't obliterate an existing one!
  const analytics = (window.analytics = window.analytics || [])

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return

  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    if (window.console && console.error) {
      console.error('Segment snippet included twice.')
    }
    return
  }

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true

  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    'trackSubmit',
    'trackClick',
    'trackLink',
    'trackForm',
    'pageview',
    'identify',
    'reset',
    'group',
    'track',
    'ready',
    'alias',
    'debug',
    'page',
    'once',
    'off',
    'on',
  ]

  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method: string) {
    return function () {
      const args = Array.prototype.slice.call(arguments)
      args.unshift(method)
      analytics.push(args)
      return analytics
    }
  }

  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i]
    analytics[key] = analytics.factory(key)
  }

  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key: string, options: { cdn: string }) {
    // Create an async script element based on your key.
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.src = `https://${options.cdn}/analytics.js/v1/${key}/analytics.min.js`

    // Insert our script next to the first script element.
    const first = document.getElementsByTagName('script')[0]
    // @ts-ignore
    first.parentNode.insertBefore(script, first)
    analytics._loadOptions = options
  }

  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = '4.1.0'

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  analytics.load(writeKey, {
    cdn: hostname,
  })
}

const SegmentPageTracking = ({ location }: { location: Location }) => {
  const trackPage = (path: string): void => {
    window.analytics.page({
      path,
    })
  }

  useEffect(() => trackPage(location.pathname), [location.pathname])

  return null
}

export const TrackingRoute = () => <Route component={SegmentPageTracking} />

const SegmentUserTrackingComponent = ({ user }: { user: User | null }): null => {
  function identifyUser() {
    // Only call identify when logged in.
    // This will be null when logged out, or loading.
    if (user) {
      window.analytics.identify(user.unique_id, { email: user.email })
    }
  }
  useEffect(identifyUser, [user && user.unique_id])
  return null
}

export const SegmentUserTracking = connect((state: ApplicationState) => ({
  user: state.user.user,
}))(SegmentUserTrackingComponent)

export const CheckoutStepTracking = ({ step }: { step: number }) => {
  function trackView() {
    window.analytics.track('Viewed Checkout Step', { step: step })
  }
  useEffect(trackView, [step])
  return null
}
