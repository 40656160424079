import { ApplicationState } from 'rootReducer'
import { Uuid } from 'src/types'

import { Invoice } from './types'

// eslint-disable-next-line import/prefer-default-export
export const getInvoice = (uuid: Uuid, orderCode: string) => (
  state: ApplicationState
): Invoice | undefined => {
  if (state.dashboard.byUuid[uuid]) {
    return (state.dashboard.byUuid[uuid].invoices || {})[orderCode]
  }
  return undefined
}
