import React, { Fragment, FormEvent, ChangeEvent } from 'react'
import { useToggle } from 'common/hooks'
import { BaseFormFieldInterface } from 'common/Form/BaseFormField'
import parseErrors from 'common/Form/utils'
import { Form, TransitionGroup, Checkbox } from 'semantic-ui-react'

const CheckboxFormField = ({
  onChange,
  name,
  errors,
  value,
  checked,
  ...field
}: BaseFormFieldInterface) => {
  const showError = errors && !!errors.length
  const [localValue, toggle] = useToggle(checked || !!value || false)

  const onChangeHandler = (event: FormEvent<HTMLInputElement>) => {
    toggle()
    onChange(event as ChangeEvent<HTMLInputElement>, { name, value: !localValue })
  }

  return (
    <Fragment>
      <Form.Field>
        <Checkbox label={field.label} onChange={onChangeHandler} checked={localValue} />
        <TransitionGroup animation="slide down">
          {showError && <span className="help error">{parseErrors(errors)[0]}</span>}
        </TransitionGroup>
      </Form.Field>
    </Fragment>
  )
}

export default CheckboxFormField
