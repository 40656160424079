import React, { useEffect, useState } from 'react'
import { Header, Icon, Transition } from 'semantic-ui-react'

const Loading = () => {
  const [visible, setVisible] = useState<boolean>(false)
  useEffect(() => {
    const timer = setTimeout(() => setVisible(true), 500)
    return () => {
      clearTimeout(timer)
    }
  }, [null])
  return (
    <Transition animation="fade up" visible={visible} duration={600}>
      <Header as="h1" textAlign="center" icon>
        <Icon name="spinner" loading />
        Loading
        <Header.Subheader>Please wait while we fetch your details</Header.Subheader>
      </Header>
    </Transition>
  )
}

export default Loading
