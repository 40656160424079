import React, { PureComponent, Fragment, FormEvent, ChangeEvent } from 'react'
import { BaseFormFieldInterface } from 'common/Form/BaseFormField'
import parseErrors from 'common/Form/utils'
import { Form, TransitionGroup, Radio, CheckboxProps } from 'semantic-ui-react'

class RadioGroupFormField extends PureComponent<BaseFormFieldInterface> {
  onChangeHandler = (event: FormEvent<HTMLInputElement>, checkboxProps: CheckboxProps) => {
    const { onChange, name } = this.props
    const { value } = checkboxProps
    onChange(event as ChangeEvent<HTMLInputElement>, { name, value: value || '' })
  }

  render() {
    const { errors, value, ...field } = this.props

    const showError = errors && !!errors.length

    return (
      <Fragment>
        {field.label && (
          <Form.Field>
            <label htmlFor={field.name}>{field.label}</label>
          </Form.Field>
        )}
        {field.options &&
          field.options.map((option) => {
            return (
              <Form.Field key={option.value as string}>
                <Radio
                  key={option.value as string}
                  value={option.value}
                  label={option.label}
                  checked={value === option.value}
                  active={(value === option.value).toString()}
                  option={option}
                  onChange={this.onChangeHandler}
                  required
                />
              </Form.Field>
            )
          })}
        <Form.Field>
          <TransitionGroup animation="slide down">
            {showError && <span className="help error">{parseErrors(errors)[0]}</span>}
          </TransitionGroup>
        </Form.Field>
      </Fragment>
    )
  }
}

export default RadioGroupFormField
