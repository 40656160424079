import React, { useState, SyntheticEvent } from 'react'
// @ts-ignore
import Slider from 'react-rangeslider'

import { Keys } from 'common/Utils'

const LABEL_CONTAINER_CLASS = 'labelContainer'

export interface SliderOption {
  position: number
  label: string | JSX.Element
  extraText: string
  filterValue: number
}
interface DefaultRangeSliderProps {
  transformLabel: (position: number, option: SliderOption) => string | JSX.Element
  onChange(name: string, option: SliderOption): void
  options: SliderOption[]
  labelClassName: string // Not passing labelClassName will result in label clicking being disabled,
  isLoading: boolean
}

interface RangeSliderProps extends DefaultRangeSliderProps {
  name: string
}

interface SliderLabel {
  [key: string]: string | JSX.Element
}

const defaultProps: DefaultRangeSliderProps = {
  labelClassName: LABEL_CONTAINER_CLASS,
  transformLabel: (position: number, opt: SliderOption) => {
    // Renders label plainly by default
    return (
      <div className={LABEL_CONTAINER_CLASS}>
        <span>{opt.label}</span>
      </div>
    )
  },
  onChange: () => null,
  options: [],
  isLoading: false,
}

const translateOptionsToSliderLabelsProp = (options: SliderOption[]): SliderLabel => {
  if (options.length === 0) return {}

  const labels: SliderLabel = {}

  options.forEach((option) => {
    labels[option.position] = option.label
  })
  return labels
}

const RangeSlider = ({
  onChange,
  name,
  options,
  transformLabel,
  labelClassName,
  isLoading,
}: RangeSliderProps): JSX.Element => {
  // Range is the component own coordinate - ranges from 0 to 100
  const [range, setRange] = useState(0)

  const getDynamicLabels = () => {
    let newOptions: SliderOption[] = []
    newOptions = options.map((opt) => {
      return {
        ...opt,
        label: transformLabel(range, opt),
      }
    })

    return newOptions
  }

  const horizontalLabels = !isLoading ? translateOptionsToSliderLabelsProp(getDynamicLabels()) : {}

  const findClosestOption = (clickLocation: number) => {
    // We want to stick the slider to nearest option
    const closestOption: { distance: number; value: SliderOption } = {
      distance: 999999,
      value: options[0],
    }

    options.forEach((option) => {
      const distance = Math.abs(clickLocation - Number(option.position))

      if (distance < closestOption.distance) {
        closestOption.distance = distance
        closestOption.value = option
      }
    })

    return closestOption
  }

  const handleChangeComplete = (event?: SyntheticEvent, customRange?: number) => {
    const clickLocation = customRange || range

    // Find the option closest to the current slider value
    const closestOption = findClosestOption(clickLocation)

    setRange(closestOption.value.position)
    onChange(name, closestOption.value)
  }

  const handleClick = (clickLocation: number, event: React.MouseEvent) => {
    // Don't handle click if we're clicking on the slider
    const target = event.target as HTMLElement
    const parentElem = target.parentElement as HTMLElement
    if (target.className === 'rangeslider__handle') return

    // Handle Direct Label Click
    if (
      parentElem.className === 'rangeslider__label-item' ||
      parentElem.className === labelClassName
    ) {
      const closestOption = findClosestOption(clickLocation)
      setRange(closestOption.value.position)
      onChange(name, closestOption.value)
      return
    }

    const direction = clickLocation - range

    // Options have to be sorted by position for click map to work
    const sortedOptions = options.concat().sort((a, b) => a.position - b.position)

    const relIndex = sortedOptions.findIndex((option) => option.position === range)

    if (relIndex === -1) return

    // Click in right direction
    if (direction >= 0) {
      if (relIndex + 1 < sortedOptions.length) {
        const newSelection = sortedOptions[relIndex + 1]
        setRange(newSelection.position)
        onChange(name, newSelection)
        return
      }
    }

    // Click in left direction
    if (direction < 0) {
      if (relIndex - 1 > -1) {
        const newSelection = sortedOptions[relIndex - 1]
        setRange(newSelection.position)
        onChange(name, newSelection)
      }
    }
  }

  const handleChange = (value: number, event: React.KeyboardEvent) => {
    // Event is only relevant for keydown that we want to capture
    if (event.type === 'keydown') {
      // Options have to be sorted by position
      const sortedOptions = options.concat().sort((a, b) => a.position - b.position)

      const relIndex = sortedOptions.findIndex((option) => option.position === range)

      if (relIndex === -1) return

      // Step Up upon Up and Right Arrow
      if (event.keyCode === Keys.UP || event.keyCode === Keys.RIGHT) {
        if (relIndex + 1 < sortedOptions.length) {
          const newSelection = sortedOptions[relIndex + 1]
          setRange(newSelection.position)
          onChange(name, newSelection)
          return
        }
      }

      // Step Down upon Left and Down Arrow
      if (event.keyCode === Keys.DOWN || event.keyCode === Keys.LEFT) {
        if (relIndex - 1 > -1) {
          const newSelection = sortedOptions[relIndex - 1]
          setRange(newSelection.position)
          onChange(name, newSelection)
          return
        }
      }
    }

    // This is to handle click as opposed to drag
    if (event.type === 'mousedown' || event.type === 'pointerdown') {
      handleClick(value, (event as unknown) as React.MouseEvent)
      return
    }

    setRange(value)
  }

  return (
    <Slider
      min={0}
      max={100}
      tooltip={false}
      value={range}
      labels={horizontalLabels}
      onChange={handleChange}
      onChangeComplete={handleChangeComplete}
    />
  )
}

RangeSlider.defaultProps = defaultProps

export default RangeSlider
