import React, { ReactNode } from 'react'
import { Container, Sidebar } from 'semantic-ui-react'
import { useBoolean } from 'common/hooks'
import ErrorBoundary from 'common/ErrorBoundary'
import Header, { MobileSiteMenu } from './Header'
import Footer from './Footer'

interface BodyProps {
  children: ReactNode
  bodyClass?: string
}

interface PageProps extends BodyProps {
  form?: boolean | 'two wide'
}

const Body = ({ children, bodyClass }: BodyProps) => (
  <Container className={bodyClass || 'page'}>{children}</Container>
)

const FormContainer = ({ children }: BodyProps) => (
  <Container text className="page">
    {children}
  </Container>
)

const TwoFormContainer = ({ children }: BodyProps) => (
  <Container text className="kgn-twocolumn page">
    {children}
  </Container>
)

const Page = ({ children, form, bodyClass }: PageProps) => {
  const [siteMenuOpen, openSiteMenu, closeSiteMenu] = useBoolean(false)
  let PageContainer = Body
  if (form === true) {
    PageContainer = FormContainer
  } else if (form === 'two wide') {
    PageContainer = TwoFormContainer
  }
  return (
    <Sidebar.Pushable>
      <MobileSiteMenu open={siteMenuOpen} closeMenu={closeSiteMenu} />
      <Sidebar.Pusher dimmed={siteMenuOpen} className="fullheight">
        <Header openSiteMenu={openSiteMenu} />
        <PageContainer bodyClass={bodyClass}>
          <ErrorBoundary>{children}</ErrorBoundary>
        </PageContainer>
        <Footer />
      </Sidebar.Pusher>
    </Sidebar.Pushable>
  )
}

export default {
  Page,
  FormContainer,
}
