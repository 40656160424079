import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Segment, Header, Modal } from 'semantic-ui-react'
import { Product } from 'common/Products/types'
import AddonSummary from 'common/ProductCard/AddonSummary'
import { Msisdn } from 'src/types'
import LinkButton from 'common/LinkButton'

const StyledHeader = styled.span`
  color: grey;
`

interface AddonsSummarySectionProps {
  addons: Product[]
  serviceNumber: Msisdn | null
  balanceDue: number
}

const AddonsSummarySection: React.FC<AddonsSummarySectionProps> = ({
  serviceNumber,
  addons,
  balanceDue,
}) => {
  return (
    <Fragment>
      <StyledHeader>Prepay Plan Number</StyledHeader>
      <Segment basic secondary>
        <h4>{serviceNumber}</h4>
      </Segment>

      <StyledHeader>Selected {addons.length > 1 ? 'Add-Ons' : 'Add-On'}</StyledHeader>
      {addons.map((addon) => (
        <AddonSummary addon={addon} key={addon.unique_id} />
      ))}

      <StyledHeader>Total Due</StyledHeader>
      <Segment basic secondary>
        <Header as="h3">${balanceDue.toFixed(2)}</Header>
      </Segment>
    </Fragment>
  )
}

export const MobileAddonsSummarySection: React.FC<AddonsSummarySectionProps> = ({
  serviceNumber,
  addons,
  balanceDue,
}) => {
  return (
    <div>
      <Modal
        closeIcon
        trigger={
          <LinkButton floated="right" secondary type="button">
            SEE SUMMARY
          </LinkButton>
        }
      >
        <Modal.Header>Summary</Modal.Header>
        <Modal.Content>
          <AddonsSummarySection
            serviceNumber={serviceNumber}
            addons={addons}
            balanceDue={balanceDue}
          />
        </Modal.Content>
      </Modal>
      <Header as="h3">Total Due: ${balanceDue}</Header>
    </div>
  )
}

export default AddonsSummarySection
