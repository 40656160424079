import React from 'react'
import { Icon, IconProps } from 'semantic-ui-react'
import { Omit } from 'common/Utils'

type SvgIcons =
  | 'visa'
  | 'mastercard'
  | 'amex'
  | 'cvc'
  | 'cvc-amex'
  | 'sad-face'
  | 'addon'
  | 'usage'
  | 'surprised-face'
  | 'sim-card'
  | 'sim-card-white'

interface Props extends Omit<IconProps, 'name'> {
  name: SvgIcons
  className?: string
}

export default ({ name, className, ...iconProps }: Props) => (
  <Icon className={`svg-icon ${name} ${className || ''}`} {...iconProps} />
)
