import React, { Fragment, useMemo, useState, useEffect } from 'react'
import { Header, Transition, Icon, Image, Grid, Segment } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { goBack as startAgain } from 'pages/ActivatePage/actions'
import * as userActions from 'common/UserContainer/actions'
import ActivationStageHeader from 'common/ActivationStageHeader'
import TextAlign from 'common/TextAlign'
import SvgIcon from 'common/SvgIcon'
import PrimaryActionButton from 'common/Buttons/PrimaryActionButton'
import { BodyText1 } from 'common/Typography'
import { ActivationCheckoutInterface } from 'pages/ActivatePage/types'
import { ApplicationState } from 'rootReducer'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const insertSim = require('static/img/SIM_Card_and_Device.svg')

const ContainerBreakoutStyle = {
  marginLeft: '-3.5em',
  marginRight: '-3.5em',
  borderRadius: 0,
  paddingRight: '3em',
  paddingLeft: '3em',
}

interface Props {
  checkout?: ActivationCheckoutInterface
  resetState?: Function
  loggedIn?: boolean
  updateAuthenticationState: Function
}

export const SuccessStageComponent = ({
  checkout,
  loggedIn = false,
  resetState,
  updateAuthenticationState,
}: Props) => {
  const [animationIdx, setAnimation] = useState(0)
  const topComplete = useMemo(
    () => () => {
      setAnimation(1)
    },
    [setAnimation]
  )
  const secondComplete = useMemo(
    () => () => {
      setAnimation(2)
    },
    [setAnimation]
  )
  useEffect(() => {
    if (!loggedIn) {
      updateAuthenticationState()
    }
  }, [])
  useEffect(() => () => resetState && resetState(), [resetState])

  return (
    <Fragment>
      <ActivationStageHeader activeStage="ALL" />
      <Segment basic padded>
        {/* Add empty div to give Header top margin */}
        <div />
        <Transition animation="vertical flip" transitionOnMount duration={500} onShow={topComplete}>
          <Header as="h1" textAlign="center">
            <Header.Content>
              <Icon name="check" color="teal" /> Activation Complete!
              <Header.Subheader>
                You can now use your new Kogan Mobile {(checkout && checkout.plan_title) || ''}{' '}
                Prepay Plan
              </Header.Subheader>
              <Header.Subheader>
                {checkout &&
                  !checkout.setup_porting &&
                  `Your new phone number is ${checkout.phone_number}`}
                {checkout &&
                  checkout.setup_porting &&
                  'Your number transfer request is in progress. ' &&
                  'You will receive an email confirmation upon the successful transfer of your Existing Phone Number.'}
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Transition>
        <Transition
          animation="vertical flip"
          visible={animationIdx > 0}
          duration={500}
          onShow={secondComplete}
        >
          <TextAlign align="center">
            <Image src={insertSim} centered />
            <Header as="h2">
              <Header.Subheader>
                If you haven’t already, insert your SIM card into your device!
              </Header.Subheader>
            </Header>
            <div />
          </TextAlign>
        </Transition>
      </Segment>
      <Transition animation="fade" visible={animationIdx > 0}>
        <div>
          <Segment color="teal" basic inverted style={ContainerBreakoutStyle}>
            <Header textAlign="center">What&apos;s next?</Header>
          </Segment>

          <Grid columns="equal" padded="vertically" stackable>
            <Grid.Row>
              <Grid.Column textAlign="center">
                {!loggedIn && (
                  <BodyText1>
                    A confirmation email has been sent to{' '}
                    {(checkout && checkout.email && <strong>{checkout.email}</strong>) ||
                      'your email'}
                    .
                    <br />
                    Click the link in the email to confirm your email address and you can get access
                    to <Link to="/login/">your dashboard</Link> to:
                  </BodyText1>
                )}
                {loggedIn && (
                  <BodyText1>
                    Go to <Link to="/">your dashboard</Link> to:
                  </BodyText1>
                )}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column only="computer tablet" width={2} />
              <Grid.Column verticalAlign="middle" textAlign="center">
                <Header as="h3" icon>
                  <Link to="/">
                    <SvgIcon name="usage" color="teal" />
                    View Your Usage
                  </Link>
                </Header>
              </Grid.Column>
              <Grid.Column verticalAlign="middle" textAlign="center">
                <Header as="h3" icon>
                  <Link to="/">
                    <SvgIcon name="addon" color="teal" />
                    Purchase Add-Ons
                  </Link>
                </Header>
              </Grid.Column>
              <Grid.Column verticalAlign="middle" textAlign="center">
                <Header as="h3" icon>
                  <Link to="/">
                    <Icon name="cog" color="teal" />
                    Configure Settings
                  </Link>
                </Header>
              </Grid.Column>
              <Grid.Column only="computer tablet" width={2} />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column textAlign="center">
                <PrimaryActionButton to="/" as={Link}>
                  GO TO DASHBOARD
                </PrimaryActionButton>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Transition>
    </Fragment>
  )
}

const SuccessStage = connect(
  (state: ApplicationState) => ({
    checkout: state.activation.checkout,
    loggedIn: state.user.authenticated,
  }),
  {
    updateAuthenticationState: userActions.isAuthenticated,
    resetState: () => startAgain(null),
  }
)(SuccessStageComponent)

export default SuccessStage
