export enum ChangeEmailRequestState {
  CREATED = 'CREATED',
  REQUESTED = 'REQUESTED',
  VERIFIED = 'VERIFIED',
  EXPIRED = 'EXPIRED',
  FINISHED = 'FINISHED',
  CANCELED = 'CANCELED',
}

export interface ChangeEmailRequest {
  new_email: string
  state: ChangeEmailRequestState
}
