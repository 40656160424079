import React from 'react'
import { Placeholder, Header, Responsive } from 'semantic-ui-react'
import { ProductCard } from './index'

const PlaceholderBlock: React.FC<{ lines: number }> = ({ lines }) => (
  <Placeholder fluid>
    <Placeholder.Paragraph>
      {Array(...({ length: lines } as any)).map((l, e) => (
        // eslint-disable-next-line react/no-array-index-key
        <Placeholder.Line key={e} />
      ))}
    </Placeholder.Paragraph>
  </Placeholder>
)

const PlaceholderPlanCard: React.FC<{ horizontal?: boolean }> = ({ horizontal }) => (
  <ProductCard horizontal={horizontal}>
    <ProductCard.Header color="grey">
      <Placeholder fluid>
        <Placeholder.Header>
          <Header as="h1">
            <Placeholder.Line />
          </Header>
        </Placeholder.Header>
      </Placeholder>
    </ProductCard.Header>
    <ProductCard.Content style={{ minWidth: '12em' }}>
      <PlaceholderBlock lines={2} />
    </ProductCard.Content>
    {!horizontal && (
      <Responsive as={ProductCard.Content} minWidth={Responsive.onlyTablet.minWidth}>
        <PlaceholderBlock lines={2} />
      </Responsive>
    )}
    <ProductCard.Content>
      <PlaceholderBlock lines={horizontal ? 2 : 3} />
    </ProductCard.Content>
    <div>
      <PlaceholderBlock lines={1} />
      <div>&nbsp;</div>
      <Header as="h1">
        <PlaceholderBlock lines={1} />
      </Header>
    </div>
  </ProductCard>
)

export const PlaceholderAddonCard: React.FC<{ horizontal?: boolean }> = ({ horizontal }) => (
  <ProductCard horizontal={horizontal}>
    <ProductCard.Header color="grey">
      <Placeholder fluid>
        <Placeholder.Header>
          <Header as="h1">
            <Placeholder.Line />
          </Header>
        </Placeholder.Header>
      </Placeholder>
    </ProductCard.Header>
    <ProductCard.Content style={{ minWidth: '12em' }}>
      <PlaceholderBlock lines={2} />
    </ProductCard.Content>
    <ProductCard.Content>
      <PlaceholderBlock lines={2} />
    </ProductCard.Content>
    <div>
      <Header as="h1">
        <PlaceholderBlock lines={1} />
      </Header>
    </div>
  </ProductCard>
)

export default PlaceholderPlanCard
