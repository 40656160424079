import React, { useCallback, useEffect, useMemo, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Placeholder,
  Header,
  Table,
} from 'semantic-ui-react'
import { ApplicationState } from 'rootReducer'

import { Uuid } from 'src/types'
import { formatDate, formatPrice } from 'common/Utils'

import { getPhoneNumberByUuid } from 'common/Dashboard/reducer'
import { useNavigate, useParams, useResponsive } from 'common/hooks'
import { fetchPurchaseHistory } from 'pages/PurchaseHistoryPage/actions'
import { getPurchaseHistoryByUuid } from 'pages/PurchaseHistoryPage/reducer'
import LinkButton from 'common/LinkButton'
import PlaceholderRows from 'common/PlaceholderRows'
import { PurchaseHistorySelector, PurchaseHistory } from './types'

const PurchaseHistoryBreadcrumb = () => {
  const { uuid } = useParams()
  const navigate = useNavigate()
  const onClick = useCallback(() => navigate(`/services/${uuid}/`), [uuid, navigate])
  const phoneNumber = useSelector(getPhoneNumberByUuid(uuid))
  return (
    <Breadcrumb size="small">
      <BreadcrumbSection link as={phoneNumber ? null : Placeholder} onClick={onClick}>
        {phoneNumber || '000 000 0000'}
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection active>Purchase History</BreadcrumbSection>
    </Breadcrumb>
  )
}

const SelectableTable = ({ uuid, purchase }: { uuid: string; purchase: PurchaseHistory }) => {
  const { isMobile } = useResponsive()

  const navigate = useNavigate()

  const handleClick = useCallback(
    () => navigate(`/services/${uuid}/invoices/${purchase.order_code}/`),
    [uuid, navigate]
  )

  let linkText
  if (isMobile) {
    linkText = 'VIEW'
  } else if (purchase.document_type === 'credit') {
    linkText = 'VIEW REFUND'
  } else {
    linkText = 'VIEW INVOICE'
  }

  return (
    <Table.Row key={purchase.order_code} onClick={handleClick}>
      <Table.Cell textAlign="left">{formatDate(purchase.created)}</Table.Cell>
      <Table.Cell textAlign="left">{purchase.order_code}</Table.Cell>
      {!isMobile && <Table.Cell textAlign="right">{formatPrice(purchase.total_price)}</Table.Cell>}
      <Table.Cell textAlign="left">
        <LinkButton to={`/services/${uuid}/invoices/${purchase.order_code}/`}>
          {linkText}
        </LinkButton>
      </Table.Cell>
    </Table.Row>
  )
}

const PurchaseTable = ({ uuid, purchases }: { uuid: string; purchases?: PurchaseHistory[] }) => {
  const { isMobile } = useResponsive()
  const body = useMemo(() => {
    if (!purchases) return <PlaceholderRows columns={4} rows={3} />
    return purchases.map((purchase) => (
      <SelectableTable key={purchase.order_code} purchase={purchase} uuid={uuid} />
    ))
  }, [isMobile, purchases])

  return (
    <Table className="invoices" selectable unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Date</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Order #</Table.HeaderCell>
          {!isMobile && <Table.HeaderCell textAlign="right">Amount Paid</Table.HeaderCell>}
          <Table.HeaderCell textAlign="left">Invoice</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{body}</Table.Body>
    </Table>
  )
}

const Loader = ({ uuid }: { uuid: Uuid }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    // We should never cache this as customers might get refund etc
    // We always want it to be as fresh as posibble
    dispatch(fetchPurchaseHistory(uuid, false))
  }, [uuid, dispatch])
  return null
}

const PurchaseHistoryPage = () => {
  const { uuid } = useParams()
  const purchaseHistorySelector = useMemo<PurchaseHistorySelector>(
    () => getPurchaseHistoryByUuid(uuid),
    [uuid]
  )
  const purchaseHistory = useSelector<ApplicationState, PurchaseHistory[] | undefined>(
    purchaseHistorySelector
  )
  return (
    <Fragment>
      <Loader uuid={uuid} />
      <PurchaseHistoryBreadcrumb />
      <Header as="h1">Purchase History</Header>
      <PurchaseTable uuid={uuid} purchases={purchaseHistory} />
    </Fragment>
  )
}

export default PurchaseHistoryPage
