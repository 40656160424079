import React from 'react'
import classNames from 'classnames'

interface CaptionProps {
  className?: string
  as?: any
  dangerouslySetInnerHTML?: object
}

const Caption: React.FC<CaptionProps> = ({ className, as: forwardAs, ...props }) => {
  const ElementType = forwardAs || 'p'
  return <ElementType {...props} className={classNames(className, 'kogan-text-caption')} />
}

export default Caption
