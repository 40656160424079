/* eslint-disable no-underscore-dangle */
import React, { PureComponent, ReactNode } from 'react'
import { Form, Button, Message, TransitionGroup, Icon, SemanticICONS } from 'semantic-ui-react'
import parseErrors from 'common/Form/utils'
import { FieldOnBlur, FieldOnChange, RenderedFieldSet } from 'common/Form/BaseFormField'
import LinkButton from 'common/LinkButton'
import ActionRow from 'common/ActionRow'
import { useResponsive } from 'common/hooks'
import {
  ErrorsInterface,
  FormData,
  GroupedFieldInterface,
  DirtyFields,
  FormDataValue,
} from '../index'

interface DefaultProps {
  isDisabled: boolean
  isSubmitting: boolean
  submitButtonText?: string
  submitButtonIcon?: SemanticICONS
  onSubmit(): void
  onBlur(name: string, value: FormDataValue, formData: FormData): void
  onChange(name: string, value: FormDataValue): void
  errors: ErrorsInterface
  headerSection?: ReactNode
  formData: FormData
  dirtyFields: DirtyFields
  renderSubmitButton: React.FC<ButtonRenderer>
}

export interface FormRenderProps {
  formData: FormData
  errors: ErrorsInterface
  isSubmitting: boolean
  formId: string
  onChange: FieldOnChange
  onBlur: FieldOnBlur
  dirtyFields: DirtyFields
}

interface Props extends DefaultProps {
  size?: string
  initialValue?: FormData
  id: string
  children?: (renderProps: FormRenderProps) => JSX.Element
  fields?: GroupedFieldInterface[]
  cancelButtonText?: string
  cancelProps?: object
}

export const submitIconStyle = { float: 'right' }
const errorAnimationStyle = { hide: 0, show: 300 }

export type CombinedChangeEvent = React.ChangeEvent | React.MouseEvent

export interface ButtonRenderer {
  isSubmitting: boolean
  isDisabled: boolean
  submitButtonIcon?: SemanticICONS
  submitButtonText?: string
  hasCancelButton: boolean
}

const DefaultSubmitRender: React.FC<ButtonRenderer> = ({
  isSubmitting,
  isDisabled,
  submitButtonIcon,
  submitButtonText,
  hasCancelButton,
}) => {
  const responsiveData = useResponsive()
  return (
    <Button
      type="submit"
      primary
      loading={isSubmitting}
      disabled={isDisabled}
      size="medium"
      fluid={responsiveData.isMobile || !hasCancelButton}
      tabIndex={0}
    >
      {submitButtonText}
      {submitButtonIcon && <Icon name={submitButtonIcon} style={submitIconStyle} />}
    </Button>
  )
}
export default class BaseForm extends PureComponent<Props> {
  static defaultProps: DefaultProps = {
    errors: {},
    isDisabled: false,
    isSubmitting: false,
    submitButtonText: 'Submit',
    onSubmit: () => null,
    onBlur: () => null,
    onChange: () => null,
    formData: {},
    dirtyFields: {},
    renderSubmitButton: DefaultSubmitRender,
  }

  constructor(props: Props) {
    super(props)
    this.state = props.initialValue || {}
  }

  onChange: FieldOnChange = (event, data) => {
    event.stopPropagation()
    const { onChange } = this.props
    onChange(data.name, data.value)
  }

  onBlur = (event: React.ChangeEvent) => {
    const { onBlur, formData } = this.props
    event.stopPropagation()
    event.preventDefault()
    const target = event.currentTarget as HTMLInputElement
    onBlur(target.name, target.value, formData)
  }

  onSubmit = () => {
    const { onSubmit } = this.props
    onSubmit()
  }

  formHasErrors = () => {
    const { errors } = this.props

    // errors.__all__ is an error from the server which means it can't be handled by the user
    // before they attempt to resubmit, so we ignore it
    let hasErrors = false
    if (!errors.__all__) {
      hasErrors = Object.values(errors).filter(Boolean).length !== 0
    }
    return hasErrors
  }

  render() {
    const {
      id,
      fields,
      isDisabled,
      isSubmitting,
      children,
      errors,
      submitButtonText,
      submitButtonIcon,
      headerSection,
      formData,
      dirtyFields,
      cancelButtonText,
      cancelProps,
      renderSubmitButton: RenderSubmitButton,
      onSubmit: _,
      initialValue: _1,
      onBlur: _2,
      ...rest
    } = this.props

    let formErrors: any = errors.__all__
    if (formErrors) {
      // Lists of errors should be unique
      const distinct = (it: string, i: number, ar: string[]) => ar.indexOf(it) === i
      formErrors = formErrors.filter(distinct)
    }

    const renderProps: FormRenderProps = {
      formData,
      errors,
      isSubmitting,
      formId: id,
      onChange: this.onChange,
      onBlur: this.onBlur,
      dirtyFields,
    }

    const cancelButton = cancelButtonText && (
      <LinkButton size="large" {...cancelProps} disabled={isSubmitting}>
        {cancelButtonText}
      </LinkButton>
    )

    return (
      <Form
        size="large"
        id={id}
        onSubmit={this.onSubmit}
        error={!!Object.keys(errors).length}
        {...rest}
      >
        {headerSection}
        {fields && <RenderedFieldSet fields={fields} {...renderProps} />}
        {children && children(renderProps)}
        <TransitionGroup duration={errorAnimationStyle}>
          {formErrors && (
            <div>
              <Message
                error
                size="tiny"
                icon="exclamation triangle"
                content={formErrors.length === 1 ? parseErrors(formErrors)[0] : undefined}
                list={formErrors.length > 1 ? parseErrors(formErrors) : undefined}
              />
              {/* Hack to keep the margin. It cant be the last child. */}
              <div />
            </div>
          )}
        </TransitionGroup>
        <ActionRow
          primaryAction={
            <RenderSubmitButton
              isSubmitting={isSubmitting}
              isDisabled={isDisabled || isSubmitting || this.formHasErrors()}
              submitButtonIcon={submitButtonIcon}
              submitButtonText={submitButtonText}
              hasCancelButton={!!cancelButtonText}
            />
          }
          cancelAction={cancelButtonText ? cancelButton : undefined}
        />
      </Form>
    )
  }
}
