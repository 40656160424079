import { PureComponent, ReactNode } from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { makeLoadingSelector } from 'common/Loading/reducer'
import * as userActions from '../actions'

export interface LogoutRenderProps {
  isLoading: boolean
  doLogout(): void
}

interface LogoutContainerProps {
  children(arg0: LogoutRenderProps): ReactNode
  isLoading: boolean
  doLogout(): void
}

class LogoutContainer extends PureComponent<LogoutContainerProps> {
  render() {
    const { children, isLoading, doLogout } = this.props
    return children({
      isLoading,
      doLogout,
    })
  }
}

const ConnectedLogoutContainer = connect(
  (state: ApplicationState) => ({
    isLoading: makeLoadingSelector(['USER_LOGOUT'])(state),
  }),
  {
    doLogout: userActions.logout,
  }
)(LogoutContainer)

export default ConnectedLogoutContainer
