import axios from 'axios'
import { PlanFilterOption } from './types'

export enum Actions {
  GET_PLAN_FILTER_FAILURE = 'GET_PLAN_FILTER_FAILURE',
  GET_PLAN_FILTER_REQUEST = 'GET_PLAN_FILTER_REQUEST',
  GET_PLAN_FILTER_SUCCESS = 'GET_PLAN_FILTER_SUCCESS',
}

export interface GetPlanFilterAction {
  type: Actions
  payload: {
    data?: PlanFilterOption[]
  }
}

export const getPlanFilterRequest = (): GetPlanFilterAction => ({
  type: Actions.GET_PLAN_FILTER_REQUEST,
  payload: {},
})

export const getPlanFilterFailure = (): GetPlanFilterAction => ({
  type: Actions.GET_PLAN_FILTER_FAILURE,
  payload: {},
})

export const getPlanFilterSuccess = (data: PlanFilterOption[]): GetPlanFilterAction => ({
  type: Actions.GET_PLAN_FILTER_SUCCESS,
  payload: { data },
})

export function fetchPlanFilter(): Function {
  return (dispatch: Function) => {
    dispatch(getPlanFilterRequest())
    axios
      .get('/api/products/filters/')
      .then((data) => {
        dispatch(getPlanFilterSuccess(data.data))
      })
      .catch(() => {
        dispatch(getPlanFilterFailure())
      })
  }
}
