import React, { Fragment, useCallback, useEffect, useState } from 'react'
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Divider,
  Grid,
  Header,
  Image,
  Segment,
} from 'semantic-ui-react'
import { Product, ProductByUniqueId, ProductsReducerState } from 'common/Products/types'
import { connect, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Link, Redirect } from 'react-router-dom'
import Layout from 'common/Layout'
import { ProductLoader } from 'common/Products'
import { ApplicationState } from 'rootReducer'
import { useBoolean, useNavigate, useParams } from 'common/hooks'
import makeCreditCardForm from 'common/Form/CreditCardFormSection'
import { makeRetryHandler } from 'pages/CheckoutPage/utils'
import { FormRenderProps } from 'common/Form/BaseForm'
import { Uuid } from 'src/types'
import Form, { FormSubmitSignature } from 'common/Form'
import { RenderedFieldSet } from 'common/Form/BaseFormField'
import LinkButton from 'common/LinkButton'
import { getSavedCard } from 'common/UserContainer/reducer'
import VoucherPlanSwitcher, {
  PaymentType,
  typeToUrl,
  urlToType,
} from 'pages/ActivatePage/Stages/SelectPaymentMethod/VoucherPlanSwitcher'
import { CreditCardSmallText, VoucherSmallText } from 'common/SmallText'
import { makeLoadingSelector, makeSuccessSelector } from 'common/Loading/reducer'
import { getPhoneNumberByUuid } from 'common/Dashboard/reducer'
import IconButtonOption from 'common/IconButtonOption'
import * as formActions from 'common/Form/actions'
import PlanTermsAccordion from 'pages/ActivatePage/Stages/SelectPlan/PlanTermsAccordion'
import { getProductById } from 'common/Products/reducer'
import SubmitIntercept from 'common/Form/SubmitIntercept'
import { getVoucherProduct } from 'pages/ActivatePage/reducer'
import * as actions from './actions'
import { RechargePageProps, Schedule, Service, ServiceDetailProps } from './types'
import { getCardMaskEnd } from './utils'
import PlanSelectionForm from './PlanSelectionForm'
import VoucherInputField from './VoucherCodeField'
import { getSavedVoucherCode, getScheduleByShortId } from './reducer'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const planDetailsImageSrc = require('static/img/plan-details.png')

export const RECHARGE_FORM_ID = 'recharge'

export const ServicesLoader = connect(
  (state: ApplicationState) => ({ alreadyLoaded: makeSuccessSelector(['GET_SERVICES'])(state) }),
  {
    fetchServices: actions.fetchServices,
  }
)(({ fetchServices, alreadyLoaded }: { fetchServices: Function; alreadyLoaded: boolean }) => {
  useEffect(() => {
    // componentDidMount -- fetch data
    if (!alreadyLoaded) {
      fetchServices()
    }
  }, [null])

  return null
})

const { schema, fields } = makeCreditCardForm()
const RetryHandler = makeRetryHandler(RECHARGE_FORM_ID)
const renderSuccess = () => (
  <Fragment>
    <Redirect to="/services/renew/success/" />
  </Fragment>
)

interface BreadcrumbProps {
  service: Service
}

const RechargeServiceBreadcrumb = ({ service }: BreadcrumbProps) => {
  const { uuid } = service
  const phoneNumber = useSelector(getPhoneNumberByUuid(uuid))
  return (
    <Breadcrumb size="small" className="">
      <BreadcrumbSection link as={Link} to={`/services/${uuid}/`} push="true">
        {phoneNumber}
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection active>Renew your Prepay Plan</BreadcrumbSection>
    </Breadcrumb>
  )
}

const NoServiceBreadcrumb = () => {
  return (
    <Breadcrumb size="small" className="">
      <BreadcrumbSection link as={Link} to="/" push="true">
        My Dashboard
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection active>Renew your Prepay Plan</BreadcrumbSection>
    </Breadcrumb>
  )
}

const getSelectedPlanUUID = (
  voucherPlan: Product | undefined,
  selectedMethod: PaymentType | undefined,
  selectedPlan: Product | undefined
): string => {
  let uuid = ''
  if (voucherPlan && selectedMethod === PaymentType.OPTION_VOUCHER) {
    uuid = voucherPlan.unique_id
  } else if (selectedPlan) {
    uuid = selectedPlan.unique_id
  }
  return uuid
}

export const ServiceDetail = ({
  plans,
  schedule,
  savedCard,
  onChange,
  defaultSelectedPlan,
}: ServiceDetailProps) => {
  const voucherPlan = useSelector(getVoucherProduct)

  const { service } = schedule
  const [selectedPlan, setPlan] = useState<Product | undefined>(defaultSelectedPlan)
  useEffect(() => {
    if (defaultSelectedPlan) {
      setPlan(defaultSelectedPlan)
    }
  }, [defaultSelectedPlan])
  const validPlanIds = plans.allUniqueIds.filter((id) => !plans.byUniqueId[id].new_customer_only)
  const validPlans: ProductsReducerState = {
    byUniqueId: validPlanIds.reduce(
      (obj: ProductByUniqueId, uuid: Uuid) => ({
        ...obj,
        [uuid]: plans.byUniqueId[uuid],
      }),
      {}
    ),
    allUniqueIds: validPlanIds,
  }
  const { shortId, paymentMethod, code } = useParams()
  const [creditCardFormVisible, showCreditCardForm] = useBoolean(!savedCard)
  const navigate = useNavigate(true)
  const onClick = useCallback(
    (value) => {
      if (!value) {
        navigate(`/services/renew/${shortId}/`)
      } else {
        navigate(`/services/renew/${shortId}/${typeToUrl(value)}/`)
      }
    },
    [shortId]
  )

  const selectedMethod = urlToType(paymentMethod)
  const onPlanChange = useCallback(
    (plan?: Product) => {
      if (plan) {
        setPlan(plan)
        onChange(RECHARGE_FORM_ID, 'product', plan.unique_id)
      } else {
        setPlan(undefined)
        onChange(RECHARGE_FORM_ID, 'product', '')
      }
    },
    [setPlan, onChange]
  )

  let formButtonText = 'RENEW'
  // Only show saved card if we are not using a voucher and they have a saved card
  if (
    selectedMethod === PaymentType.OPTION_PAYMENT &&
    !creditCardFormVisible &&
    savedCard !== undefined
  ) {
    formButtonText = `RENEW WITH ${savedCard.card_type.toUpperCase()} ${getCardMaskEnd(
      savedCard.masked_card_number
    )}`
  }

  let rebillPrice
  let nextPlan

  if (selectedMethod === PaymentType.OPTION_VOUCHER && !!voucherPlan) {
    // Get product rebill price from voucher product instead
    rebillPrice = parseFloat(voucherPlan.price_rebill)
  } else if (selectedMethod === PaymentType.OPTION_PAYMENT) {
    nextPlan = getSelectedPlanUUID(voucherPlan, selectedMethod, selectedPlan)
      ? validPlans.byUniqueId[getSelectedPlanUUID(voucherPlan, selectedMethod, selectedPlan)]
      : undefined
    if (nextPlan && selectedPlan) {
      rebillPrice = parseFloat(nextPlan.price_rebill)
    } else {
      rebillPrice = 0
    }
  }

  let disabled = false
  if (selectedMethod === PaymentType.OPTION_PAYMENT) {
    disabled = !(selectedPlan && plans)
  }

  const submitHandler: FormSubmitSignature = (data, errors) => {
    return {
      data: {
        ...data,
        product: getSelectedPlanUUID(voucherPlan, selectedMethod, selectedPlan),
      },
      errors,
    }
  }
  return (
    <Fragment>
      <Header as="h2">Renew your Prepay Plan</Header>
      <p>Please select which of the following applies to you:</p>
      <VoucherPlanSwitcher onChange={onClick} value={selectedMethod} />
      {!!selectedMethod && (
        <Fragment>
          {selectedMethod === PaymentType.OPTION_PAYMENT && (
            <Fragment>
              <ProductLoader />
              <PlanSelectionForm
                plans={validPlans}
                previousPlan={selectedPlan}
                onPlanChange={onPlanChange}
              />
            </Fragment>
          )}
          <RetryHandler />
          <Form
            id={RECHARGE_FORM_ID}
            url="/api/orders/recharge/"
            validationSchema={
              selectedMethod === PaymentType.OPTION_PAYMENT ? Yup.object(schema) : undefined
            }
            submitButtonText={formButtonText}
            submitButtonIcon="arrow right"
            isDisabled={disabled}
            renderSuccess={renderSuccess}
            initialValue={{
              use_saved_card: !creditCardFormVisible,
              product: '',
              schedule: schedule.unique_id,
              payment_method: selectedMethod || '',
              uuid: service.uuid,
              code,
            }}
          >
            {(renderProps: FormRenderProps) => (
              <Fragment>
                <SubmitIntercept handler={submitHandler} />
                <RenderedFieldSet
                  fields={creditCardFormVisible ? [...fields] : []}
                  {...renderProps}
                />
                {selectedMethod === PaymentType.OPTION_VOUCHER && (
                  <VoucherInputField formId={RECHARGE_FORM_ID} {...renderProps} />
                )}
                <PlanTermsAccordion />
              </Fragment>
            )}
          </Form>
          {selectedMethod === PaymentType.OPTION_PAYMENT && !creditCardFormVisible && (
            <Fragment>
              <br />
              <Segment basic textAlign="center">
                <LinkButton secondary onClick={showCreditCardForm}>
                  USE ANOTHER CARD
                </LinkButton>
              </Segment>
            </Fragment>
          )}
          <h4>Important Information</h4>
          <p className="fine-print">
            Renewing this Prepay Plan will mean that you lose any data remaining in your current
            Prepay Plan.
          </p>
          {selectedMethod === PaymentType.OPTION_PAYMENT && (
            <CreditCardSmallText rebillPrice={rebillPrice} buttonText="Renew" action="renewing" />
          )}
          {selectedMethod === PaymentType.OPTION_VOUCHER && (
            <VoucherSmallText rebillPrice={rebillPrice} buttonText="Renew" />
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

const ConnectedServiceDetail = connect(
  (state: ApplicationState, ownProps: { schedule: Schedule }) => ({
    plans: state.products,
    savedCard: getSavedCard(state),
    defaultSelectedPlan: getProductById(
      state,
      ownProps.schedule.product.next_product_unique_id
        ? ownProps.schedule.product.next_product_unique_id
        : ownProps.schedule.product.unique_id
    ),
  }),
  {
    onChange: formActions.onChange,
  }
)(ServiceDetail)

const RechargePage = ({
  loading,
  services,
  selectedSchedule,
  setVoucher,
  clearVoucher,
  savedVoucherCode,
}: RechargePageProps) => {
  const { shortId, code } = useParams()
  const navigate = useNavigate(true)
  useEffect(() => {
    if (!shortId && code) {
      // store the code in redux until the user has selected a plan to recharge
      setVoucher(code)
    }
  }, [setVoucher, shortId, code])
  useEffect(() => {
    if (shortId && !code && savedVoucherCode) {
      clearVoucher()
      navigate(`/services/renew/${shortId}/voucher/${savedVoucherCode}/`)
    }
  }, [shortId, code, savedVoucherCode, clearVoucher])
  return (
    <Layout.Page form="two wide">
      <ServicesLoader />
      <div>
        <Grid columns="equal" stackable relaxed>
          <Grid.Column only="computer tablet">
            <Image src={planDetailsImageSrc} />
          </Grid.Column>
          <Grid.Column verticalAlign="top" style={{ minHeight: 530 }}>
            {selectedSchedule ? (
              <Fragment>
                <RechargeServiceBreadcrumb service={selectedSchedule.service} />
                <ConnectedServiceDetail schedule={selectedSchedule} />
                <Divider hidden />
                <LinkButton to={`/services/${selectedSchedule.service.uuid}/`}>Cancel</LinkButton>
              </Fragment>
            ) : (
              <Grid.Column>
                <NoServiceBreadcrumb />
                <Header as="h2">Renew your Prepay Plan</Header>
                <p>Please select the Prepay Plan you would like to renew:</p>
                {!loading &&
                  Object.keys(services).map((uuid: Uuid) => {
                    const service = services[uuid]
                    return Object.keys(service.schedules)
                      .filter(
                        (scheduleId) =>
                          service.schedules[scheduleId].short_id === service.plan_short_id
                      )
                      .map((schedule_id: string) => {
                        const schedule = service.schedules[schedule_id]
                        return (
                          <Fragment key={uuid}>
                            <IconButtonOption
                              iconLeft="list"
                              direction="forwards"
                              as={Link}
                              to={`/services/renew/${schedule.short_id}/`}
                            >
                              <p>
                                <strong>{service.phone_number}</strong>
                              </p>
                              {schedule.product.title} - {schedule.product.length} Days Prepay Plan
                            </IconButtonOption>
                            <br />
                          </Fragment>
                        )
                      })
                  })}
              </Grid.Column>
            )}
          </Grid.Column>
        </Grid>
        {/* Stop the min-height div from moving around from margins */}
        &nbsp;
      </div>
    </Layout.Page>
  )
}

const ConnectedRechargePage = connect(
  (state: ApplicationState, { shortId }: { shortId: string }) => ({
    services: state.services.byUuid,
    loading: makeLoadingSelector(['GET_PRODUCTS, GET_SERVICES'])(state),
    selectedSchedule: getScheduleByShortId(state, shortId),
    savedVoucherCode: getSavedVoucherCode(state),
  }),
  (dispatch) => ({
    setVoucher: (code: string) => dispatch(actions.setVoucherCode(code)),
    clearVoucher: () => dispatch(actions.clearVoucherCode()),
  })
)(RechargePage)

export default () => {
  const { shortId } = useParams()
  return <ConnectedRechargePage shortId={shortId} />
}
