import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { Icon, Button, Responsive, Menu } from 'semantic-ui-react'
import { User as UserType } from 'common/UserContainer/reducer'
import { Service as ServiceInterface } from 'pages/RechargePage/types'
import { makeLoadingSelector } from 'common/Loading/reducer'
import { useParams } from 'common/hooks'
import { ApplicationState } from 'src/rootReducer'
import GenericMobileSelectedNav from 'common/GenericMobileSelectedNav'
import GenericNav from 'common/GenericNav'
import Service from './Service'

interface DashboardNavProps {
  services: {
    [key: string]: ServiceInterface
  }
  loading: boolean
  user: UserType
}

const MobileDashboardNav = ({ services, user }: DashboardNavProps) => {
  const { uuid } = useParams()
  const selectedService = Object.values(services).find((service) => service.uuid === uuid)
  return (
    <GenericMobileSelectedNav heading={`Welcome, ${user.first_name}`}>
      {uuid && selectedService && <Service service={selectedService} readOnly />}
    </GenericMobileSelectedNav>
  )
}

const DashboardNav = ({ services, user }: DashboardNavProps) => {
  const { uuid } = useParams()

  // If on dashboard homepage root we want to redirect to first available service detail
  // But only do it on desktop and larger
  let redirect = <Fragment />
  if (!uuid && Object.keys(services).length > 0) {
    const firstService = services[Object.keys(services)[0]]
    redirect = (
      <Responsive minWidth={Responsive.onlyTablet.minWidth} as={Fragment}>
        <Redirect to={`/services/${firstService.uuid}/`} />
      </Responsive>
    )
  }

  const footerLink = (
    <Button as={Link} to="/account/contact-details/" fluid inverted>
      ACCOUNT SETTINGS
    </Button>
  )

  const handleNewSimRedirect = () => {
    window.location.href = '/activate/new/'
  }

  const menuLinks = (
    <Fragment>
      {Object.keys(services).map((skey) => {
        const service = services[skey]
        return <Service service={services[skey]} key={service.uuid} />
      })}
      <Menu.Item onClick={handleNewSimRedirect}>
        <Icon name="plus" />
        <strong>Activate New Sim</strong>
      </Menu.Item>
    </Fragment>
  )

  return (
    <GenericNav redirect={redirect} footerLink={footerLink} heading={`Welcome, ${user.first_name}`}>
      {menuLinks}
    </GenericNav>
  )
}

export default connect((state: ApplicationState) => ({
  services: state.services.byUuid,
  user: state.user.user as UserType,
  loading: makeLoadingSelector(['GET_PRODUCTS, GET_SERVICES'])(state),
}))(DashboardNav)

export const MobileSelectedNav = connect((state: ApplicationState) => ({
  services: state.services.byUuid,
  user: state.user.user as UserType,
  loading: makeLoadingSelector(['GET_PRODUCTS, GET_SERVICES'])(state),
}))(MobileDashboardNav)
