import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Header, Grid, Image, Responsive, Accordion } from 'semantic-ui-react'
import { ApplicationState } from 'rootReducer'

import ConnectedForm, { GroupedFieldInterface, phoneNumberModifier } from 'common/Form'
import { enterSimPreSubmit } from 'common/Porting/Forms'
import SubmitIntercept from 'common/Form/SubmitIntercept'
import LinkButton from 'common/LinkButton'
import PortingOptions from 'common/Porting/Forms/PortingOptions'
import * as Yup from 'yup'
import PhoneNumber from 'awesome-phonenumber'
import AnimatedTransition from 'common/AnimatedTransition'
import { useToggle } from 'common/hooks'
import preloadImages from 'common/ImagePreloader'
import { getActivationProduct, usingVoucher } from 'pages/ActivatePage/reducer'
import { Product } from 'common/Products/types'
import { PlanSummary } from 'common/ProductCard'
import { CheckoutStepTracking } from '../../../segment'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const simSerial = require('static/img/4-sim-serial-a.png')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const simFront = require('static/img/1-sim-front.png')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const simBack = require('static/img/2-sim-rear.png')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const simPhone = require('static/img/3-sim-phone-a.png')

const SimHelp = ({ lastField }: { lastField?: string | null }) => {
  const [stage, toggleStage] = useToggle(false)
  const [allLoaded, markLoaded] = useToggle(false)

  const onShow = useMemo(
    () => () => {
      if (!stage) setTimeout(toggleStage, 1000)
    },
    [stage]
  )
  useEffect(() => {
    preloadImages([simFront, simBack, simSerial, simPhone]).then(markLoaded).catch(markLoaded)
  }, [null])

  if (lastField) {
    return <Image src={lastField === 'msisdn' ? simPhone : simSerial} />
  }

  if (!allLoaded) return null

  return (
    <AnimatedTransition
      transitionOnMount={!stage}
      onShow={onShow}
      animation={stage ? 'horizontal flip' : 'fade up'}
      duration={stage ? 300 : 1000}
    >
      <Image src={stage ? simBack : simFront} key={stage ? 1 : 2} />
    </AnimatedTransition>
  )
}

const SimHelpMobile = () => (
  <Accordion
    panels={[
      {
        key: 'help',
        title: { content: 'How do I find my Kogan SIM Number?', as: 'h4', className: 'ui' },
        content: { content: <Image src={simSerial} />, className: 'ui vertical segment' },
      },
    ]}
  />
)

const fields: GroupedFieldInterface[] = [
  {
    label: 'Phone Number (on the SIM pack)',
    name: 'msisdn',
    type: 'tel',
    required: true,
    inputModifier: phoneNumberModifier,
  },
  {
    label: 'Last 4 Digits of Kogan SIM Number',
    name: 'sim_serial',
    required: true,
    maxLength: 4,
    type: 'pin_code',
  },
  {
    name: 'sim_help',
    content: (
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth} as={SimHelpMobile} key="sim_help" />
    ),
  },
  {
    name: 'setup_porting',
    label: 'Would you like to transfer your Existing Phone Number to Kogan Mobile?',
    required: true,
    component: PortingOptions,
  },
  {
    name: 'validator-hidden',
    component: () => <SubmitIntercept handler={enterSimPreSubmit} />,
  },
]

const activateSimSchema = Yup.object({
  msisdn: Yup.string()
    .required('This field is required.')
    .test({
      name: 'valid_mobile_number',
      message:
        'Invalid Phone Number (on the SIM pack). Please enter the Phone Number found on your Kogan Mobile SIM Pack.',
      test: (value) => {
        const phone = new PhoneNumber(value, 'NZ')
        return phone.isValid() && phone.isMobile()
      },
    })
    .test({
      name: 'valid_country',
      message:
        'Please ensure you are using a New Zealand Kogan Mobile Phone Number (on the SIM pack).',
      test: (value) => {
        const phone = new PhoneNumber(value, 'NZ')
        return ['NZ', 'AU'].includes(phone.getRegionCode())
      },
    }),
  sim_serial: Yup.string()
    .required('This field is required.')
    .min(4, 'Please enter the last 4 digits of your SIM Number.')
    .max(4, 'Please enter the last 4 digits of your SIM Number.'),
})

const SmallP = styled.p`
  font-size: 1rem;
  text-align: center;
  margin-top: 2rem;
`

const EnterSimForm = ({ onFocus, product }: { onFocus?: Function; product?: Product }) => (
  <Fragment>
    <Header as="h1">
      {product
        ? 'Start using your new Prepay Plan with your voucher!'
        : 'Start using your new Prepay Plan!'}
    </Header>
    {product && <PlanSummary plan={product} />}
    <ConnectedForm
      id="activate_1_sim"
      fields={fields}
      validationSchema={activateSimSchema}
      url="/api/activate/sim/"
      submitButtonText="START"
      submitButtonIcon="arrow right"
      onFocus={onFocus}
    />
    <SmallP>
      Don&apos;t have a SIM Card? &nbsp;
      <LinkButton href="https://www.kogan.com/nz/kogan-mobiles-sim/">ORDER A NEW SIM</LinkButton>
    </SmallP>
  </Fragment>
)

const ConnectedEnterSimForm = connect((state: ApplicationState) => ({
  product: usingVoucher(state) ? getActivationProduct(state) : undefined,
}))(EnterSimForm)

const EnterSim = () => {
  const [lastField, setField] = useState<string | null>(null)
  const onFocus = useMemo(
    () => (event: FocusEvent) => {
      const target = event.target as HTMLInputElement
      setField((target && target.name) || null)
    },
    [setField]
  )
  return (
    <Fragment>
      <CheckoutStepTracking step={1} />
      <Grid columns="equal" stackable relaxed>
        <Grid.Column only="computer tablet" style={{ minHeight: 505 }}>
          <SimHelp lastField={lastField} />
        </Grid.Column>
        <Grid.Column verticalAlign="middle">
          <ConnectedEnterSimForm onFocus={onFocus} />
        </Grid.Column>
      </Grid>
    </Fragment>
  )
}

export default EnterSim
