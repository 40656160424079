import React from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import StageHeader from 'common/StageHeader'
import { STAGES, PORTING_STAGES } from 'pages/ActivatePage/constants'

interface ConnectedStageHeaderProps {
  activeStage?: 'ALL' | string
  setup_porting: boolean
}

const ConnectedStageHeader = ({ activeStage, setup_porting }: ConnectedStageHeaderProps) => {
  const stages = setup_porting ? PORTING_STAGES : STAGES
  return <StageHeader stages={stages} activeStage={activeStage} />
}

export default connect((state: ApplicationState) => {
  const { checkout } = state.activation
  return {
    setup_porting: (checkout && checkout.setup_porting) || false,
  }
})(ConnectedStageHeader)
