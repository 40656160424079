import React, { ReactNode, Fragment } from 'react'

import { Header, Icon, Menu, Responsive, Divider } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

interface TabLinkProps {
  children?: ReactNode
  active?: boolean // semantic-ui parameter for determining what's selected
  heading?: string
  link: string
  selected?: boolean
}

const TabLink = ({ children, heading, link, selected }: TabLinkProps) => (
  <Fragment>
    <Menu.Item as={NavLink} to={selected ? `/` : link} className="arrowtip">
      {heading && (
        <Header inverted as="h3" className={classNames({ nomargin: selected })}>
          {heading}
        </Header>
      )}
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth}>
        {!selected && <Icon name="arrow right" style={{ float: 'right' }} />}
      </Responsive>
      {children}
    </Menu.Item>
    <Divider fitted />
  </Fragment>
)

export default TabLink
