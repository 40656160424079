import React, { ReactNode, Fragment } from 'react'
import { Header, Icon, SemanticICONS } from 'semantic-ui-react'
import { BodyText1 } from 'common/Typography'

const SuccessConfirmation = ({
  title = 'Success',
  iconName = 'check',
  children = null,
}: {
  title?: string
  iconName?: SemanticICONS
  children?: ReactNode
}) => (
  <Fragment>
    <Header as="h2" textAlign="left">
      <Header.Content>
        <Icon name={iconName} color="teal" /> {title}
      </Header.Content>
    </Header>
    <BodyText1>{children}</BodyText1>
  </Fragment>
)

export default SuccessConfirmation
