import React from 'react'
import SvgIcon from 'common/SvgIcon'
import { connect } from 'react-redux'
import BaseFormField, { BaseFormFieldInterface, FieldOnChange } from 'common/Form/BaseFormField'
import { ApplicationState } from 'rootReducer'
import { getFormDataById } from 'common/Form/reducer'
import { Card, formatCvc, getCardTypeByValue } from '../cardTypes'

interface Props extends BaseFormFieldInterface {
  cardType?: Card
}

class CreditCardCvcFormField extends React.PureComponent<Props> {
  onChange: FieldOnChange = (event, { name, value }) => {
    const { onChange } = this.props

    onChange(event, { name, value: formatCvc(value as string) })
  }

  render() {
    const { errors, cardType, onChange, ...field } = this.props
    const updatedField = {
      ...field,
      type: 'tel',
      maxLength: cardType ? cardType.cvcLength : 4,
      icon: <SvgIcon name={cardType ? cardType.cvcIcon : 'cvc'} />,
    }
    return <BaseFormField errors={errors} onChange={this.onChange} {...updatedField} />
  }
}

const mapStateToProps = (state: ApplicationState, props: BaseFormFieldInterface) => {
  const cardNumber = (getFormDataById(state, props.formId) || {})['credit-card-number']
  return { cardType: getCardTypeByValue(cardNumber) }
}
export default connect(mapStateToProps)(CreditCardCvcFormField)
