import { useState, useContext, useCallback, useEffect, useMemo } from 'react'
// @ts-ignore
import { __RouterContext as RouterContext, RouteComponentProps } from 'react-router-dom'
import { Responsive } from 'semantic-ui-react'

type EmptyFunction = () => void

export function useBoolean(initialValue: boolean): [boolean, EmptyFunction, EmptyFunction] {
  const [toggleValue, setToggleValue] = useState<boolean>(initialValue)
  const setOn = useCallback<EmptyFunction>(() => setToggleValue(true), [setToggleValue])
  const setOff = useCallback<EmptyFunction>(() => setToggleValue(false), [setToggleValue])
  return [toggleValue, setOn, setOff]
}

export function useToggle(initialValue: boolean): [boolean, EmptyFunction] {
  const [toggleValue, setToggleValue] = useState<boolean>(initialValue)
  const doToggle = useCallback<EmptyFunction>(() => setToggleValue(!toggleValue), [
    toggleValue,
    setToggleValue,
  ])
  return [toggleValue, doToggle]
}

export function useBooleanToggle(
  initialValue: boolean
): [boolean, EmptyFunction, EmptyFunction, EmptyFunction] {
  const [toggleValue, setToggleValue] = useState<boolean>(initialValue)
  const doToggle = useCallback<EmptyFunction>(() => setToggleValue(!toggleValue), [
    toggleValue,
    setToggleValue,
  ])
  const setOn = useCallback<EmptyFunction>(() => setToggleValue(true), [setToggleValue])
  const setOff = useCallback<EmptyFunction>(() => setToggleValue(false), [setToggleValue])
  return [toggleValue, doToggle, setOn, setOff]
}

/* Shamelessly stolen from
 * https://github.com/ReactTraining/react-router/issues/6430
 * (additional functionality above)
 * */
export const useRouter = <T>(): RouteComponentProps<T> =>
  useContext(RouterContext) as RouteComponentProps<T>

export const useParams = <T>(): T => {
  const { match } = useRouter<T>()
  return match.params
}

export const useLocation = (): string => {
  const { location } = useRouter()
  return location.pathname
}

export const useNavigate = (replace: boolean = false): ((to: string) => void) => {
  const { history } = useRouter()

  const visit = useCallback(
    (to: string): void => {
      if (replace) {
        history.replace(to)
      } else {
        history.push(to)
      }
    },
    [history, replace]
  )
  return visit
}

type ResizeListener = (width: number) => void

export interface ResponsiveSizes {
  isMobile: boolean
  isTablet: boolean
  isComputer: boolean
  isLargeScreen: boolean
  isWidescreen: boolean
}

const makeUseResponsive = (): (() => ResponsiveSizes) => {
  let bounded = false
  const listeners: ResizeListener[] = []

  const getWindowWidth = (): number => {
    return window ? window.innerWidth : 0
  }

  const bindToWindow = () => {
    if (!bounded && window) {
      bounded = true
      window.addEventListener('resize', () => {
        listeners.forEach((listener) => listener(getWindowWidth()))
      })
    }
  }

  return () => {
    const [windowSize, setWindowSize] = useState<number>(getWindowWidth())

    useEffect(() => {
      const listener: ResizeListener = (width) => setWindowSize(width)
      listeners.push(listener)
      bindToWindow()

      return () => {
        const position = listeners.indexOf(listener)
        if (position >= 0) listeners.splice(position, 1)
      }
    }, [setWindowSize])

    return useMemo<ResponsiveSizes>(
      () => ({
        isMobile: windowSize <= (Responsive.onlyMobile.maxWidth as number),
        isTablet:
          windowSize <= (Responsive.onlyTablet.maxWidth as number) &&
          windowSize >= (Responsive.onlyTablet.minWidth as number),
        isComputer:
          windowSize <= (Responsive.onlyComputer.maxWidth as number) &&
          windowSize >= (Responsive.onlyComputer.minWidth as number),
        isLargeScreen:
          windowSize <= (Responsive.onlyLargeScreen.maxWidth as number) &&
          windowSize >= (Responsive.onlyLargeScreen.minWidth as number),
        isWidescreen: windowSize >= (Responsive.onlyWidescreen.minWidth as number),
      }),
      [windowSize]
    )
  }
}

export const useResponsive = makeUseResponsive()
