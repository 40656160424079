import axios from 'axios'
import { ApplicationState } from 'rootReducer'
import { getInvoice } from 'pages/InvoicePage/reducer'
import { Uuid } from 'src/types'
import { Invoice, InInvoice } from './types'

export enum InvoiceActionTypes {
  GET_INVOICE_REQUEST = 'DASHBOARD_GET_INVOICE_REQUEST',
  GET_INVOICE_SUCCESS = 'DASHBOARD_GET_INVOICE_SUCCESS',
  GET_INVOICE_FAILURE = 'DASHBOARD_GET_INVOICE_FAILURE',
}

export interface GetInvoiceAction {
  type: InvoiceActionTypes
  payload: {
    uuid: Uuid
    orderCode: string
    data?: Invoice
  }
}

export const getInvoiceRequest = (uuid: Uuid, orderCode: string): GetInvoiceAction => ({
  type: InvoiceActionTypes.GET_INVOICE_REQUEST,
  payload: { uuid, orderCode },
})
export const getInvoiceFailure = (uuid: Uuid, orderCode: string): GetInvoiceAction => ({
  type: InvoiceActionTypes.GET_INVOICE_FAILURE,
  payload: { uuid, orderCode },
})
export const getInvoiceSuccess = (
  uuid: Uuid,
  orderCode: string,
  data: Invoice
): GetInvoiceAction => ({
  type: InvoiceActionTypes.GET_INVOICE_SUCCESS,
  payload: { uuid, orderCode, data },
})

const convertDates = (data: InInvoice): Invoice => {
  return {
    created: new Date(data.created),
    email: data.email,
    full_name: data.full_name,
    line_items: data.line_items,
    order_code: data.order_code,
    tax: data.tax,
    total_price: data.total_price,
    document_type: data.document_type,
    refund_ref: data.refund_ref,
  }
}

export const fetchInvoice = (uuid: Uuid, orderCode: string, cache: boolean = true) => {
  return (dispatch: Function, getState: () => ApplicationState) => {
    // invoice isn't going to change per request, so we'll cache it in redux
    const state = getState()
    if (cache && getInvoice(uuid, orderCode)(state)) return

    dispatch(getInvoiceRequest(uuid, orderCode))
    axios
      .get(`/api/account/invoices/${uuid}/${orderCode}/`)
      .then((data) => {
        dispatch(getInvoiceSuccess(uuid, orderCode, convertDates(data.data)))
      })
      .catch(() => dispatch(getInvoiceFailure(uuid, orderCode)))
  }
}
