import React, { useEffect } from 'react'
import { FormContext, FormSubmitSignature } from 'common/Form'

export const SubFormHandler = ({
  handler,
  value,
}: {
  handler: FormSubmitSignature
  value: FormSubmitSignature[]
}) => {
  useEffect(() => {
    const handleSubmit: FormSubmitSignature = (data, errors, dirtyFields) => {
      return handler(data, errors, dirtyFields)
    }
    value.push(handleSubmit)
    return function cleanup() {
      value.splice(value.indexOf(handleSubmit, 1))
    }
  })
  return null
}

const SubmitIntercept = ({ handler }: { handler: FormSubmitSignature }) => {
  return (
    <FormContext.Consumer>
      {(value) => <SubFormHandler handler={handler} value={value} />}
    </FormContext.Consumer>
  )
}

export default SubmitIntercept
