import React from 'react'
import { Icon, Popup } from 'semantic-ui-react'
import { FieldInterface, FormSubmitSignature, GroupedFieldInterface, FormData } from 'common/Form'
import SubmitIntercept from 'common/Form/SubmitIntercept'
import * as Yup from 'yup'
import encryptData, { validate } from '../adyenEncrypt'
import CreditCardCvcFormField from '../Cvc'
import CreditCardExpiryFormField from '../Expiry'
import CreditCardNumberFormField from '../Number'

export const extractExpiry = (expiry?: string): FormData => {
  if (!expiry) return {}
  const [expiryMonth, expiryYear] = expiry.replace(/\s/g, '').split('/')
  const today = new Date()
  // todo: check back in 2090 when it could be 21XX
  const currentYearFirstTwoDigit = `${today.getFullYear()}`.substring(0, 2)
  return { expiryMonth, expiryYear: `${currentYearFirstTwoDigit}${expiryYear}` }
}

const creditCardPreSubmit: FormSubmitSignature = (data, errors) => {
  const {
    'credit-card-number': number,
    'credit-card-name': holderName,
    'credit-card-expiry': expiry,
    'credit-card-cvc': cvc,
    ...rest
  } = data
  const { expiryMonth, expiryYear } = extractExpiry(expiry as string)

  // filter out any undefined values
  const filteredData = JSON.parse(
    JSON.stringify({
      number,
      holderName,
      expiryMonth,
      expiryYear,
      cvc,
    })
  )

  const adyenEncrypted = encryptData(filteredData)
  if (!adyenEncrypted) {
    // there was a validation error
    return {
      data: rest,
      errors: {
        __all__: ['Please check your payment details and try again'],
        ...errors,
      },
    }
  }

  return {
    data: {
      ...rest,
      adyen_encrypted: adyenEncrypted,
      payment_method: '1',
      // updating a card
      use_saved_card: false,
    },
    errors,
  }
}

export const numberField: FieldInterface = {
  name: 'credit-card-number',
  label: 'Card Number',
  autocomplete: 'cc-number',
  component: CreditCardNumberFormField,
  required: true,
  inputMode: 'numeric',
}

export const expiryField: FieldInterface = {
  name: 'credit-card-expiry',
  placeholder: 'MM / YY',
  label: 'Expiry',
  required: true,
  autocomplete: 'cc-exp',
  component: CreditCardExpiryFormField,
  inputMode: 'numeric',
}

const CSC_TOOLTIP =
  'The CSC is either a 3 digit number on the signature strip of your card or ' +
  'in the case of Amex it is a 4 digit number on your front of the card.'

export const cvcField: FieldInterface = {
  name: 'credit-card-cvc',
  required: true,
  label: (
    <span>
      CSC{' '}
      <Popup
        size="small"
        content={CSC_TOOLTIP}
        trigger={<Icon color="grey" name="info circle" />}
      />
    </span>
  ),
  autocomplete: 'cc-csc',
  component: CreditCardCvcFormField,
  inputMode: 'numeric',
}

export const nameField: FieldInterface = {
  name: 'credit-card-name',
  label: 'Name on Card',
  type: 'text',
  autocomplete: 'cc-name',
  required: true,
}

export const interceptField: FieldInterface = {
  name: 'credit-card-hidden',
  component: () => <SubmitIntercept handler={creditCardPreSubmit} />,
}

const makeCreditCardSection = (): {
  schema: Yup.ObjectSchemaDefinition<object>
  fields: GroupedFieldInterface[] | FieldInterface[]
} => {
  const schema = {
    'credit-card-number': Yup.string()
      .required('This field is required.')
      .test({
        name: 'credit-card-number',
        test: (number) => {
          const validator = validate({ number })
          return validator.number
        },
        message: 'Please enter a valid credit card number.',
      }),

    'credit-card-name': Yup.string().required('This field is required.'),
    'credit-card-expiry': Yup.string()
      .required('This field is required.')
      .test({
        name: 'credit-card-expiry',
        test: (expiry) => {
          const validator = validate(extractExpiry(expiry))
          return validator.month && validator.year
        },
        message: 'Please enter a valid expiry date.',
      }),

    'credit-card-cvc': Yup.string()
      .required('This field is required.')
      .test({
        name: 'credit-card-cvc',
        test: (cvc) => {
          const validator = validate({ cvc })
          return validator.cvc
        },
        message: 'Please enter a valid CSC.',
      }),
  }

  return {
    schema,
    fields: [numberField, [expiryField, cvcField], nameField, interceptField],
  }
}
export default makeCreditCardSection
