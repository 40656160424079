import { makeDropdown, DropDownItem } from 'common/Dropdown'
import { makeLoadingSelector } from 'common/Loading/reducer'

const ProvidersDropdown = makeDropdown(
  (state) => {
    const providers =
      (state.activation.checkout && state.activation.checkout.porting_providers) ||
      state.dashboard.providers ||
      []
    const options: DropDownItem[] = providers.map(
      ([providerId, providerName]: [string, string]) => {
        return {
          key: providerId,
          text: providerName,
          value: providerId,
        }
      }
    )
    return options
  },
  (state) => makeLoadingSelector(['GET_ACTIVATION_STATE'])(state)
)

export default ProvidersDropdown
