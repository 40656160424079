import React, { PureComponent, ReactNode } from 'react'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { makeLoadingSelector } from 'common/Loading/reducer'
import { User } from './reducer'

interface ChildrenRenderProps {
  authenticated: boolean
  user: User | null
  isLoading: boolean
}

interface Props {
  children(arg0: ChildrenRenderProps): ReactNode
}

class UserContainer extends PureComponent<ChildrenRenderProps & Props> {
  render() {
    const { authenticated, user, children, isLoading } = this.props
    return children({
      authenticated,
      user,
      isLoading,
    })
  }
}

const mapStateToProps = (state: ApplicationState): ChildrenRenderProps => {
  const { authenticated, user, fetched } = state.user
  return {
    authenticated,
    user,
    isLoading: makeLoadingSelector(['USER_AUTHENTICATED'])(state) || !fetched,
  }
}

export function withUser<ExtraProps>(
  component: React.ComponentType<any>
): React.ComponentType<ExtraProps> {
  const Connected = connect<ChildrenRenderProps, {}, {}, ApplicationState>(mapStateToProps)(
    component
  )
  return (props: ExtraProps): React.ReactElement<ExtraProps> => <Connected {...props} />
}

export default withUser<Props>(UserContainer)
