import makeCreditCardSection, {
  cvcField,
  expiryField,
  interceptField,
  nameField,
  numberField,
} from './Form'

export default makeCreditCardSection
export { cvcField, expiryField, interceptField, nameField, numberField }
