import React from 'react'
import Layout from 'common/Layout'
import LoginForm from './LoginForm'

const LoginPage = () => (
  <Layout.Page form>
    <LoginForm />
  </Layout.Page>
)

export default LoginPage
