import React, { useCallback } from 'react'
import { Redirect, RouteProps, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { makeLoadingSelector } from 'common/Loading/reducer'
import LoadingPage from 'pages/LoadingPage'

interface AnonymousRouteProps extends RouteProps {
  component: React.ComponentType<any>
  authenticated: boolean
  isLoading?: boolean
}

export const AnonymousRouteComponent = ({
  authenticated,
  component: Component,
  isLoading,
  ...rest
}: AnonymousRouteProps) => {
  const renderComponent = useCallback((): React.ReactNode => {
    if (isLoading) {
      return <LoadingPage />
    }
    return !authenticated ? <Component /> : <Redirect to="/" />
  }, [authenticated, Component, isLoading])

  return <Route {...rest} render={renderComponent} />
}

export const mapStateToProps = (state: ApplicationState) => ({
  authenticated: state.user.authenticated,
  isLoading: !state.user.fetched || makeLoadingSelector(['USER_AUTHENTICATED'])(state),
})

export default connect(mapStateToProps)(AnonymousRouteComponent)
