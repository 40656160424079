import { ApplicationState } from 'rootReducer'
import { Uuid } from 'src/types'

import { UsageDetail } from './types'

// eslint-disable-next-line import/prefer-default-export
export const getDetailsByUuid = (uuid: Uuid, page?: number) => (
  state: ApplicationState
): UsageDetail | undefined => {
  const pageKey = page || 0
  if (state.dashboard.byUuid[uuid] && state.dashboard.byUuid[uuid].detail) {
    const { detail } = state.dashboard.byUuid[uuid]
    if (!detail || !detail[pageKey]) {
      return undefined
    }
    return detail[pageKey]
  }
  return undefined
}
