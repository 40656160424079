import React, { useCallback } from 'react'
import { Header, Image, Button, Grid } from 'semantic-ui-react'

import Layout from 'common/Layout'
import { useNavigate, useParams } from 'common/hooks'
import styled from 'styled-components'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const existingServiceImg = require('./existing-service@2x.png')
// eslint-disable-next-line @typescript-eslint/no-var-requires
const newServiceImg = require('./new-service@2x.png')

const StyledButton = styled(Button)`
  &.ui.button {
    font-weight: normal;
  }
`

const BigIconButton: React.FC<{
  children: React.ReactText
  icon: object
  onClick: React.MouseEventHandler
}> = ({ children, icon, onClick }) => (
  <StyledButton tabIndex={0} onClick={onClick} basic fluid size="medium">
    <Image src={icon} size="tiny" centered />
    <br />
    {children}
  </StyledButton>
)

const RechargeOrActivatePage = () => {
  const onActivateClick = useCallback(() => {
    // need to call backend code here
    window.location.pathname = `/activate/new/`
  }, [null])

  const navigate = useNavigate(false)
  const { code } = useParams()
  const onRechargeClick = useCallback(() => {
    navigate(`/services/renew/voucher/${code}/`)
  }, [navigate, code])
  return (
    <Layout.Page form="two wide">
      <Header as="h2">Start with a Voucher Code</Header>
      <Grid columns={2} stackable>
        <Grid.Column>
          <BigIconButton icon={newServiceImg} onClick={onActivateClick}>
            Activate a new Prepay Plan
          </BigIconButton>
        </Grid.Column>
        <Grid.Column>
          <BigIconButton icon={existingServiceImg} onClick={onRechargeClick}>
            Renew an existing Prepay Plan
          </BigIconButton>
        </Grid.Column>
      </Grid>
    </Layout.Page>
  )
}

export default RechargeOrActivatePage
