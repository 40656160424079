export enum RouteTypes {
  LoginNext = 'SET_LOGIN_NEXT',
}

export interface LoginTextAction {
  type: RouteTypes.LoginNext
  url?: string
}

export const setLoginNext = (url: string): LoginTextAction => ({ type: RouteTypes.LoginNext, url })
export const clearLoginNext = (): LoginTextAction => ({ type: RouteTypes.LoginNext })
