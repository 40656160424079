import { MouseEvent as ReactMouseEvent } from 'react'
import { SemanticCOLORS } from 'semantic-ui-react'

export enum ProductType {
  PLAN = 1,
  ADDON = 2,
}

// eslint-disable-next-line import/prefer-default-export
export interface Product {
  sku: string
  unique_id: string
  title: string
  short_title: string
  data: string
  data_gb: number
  voice_mins: number
  txt_sms: number
  roaming: boolean
  length: number
  price: string
  price_rebill: string
  rrp: string
  colour?: SemanticCOLORS
  product_type: ProductType
  sort_order: number
  next_product_unique_id: string | null
  new_customer_only: boolean
  [key: string]: any
}

export type UniqueId = string

export interface ProductByUniqueId {
  [key: string]: Product
}

export interface ProductsReducerState {
  byUniqueId: ProductByUniqueId
  allUniqueIds: string[]
}

export type ProductClickEvent = (
  event: ReactMouseEvent<Element, MouseEvent>,
  unique_id: Product['unique_id']
) => void
