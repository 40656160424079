import React, { useEffect, useMemo, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbSection,
  Header,
  Table,
  Grid,
  Image,
  Placeholder,
  Divider,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { ApplicationState } from 'rootReducer'
import { ProductType } from 'common/Products/types'
import { ServicesLoader } from 'pages/RechargePage'

import { Uuid } from 'src/types'
import { formatDate, formatPrice } from 'common/Utils'

import { getPhoneNumberByUuid } from 'common/Dashboard/reducer'
import { useParams, useResponsive } from 'common/hooks'
import { fetchInvoice } from 'pages/InvoicePage/actions'
import { getInvoice } from 'pages/InvoicePage/reducer'
import { InvoiceSelector, Invoice } from './types'

// https://github.com/wmonk/create-react-app-typescript/issues/32
// eslint-disable-next-line @typescript-eslint/no-var-requires
const logo = require('static/img/kogan_mobile_logo.svg')

const InvoicePlaceholder = () => (
  <Fragment>
    <Divider hidden />
    <Placeholder fluid>
      <Placeholder.Header>
        <Placeholder.Line length="full" />
        <Placeholder.Line length="full" />
      </Placeholder.Header>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell>
              <Placeholder>
                <Placeholder.Line />
              </Placeholder>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </Placeholder>
  </Fragment>
)

const InvoiceBreadcrumb = ({ orderReference }: { orderReference: string }) => {
  const { uuid } = useParams()
  const phoneNumber = useSelector(getPhoneNumberByUuid(uuid))
  const { isMobile } = useResponsive()
  return (
    <Breadcrumb size="small">
      <BreadcrumbSection link as={phoneNumber ? Link : Placeholder} to={`/services/${uuid}/`}>
        {phoneNumber || '000 000 0000'}
      </BreadcrumbSection>
      <BreadcrumbDivider />
      <BreadcrumbSection link as={Link} to={`/services/${uuid}/invoices/`}>
        Purchase History
      </BreadcrumbSection>
      {!isMobile && (
        <Fragment>
          <BreadcrumbDivider />
          <BreadcrumbSection>Order #{orderReference} </BreadcrumbSection>
        </Fragment>
      )}
    </Breadcrumb>
  )
}

const InvoiceHeader = ({
  orderDate,
  fullName,
  email,
  orderReference,
}: {
  orderDate?: Date
  fullName?: string
  email?: string
  orderReference?: string
}) => {
  return (
    <Grid columns={16} stackable relaxed>
      <Grid.Row>
        <Grid.Column mobile={16} computer={16}>
          {orderReference && (
            <Fragment>
              <div>Refund #{orderReference}</div>
              <br />
            </Fragment>
          )}
          {!orderDate ? (
            <Placeholder>
              <Placeholder.Line length="short" />
            </Placeholder>
          ) : (
            formatDate(orderDate)
          )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column mobile={8} computer={10}>
          <p>
            <b>Bill to</b>
          </p>
          {!fullName && !email ? (
            <Placeholder>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
          ) : (
            <p>
              {fullName}
              <br />
              {email}
            </p>
          )}
        </Grid.Column>
        <Grid.Column mobile={8} computer={6}>
          <p>
            <b>Bill from</b>
          </p>
          {!fullName && !email ? (
            <Placeholder>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
          ) : (
            <p>
              One New Zealand Group Limited
              <br />
              NZBN 9429037753115
              <br />
              GST Number 70-710-455
            </p>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const InvoiceTable = ({ invoice }: { invoice?: Invoice }) => {
  const { isMobile } = useResponsive()
  const body = useMemo(() => {
    if (!invoice) return null

    return invoice.line_items.map((row) => {
      const productType = row.product.product_type === ProductType.PLAN ? 'Prepay Plan' : ''
      return (
        <Table.Row key={row.title}>
          <Table.Cell textAlign="left">
            <div>
              {row.title} {productType} ({row.product.length} days){' '}
              <small>[{row.product.sku}]</small>
            </div>
            <ul>
              {row.product.data_gb > 0 && <li>Data: {row.product.data_gb}GB</li>}
              {row.product.voice_mins > 0 && (
                <li>International Calls: {row.product.voice_mins} minutes</li>
              )}
              {row.product.txt_sms > 0 && <li>International SMS/MMS: {row.product.txt_sms}</li>}
            </ul>
          </Table.Cell>
          {!isMobile && (
            <Table.Cell textAlign="right" verticalAlign="top">
              {row.quantity}
            </Table.Cell>
          )}
          {!isMobile && (
            <Table.Cell textAlign="right" verticalAlign="top">
              {formatPrice(row.unit_price)}
            </Table.Cell>
          )}
          <Table.Cell textAlign="right" verticalAlign="top">
            {isMobile && row.quantity > 0 && `${row.quantity} x `}
            {formatPrice(row.subtotal)}
          </Table.Cell>
        </Table.Row>
      )
    })
  }, [isMobile, invoice])

  if (!invoice) return <InvoicePlaceholder />
  return (
    <Table unstackable className="invoices">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell textAlign="left">Description</Table.HeaderCell>
          {!isMobile && <Table.HeaderCell textAlign="right">Quantity</Table.HeaderCell>}
          {!isMobile && <Table.HeaderCell textAlign="right">Unit Price</Table.HeaderCell>}
          <Table.HeaderCell textAlign="right">Subtotal (NZD)</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{body}</Table.Body>
    </Table>
  )
}

const InvoiceTotal = ({ total, tax }: { total: string; tax: string }) => {
  return (
    <Grid textAlign="right">
      <Grid.Column>
        <p>
          <b>Total (NZD): {formatPrice(total)}</b>
        </p>
        <p>Includes GST of {formatPrice(tax)}</p>
      </Grid.Column>
    </Grid>
  )
}

const InvoiceFooter = () => {
  const { isMobile } = useResponsive()
  return (
    <Fragment>
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Grid centered>
        <Grid.Column textAlign="center">
          {isMobile && <Image src={logo} alt="logo" size="tiny" centered />}
        </Grid.Column>
      </Grid>
    </Fragment>
  )
}

const Loader = ({ uuid, orderCode }: { uuid: Uuid; orderCode: string }) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchInvoice(uuid, orderCode))
  }, [uuid, dispatch])
  return null
}

const InvoicePage = () => {
  const { uuid, orderCode } = useParams()
  const { isMobile } = useResponsive()

  const invoiceSelector = useMemo<InvoiceSelector>(() => getInvoice(uuid, orderCode), [
    uuid,
    orderCode,
  ])

  const invoice = useSelector<ApplicationState, Invoice | undefined>(invoiceSelector)

  let title
  if (invoice && invoice.document_type === 'credit') {
    title = 'Tax Credit Note'
  } else if (invoice && invoice.document_type === 'invoice') {
    title = 'Tax Invoice'
  } else {
    title = (
      <Placeholder>
        <Placeholder.Line />
      </Placeholder>
    )
  }

  let orderTitle
  if (invoice) {
    orderTitle = `Order #${invoice.order_code}`
  } else {
    orderTitle = (
      <Placeholder>
        <Placeholder.Line />
      </Placeholder>
    )
  }

  return (
    <Fragment>
      <Loader uuid={uuid} orderCode={orderCode} />
      <Fragment>
        <ServicesLoader />
        <InvoiceBreadcrumb orderReference={invoice ? invoice.order_code : ''} />
        <Divider hidden />
        <Grid columns={16}>
          <Grid.Row>
            <Grid.Column width={isMobile ? 16 : 10}>
              <Header as="h1">
                {title}
                <Header.Subheader>{orderTitle}</Header.Subheader>
              </Header>
            </Grid.Column>
            {!isMobile && (
              <Grid.Column textAlign="right" width={6}>
                <Image src={logo} alt="logo" size="tiny" floated="right" />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
        <InvoiceHeader
          orderDate={invoice ? invoice.created : undefined}
          fullName={invoice ? invoice.full_name : undefined}
          orderReference={invoice ? invoice.refund_ref : undefined}
          email={invoice ? invoice.email : undefined}
        />
        <InvoiceTable invoice={invoice} />
        {invoice && <InvoiceTotal total={invoice.total_price} tax={invoice.tax} />}
        <InvoiceFooter />
      </Fragment>
    </Fragment>
  )
}

export default InvoicePage
