import React, { Fragment, ReactNode } from 'react'
import { Header, Segment, Menu } from 'semantic-ui-react'

interface GenericMobileSelectedNavProps {
  children: ReactNode
  heading: string
}

const GenericMobileSelectedNav = ({ heading, children }: GenericMobileSelectedNavProps) => {
  return (
    <Fragment>
      <Segment inverted className="sidenav mobile nopadding" color="teal">
        <Segment padded basic>
          <Header as="h2" textAlign="left" inverted icon>
            {heading}
          </Header>
        </Segment>
        <Menu size="large" secondary fluid vertical>
          {children}
        </Menu>
      </Segment>
    </Fragment>
  )
}

export default GenericMobileSelectedNav
