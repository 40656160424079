import { ApplicationState } from 'src/rootReducer'
import { Uuid } from 'src/types'
import {
  UpdateAutoRechargeAction,
  UpdateAutoRechargeActionTypes,
} from 'pages/UsageSummaryPage/actions'
import { ServiceActionTypes, ServiceActions, RechargeVoucherActionTypes } from './actions'
import { Service, ServicesReducerState, Schedule, ServiceByUuid } from './types'

export const initialServiceState: ServicesReducerState = {
  byUuid: {},
  allUuids: [],
  schedulesByShortId: {},
  savedVoucherCode: '',
}

const reduceSchedules = (service: Service, schedules: { [key: string]: Schedule }) => {
  return Object.keys(schedules).reduce((map, name) => {
    const schedule = schedules[name]
    schedule.service = service
    return { ...map, [name]: schedule }
  }, {})
}

const byShortId = (schedules: { [key: string]: Schedule }) => {
  return Object.keys(schedules).reduce((map, name) => {
    return { ...map, [schedules[name].short_id]: schedules[name] }
  }, {})
}

const reduceServices = (services: ServiceByUuid) => {
  return Object.keys(services).reduce(
    (map, name) => {
      const service = services[name]
      const schedules = reduceSchedules(service, service.schedules)
      service.schedules = schedules
      return {
        services: { ...map.services, [name]: service },
        schedules: { ...map.schedules, ...byShortId(schedules) },
      }
    },
    { services: {}, schedules: {} }
  )
}

const servicesReducer = (
  state: ServicesReducerState = initialServiceState,
  action: ServiceActions | UpdateAutoRechargeAction
): ServicesReducerState => {
  switch (action.type) {
    case ServiceActionTypes.GET_SERVICES_SUCCESS:
      if (action.payload.data) {
        const { services, schedules } = reduceServices(action.payload.data)
        return {
          ...state,
          byUuid: services,
          allUuids: Object.keys(action.payload.data),
          schedulesByShortId: schedules,
        }
      }
      return state
    case UpdateAutoRechargeActionTypes.UPDATE_AUTO_RECHARGE_SUCCESS:
      if (action.payload) {
        const combined = {
          ...state.byUuid,
          [action.payload.service.uuid]: action.payload.service,
        }
        const { services, schedules } = reduceServices(combined)
        return {
          ...state,
          byUuid: services,
          allUuids: Object.keys(combined),
          schedulesByShortId: schedules,
        }
      }
      return state
    case RechargeVoucherActionTypes.CLEAR_VOUCHER_CODE:
      return {
        ...state,
        savedVoucherCode: '',
      }
    case RechargeVoucherActionTypes.SET_VOUCHER_CODE:
      return {
        ...state,
        savedVoucherCode: action.payload as string,
      }
    default:
      return state
  }
}

export const getServiceById = (state: ApplicationState, id: Uuid) => state.services.byUuid[id]

export const getPlanSchedule = (state: ApplicationState, uuid: Uuid): Schedule | undefined => {
  const service = state.services.byUuid[uuid]
  if (!service) return undefined
  return state.services.schedulesByShortId[service.plan_short_id]
}
export const getScheduleByShortId = (state: ApplicationState, shortId: string) => {
  return state.services.schedulesByShortId[shortId]
}

export const getAutoRechargeEnabled = (uuid: Uuid) => (state: ApplicationState) => {
  const schedule = getPlanSchedule(state, uuid)
  if (!schedule) return false
  return schedule.auto_recharge_enabled
}

export const getAllServices = (state: ApplicationState) => {
  const { services } = state

  return services.allUuids.reduce((acc: Service[], id: Uuid) => {
    return [...acc, services.byUuid[id]]
  }, [])
}

export const getSavedVoucherCode = (state: ApplicationState): string => {
  return state.services.savedVoucherCode
}

export default servicesReducer
