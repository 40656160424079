import React, { useEffect } from 'react'
import { Route, Redirect, RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  makeSuccessSelector,
  makeLoadingSelector,
  makeFailureSelector,
} from 'common/Loading/reducer'
import Layout from 'common/Layout'
import AnimatedSwitch from 'common/AnimatedTransition/Switch'
import { ApplicationState } from 'rootReducer'
import { getActivationState } from './actions'
import { ActivationState } from './types'
import EnterSim from './Stages/EnterSim'
import PortingStage from './Stages/PortingStage'
import SelectPaymentMethod from './Stages/SelectPaymentMethod'
import SelectPlan from './Stages/SelectPlan'
import PaymentStage from './Stages/PaymentStage'
import ContactDetailStage from './Stages/SignupStage'
import ActivationProcessing from './Stages/ActivationProcessing'
import ActivationError from './Stages/ActivationError'
import ErrorScreen from './Stages/Error'
import Loading from './Stages/Loading'
import SuccessStage from './Stages/Success'
import { STAGE_MAPPINGS } from './constants'

interface ActivatePageProps extends RouteComponentProps {
  errored: boolean
  loading: boolean
  success: boolean
  serverState: ActivationState
  doGetActivationState: Function
}

const ActivatePage = ({
  success,
  errored,
  loading,
  location,
  serverState,
  doGetActivationState,
}: ActivatePageProps) => {
  useEffect(() => {
    if (!success && !loading) doGetActivationState()
  }, [null])

  if (success) {
    // Ensure URL is correct with server state.
    const allowedUrls = STAGE_MAPPINGS.get(serverState)
    if (allowedUrls && !allowedUrls.includes(location.pathname)) {
      // On a bad URL.
      // NOTE: Redirects to the first URL. Needs more smarts as we add more paths.
      return <Redirect to={allowedUrls[0]} push={false} />
    }
  }

  let inner
  if (errored || serverState === 'PERMANENTLY_FAILED') {
    inner = (
      <ErrorScreen
        tryAgainMethod={serverState === 'PERMANENTLY_FAILED' ? 'startAgain' : 'refresh'}
      />
    )
  } else if (success) {
    inner = (
      <AnimatedSwitch animation="scale" transitionOnMount duration={300}>
        <Route path="/activate/" exact component={EnterSim} />
        <Route path="/activate/porting/" component={PortingStage} />
        <Route path="/activate/signup/" component={ContactDetailStage} />
        <Route path="/activate/method/" component={SelectPaymentMethod} />
        <Route path="/activate/plans/" component={SelectPlan} />
        <Route path="/activate/checkout/" component={PaymentStage} />
        <Route path="/activate/processing/" component={ActivationProcessing} />
        <Route path="/activate/error/" component={ActivationError} />
        <Route path="/activate/success/" component={SuccessStage} />
      </AnimatedSwitch>
    )
  } else {
    inner = <Loading />
  }

  return (
    <Layout.Page form="two wide">
      <div style={{ minHeight: 450 }}>
        {inner}
        {/* Stop the min-height div from moving around from margins */}
        &nbsp;
      </div>
    </Layout.Page>
  )
}

const ConnectedActivationPage = connect(
  (state: ApplicationState) => ({
    loading: makeLoadingSelector(['GET_ACTIVATION_STATE'])(state),
    errored: makeFailureSelector(['GET_ACTIVATION_STATE'])(state),
    success: makeSuccessSelector(['GET_ACTIVATION_STATE'])(state),
    serverState: (state.activation.checkout && state.activation.checkout.state) || null,
  }),
  {
    doGetActivationState: getActivationState,
  }
)(ActivatePage)

export default ConnectedActivationPage
