import { useRef, useMemo, RefObject } from 'react'
import { Input } from 'semantic-ui-react'

interface FieldRef {
  id: string
  ref: RefObject<Input>
}

const inputRefMap: { [key: string]: FieldRef[] } = {}

const setRef = (formId: string, id: string, ref: RefObject<Input>) => {
  if (!inputRefMap[formId]) {
    inputRefMap[formId] = []
  }
  const inputRefs = inputRefMap[formId]
  // since fields are dynamic, we could have set the field ref on a previous render
  const existing = inputRefs.find(({ id: checkId }) => checkId === id)
  if (existing) {
    existing.ref = ref
  } else {
    inputRefs.push({ id, ref })
  }
}

const useAutoNext = (formId: string, id: string): [RefObject<Input>, Function] => {
  const ref = useRef<Input>(null)
  const next = useMemo<Function>(() => {
    return () => {
      const inputRefs = inputRefMap[formId]
      const idx = inputRefs.findIndex(({ id: checkId }) => checkId === id)
      if (idx >= 0 && idx < inputRefs.length - 1) {
        const { ref: nextRef } = inputRefs[idx + 1]
        // make sure that it's got the right tabindex as well
        if (nextRef.current !== null) nextRef.current.focus()
      }
    }
  }, [formId, id])
  setRef(formId, id, ref)
  return [ref, next]
}

export default useAutoNext
