/* eslint-disable no-underscore-dangle */
import { createStore, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer, { ApplicationState } from 'rootReducer'

import { middleware as segmentMiddleware } from './middleware/segment'

const composeStore = () => {
  const middleware = [thunk, segmentMiddleware]
  let composeEnhancers = compose
  // @ts-ignore
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    // @ts-ignore
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: process.env.NODE_ENV !== 'production',
      traceLimit: 25,
    })
  }
  return composeEnhancers(applyMiddleware(...middleware))(createStore)
}

export default function configureStore(initialState?: ApplicationState) {
  const store = composeStore()(rootReducer, initialState)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('rootReducer', () => {
      import('rootReducer').then(({ default: nextRootReducer }) => {
        store.replaceReducer(nextRootReducer)
      })
    })
  }

  return store
}
