import React, { Fragment, useMemo, useEffect } from 'react'

import { Icon, Progress, Placeholder } from 'semantic-ui-react'

import { Schedule, Service as ServiceInterface } from 'pages/RechargePage/types'
import TabLink from 'common/TabLink'
import { useResponsive, useParams } from 'common/hooks'
import { getPlanSchedule } from 'pages/RechargePage/reducer'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { getSummmaryByUuid } from 'pages/UsageSummaryPage/reducer'
import { Entitlement, UsageSummary } from 'pages/UsageSummaryPage/types'
import { fetchUsageSummary } from 'pages/UsageSummaryPage/actions'
import { SummaryLoader } from 'pages/UsageSummaryPage'
import { makeLoadingSelector, makeRequestKey } from 'common/Loading/reducer'

interface ServiceProps {
  service: ServiceInterface
  readOnly?: boolean
}

const ServiceContent: React.FC<{ service: ServiceInterface }> = ({ service }) => {
  const { isMobile } = useResponsive()
  const planSchedule = useSelector<ApplicationState, Schedule>(
    (state) => getPlanSchedule(state, service.uuid) as Schedule
  )
  const summary = useSelector<ApplicationState, UsageSummary | undefined>((state) =>
    getSummmaryByUuid(service.uuid)(state)
  )
  const data = useMemo<Entitlement | undefined>(() => {
    if (!summary) return undefined
    if (!summary.plan) return undefined
    return summary.plan.entitlements[0]
  }, [summary])
  const loadingSelector = useMemo(
    () => makeLoadingSelector([makeRequestKey('GET_USAGE_SUMMARY', service.uuid)]),
    [service.uuid]
  )

  const isLoading = useSelector(loadingSelector)

  if (service.is_expired) {
    return (
      <Fragment>
        <p>
          <span>
            <Icon name="warning sign" />
          </span>
          <span>Prepay Plan Expired</span>
        </p>
      </Fragment>
    )
  }

  return (
    <Fragment>
      <p>
        {planSchedule.product.title} - {planSchedule.product.length} Days Prepay Plan
      </p>

      {data ? (
        <Fragment>
          <p>
            <small>
              {data.remainingAllowance.amount}
              {data.remainingAllowance.unit} of {data.totalAllowance.amount}
              {data.totalAllowance.unit} remaining
            </small>
          </p>
          <Progress
            inverted
            color="grey"
            size="small"
            percent={
              ((parseFloat(data.totalAllowance.amount) -
                parseFloat(data.remainingAllowance.amount)) /
                parseFloat(data.totalAllowance.amount)) *
              100
            }
            className="nomargin"
          />
        </Fragment>
      ) : (
        isLoading &&
        isMobile && (
          /* This must be only shown for mobile because the inverted placeholder
        does not support the darker selected state used on desktop */
          <Fragment>
            <Placeholder inverted>
              <Placeholder.Paragraph>
                <Placeholder.Line length="medium" />
                <Placeholder.Line length="full" />
              </Placeholder.Paragraph>
            </Placeholder>
          </Fragment>
        )
      )}
    </Fragment>
  )
}

const Service = ({ service, readOnly }: ServiceProps) => {
  const { isMobile } = useResponsive()
  const dispatch = useDispatch()
  const { uuid } = useParams()

  const selected = uuid === service.uuid

  // This is to trigger summary refresh when switching between services
  useEffect(() => {
    if (selected && !readOnly) {
      dispatch(fetchUsageSummary(service.uuid, false))
    }
  }, [selected, service.uuid, dispatch, readOnly])

  return (
    <TabLink selected={selected} heading={service.phone_number} link={`/services/${service.uuid}/`}>
      {((isMobile && !selected) || !isMobile) && <SummaryLoader uuid={service.uuid} />}
      {(!isMobile || !selected) && <ServiceContent service={service} />}
    </TabLink>
  )
}

export default Service
