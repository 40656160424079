import React, { Fragment } from 'react'
import { Grid } from 'semantic-ui-react'

interface ActionRowProps {
  cancelAction: React.ReactNode
  primaryAction: React.ReactNode
}

const ActionRow: React.FC<ActionRowProps> = ({ cancelAction, primaryAction }) => (
  <Grid stackable reversed="tablet computer" columns={16}>
    <Grid.Row>
      <Grid.Column verticalAlign="middle" textAlign="center" only="mobile" width={16}>
        {primaryAction}
      </Grid.Column>
      <Grid.Column
        verticalAlign="middle"
        textAlign="right"
        only="tablet computer"
        width={cancelAction ? 12 : 16}
      >
        {primaryAction}
      </Grid.Column>
      {cancelAction && (
        <Fragment>
          <Grid.Column verticalAlign="middle" textAlign="center" only="mobile" width={16}>
            {cancelAction}
          </Grid.Column>

          <Grid.Column verticalAlign="middle" textAlign="left" only="tablet computer" width={4}>
            {cancelAction}
          </Grid.Column>
        </Fragment>
      )}
    </Grid.Row>
  </Grid>
)

export default ActionRow
