import React, { Fragment, useCallback } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Message, Icon, Grid } from 'semantic-ui-react'
import { useToggle, useParams } from 'common/hooks'
import LinkButton from 'common/LinkButton'
import { PortingRequest, PortingStatus } from 'common/Porting/types'
import { acknowledgePortingFailure } from 'common/Porting/actions'

const PORTING_FAQ = 'https://www.koganmobile.co.nz/porting-failed/'

interface InfoBarProps {
  portingRequest: PortingRequest
  acknowledgeFailure: (msisdn: string) => void
}

const SlimColumn = styled(Grid.Column)`
  &&& {
    padding: 0 !important;
  }
`

const InfoBar = ({ portingRequest, acknowledgeFailure }: InfoBarProps) => {
  const showFailureInitially = !portingRequest.failure_acknowledged_at

  const [showFailureMessage, toggleFailureMessage] = useToggle(showFailureInitially)
  const { uuid } = useParams()

  const dismissFailureMessage = useCallback(() => {
    acknowledgeFailure(uuid)
    toggleFailureMessage()
  }, [uuid])

  return (
    <Fragment>
      {(portingRequest.status === PortingStatus.SUBMITTED ||
        portingRequest.status === PortingStatus.NEW) && (
        <Message color="grey">
          <Grid>
            <SlimColumn width={1} verticalAlign="middle" textAlign="center">
              <Icon color="teal" name="exchange" />
            </SlimColumn>
            <SlimColumn width={14}>
              Your request to Transfer (Port) the Number you want to bring is currently being
              processed. The process usually takes 4 hours during standard business hours, but can
              take longer on rare occasions. You will receive an email once your Existing Phone
              Number has been transferred to this Kogan Mobile Prepay Plan. For more information,
              please refer to our{' '}
              <LinkButton
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.koganmobile.co.nz/porting-faq/"
                isAnchor
              >
                FAQ
              </LinkButton>{' '}
              about Transferring (Porting) the Number you want to bring.
            </SlimColumn>
          </Grid>
        </Message>
      )}
      {
        /* Failure message hasn't been decided yet */
        portingRequest.status === PortingStatus.FAILED && showFailureMessage && (
          <Message color="grey">
            <Grid>
              <SlimColumn width={1} verticalAlign="middle" textAlign="center">
                <Icon color="red" name="warning sign" />
              </SlimColumn>
              <SlimColumn width={12}>
                Sorry, your request to Transfer (Port) your Existing Phone Number to Kogan Mobile
                has failed. Please try again or check the{' '}
                <a href={PORTING_FAQ} target="_blank" rel="noopener noreferrer">
                  FAQ
                </a>{' '}
                for instructions on what to do next.
              </SlimColumn>
              <SlimColumn width={3} verticalAlign="middle" textAlign="center">
                <LinkButton to="port/">Try Again</LinkButton>
                {'   '}
                <Icon size="large" color="grey" name="close" onClick={dismissFailureMessage} />
              </SlimColumn>
            </Grid>
          </Message>
        )
      }
    </Fragment>
  )
}

export default connect(null, {
  acknowledgeFailure: acknowledgePortingFailure,
})(InfoBar)
