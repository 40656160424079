import { FormActions, SuccessAction, FailAction } from 'common/Form/actions'
import { LOGIN_FORM_ID } from 'pages/LoginPage/LoginForm'
import { PASSWORD_RESET_FORM_ID } from 'pages/ForgotPasswordPage/ForgotPasswordForm'

export interface PasswordResetState {
  resetEmail?: string
}

export const initialState: PasswordResetState = {}

export default function reducer(
  state = initialState,
  action: SuccessAction | FailAction
): PasswordResetState {
  switch (action.type) {
    case FormActions.FORM_FAILURE:
      if (action.payload.id === LOGIN_FORM_ID) {
        return {
          ...state,
          resetEmail: action.payload.formData.username,
        }
      }
      return state
    case FormActions.FORM_SUCCESS:
      if (action.payload.id === PASSWORD_RESET_FORM_ID) {
        return {
          ...state,
          resetEmail: action.payload.formData.email,
        }
      }
      return state
    default:
      return state
  }
}
