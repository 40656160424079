import React from 'react'
import Layout from 'common/Layout'
import AddonCheckout from './AddonCheckout'

const PurchaseAddonsPage: React.FC<{}> = () => {
  return (
    <Layout.Page form="two wide">
      <AddonCheckout />
    </Layout.Page>
  )
}

export default PurchaseAddonsPage
