import React, { Fragment } from 'react'
import { User } from 'common/UserContainer/reducer'
import { BodyText1 } from 'common/Typography'
import { Link } from 'react-router-dom'
import { Header, Segment } from 'semantic-ui-react'

interface Props {
  user: User
}

const ConfirmPhoneChange = ({ user }: Props) => (
  <Fragment>
    <Header as="h1">Confirm Change</Header>
    <BodyText1>
      To confirm your contact phone number change, an email has been sent to {user.email}.
    </BodyText1>
    <BodyText1>
      <strong>Click the link in the email to confirm your new contact phone number.</strong>
    </BodyText1>
    <p>Please note: Your contact phone number will remain the same until you confirm the change.</p>
    <Segment basic textAlign="center">
      <strong>
        <Link to="/">BACK TO DASHBOARD</Link>
      </strong>
    </Segment>
  </Fragment>
)

export default ConfirmPhoneChange
