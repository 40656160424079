import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { Menu, Accordion, Dropdown } from 'semantic-ui-react'
import { MenuSectionInterface } from 'common/Layout/reducer'

export function translateColumn(section: MenuSectionInterface): JSX.Element[] {
  return section.value.links.map((link) => {
    if (link.type === 'internal_link') {
      return <Menu.Item key={link.id} as={Link} to={link.value.path} content={link.value.title} />
    }
    return (
      <Menu.Item
        key={link.id}
        as="a"
        href={link.value.url}
        content={link.value.title}
        target="_blank"
        rel="noopener noreferrer"
      />
    )
  })
}

export function renderMobileDropdownSection(section: MenuSectionInterface): JSX.Element {
  const columns = <Fragment key={section.id}>{translateColumn(section)}</Fragment>
  return section.value.heading === '' ? (
    columns
  ) : (
    <Accordion
      as={Menu}
      key={section.id}
      vertical
      inverted
      fluid
      size="huge"
      panels={[
        {
          key: section.id,
          title: section.value.heading,
          content: { content: columns },
        },
      ]}
    />
  )
}

export function renderDesktopDropdownSection(section: MenuSectionInterface): JSX.Element {
  const columns = <Fragment key={section.id}>{translateColumn(section)}</Fragment>
  return section.value.heading === '' ? (
    columns
  ) : (
    <Dropdown simple item key={section.id} trigger={section.value.heading}>
      <Dropdown.Menu as={Menu}>{columns}</Dropdown.Menu>
    </Dropdown>
  )
}
