import { combineReducers } from 'redux'
import forms, { FormState, initialState as initialFormState } from 'common/Form/reducer'
import user, {
  UserReducerState,
  initialState as initialUserState,
} from 'common/UserContainer/reducer'
import loading, {
  LoadingReducerState,
  initialState as initialLoadingState,
} from 'common/Loading/reducer'
import passwordReset, {
  PasswordResetState,
  initialState as initialPasswordResetState,
} from 'common/PasswordReset/reducer'
import layout, { ContentState, initialState as initialContentState } from 'common/Layout/reducer'
import checkout, {
  CheckoutState,
  initialState as initialCheckoutState,
} from 'pages/CheckoutPage/reducer'
import activation, {
  ActivationReducerState,
  initialActivationState,
} from 'pages/ActivatePage/reducer'
import services, { initialServiceState } from 'pages/RechargePage/reducer'
import products, { initialProductState } from 'common/Products/reducer'
import dashboard, { initialDashboardState } from 'common/Dashboard/reducer'
import router, { initialRouterState, RouterState } from 'common/Routes/reducer'
import { ProductsReducerState } from 'common/Products/types'
import { ServicesReducerState } from 'pages/RechargePage/types'
import { DashboardState } from 'common/Dashboard/types'

export interface ApplicationState {
  forms: FormState
  user: UserReducerState
  loading: LoadingReducerState
  passwordReset: PasswordResetState
  layout: ContentState
  checkout: CheckoutState
  activation: ActivationReducerState
  services: ServicesReducerState
  products: ProductsReducerState
  dashboard: DashboardState
  router: RouterState
}

export const initialApplicationState = {
  forms: initialFormState,
  user: initialUserState,
  loading: initialLoadingState,
  passwordReset: initialPasswordResetState,
  layout: initialContentState,
  checkout: initialCheckoutState,
  services: initialServiceState,
  products: initialProductState,
  activation: initialActivationState,
  dashboard: initialDashboardState,
  router: initialRouterState,
}

export default combineReducers<ApplicationState>({
  forms,
  user,
  loading,
  passwordReset,
  layout,
  checkout,
  services,
  products,
  activation,
  dashboard,
  router,
})
