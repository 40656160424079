import { ActivationState } from 'pages/ActivatePage/types'
import { Stage } from 'common/StageHeader'

export const STAGES: Stage[] = [
  { title: 'SIM Pack Details' },
  { title: 'Personal Details' },
  { title: 'Prepay Plan Details' },
  { title: 'Payment Details' },
]

export const PORTING_STAGES: Stage[] = [
  { title: 'Number Transfer Details' },
  { title: 'Personal Details' },
  { title: 'Prepay Plan Details' },
  { title: 'Payment Details' },
]

export const STAGE_MAPPINGS = new Map<ActivationState, string[]>([
  [null, ['/activate/']],
  ['PORTING_ENTERED', ['/activate/porting']],
  ['CREATED', ['/activate/signup/']],
  ['DETAILS_ENTERED', ['/activate/method/']],
  ['METHOD_ENTERED', ['/activate/plans/']],
  ['PAYMENT', ['/activate/checkout/']],
  ['ACTIVATING', ['/activate/processing/']],
  ['ERROR_CAN_RETRY', ['/activate/error/']],
  ['SUCCESSFUL', ['/activate/success/']],
])
