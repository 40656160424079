import React, { Fragment } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Store } from 'redux'
import { Provider } from 'react-redux'
import HomePage from 'pages/HomePage'
import AccountPage from 'pages/AccountPage'
import LoginPage from 'pages/LoginPage'
import ForgotPasswordPage from 'pages/ForgotPasswordPage'
import PasswordResetPage from 'pages/PasswordResetPage'
import VerifyPage from 'pages/VerifyPage'
import NotFoundPage from 'pages/NotFoundPage'
import RechargePage from 'pages/RechargePage'
import StyleguidePage from 'pages/StyleguidePage'
import ActivatePage from 'pages/ActivatePage'
import PrivateRoute from 'common/Routes/PrivateRoute'
import { RedirectOnLogin } from 'common/Routes/SaveRedirect'
import ScrollToTop from 'common/ScrollToTop'
import AnonymousRoute from 'common/Routes/AnonymousRoute'
import UnverifiedPrivateRoute from 'common/Routes/UnverifiedPrivateRoute'
import FetchInitialUser from 'common/UserContainer/FetchInitialUser'
import RechargeSuccessPage, { AddonsSuccessPage } from 'pages/RechargePage/SuccessPage'
import PurchaseAddonsPage from 'pages/PurchaseAddonsPage'
import PortingServicePage from 'pages/PortingServicePage'
import PortingSuccessPage from 'pages/PortingServicePage/SuccessPage'
import RechargeOrActivatePage from 'pages/RechargeOrActivatePage'
import { TrackingRoute, SegmentUserTracking } from './segment'

const rechargePaths = [
  '/services/renew/:paymentMethod(voucher)/:code/',
  '/services/renew/:shortId/:paymentMethod(voucher)/:code/',
  '/services/renew/:shortId/:paymentMethod(voucher|plan)/',
  '/services/renew/:shortId/',
]

// HACK: Required for iOS to close dropdowns on blur
// as iOS does not fire blur events unless you click on a 'clickable' element
// Answer: Make everything clickable!
const nullClickHandler = () => null

const App = ({ store }: { store: Store }) => (
  /* eslint-disable-next-line */
  <div onClick={nullClickHandler}>
    <Provider store={store}>
      <FetchInitialUser />
      <SegmentUserTracking />
      <BrowserRouter>
        <Fragment>
          <RedirectOnLogin />
          <TrackingRoute />
          <ScrollToTop />
          <Switch>
            <AnonymousRoute exact path="/forgot-password/" component={ForgotPasswordPage} />
            <AnonymousRoute
              exact
              path="/password-reset/:uid64/:token"
              component={PasswordResetPage}
            />
            <UnverifiedPrivateRoute
              allowVerified
              path="/account/contact-details/change-email/"
              component={AccountPage}
            />
            <PrivateRoute path="/account/" component={AccountPage} />
            <AnonymousRoute exact path="/login/" component={LoginPage} />
            <UnverifiedPrivateRoute exact path="/verify/" component={VerifyPage} />
            <PrivateRoute exact path="/services/:uuid/addons/" component={PurchaseAddonsPage} />
            <PrivateRoute exact path="/services/:uuid/port/" component={PortingServicePage} />
            <PrivateRoute
              exact
              path="/services/:uuid/port/success/"
              component={PortingSuccessPage}
            />
            <PrivateRoute
              exact
              path="/services/:uuid/addons/success/"
              component={AddonsSuccessPage}
            />
            <PrivateRoute exact path="/services/renew/success/" component={RechargeSuccessPage} />
            {rechargePaths.map((path) => (
              <PrivateRoute exact path={path} key={path} component={RechargePage} />
            ))}
            {/* A common mistype */}
            <PrivateRoute exact path="/services/renew/" component={NotFoundPage} />
            <PrivateRoute path="/services/:uuid/" component={HomePage} />
            <PrivateRoute exact path="/services/renew/" component={RechargePage} />
            <Route exact path="/styleguide/" component={StyleguidePage} />
            <Route path="/activate/" component={ActivatePage} />
            <Route exact path="/use-voucher/:code/" component={RechargeOrActivatePage} />
            <PrivateRoute exact path="/" component={HomePage} />

            <Route component={NotFoundPage} />
          </Switch>
        </Fragment>
      </BrowserRouter>
    </Provider>
  </div>
)

export default App
