import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Grid, Progress, Segment, Header, Button, Divider } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import { chunk, formatDate } from 'common/Utils'
import { useParams, useResponsive } from 'common/hooks'
import { Addons, Entitlement } from '../types'
import { calculateUsedPercentage } from '../utils'

const CustomRow = styled(Grid.Row)`
  &&& {
    padding: 3.5px 0;
    align-items: center;
  }
`

const MessageRow = styled(Grid.Row)`
  &&& {
    padding-top: 0;
    margin-top: -0.5em;
  }
`

const CustomCol = styled(Grid.Column)`
  &&&& {
    padding-right: 0;
  }
`

const SubHeader = styled(Header.Subheader)`
  &&&&& {
    font-weight: normal;
    font-size: inherit;
    margin-top: 0.5em;
  }
`

const EntitlementEntry = ({ entitlements }: { entitlements: Entitlement[] }) => {
  return (
    <Fragment>
      {entitlements.map((entitlement) => (
        <CustomRow key={entitlement.name}>
          <CustomCol mobile={11} tablet={8} largeScreen={9} widescreen={9}>
            <Progress
              className="nomargin"
              color="teal"
              size={entitlements.length > 1 ? 'small' : undefined}
              percent={calculateUsedPercentage(
                entitlement.totalAllowance.amount,
                entitlement.remainingAllowance.amount
              )}
            />
          </CustomCol>
          <CustomCol mobile={5} tablet={8} largeScreen={7} widescreen={7}>
            {entitlement.remainingAllowance.amount}
            {entitlement.remainingAllowance.unit !== 'GB' && ' '}
            {entitlement.remainingAllowance.unit} remaining
          </CustomCol>
        </CustomRow>
      ))}
    </Fragment>
  )
}

const AddonCard = ({ addon }: { addon: Addons }) => {
  const endDate = addon.expiry_date || undefined

  return (
    <Grid.Column>
      <Segment className="noshadow">
        <Header as="h3">
          {addon.name}
          {endDate && <SubHeader>Expires on: {formatDate(new Date(endDate))}</SubHeader>}
        </Header>
        <Divider hidden />
        <Grid>
          <EntitlementEntry entitlements={addon.entitlements} />
        </Grid>
        <Divider hidden />
      </Segment>
    </Grid.Column>
  )
}

const keyPerRows = (addonsPerRow: Addons[]) => {
  let sumid = 0
  addonsPerRow.forEach((addon) => {
    sumid += Number(addon.id)
  })
  return sumid
}

const AddonTable = ({ addons, apiDown }: { addons: Addons[]; apiDown: boolean }) => {
  // Chunk Addons into a smaller list with length of 3
  // so that we can place it in the row of 3 cols
  const { isTablet, isMobile: onlyMobile } = useResponsive()
  const rowSize = isTablet ? 2 : 3
  const addonsPerRows = chunk(addons, rowSize)
  const { uuid } = useParams()
  const isMobile = isTablet || onlyMobile
  return (
    <Grid stackable columns={2}>
      <Grid.Row>
        <Grid.Column>
          <Header as="h2">Add-Ons</Header>
        </Grid.Column>
        {!isMobile && (
          <Grid.Column textAlign="right">
            <Button size="small" as={Link} to={`/services/${uuid}/addons/`} basic color="teal">
              PURCHASE ADD-ONS
            </Button>
          </Grid.Column>
        )}
      </Grid.Row>
      {apiDown && (
        <MessageRow>
          <Grid.Column>
            <p>Your Add-On usage is not available at the moment</p>
          </Grid.Column>
        </MessageRow>
      )}
      {addonsPerRows.map((addonsRow) => (
        <Grid.Row columns={rowSize} key={keyPerRows(addonsRow)}>
          {addonsRow.map((addon: Addons) => (
            <AddonCard key={addon.id} addon={addon} />
          ))}
        </Grid.Row>
      ))}
      {isMobile && (
        <Grid.Column width={16}>
          <Button as={Link} to={`/services/${uuid}/addons/`} basic fluid color="teal">
            PURCHASE ADD-ONS
          </Button>
        </Grid.Column>
      )}
    </Grid>
  )
}

export default AddonTable
