import { FormActions, SuccessAction as FormSuccessAction } from 'common/Form/actions'
import { Product } from 'common/Products/types'
import { ApplicationState } from 'rootReducer'

import { Actions, ActivateActions, VoucherActionTypes, GetVoucherSuccessAction } from './actions'
import { ActivationCheckoutInterface, ActivationState, Voucher } from './types'

export interface ActivationReducerState {
  checkout?: ActivationCheckoutInterface
  vouchers: Voucher[]
  currentVoucherCode?: string
}

export const getVoucherProduct = (state: ApplicationState): Product | undefined => {
  if (!state.activation.currentVoucherCode) return undefined
  const voucher = state.activation.vouchers.find(
    (v) => v.code === state.activation.currentVoucherCode
  )
  if (voucher === undefined) {
    // voucher not present, still loading
    return undefined
  }
  return voucher.product
}

export const getActivationProduct = (state: ApplicationState): Product | undefined => {
  if (state.activation && state.activation.checkout) return state.activation.checkout.product
  return undefined
}

export const usingVoucher = (state: ApplicationState): boolean => {
  if (state.activation && state.activation.checkout)
    return !!state.activation.checkout.using_voucher
  return false
}

export const initialActivationState = { vouchers: [] }

export default function reducer(
  state: ActivationReducerState = initialActivationState,
  action: ActivateActions | FormSuccessAction | GetVoucherSuccessAction
): ActivationReducerState {
  switch (action.type) {
    case Actions.GET_ACTIVATION_STATE_SUCCESS:
    case Actions.ACTIVATION_POLL_SUCCESS:
      return {
        ...state,
        checkout: action.payload.data,
      }
    case Actions.GET_ACTIVATION_STATE_FAILURE:
      return {
        ...state,
        checkout: undefined,
      }
    case Actions.ACTIVATION_GO_BACK:
      if (!state.checkout || !action.payload) {
        return {
          ...state,
          checkout: undefined,
        }
      }
      return {
        ...state,
        checkout: {
          ...state.checkout,
          state: action.payload as ActivationState,
        },
      }
    case VoucherActionTypes.LOAD_VOUCHER_SUCCESS:
      return {
        ...state,
        vouchers: [...state.vouchers, action.payload as Voucher],
      }
    case VoucherActionTypes.LOAD_VOUCHER_REQUEST:
      return {
        ...state,
        currentVoucherCode: action.payload as string,
      }
    case FormActions.FORM_SUCCESS: {
      // Hook into the form submits and update with the response data
      if (action.payload.id.startsWith('activate_')) {
        return {
          ...state,
          checkout: action.payload.data,
        }
      }
      return state
    }

    default:
      return state
  }
}
