import { ApplicationState } from 'rootReducer'
import { Uuid } from 'src/types'

import { PurchaseHistory } from './types'

// eslint-disable-next-line import/prefer-default-export
export const getPurchaseHistoryByUuid = (uuid: Uuid) => (
  state: ApplicationState
): PurchaseHistory[] | undefined => {
  if (state.dashboard.byUuid[uuid]) return state.dashboard.byUuid[uuid].purchase_history
  return undefined
}
