import React, { useCallback } from 'react'
import { Redirect, RouteProps, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { ApplicationState } from 'rootReducer'
import { makeLoadingSelector } from 'common/Loading/reducer'
import LoadingPage from 'pages/LoadingPage'
import SaveRedirect from 'common/Routes/SaveRedirect'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>
  emailVerified: boolean
  authenticated: boolean
  isLoading?: boolean
}

export const PrivateRouteComponent = ({
  authenticated,
  emailVerified,
  component: Component,
  isLoading,

  ...rest
}: PrivateRouteProps) => {
  const renderComponent = useCallback((): React.ReactNode => {
    if (isLoading) {
      return <LoadingPage />
    }
    if (authenticated && !emailVerified) {
      return <Redirect to="/verify/" />
    }
    if (!authenticated) {
      return <SaveRedirect to="/login/" />
    }
    return <Component />
  }, [isLoading, authenticated, emailVerified, Component])

  return <Route {...rest} render={renderComponent} />
}

PrivateRouteComponent.defaultProps = { isLoading: false }
PrivateRouteComponent.displayName = 'PrivateRouteComponent'

const loadingSelector = makeLoadingSelector(['USER_AUTHENTICATED'])
export const mapStateToProps = (state: ApplicationState) => ({
  authenticated: state.user.authenticated,
  emailVerified: state.user.user !== null && state.user.user.email_verified,
  isLoading: !state.user.fetched || loadingSelector(state),
})

export default connect(mapStateToProps)(PrivateRouteComponent)
