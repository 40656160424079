import React, { Fragment } from 'react'
import styled from 'styled-components'

export const getPriceParts = (price: number) => {
  const equivalentPrice = price.toFixed(2)
  const equivalentDollars = equivalentPrice.toString().split('.')[0]
  const equivalentCents = equivalentPrice.toString().split('.')[1]
  return [equivalentDollars, equivalentCents]
}

// Not a special value.
const Small = styled.small`
  font-size: 58.33%;
`

export const Dollars = styled.span`
  vertical-align: middle;
  margin: 0 2px;
`

const Cents = styled(Small)`
  vertical-align: top;
`

const FormattedCurrency = ({ value }: { value: number }) => {
  const [dollars, cents] = getPriceParts(value)
  return (
    <Fragment>
      <Small>$</Small>
      <Dollars>{dollars}</Dollars>
      <Cents>.{cents}</Cents>
    </Fragment>
  )
}

export default FormattedCurrency
