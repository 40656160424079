import React, { ReactNode, useMemo } from 'react'
import { SemanticTRANSITIONS, TransitionPropDuration } from 'semantic-ui-react'
import { Switch as RouterSwitch, Route, RouteProps } from 'react-router-dom'
import AnimatedTransition from 'common/AnimatedTransition'

interface Props {
  animation?: SemanticTRANSITIONS
  duration?: number | string | TransitionPropDuration
  children: ReactNode
  transitionOnMount?: boolean
}

const AnimatedSwitch = ({ animation, duration, children, transitionOnMount }: Props) => {
  // Avoid the bind on each render
  const inner = useMemo(
    () => ({ location }: RouteProps) => (
      <AnimatedTransition
        animation={animation}
        duration={duration}
        transitionOnMount={transitionOnMount}
      >
        <div key={location && location.pathname}>
          <RouterSwitch location={location}>{children}</RouterSwitch>
        </div>
      </AnimatedTransition>
    ),
    [children, duration, animation]
  )

  return <Route render={inner} />
}

export default AnimatedSwitch
