import React, { Fragment } from 'react'
import { Header, Button, Segment, Message, TransitionGroup, Grid } from 'semantic-ui-react'
import { BodyText1 } from 'common/Typography'
import Layout from 'common/Layout'
import UserContainer from 'common/UserContainer'
import VerificationEmailContainer from './VerificationEmailContainer'

const ResendVerificationLink = () => (
  <VerificationEmailContainer>
    {({ isSending, sendVerification, success }) => (
      <Fragment>
        <Grid padded="vertically" stretched>
          <Grid.Row>
            <Grid.Column width={10} floated="left" verticalAlign="middle">
              <strong>Didn&apos;t receive the email?</strong>
            </Grid.Column>
            <Grid.Column width={6} floated="right">
              <Button
                inverted
                fluid
                color="red"
                onClick={!isSending ? sendVerification : undefined}
                loading={isSending}
              >
                RESEND
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <TransitionGroup animation="fade" duration={{ hide: 0, show: 300 }}>
          {success && (
            <div>
              {/* Hack to keep the margin. It cant be the first child. */}
              <div />
              <Message
                success
                size="tiny"
                content="Email resent. Please check your inbox."
                icon="checkmark"
              />
            </div>
          )}
        </TransitionGroup>
      </Fragment>
    )}
  </VerificationEmailContainer>
)

const VerifyPage = () => (
  <Layout.Page form>
    <Segment vertical>
      <Header as="h1" textAlign="left">
        Confirm Your Email Address
      </Header>
      <UserContainer>
        {({ user }) => (
          <BodyText1>
            A confirmation email has been sent to {user ? <strong>{user.email}</strong> : 'you'}.
          </BodyText1>
        )}
      </UserContainer>
      <BodyText1>Click the link in the email to confirm your email address.</BodyText1>
    </Segment>
    <ResendVerificationLink />
  </Layout.Page>
)

export default VerifyPage
