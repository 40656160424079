import SuccessConfirmation from 'common/Form/SuccessConfirmation'
import React, { useMemo, useState, useEffect, ReactNode } from 'react'
import { connect } from 'react-redux'
import { InputOnChangeData, SemanticICONS, SemanticSIZES } from 'semantic-ui-react'
import { ApplicationState } from 'rootReducer'
import * as Yup from 'yup'
import * as formActions from './actions'
import BaseForm, { ButtonRenderer, FormRenderProps } from './BaseForm'

export type FieldAutocomplete =
  | 'off'
  | 'email'
  | 'username'
  | 'new-password'
  | 'current-password'
  | 'cc-name'
  | 'cc-number'
  | 'cc-csc'
  | 'cc-exp'

export type FieldInputMode = 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'

export type InputModifier = (input: InputOnChangeData) => InputOnChangeData
export type VariableInputModifier = (param: any) => InputModifier

export interface Option {
  label?: string | JSX.Element
  value: string | number
}

export interface FieldInterface {
  name: string
  label?: string | JSX.Element
  help?: string
  type?: string
  icon?: string | ReactNode
  iconPosition?: 'left'
  placeholder?: string
  required?: boolean
  disabled?: boolean
  maxLength?: number
  masked?: boolean // only used on password fields
  autocomplete?: FieldAutocomplete
  inputMode?: FieldInputMode
  component?: Function
  className?: string
  width?: number
  inputModifier?: InputModifier
  isLoading?: boolean
  options?: Option[]
  checked?: boolean // only used for checked field
}

export interface InformationBlockInterface {
  name: string
  content: string | JSX.Element
}

export type GroupedFieldInterface = FieldInterface | FieldInterface[] | InformationBlockInterface

export interface LinkErrorsInterface {
  actions: {
    url: string
    text: string
  }[]
  message: string
}

export type FieldError = string | LinkErrorsInterface

export type FieldErrorsInterface = FieldError[]

export interface ErrorsInterface {
  [key: string]: FieldErrorsInterface
}

export type FormDataValue = string | number | boolean | string[]

export interface FormData {
  [key: string]: FormDataValue
}

export interface DirtyFields {
  [key: string]: boolean
}

const getDirtyFields = (dirtyFields: DirtyFields): string[] => {
  return Object.keys(dirtyFields).filter((key: string) => dirtyFields[key] === true)
}

interface Props extends DefaultProps {
  id: string
  url: string
  submitButtonIcon?: SemanticICONS
  cancelButtonText?: string
  cancelProps?: object
  successMessage?: string
  renderSuccess?: (responseData: any, responseStatus: number) => JSX.Element | null | void
  validationSchema?: Yup.ObjectSchema<{}>
  size?: SemanticSIZES
  initialValue?: FormData
  // Used as a key to reset the form when the database is updated.
  // If not set, the form will retain the state from last submit.
  resetKey?: string
  responseData?: any
  responseStatus?: number
  headerSection?: ReactNode
  children?: (renderProps: FormRenderProps) => JSX.Element
  fields?: GroupedFieldInterface[]
  onFocus?: Function
  renderSubmitButton?: React.FC<ButtonRenderer>
}

interface DefaultProps {
  submitButtonText?: string
  transformData: (data: FormData) => FormData
  // Redux state
  success?: boolean
  isSubmitting: boolean
  isDisabled: boolean
  errors: ErrorsInterface
  data: FormData
  dirtyFields: { [key: string]: boolean }
  // Redux actions
  reset(id: string): void
  submit(id: string, url: string, data: FormData): void
  setFieldError(id: string, errors: ErrorsInterface): void
  clearFieldError(id: string, name: string): void
  onChange(id: string, name: string, value: FormDataValue): void
  setFormValues(id: string, arg1: { [key: string]: FormDataValue }): void
}

export const FormContext = React.createContext<FormSubmitSignature[]>([])

export type FormSubmitSignature = (
  data: any,
  errors: ErrorsInterface,
  dirtyFields: string[]
) => { data: FormData; errors: ErrorsInterface }

export const uppercaseModifier: InputModifier = ({ value, ...rest }) => {
  return { ...rest, value: value.replace(/\s/gi, '').toLocaleUpperCase() }
}

const PHONE_CHARS = new RegExp(/[+\d ()-]/)
export const phoneNumberModifier: InputModifier = ({ value, ...rest }) => {
  return {
    ...rest,
    value: value
      .split('')
      .filter((char) => PHONE_CHARS.exec(char))
      .join(''),
  }
}

const NUMERIC_CHARS = new RegExp(/[\d]/)
export const numericModifier: InputModifier = ({ value, ...rest }) => {
  return {
    ...rest,
    value: value
      .split('')
      .filter((char) => NUMERIC_CHARS.exec(char))
      .join(''),
  }
}

const ALPHANUMERIC_CHARS = new RegExp(/[a-z0-9]/i)
export const alphaNumericModifier: InputModifier = ({ value, ...rest }) => {
  return {
    ...rest,
    value: value
      .split('')
      .filter((char) => ALPHANUMERIC_CHARS.exec(char))
      .join(''),
  }
}

export const maxLengthModifier: VariableInputModifier = (max) => ({ value, ...rest }) => {
  return {
    ...rest,
    value: value.substr(0, max),
  }
}

const ConnectedForm = (props: Props) => {
  const { data, errors, id } = props
  const [submitHandlers] = useState<FormSubmitSignature[]>([])

  // set initialValues if we have any on load, reset form on unload
  useEffect(() => {
    const { initialValue, setFormValues } = props
    if (initialValue) setFormValues(id, initialValue)

    return () => {
      const { reset } = props
      reset(id)
    }
  }, [id])

  const onChange = useMemo(
    () => (name: string, value: string | number) => {
      const { onChange: parentOnChange, clearFieldError } = props
      parentOnChange(id, name, value)
      if (errors[name]) clearFieldError(id, name)
    },
    // can be further optimised by having a `getErrors` function instead of passing in errors directly
    [errors, id]
  )

  const onSubmit = useMemo(
    () => () => {
      const { submit, setFieldError, url, dirtyFields, transformData } = props
      const { errors: newErrors, data: newData } = submitHandlers.reduce(
        (
          currentData: { data: FormData; errors: ErrorsInterface },
          handler: FormSubmitSignature
        ) => {
          return handler(currentData.data, currentData.errors, getDirtyFields(dirtyFields))
        },
        { errors: {}, data }
      )
      if (Object.keys(newErrors).length > 0) {
        setFieldError(id, newErrors)
      } else {
        const submittedData = transformData(newData)
        submit(id, url, submittedData)
      }
    },
    // same as above, create a `getData` function
    [data, id]
  )

  const onBlur = useMemo(
    () => (name: string, value: string | number, formData: FormData) => {
      const { validationSchema, clearFieldError, setFieldError } = props
      if (!validationSchema) return
      if (!value) return

      Yup.reach(validationSchema, name)
        .validate(value, { context: formData })
        .then(() => {
          clearFieldError(id, name)
        })
        .catch((err) => {
          setFieldError(id, { [name]: err.errors })
        })
    },
    [id]
  )

  const {
    isSubmitting,
    isDisabled,
    success,
    renderSuccess,
    children,
    dirtyFields,
    successMessage,
    resetKey,
    responseData,
    responseStatus,
    renderSubmitButton,
    submit: _,
    reset: _1,
    validationSchema: _2,
    setFieldError: _3,
    clearFieldError: _4,
    onChange: _5,
    setFormValues: _6,
    data: _7,
    transformData: _8,
    ...rest
  } = props

  if (success) {
    if (renderSuccess) {
      const result = renderSuccess(responseData, responseStatus as number)
      // Only render if it returned something
      if (result) return result
    }
    if (successMessage) return <SuccessConfirmation>{successMessage}</SuccessConfirmation>
  }

  return (
    <FormContext.Provider value={submitHandlers}>
      <BaseForm
        key={resetKey}
        onSubmit={onSubmit}
        errors={errors}
        isDisabled={isDisabled}
        isSubmitting={isSubmitting}
        onBlur={onBlur}
        onChange={onChange}
        formData={data}
        dirtyFields={dirtyFields}
        renderSubmitButton={renderSubmitButton}
        {...rest}
      >
        {children}
      </BaseForm>
    </FormContext.Provider>
  )
}

const defaultProps: DefaultProps = {
  data: {},
  errors: {},
  isSubmitting: false,
  isDisabled: false,
  submitButtonText: 'Submit',
  success: false,
  dirtyFields: {},
  transformData: (data) => data,
  submit: () => null,
  reset: () => null,
  setFieldError: () => null,
  clearFieldError: () => null,
  onChange: () => null,
  setFormValues: () => null,
}

ConnectedForm.defaultProps = defaultProps

function mapStateToProps(state: ApplicationState, props: Props) {
  return state.forms[props.id] || {}
}

export default connect(
  mapStateToProps,
  {
    submit: formActions.submit,
    reset: formActions.reset,
    onChange: formActions.onChange,
    setFieldError: formActions.setFieldError,
    clearFieldError: formActions.clearFieldError,
    setFormValues: formActions.setFormValues,
  },
  (stateProps, dispatchProps, ownProps) => {
    // Redefine the priority of props, so forms can override state
    return {
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
    }
  }
)(ConnectedForm)
