import React, { Fragment } from 'react'
import styled from 'styled-components'
import { Responsive, Form } from 'semantic-ui-react'
import * as Yup from 'yup'
import {
  GroupedFieldInterface,
  FormSubmitSignature,
  InputModifier,
  numericModifier,
  alphaNumericModifier,
  maxLengthModifier,
  phoneNumberModifier,
} from 'common/Form'
import SubmitIntercept from 'common/Form/SubmitIntercept'
import SimInfo from 'common/Porting/SimInfo'
import PortHelpButton from 'common/Porting/Forms/PortHelpButton'
import ProvidersDropdown from 'common/Porting/Forms/ProvidersDropdown'

const SmallP = styled.p`
  font-size: 0.8rem;
  margin-top: 1em;
  font-weight: normal;
`
const AccountNumberLabel = (
  <Fragment>
    <strong>Account Number</strong>
    <SmallP>You can find the account number on your bill</SmallP>
  </Fragment>
)

export enum BILLING_METHODS {
  PREPAY = 1,
  ACCOUNT = 2,
}

export const enterSimPreSubmit: FormSubmitSignature = (data, errors) => {
  const { setup_porting } = data

  let newErrors = { ...errors }

  // setup_porting is boolean, it's undefined when not selected
  if (setup_porting === undefined) {
    newErrors = {
      ...newErrors,
      setup_porting: [
        'Please choose whether you would like to Transfer (Port) your Existing Phone ' +
          'Number or use the Phone Number on the SIM pack.',
      ],
    }
  }

  return { data, errors: newErrors }
}

const portingPreSubmit: FormSubmitSignature = (data, errors) => {
  const { billing_method, account_number, prepaid_sim_number } = data

  let newErrors = { ...errors }

  if (!billing_method) {
    newErrors = {
      ...newErrors,
      billing_method: [
        'You must provide an Existing Prepay SIM Number or an Existing Account ' +
          'Number to Transfer (Port) the Phone Number you want to bring.',
      ],
    }
  }

  if (billing_method === BILLING_METHODS.PREPAY && !prepaid_sim_number) {
    newErrors = {
      ...newErrors,
      prepaid_sim_number: [
        'Please provide an Existing Prepay SIM Number to Transfer (Port) the Number you want to bring.',
      ],
    }
  }

  if (billing_method === BILLING_METHODS.ACCOUNT && !account_number) {
    newErrors = {
      ...newErrors,
      account_number: [
        'Please provide an Existing Account Number to Transfer (Port) the Phone Number you want to bring.',
      ],
    }
  }

  return {
    data,
    errors: newErrors,
  }
}

const bottomFields: GroupedFieldInterface[] = [
  {
    name: 'porting_help',
    content: (
      <>
        <Form.Field label="Porting Details"></Form.Field>
        <SmallP>
          <ol>
            <li>
              Ensure your old SIM card is inserted until you receive a mobile port confirmation text
              from 2542
            </li>
            <li>
              Confirm the port by replying &apos;Yes&apos; within 2 hours of receiving the text
            </li>
            <li>You can now insert your new Kogan Mobile SIM card!</li>
          </ol>
          Transferring (Porting) the Number you want to bring usually takes 4 hours during standard
          business hours, but can take longer on rare occasions. Your old service will be
          automatically disconnected. For more information, please refer to our{' '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.koganmobile.co.nz/porting-faq/"
          >
            FAQ
          </a>{' '}
          about Transferring (Porting) the Number you want to bring.
        </SmallP>
      </>
    ),
  },
]

const fields: GroupedFieldInterface[] = [
  {
    label: 'Number you want to bring',
    name: 'msisdn',
    required: true,
    type: 'tel',
    inputModifier: phoneNumberModifier,
  },
  {
    label: "Who you're leaving",
    name: 'provider',
    required: true,
    component: ProvidersDropdown,
  },
  {
    label: 'How do you pay them?',
    name: 'billing_method',
    required: true,
    options: [
      {
        label: 'Prepay',
        value: 1,
      },
      {
        label: 'Pay Monthly (Account)',
        value: 2,
      },
    ],
    type: 'radio_group',
  },
  {
    name: 'porting-validation-hidden',
    component: () => <SubmitIntercept handler={portingPreSubmit} />,
  },
]

const portingMaxLengthModifier = maxLengthModifier(20)
const maxNumericModifier: InputModifier = (input) => {
  let next = numericModifier(input)
  next = portingMaxLengthModifier(next)
  return next
}

const maxAlphaNumericModifier: InputModifier = (input) => {
  let next = alphaNumericModifier(input)
  next = portingMaxLengthModifier(next)
  return next
}

export const generatePortingFormFields = (
  method: number,
  togglePortHelp: () => void,
  showMobilePortHelp: boolean
) => {
  const portHelpField = {
    name: 'payment_help',
    component: () => (
      <Responsive maxWidth={Responsive.onlyMobile.maxWidth} className="field" key="payment_help">
        {showMobilePortHelp && <SimInfo method={method} />}
      </Responsive>
    ),
  }
  if (method === 1) {
    return [
      ...fields,
      {
        label: 'Old/Previous SIM Serial Number',
        name: 'prepaid_sim_number',
        inputModifier: maxNumericModifier,
        type: 'tel',
      },
      {
        name: 'show_sim_help_button',
        component: () => (
          <Responsive
            maxWidth={Responsive.onlyMobile.maxWidth}
            className="field"
            key="mobile_sim_help_button"
          >
            <PortHelpButton onHelpClick={togglePortHelp} helpText="HOW TO FIND YOUR SIM NUMBER" />
          </Responsive>
        ),
      },
      portHelpField,
      ...bottomFields,
    ]
  }
  if (method === 2) {
    return [
      ...fields,
      {
        label: AccountNumberLabel,
        name: 'account_number',
        inputModifier: maxAlphaNumericModifier,
      },
      {
        name: 'show_sim_help_button',
        component: () => (
          <Responsive
            maxWidth={Responsive.onlyMobile.maxWidth}
            className="field"
            key="mobile_sim_help_button"
          >
            <PortHelpButton
              onHelpClick={togglePortHelp}
              helpText="HOW TO FIND YOUR ACCOUNT INFORMATION"
            />
          </Responsive>
        ),
      },
      portHelpField,
      ...bottomFields,
    ]
  }
  return [...fields, ...bottomFields]
}

export const portingFormschema = Yup.object({
  msisdn: Yup.string().required('This field is required.'),
  provider: Yup.string().required('This field is required.'),
  prepaid_sim_number: Yup.string().test({
    name: 'prepaid_sim_number_len',
    test: (value: string) => {
      return value.length >= 16 && value.length <= 20
    },
    message: 'Please enter a valid Prepay SIM Number between 16 and 20 digits.',
  }),
  account_number: Yup.string().test({
    name: 'acc_number_len',
    test: (value: string) => {
      return value.length <= 20
    },
    message: 'Please enter a valid Existing Account Number up to 20 digits.',
  }),
})
